import React from 'react';
import config from "../../../config";
import { numberMask, imageParser } from '../../../utils/utils';
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar, Typography } from '@material-ui/core';
import { Points, Flag, Medal } from '../../../components/Images/Images';

export default function ListItemPosition(props) {
    const { item, module, action, userPosition } = props;
    let isDisabled = module && module.ENABLED_SELECT && module.ENABLED_SELECT !== 'Y';
    let haveAvatar = module.IMAGE_USER === "Y";
    let haveFlag = module.FLAG_REGION === "Y";

    return (
        <ListItem
            button={isDisabled || userPosition ? false : true}
            onClick={action}
            className={`list-item-ranking ${userPosition ? "__active" : ""}`}
        >
            <div className="list-item-ranking-position">
                {
                    item.position < 4 ?
                        <Medal type={item.position} className="img-md" />
                        :
                        <Typography variant="h3" component="p">
                            {item.position}
                        </Typography>
                }
            </div>
            {
                haveAvatar ?
                    <ListItemAvatar>
                        <Avatar
                            src={imageParser(item.image)}
                            alt={item.name}
                            className={userPosition ? "avatar-bordered border-white" : ""}
                        />
                    </ListItemAvatar>
                    : null
            }
            <ListItemText
                disableTypography
                primary={
                    <Typography variant="body1" component="h5">
                        {item.name}
                    </Typography>
                }
                secondary={
                    <div className="d-flex flex-row align-items-center">
                        <Points type="xp" className="img-xxs mr-1" />
                        <Typography className="text-xp" variant="body2" component="p">
                            {numberMask(item.qi_score)}
                        </Typography>
                    </div>
                }
            />
            {
                config.ranking.flag.enabled && haveFlag ?
                    config.ranking.flag.type === "states" && (item.country && item.country === "BR") && (item.state && item.state.length === 2) ?
                        <ListItemSecondaryAction>
                            <Flag
                                type="states"
                                code={item.state.toLowerCase()}
                                className="img-xxs"
                            />
                        </ListItemSecondaryAction>
                        :
                        config.ranking.flag.type === "country" && (item.country && item.country.length === 2) ?
                            <ListItemSecondaryAction>
                                <Flag
                                    type="country"
                                    code={item.country.toLowerCase()}
                                    className="img-xxs"
                                />
                            </ListItemSecondaryAction>
                            : null
                    : null
            }
        </ListItem>
    );
}

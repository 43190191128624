import { profileService } from '../../services/profile.service'

// CONST
export const Types = {
    PROFILE: 'profile/PROFILE',
    TRAIL_STATS: 'profile/TRAIL_STATS',
    TRAIL_LIST: 'profile/TRAIL_LIST',
    CLASSROOM_STATS: 'profile/CLASSROOM_STATS',
    CLASSROOM_LIST: 'profile/CLASSROOM_LIST',
    SCORM_STATS: 'profile/SCORM_STATS',
    SCORM_LIST: 'profile/SCORM_LIST',
    PERFORMANCE_STATS: 'profile/PERFORMANCE_STATS',
    PERFORMANCE_LIST: 'profile/PERFORMANCE_LIST',
    RESET: 'profile/RESET',
};

// INITIAL STATE
const initialState = {
    profile: {},
    trailStats: {},
    trailList: {},
    classroomStats: {},
    classroomList: {},
    scormStats: {},
    scormList: {},
    performanceStats: {},
    performanceList: {},
}

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.PROFILE:
            return {
                ...state,
                profile: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.TRAIL_STATS:
            return {
                ...state,
                trailStats: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.TRAIL_LIST:
            return {
                ...state,
                trailList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.CLASSROOM_STATS:
            return {
                ...state,
                classroomStats: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.CLASSROOM_LIST:
            return {
                ...state,
                classroomList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SCORM_STATS:
            return {
                ...state,
                scormStats: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SCORM_LIST:
            return {
                ...state,
                scormList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.PERFORMANCE_STATS:
            return {
                ...state,
                performanceStats: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.PERFORMANCE_LIST:
            return {
                ...state,
                performanceList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.RESET:
            return {
                ...state,
                profile: {},
                trailStats: {},
                trailList: {},
                classroomStats: {},
                classroomList: {},
                scormStats: {},
                scormList: {},
                performanceStats: {},
                performanceList: {},
            }
        default:
            return state
    }
}

// ACTIONS
function getProfile(params) {
    return dispatch => {
        return profileService.getProfile(params).then(data => {
            dispatch({
                type: Types.PROFILE,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PROFILE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTrailStats(params) {
    return dispatch => {
        return profileService.getTrailStats(params).then(data => {
            dispatch({
                type: Types.TRAIL_STATS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TRAIL_STATS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTrailList(params) {
    return dispatch => {
        return profileService.getTrailList(params).then(data => {
            dispatch({
                type: Types.TRAIL_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TRAIL_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getClassroomStats(params) {
    return dispatch => {
        return profileService.getClassroomStats(params).then(data => {
            dispatch({
                type: Types.CLASSROOM_STATS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.CLASSROOM_STATS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getClassroomList(params) {
    return dispatch => {
        return profileService.getClassroomList(params).then(data => {
            dispatch({
                type: Types.CLASSROOM_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.CLASSROOM_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getScormStats(params) {
    return dispatch => {
        return profileService.getScormStats(params).then(data => {
            dispatch({
                type: Types.SCORM_STATS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SCORM_STATS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getScormList(params) {
    return dispatch => {
        return profileService.getScormList(params).then(data => {
            dispatch({
                type: Types.SCORM_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SCORM_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getPerformanceStats(params) {
    return dispatch => {
        return profileService.getPerformanceStats(params).then(data => {
            dispatch({
                type: Types.PERFORMANCE_STATS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PERFORMANCE_STATS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getPerformanceList(params) {
    return dispatch => {
        return profileService.getPerformanceList(params).then(data => {
            dispatch({
                type: Types.PERFORMANCE_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PERFORMANCE_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    getProfile,
    getTrailStats,
    getTrailList,
    getClassroomStats,
    getClassroomList,
    getScormStats,
    getScormList,
    getPerformanceStats,
    getPerformanceList,
    reset
}

import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import LibraryList from './library.list';
import FileList from './file.list';

export default function VirtualLibrary(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.VIRTUAL_LIBRARY && auth.modules.VIRTUAL_LIBRARY.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        auth.authenticated &&
        <Switch>
            <Route path={`${props.match.path}`} exact component={(props) =>
                <LibraryList {...props} />}
            />
            <Route path={`${props.match.path}/:idCategory`} exact component={(props) =>
                <FileList {...props} />
            } />
        </Switch>
    )
};

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import { scormService } from '../../../services/scorm.service';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ScormActions } from '../../../redux/ducks/scorm';
import useReactRouter from 'use-react-router';
import { imageParser } from '../../../utils/utils';
import {
    Grid, Divider, Container, InputBase,
    IconButton, AppBar, Toolbar, Typography
} from '@material-ui/core';
import QrLayout from '../../../components/QrLayout2';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from './components/CategoryItem';
import { LoadingCategoryItem, LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ScormThemesList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const { themes } = useSelector(state => state.scorm);
    const [didMount, setDidMount] = useState(false);
    const [loadingSublist, setLoadingSublist] = useState(true);
    const [sublist, setSublist] = useState([]);
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [filteredScormList, setFilteredScormList] = useState(null);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    let limit = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(ScormActions.getThemesList({}));
        }

        return () => {
            dispatch(ScormActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    useEffect(() => {
        if ((themes.data && themes.data.length !== 0) && loadingSublist) {
            setLoadingSublist(false)
            themes.data.forEach(async item => {
                let list = await scormService.list({
                    id_category: item.id_category,
                    limit: limit
                })
                setSublist(sublist => [...sublist, { id_category: item.id_category, scorms: list.scorms }]);
            });
        }
    }, [themes, loadingSublist, limit]);

    useEffect(() => {
        setFilteredScormList(null);

        async function fetchDataFiltered() {
            let list = await scormService.list({
                filter: search
            });
            setFilteredScormList(list);
        }

        const timer = setTimeout(() => {
            if (search !== "") {
                fetchDataFiltered()
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [search]);

    function goBack() {
        let currentOrigin = "list";

        switch (origin) {
            case 'scorm_list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('SCORM_TITLE')}
                        </Typography>
                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(''); setFilteredScormList(null); setSearchOpen(false) }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const ScormList = (id) => {
        if (sublist && sublist.length !== 0) {
            return (
                sublist.filter(obj => obj.id_category === id).map(item => (
                    item.scorms.map((scorm, index) => {
                        return (
                            <CategoryItem
                                key={index}
                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${scorm.id_scorm}/details`, state: { origin: 'list' } })}
                                image={scorm.image}
                                title={scorm.title}
                                status={scorm.completed}
                                totalCompleted={scorm.total_package_complete}
                                totalLessons={scorm.total_package}
                                obligatory={scorm.obligatory}
                            />
                        )
                    })
                ))
            )
        } else {
            return (
                <div className="category-grid">
                    <Grid container className="category-list">
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                    </Grid>
                </div>
            )
        }
    }

    const ThemeList = () => {
        return (
            themes.data.map((item, index) => {
                return (
                    parseInt(item.total_scorm) > 0 ?
                        <div key={index}>
                            {index < themes.data.length - 1
                                ?
                                <Divider variant="middle" />
                                : null
                            }
                            <SectionModule
                                iconType="image"
                                iconBgColor={item.bg_color}
                                icon={imageParser(item.image)}
                                title={item.title}
                                action={() => history.push({ pathname: history.location.pathname + `/${item.id_encrypted}/list`, state: { theme: item, origin: 'list' } })}
                            >
                                <SectionCategoryGrid action={() => history.push({ pathname: history.location.pathname + `/${item.id_encrypted}/list`, state: { theme: item, origin: 'list' } })}>
                                    {ScormList(item.id_category)}
                                </SectionCategoryGrid>
                            </SectionModule>
                        </div>
                        : null
                )
            })
        )
    }

    const FilteredScorm = () => {
        if (filteredScormList && filteredScormList.scorms.length > 0) {
            return (
                filteredScormList.scorms.map((item, index) => {
                    let imgFileData = item.image.blob ? {
                        'storage_type': item.image.blob.storage_type,
                        'blob_container': item.image.blob.blob_container,
                        'file_path': item.image.blob.file_name
                    } : {
                        'source': item.image.source
                    }
                    return (
                        <Grid item key={index} xs={6} sm={3} md={4} lg={3}>
                            <CategoryItem
                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_scorm}/details`, state: { origin: 'list' } })}
                                image={imgFileData}
                                title={item.title}
                                status={item.completed}
                                totalCompleted={item.total_package_complete}
                                totalLessons={item.total_package}
                                obligatory={item.obligatory}
                            />
                        </Grid>
                    )
                })
            )
        } else if (filteredScormList && filteredScormList.scorms.length === 0) {
            return (
                <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            );
        }

        return (
            <Grid container spacing={2} className="pt-2">
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
            </Grid>
        )
    }

    return (
        <QrLayout id="scorm-themes-list">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            {
                themes && themes.load === "finished" ?
                    !themes.error ?
                        themes.data.length !== 0 ?
                            search.length !== 0 ?
                                <Container maxWidth="lg" className="pt-3 pb-3">
                                    <Grid container spacing={2} className="category-list __fluid">
                                        <FilteredScorm />
                                    </Grid>
                                </Container>
                                :
                                <Container maxWidth="lg" className="container-fluid">
                                    <ThemeList />
                                </Container>
                            :
                            <EmptyContent icon={<Icon ic="play_scorm" className="icon-xxl" />} text={intl.get("EMPTY_SCORMS")} />
                        :
                        <ErrorContent />
                    :
                    <LoadingContent />
            }
        </QrLayout>
    )
}

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Divider } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from '../../Learn/Scorm/components/CategoryItem';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingCategoryGrid } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ScormSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const scorms = useSelector(state => state.home.scorms);
    const [didMount, setDidMount] = useState(false);
    const limit = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            if (config.home.scorm === "themes") {
                dispatch(HomeActions.getScormThemesList({
                    limit: limit,
                    page: 1
                }));
            } else {
                dispatch(HomeActions.getScormList({
                    limit: limit,
                    page: 1
                }));
            }
        }

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                iconType="play"
                iconSectionColor="section-play-scorm"
                icon="scorm"
                title={intl.get("SCORM_TITLE")}
                subtitle={intl.get("SCORM_SUBTITLE_SECTION")}
                action={() => history.push('/scorm')}
            >
                {children}
            </SectionModule>
        )
    }

    const SectionThemesContent = () => {
        return (
            <Section>
                <SectionCategoryGrid action={() => history.push('/scorm')}>
                    {
                        scorms.data.map((item, index) => {
                            return (
                                <CategoryItem
                                    key={index}
                                    action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_encrypted}/list`, state: { theme: item, origin: "home" } })}
                                    type="themes"
                                    bgColor={item.bg_color}
                                    image={item.image}
                                    title={item.title}
                                />
                            )
                        })
                    }
                </SectionCategoryGrid>
            </Section>
        )
    }

    const SectionContent = () => {
        return (
            <Section>
                <SectionCategoryGrid action={() => history.push('/scorm')}>
                    {
                        scorms.data.map((item, index) => {
                            return (
                                <CategoryItem
                                    key={index}
                                    action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_scorm}/details`, state: { origin: "home" } })}
                                    image={item.image}
                                    title={item.title}
                                    status={item.completed}
                                    totalCompleted={item.total_package_complete}
                                    totalLessons={item.total_package}
                                    obligatory={item.obligatory}
                                />
                            )
                        })
                    }
                </SectionCategoryGrid>
            </Section>
        )
    }

    return (
        scorms && scorms.load === "finished" ?
            !scorms.error ?
                scorms.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        {
                            config.home.scorm === "themes" ?
                                <SectionThemesContent />
                                :
                                <SectionContent />
                        }
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="play_scorm" className="icon-xxl" />}
                                    text={intl.get("EMPTY_SCORMS")}
                                    padding={true}
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        <LoadingCategoryGrid action={() => history.push('/scorm')} limit={limit} />
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    <LoadingCategoryGrid action={() => history.push('/scorm')} limit={limit} />
                </Section>
            </div>
    )
};

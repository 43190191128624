import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as RankingActions } from '../../redux/ducks/ranking';
import { imageParser, numberMask } from '../../utils/utils';
import {
    AppBar, Toolbar, IconButton, Typography, useMediaQuery,
    Tabs, Tab, Container, Grid, Button, List, Divider, CardActionArea
} from '@material-ui/core';
import QrLayout from '../../components/QrLayout2';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import ListItemPosition from './components/ListItemPosition';
import { LoadingBounce, LoadingAvatarList } from '../../components/Loading';
import { Icon, Medal, Flag, Points } from '../../components/Images/Images';

function tabsProps(index) {
    return {
        id: `appbar-tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid container
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            className="qr-tabpanel"
            {...other}
        >
            {value === index && (
                children
            )}
        </Grid>
    );
}

export default function Ranking() {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const { page, limit, ranking } = useSelector(state => state.ranking);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const qrRanking = JSON.parse(localStorage.getItem('auth.state.modules')).RANKING;
    const [value, setValue] = React.useState(0);
    const [didMount, setDidMount] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(config.ranking.filter ? 'day' : '');
    const [type, setType] = useState(qrRanking ?
        qrRanking.TAB_GENERAL === 'Y' ? 'general'
            : qrRanking.TAB_FOLLOWING === 'Y' ? 'friend' : 'group' : null);
    const tabs = [];
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_RANKING');
    let hasData = ranking && (ranking.load === "finished" && ranking.data.length !== 0) ? true : false;

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }
    }, [auth, history]);

    useEffect(() => {
        setDidMount(true);
        if (!fetching && !hasData) {
            setFetching(true);
            dispatch(
                RankingActions.getRanking({
                    page: 1,
                    limit: limit,
                    ranking_type: type,
                    ranking_period: filter,
                })
            );
        }

        if (didMount)
            return () => {
                if (history.location.pathname !== "/ranking") {
                    dispatch(RankingActions.reset())
                }
                setDidMount(false);
            }
    }, [history, didMount, dispatch, page, limit, fetching, type, filter, hasData]);

    function getTabData(tabName) {
        setFilter("day");
        dispatch(RankingActions.reset());

        switch (tabName) {
            case intl.get("RANKING_TAB_GENERAL_TITLE"):
                setType('general')
                setFetching(false);
                break;
            case intl.get("RANKING_TAB_FOLLOWING_TITLE"):
                setType('friend')
                setFetching(false);
                break;
            case intl.get("RANKING_TAB_GROUP_TITLE"):
                setType('group')
                setFetching(false);
                break;
            default:
        }
    }

    if (qrRanking && qrRanking.TAB_GENERAL === 'Y') {
        tabs.push(intl.get("RANKING_TAB_GENERAL_TITLE"))
    }
    if (qrRanking && qrRanking.TAB_FOLLOWING === 'Y') {
        tabs.push(intl.get("RANKING_TAB_FOLLOWING_TITLE"))
    }
    if (qrRanking && qrRanking.TAB_GROUP === 'Y') {
        tabs.push(intl.get("RANKING_TAB_GROUP_TITLE"))
    }

    async function handleFilter(period) {
        await dispatch(RankingActions.reset());

        if (period === "all") {
            setFilter("");
        } else {
            setFilter(period);
        }

        await dispatch(
            RankingActions.getRanking({
                page: 1,
                limit: limit,
                ranking_type: type,
                ranking_period: period === "all" ? "" : period,
            })
        );
    }

    async function nextPage() {
        let pageNumber = page;
        pageNumber++;

        setLoading(true);
        await dispatch(RankingActions.getLoadMore({
            page: pageNumber,
            limit: limit,
            ranking_type: type,
            ranking_period: filter,
        }));
        await dispatch(RankingActions.setPage({
            page: pageNumber,
        }));
        setLoading(false);
    }

    function completeTop3(total) {
        var grid = [];

        for (let i = 0; i < total; i++) {
            console.log(i)
            grid.push(
                <Grid key={i} item xs={4} className={`${total === 2 && i === 0 ? "order-1" : "order-3"} __empty`}></Grid>
            );
        }

        return grid
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        getTabData(tabs[newValue]);
    };

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"} __tabs`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        config.layout.navbar && !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('RANKING_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("RANKING_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>

                <Tabs value={value} onChange={handleChange} aria-label="tabs" variant={isScreenUpMd ? "standard" : "fullWidth"} className="appbar-default-tabs" centered>
                    {
                        tabs.map((tab, key) => {
                            return <Tab key={key} label={tab} {...tabsProps(key)} />
                        })
                    }
                </Tabs>
            </AppBar>
        )
    }

    const RenderRanking = () => {
        return (
            <Grid container className="pt-3">
                {
                    config.ranking.filter ?
                        <Container maxWidth="md" className="pb-3">
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={6} md={3}>
                                    <Button variant={`${filter === "day" ? "contained" : "outlined"}`} onClick={() => handleFilter("day")} color="primary"
                                        className="btn-tabpanel btn-block">
                                        {intl.get("LABEL_TODAY")}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button variant={`${filter === "week" ? "contained" : "outlined"}`} onClick={() => handleFilter("week")} color="primary"
                                        className="btn-tabpanel btn-block">
                                        {intl.get("LABEL_WEEK")}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button variant={`${filter === "month" ? "contained" : "outlined"}`} onClick={() => handleFilter("month")} color="primary"
                                        className="btn-tabpanel btn-block">
                                        {intl.get("LABEL_MONTH")}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button variant={`${filter === "" ? "contained" : "outlined"}`} onClick={() => handleFilter("all")} color="primary"
                                        className="btn-tabpanel btn-block">
                                        {intl.get("LABEL_EVERYTHING")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                        : null
                }
                {
                    isScreenUpMd ?
                        <Grid container>
                            <Container maxWidth="md">
                                <Grid container justify="center" spacing={2} className="top3">
                                    {
                                        ranking.data.filter(pos => parseInt(pos.position) < 4).map((item, index) => {
                                            return (
                                                <Grid key={index} item xs={4} className={
                                                    parseInt(item.position) === 1 ? "order-2" :
                                                        parseInt(item.position) === 2 ? "order-1" : "order-3"
                                                }>
                                                    <CardActionArea
                                                        disabled={(qrRanking && qrRanking.ENABLED_SELECT && qrRanking.ENABLED_SELECT !== 'Y') || item.is_me === "Y" ? true : false}
                                                        onClick={() => history.push({
                                                            pathname: `${history.location.pathname.replace(/./g, '')}/profile/${item.id_user}`,
                                                            state: {
                                                                origin: "ranking"
                                                            }
                                                        })}
                                                    >
                                                        <div className="top-avatar">
                                                            <img src={imageParser(item.image)} alt={item.name} className="avatar" />
                                                            <div className="medal">
                                                                <Medal type={item.position} className="img-lg" />
                                                            </div>
                                                        </div>
                                                        <div className="top-name">
                                                            {
                                                                config.ranking.flag.enabled && qrRanking.FLAG_REGION === "Y" ?
                                                                    config.ranking.flag.type === "states" && (item.country && item.country === "BR") && (item.state && item.state.length === 2) ?
                                                                        <Flag
                                                                            type="states"
                                                                            code={item.state.toLowerCase()}
                                                                            className="img-xxs mr-2"
                                                                        />
                                                                        :
                                                                        config.ranking.flag.type === "country" && (item.country && item.country.length === 2) ?
                                                                            <Flag
                                                                                type="country"
                                                                                code={item.country.toLowerCase()}
                                                                                className="img-xxs mr-2"
                                                                            />
                                                                            : null
                                                                    : null
                                                            }
                                                            <Typography variant="body1" component="p" className={item.is_me === "Y" ? "text-heading" : ""}>
                                                                {item.name}
                                                            </Typography>
                                                        </div>
                                                        <div className="top-points">
                                                            <Points type="xp" className="img-xxs mr-1" />
                                                            <Typography className="text-xp" variant="body2" component="p">
                                                                {numberMask(item.qi_score)}
                                                            </Typography>
                                                        </div>
                                                    </CardActionArea>
                                                </Grid>
                                            )
                                        })
                                    }
                                    {
                                        ranking.data.length <= 2 ?
                                            completeTop3(3 - ranking.data.length)
                                            : null
                                    }
                                </Grid>
                            </Container>
                            {
                                ranking.data.length > 3 ?
                                    <Container maxWidth="md" className="container-list">
                                        <List>
                                            <Divider />
                                            {
                                                config.ranking.max_positions === 0 ?
                                                    ranking.data.filter(pos => parseInt(pos.position) > 3).map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <ListItemPosition
                                                                    module={qrRanking}
                                                                    item={item}
                                                                    userPosition={item.is_me === "Y"}
                                                                    action={item.is_me === "N" ?
                                                                        () => history.push({
                                                                            pathname: `${history.location.pathname.replace(/./g, '')}/profile/${item.id_user}`,
                                                                            state: {
                                                                                origin: "ranking"
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                />
                                                                <Divider />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    ranking.data.filter(pos => parseInt(pos.position) > 3).slice(0, (config.ranking.max_positions - 3)).map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <ListItemPosition
                                                                    module={qrRanking}
                                                                    item={item}
                                                                    userPosition={item.is_me === "Y"}
                                                                    action={item.is_me === "N" ?
                                                                        () => history.push({
                                                                            pathname: `${history.location.pathname.replace(/./g, '')}/profile/${item.id_user}`,
                                                                            state: {
                                                                                origin: "ranking"
                                                                            }
                                                                        })
                                                                        : null
                                                                    }
                                                                />
                                                                <Divider />
                                                            </div>
                                                        )
                                                    })
                                            }
                                            {
                                                ranking.data.filter(me => me.is_me === "Y").length !== 0 ?
                                                    null
                                                    :
                                                    <ListItemPosition
                                                        module={qrRanking}
                                                        item={ranking.me}
                                                        userPosition={true}
                                                    />
                                            }
                                        </List>
                                    </Container>
                                    :
                                    null
                            }
                        </Grid>
                        :
                        <Container maxWidth="md" className="container-list">
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    <List>
                                        {
                                            config.ranking.max_positions === 0 ?
                                                ranking.data.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <ListItemPosition
                                                                module={qrRanking}
                                                                item={item}
                                                                userPosition={item.is_me === "Y"}
                                                                action={item.is_me === "N" ?
                                                                    () => history.push({
                                                                        pathname: `${history.location.pathname.replace(/./g, '')}/profile/${item.id_user}`,
                                                                        state: {
                                                                            origin: "ranking"
                                                                        }
                                                                    })
                                                                    : null
                                                                }
                                                            />
                                                            <Divider />
                                                        </div>
                                                    )
                                                })
                                                :
                                                ranking.data.slice(0, config.ranking.max_positions).map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <ListItemPosition
                                                                module={qrRanking}
                                                                item={item}
                                                                userPosition={item.is_me === "Y"}
                                                                action={item.is_me === "N" ?
                                                                    () => history.push({
                                                                        pathname: `${history.location.pathname.replace(/./g, '')}/profile/${item.id_user}`,
                                                                        state: {
                                                                            origin: "ranking"
                                                                        }
                                                                    })
                                                                    : null
                                                                }
                                                            />
                                                            <Divider />
                                                        </div>
                                                    )
                                                })
                                        }
                                        {
                                            ranking.data.filter(me => me.is_me === "Y").length !== 0 ?
                                                null
                                                :
                                                <ListItemPosition
                                                    module={qrRanking}
                                                    item={ranking.me}
                                                    userPosition={true}
                                                />
                                        }
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                }
                {
                    config.ranking.max_positions === 0 || ranking.data.length < config.ranking.max_positions ?
                        parseInt(ranking.total) > ranking.data.length ?
                            <Container maxWidth="md" className="pt-3 pb-4">
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={6} lg={4} align="center">
                                        {
                                            loading ?
                                                <LoadingBounce />
                                                :
                                                <Button onClick={nextPage} variant="outlined" color="primary" className="btn-block">
                                                    {intl.get("BTN_LOAD_MORE")}
                                                </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Container>
                            : null
                        : null
                }
            </Grid>
        )
    }

    return (
        auth.authenticated &&
        <QrLayout
            id="ranking"
            bottomNavigation={bottomMenuItem}
        >
            <QrAppBar />
            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                {
                    tabs.map((title, index) => {
                        return (
                            <TabPanel key={index} value={value} index={index}>
                                {
                                    ranking && ranking.load === "finished" ?
                                        !ranking.error ?
                                            ranking.data.length !== 0 ?
                                                <RenderRanking />
                                                :
                                                <Container maxWidth="md" className="pt-3 pb-3">
                                                    <EmptyContent icon={<Icon ic="trophy_filled" className="icon-xxl" />} text={intl.get("EMPTY_RANKING")} />
                                                </Container>
                                            :
                                            <Container maxWidth="md" className="pt-3 pb-3">
                                                <ErrorContent />
                                            </Container>
                                        :
                                        <Container maxWidth="md" className="pt-3 pb-3">
                                            <LoadingAvatarList limit={10} />
                                        </Container>
                                }
                            </TabPanel>
                        )
                    })
                }
            </div>
        </QrLayout>
    )
}
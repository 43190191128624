import React from 'react';
import intl from "react-intl-universal";
import config from '../../../../config';
import { getDateTime } from '../../../../utils/utils';
import { Card, CardContent, CardActionArea, CardMedia, Typography, Tooltip, Zoom, CircularProgress } from '@material-ui/core';
import { imageParser } from '../../../../utils/utils';
import { Icon } from '../../../../components/Images/Images';

export default function CategoryItem(props) {
    const { action, type, image, bgColor, title, currentStars, totalStars, currentStep, totalStep, complete, obligatory, available, dependency, dateRelease } = props;

    return (
        <Tooltip title={
            type !== "themes" && (available && available === "N") ?
                dependency ?
                    `${intl.get("TRAIL_CATEGORY_DEPENDENCY_TEXT_1")} ${dependency} ${intl.get("TRAIL_CATEGORY_DEPENDENCY_TEXT_2")}`
                    :
                    dateRelease ?
                        `${intl.get("TRAIL_CATEGORY_DATE_RELEASE_TEXT")} ${getDateTime(dateRelease)}`

                        : ""
                : ""
        }
            placement="top"
            TransitionComponent={Zoom}
            disableFocusListener
        >
            <div className="category-list-item">
                <div className={`category-list-item-content${available && available === "N" ? " __disabled" : ""}`}>
                    <Card>
                        <CardActionArea onClick={action} disabled={available && available === "N" ? true : false}>
                            {
                                obligatory && obligatory === "Y" ?
                                    <>
                                        <div className="category-flag __obligatory"></div>
                                        <div className="category-flag-label">
                                            {intl.get("LABEL_OBLIGATORY")}
                                        </div>
                                    </>
                                    : null
                            }

                            {
                                type === "themes" || (available && available === "Y") ?
                                    <CardMedia
                                        className='category-image  __banner'
                                        style={bgColor ? { backgroundColor: bgColor } : null}
                                        image={imageParser(image)}
                                        title={title}
                                    />
                                    :
                                    <div className="category-image  __banner">
                                        <div className="category-image-filter" style={{ backgroundImage: `url(${imageParser(image)})`, backgroundColor: bgColor ? bgColor : "" }}></div>
                                        <div className="category-image-locked">
                                            <Icon ic="lock" color="lightest" className="icon-lg" />
                                        </div>
                                    </div>
                            }
                            <CardContent className="category-text">
                                <Typography variant="body2" component="h4">
                                    {title}
                                </Typography>
                                {
                                    type !== "themes" ?
                                        config.learn.trail.type === "stars" ?
                                            <div className="category-text-info __stars">
                                                <Icon ic="star_filled" className="icon-xxs" />
                                                <Typography variant="caption" component="p">
                                                    {currentStars + ' / ' + totalStars}
                                                </Typography>
                                            </div>
                                            :
                                            <div className="category-text-info __progress">
                                                {
                                                    complete === "Y" ?
                                                        <Icon ic="checked" className="icon-xs mr-2 __finished" />
                                                        :
                                                        <div className="circular-progress">
                                                            <div className="progress">
                                                                <CircularProgress className="progress-inactive" variant="determinate" value={100} size={20} thickness={5} />
                                                                <CircularProgress className="progress-active" variant="determinate" value={currentStep / totalStep * 100} size={20} thickness={5}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                                <Typography variant="caption" component="p">
                                                    {currentStep + ' / ' + totalStep}
                                                </Typography>
                                            </div>
                                        :
                                        null
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </div>
        </Tooltip>
    )
}

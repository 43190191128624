import React from 'react';
import { Card, CardContent, CardActionArea, CardMedia, Typography, CircularProgress } from '@material-ui/core';
import { imageParser } from '../../../../utils/utils';
import { Icon } from '../../../../components/Images/Icons';
import intl from "react-intl-universal";

export default function CategoryItem(props) {
    const { action, type, image, bgColor, title, status, totalCompleted, totalLessons, obligatory } = props;

    return (
        <div className="category-list-item">
            <div className="category-list-item-content">
                <Card>
                    <CardActionArea onClick={action}>
                        {
                            obligatory && obligatory === "Y" ?
                                <>
                                    <div className="category-flag __obligatory"></div>
                                    <div className="category-flag-label">
                                        {intl.get("LABEL_OBLIGATORY")}
                                    </div>
                                </>
                                : null
                        }
                        <CardMedia
                            className={`category-image${type !== "themes" ? " __banner" : ""}`}
                            style={bgColor ? { backgroundColor: bgColor } : null}
                            image={imageParser(image)}
                            title={title}
                        />
                        <CardContent className="category-text">
                            <Typography variant="body2" component="h4">
                                {title}
                            </Typography>
                            {
                                type !== "themes" ?
                                    <div className="category-text-info __progress">
                                        {
                                            status === "Y" ?
                                                <Icon ic="checked" className="icon-xs mr-2 __finished" />
                                                :
                                                totalCompleted && totalLessons
                                                    ? <div className="circular-progress">
                                                        <div className="progress">
                                                            <CircularProgress className="progress-inactive" variant="determinate" value={100} size={20} thickness={5} />
                                                            <CircularProgress className="progress-active" variant="determinate" value={totalCompleted / totalLessons * 100} size={20} thickness={5}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null
                                        }
                                        {totalCompleted && totalLessons
                                            ?
                                            <Typography variant="caption" component="p">
                                                {totalCompleted + ' / ' + totalLessons}
                                            </Typography>
                                            : null}
                                    </div>
                                    : null
                            }
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    )
}

import * as React from "react"

function CharTrailFlashcardsFinishedStars0(props) {
    return (
        <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
            <defs>
                <path id="CharTrailFlashcardsFinishedStars0__b" d="M0 0h180v63H0z" />
                <path id="CharTrailFlashcardsFinishedStars0__e" d="M0 0h31.5v31.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars0__g" d="M0 0h40.5v40.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars0__i" d="M0 0h31.5v31.5H0z" />
                <filter
                    x="-11.1%"
                    y="-9.7%"
                    width="122.2%"
                    height="119.4%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars0__a"
                >
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter
                    x="-7.9%"
                    y="-7.9%"
                    width="117.5%"
                    height="117.5%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars0__d"
                >
                    <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196213942 0"
                        in="shadowOffsetOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <circle cx={90} cy={90} r={81} />
                <path
                    d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                    fill="#FFF"
                />
                <g filter="url(#CharTrailFlashcardsFinishedStars0__a)" transform="translate(36 8)">
                    <path
                        d="M34.152 2.234c2.65-2.922 5.793-.78 8.466-.613 2.673.167 3.843-.836 6.517-1.504 2.673-.668 5.18 1.67 6.516 3.342 1.337 1.67 4.01 1.67 5.013.501 1.002-1.17 2.84-4.01 6.516-3.509 3.676.501 5.012 3.008 5.012 5.18 0 2.172-.5 4.344 1.17 5.18 1.671.835 5.18.835 5.514 2.84.334 2.005.836 4.177 3.175 4.679 2.34.501 8.689-.334 10.694 4.177 2.005 4.511-1.504 7.352-3.008 9.524-1.504 2.172-1.838 6.85 1.003 8.689 2.84 1.838 4.01 4.177 2.506 8.02s.501 6.015 1.838 8.354c1.337 2.34.334 6.517-2.506 7.52-2.84 1.002-5.848.167-7.018 2.506s-1.67 5.848-5.013 6.015c-3.342.167-5.012-2.673-7.519-2.34-2.506.335-4.01 3.677-8.187 2.674-4.177-1.002-21.388-6.85-21.388-6.85s-4.177 7.518-9.022 7.017c-4.846-.501-3.008-2.84-5.514-3.342-2.507-.5-4.122 1.783-7.352.112-2.323-1.202-.111-3.398-2.117-4.567-2.005-1.17-4.121-.111-4.567-3.453-.515-3.867 2.785-5.849 2.45-7.352-.333-1.504-3.786-2.785-3.452-7.13.334-4.344 3.865-5.375 4.9-8.243 1.449-4.01-.245-4.855-1.002-11.362-.557-4.79 4.397-9.019 7.575-9.58 3.787-.668 6.127-1.559 7.13-4.566 1.002-3.008-2.674-7.13 1.67-11.92"
                        fill="#000"
                    />
                    <path
                        d="M83.787 102.246c-5.726-3.545-10.16-6.635-11.76-11.567-3.008-9.272-1.505-23.392-1.505-23.392L39.944 64.78c0 8.522.669 20.719-3.341 27.739-1.89 3.305-6.298 6.165-11.238 8.948 2.244 7.025 7.845 19.455 22.838 22.361H61.35c13.92-2.905 20.153-15.369 22.437-21.582"
                        fill="#A35500"
                    />
                    <path
                        d="M25.365 101.467c-5.547 3.123-15.733 10.184-19.34 14.108C3.87 117.923 1.927 120.7.18 123.83h48.024c-14.994-2.907-20.594-15.337-22.838-22.362m75.482 12.772c-5.985-5.399-12.024-8.871-17.061-11.994-2.284 6.213-8.516 18.677-22.437 21.582h46.355c-1.911-3.807-4.178-7.172-6.857-9.588"
                        fill="#E289A9"
                    />
                    <path
                        d="M76.788 50.427c0 15.192-10.402 27.098-20.392 26.824-9.991-.273-21.214-12.453-21.214-27.645 0-15.193 6.934-25.284 21.304-25.01 15.485.295 20.302 10.638 20.302 25.83"
                        fill="#B25900"
                    />
                    <path
                        d="M76.439 45.377s5.517-1.403 5.81 5.909c.292 7.311-6.42 6.34-6.42 6.34l.61-12.25zm-40.768-.167s-5.518-1.404-5.81 5.908c-.292 7.312 6.42 6.342 6.42 6.342l-.61-12.25z"
                        fill="#A35500"
                    />
                    <path
                        d="M45.124 48.072a1.17 1.17 0 11-2.339 0 1.17 1.17 0 012.34 0m24.728-.167a1.17 1.17 0 11-2.338 0 1.17 1.17 0 012.338 0"
                        fill="#000"
                    />
                    <path
                        d="M56.402 45.5l-4.483 10.532 3.348.98a3.646 3.646 0 002.152-.032l3.412-1.11-4.429-10.37z"
                        fill="#A35500"
                    />
                    <path
                        d="M55.985 59.21c4.438-.143 7.264 2.799 8.703 4.888.482.7-.136 1.623-.964 1.437a31.988 31.988 0 00-7.572-.755c-3.185.063-5.792.58-7.469 1.023-.815.216-1.468-.672-1.024-1.389 1.298-2.09 3.909-5.06 8.326-5.203m-6.238 16.208s3.008-1.894 6.906-2.006c3.9-.11 6.573 2.117 6.573 2.117s-3.008 5.792-6.35 6.015c-3.342.223-7.129-6.126-7.129-6.126m15.829-37.19c.032-.026.065-.05.1-.076.04.02.081.043.122.066a1.35 1.35 0 00-.222.01m8.722 8.574c-.102-5.457-4.064-9.859-9.484-10.547-.33-.041-.554.11-.67.33a1.588 1.588 0 00-.778.667c-.558.887-.285 1.81.2 2.615.093.21.224.412.387.599.273.62.698 1.192 1.19 1.621 1.095.957 2.429 1.683 3.644 2.473l4.345 2.824c.447.292 1.177.007 1.166-.582M46.616 38.06l.118-.071.104.07a1.428 1.428 0 00-.222.001m-6.903 9.496l4.21-3.023c1.177-.845 2.476-1.632 3.526-2.637a4.72 4.72 0 001.113-1.675c.155-.195.276-.402.359-.617.448-.826.677-1.76.08-2.62a1.59 1.59 0 00-.808-.632c-.127-.214-.357-.355-.684-.297-5.383.937-9.138 5.516-8.989 10.973.016.588.759.839 1.193.528"
                        fill="#000"
                    />
                    <path
                        d="M69.625 58.512a.503.503 0 01-.062-.124.51.51 0 00.117.084c0 .023-.002.045.004.07.006.019.018.037.029.054a.334.334 0 01-.088-.084m1.153-1.22l-.016-.01a.868.868 0 00.022-.19c.01-.018.015-.036.024-.054-.003.023-.003.047-.005.07a4.11 4.11 0 01-.025.184m-.851-.56l-.024.048.027-.09-.003.042m.236-.952c0 .03 0 .059.008.085v.001c-.013.023-.022.047-.033.071l-.012.004.037-.16m.597-.151c.006.04.01.083.014.124-.016-.027-.027-.057-.045-.084a.234.234 0 00-.049-.05l.04-.285c.013.098.028.196.04.295m.565.744c-.025-.667-.145-1.327-.249-1.984l-.195-1.238a18.503 18.503 0 00-.378-4.013c-.054-.264-.398-.211-.471-.004a.258.258 0 00-.07.215 17.789 17.789 0 01-.649 7.673c-.162.517-.433 1.095-.183 1.63.19.407.63.606 1.057.44.998-.386 1.172-1.802 1.138-2.72"
                        fill="#78ECFF"
                    />
                </g>
                <g transform="translate(0 117)">
                    <mask id="CharTrailFlashcardsFinishedStars0__c" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars0__b" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars0__c)">
                        <path
                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                            className="svg-ribbon-stars-back"
                        />
                        <path
                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-1.19-.193-2.992-.553-4.8-.96l-.57-.13c-2.94-.677-5.731-1.447-5.754-1.788-.018-.27 2.998-.736 6.182-1.115l.684-.08c3.307-.378 6.577-.64 6.626-.476z"
                            className="svg-ribbon-stars-shadow"
                        />
                        <path
                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                            className="svg-ribbon-stars-front"
                        />
                    </g>
                </g>
                <g filter="url(#CharTrailFlashcardsFinishedStars0__d)" transform="rotate(-12 659.94 -89.693)">
                    <mask id="CharTrailFlashcardsFinishedStars0__f" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars0__e" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars0__f)" className="svg-ribbon-stars-back">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
                <g transform="translate(69.755 123.75)">
                    <mask id="CharTrailFlashcardsFinishedStars0__h" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars0__g" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars0__h)" className="svg-ribbon-stars-back">
                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                    </g>
                </g>
                <g transform="rotate(12 -554.159 616.797)">
                    <mask id="CharTrailFlashcardsFinishedStars0__j" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars0__i" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars0__j)" className="svg-ribbon-stars-back">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CharTrailFlashcardsFinishedStars0

import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { scormService } from '../../../services/scorm.service';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ScormActions } from '../../../redux/ducks/scorm'; 
import { Redirect } from "react-router-dom";
import * as moment from 'moment';
import {
    AppBar, Button, Divider, Drawer, IconButton, List, Grid,
    ListItem, ListItemIcon, ListItemText, Toolbar, Typography
} from '@material-ui/core';
import { Icon } from '../../../components/Images/Images';

export default function ScormPackage(props) {
    const dispatch = useDispatch();
    const idScorm = props.location.state && props.location.state.idScorm ? props.location.state.idScorm : '';
    const module = props.location.state && props.location.state.module ? props.location.state.module : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const scormHost = process.env.REACT_APP_QR_HOST_SCORM;
    const [, setRunning] = useState(false);
    const [scormState, setScormState] = useState({ emptyData: 1 });
    const [, setLink] = useState(null);
    const [saving, setSaving] = useState(false);
    const [newLink, setNewlink] = useState(false);
    const [linkFrame, setlinkFrame] = useState(null);
    const [lastRequisitionTime, setLastRequisitionTime] = useState(null);
    const [lastLocalstorageTime, setLastLocalstorageTime] = useState(null);
    const [lessonStatus, setLessonStatus] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [scormFrameId, setscormFrameId] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [startPackage, setStartPackage] = useState(false);

    useEffect(() => {
        if (origin === "details") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (module && !startPackage) {
            setStartPackage(true);
            dispatch(ScormActions.packageOpen({
                'id_scorm_package_item': selectedItem.id_item,
            }));
        }
    }, [origin, module, selectedItem, startPackage, dispatch]);

    const buildQueryString = useCallback((state) => {
        if (module) {
            const result = [];
            let cookie_name = `qrsc${idScorm + module.id_scorm_package + selectedItem.id_item + auth.user.id_user}`;
            setscormFrameId(cookie_name)
            result.push(`cookie=${cookie_name}`);
            result.push(`cmi.core.student_id=${auth.user.id_user}`);
            result.push(`cmi.core.student_name=${auth.user.email}`);
            for (var i in state) {
                result.push(`${i}=${state[i]}`);
            }
            return window.btoa(window.location.origin + '#&qr&#' + selectedItem.index_path + '#&qr&#' + result.join('#&qr&#'));
        }
    }, [auth, idScorm, selectedItem, module]);

    let defineLink = useCallback((state) => {
        const link = `${scormHost}/qrscplayer.html?${(new Date()).getTime()}#${buildQueryString(state)}`;
        setLink(link);

        if (!linkFrame || newLink) {
            setNewlink(false);
            setlinkFrame(link);
        }
    }, [buildQueryString, linkFrame, newLink, scormHost]);

    useEffect(() => {
        if (Object.keys(scormState).length > 0)
            defineLink(scormState);
    }, [defineLink, scormState]);

    useEffect(() => {
        const channelPostMessage = new BroadcastChannel('qranioscorms');

        window.addEventListener("message", (event) => {
            channelPostMessage.postMessage(event.data);
        }, false);

        const channel = new BroadcastChannel('qranioscorms');

        channel.onmessage = (e) => {
            const ev = e.data;
            // Adicionado para que so envie informações para o servidor se for o mesmo id para não ter problemas com abertura em mais de uma aba
            if ((ev.scorm_frame_id !== scormFrameId) || !module) {
                return
            }
            switch (ev.type) {
                case 'dataObjectScormStart':
                    break
                case 'dataObjectScorm':
                    setRunning(true);
                    // Adicionado o controle de tempo para não ficar enviando muitas atualizações no mesmo segundo
                    if (!lastLocalstorageTime || moment().diff(lastLocalstorageTime, 'seconds') >= 40) {

                        const obj = JSON.parse(ev.data);
                        const entries = Object.entries(obj);

                        // Seta a data da ultima gravacao do localstorage com as variaveis
                        let currentTimelocal = moment();
                        setLastLocalstorageTime(currentTimelocal);

                        // Inicia a variavel para pegar do pacote qual o status do usuario no pacote
                        let currentLessonStatus = 1;

                        // Pega a constante do state para atualizar com o retorno do pacote
                        const currentState = scormState

                        entries.forEach(([key, value]) => {
                            // Pega o status do usuario no pacote para quando mudar fazer a atualização
                            if (key === 'cmi.core.lesson_status') {
                                currentLessonStatus = value;
                            }

                            if (key === 'cmi.core.lesson_status' && (value === 'completed' || value === 'passed')) {
                                module.items.map((item, index) => {
                                    if (item.id_item === selectedItem.id_item) {
                                        module.items[index].completed = 'Y';
                                    }
                                    return null;
                                })
                            }

                            // Colocado para só colocar no state e nao definir o link ainda, somente depois que processar fazer o define no link
                            currentState[key] = String(value);
                        });

                        // Adicionado depois do foreach para não travar o navegador do usuário quando tem muitas variáves
                        setScormState(currentState);
                        defineLink(currentState);

                        // Colocado um limite minimo de diferença entre as requisições para o WS ou se o status do usuario mudou
                        if (!lastRequisitionTime || moment().diff(lastRequisitionTime, 'seconds') > 40 || !lessonStatus || lessonStatus !== currentLessonStatus) {
                            //console.log(currentLessonStatus, lessonStatus)
                            // Seta o status do usuário no pacote
                            setLessonStatus(currentLessonStatus);

                            // Seta a data com o ultimo envio da requisição
                            let currentTime = moment();
                            setLastRequisitionTime(currentTime);

                            // Faz a requisição para o WS
                            scormService.setVar({
                                'id_scorm_package_item': selectedItem.id_item,
                                'scorm_variable': ev.data
                            });
                        }
                    }
                    break

                case 'dataObjectScormFinished':
                    setSaving(true);
                    setRunning(true);
                    const objFinished = JSON.parse(ev.data);
                    const entriesFinished = Object.entries(objFinished);
                    // Pega a constante do state para atualizar com o retorno do pacote
                    const currentState = scormState;

                    entriesFinished.forEach(([key, value]) => {
                        if (key === "cmi.core.lesson_status" && (value === "completed" || value === "passed")) {
                            module.items.map((item, index) => {
                                if (item.id_item === selectedItem.id_item) {
                                    module.items[index].completed = 'Y';
                                }
                                return null;
                            })
                        }

                        currentState[key] = String(value);
                    });

                    setScormState(currentState);
                    defineLink(currentState);
                    channel.close();
                    // SO FECHA O MODAL SE TIVER O RETORNO DO WS
                    sendScormFinished(selectedItem.id_item, ev.data)
                    break
                default:
            }
        }

        const currentState = {};

        if (module) {
            for (var i = 0; i < selectedItem.user_actions.length; i++) {
                currentState[selectedItem.user_actions[i].var] = String(selectedItem.user_actions[i].value);
            }
        }

        setScormState(currentState);
        defineLink(currentState);

        if (Object.keys(currentState).length > 0) {
            setRunning(true);
        }

        return function cleanup() {
            setScormState({});
            channel.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defineLink, selectedItem, lastRequisitionTime, lastLocalstorageTime, lessonStatus]);

    function saveScormData(id_item, value) {
        return new Promise(resolve => {
            resolve(
                scormService.setVar({
                    'id_scorm_package_item': id_item,
                    'scorm_variable': value
                })
            )
        });
    }

    async function sendScormFinished(id_item, value) {
        let return_data = await saveScormData(id_item, value);
        if (return_data.insert === 'ok') {
            return history.push({
                pathname: `${props.match.path.replace(/./g, '')}/scorm/${idScorm}/details`,
                state: {
                    origin: 'package'
                }
            })
        }
    }

    // ESTE BOTAO ENVIA A INFORMACAO PARA O SERVIDOR DO SCORM FECHAR O IFRAME DO PACOTE
    const buttomClose = async () => {
        let data = {
            data: 'closescorm',
            scorm_frame_id: scormFrameId
        }
        const propagateSignal = (type, data = {}) => {
            const send = { type, ...data };
            if (document.querySelector('#scorm-package-content-iframe').contentWindow) {
                document.querySelector('#scorm-package-content-iframe').contentWindow.postMessage(send, scormHost);
            }
            return true;
        }
        propagateSignal('message', data)
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-sm appbar-default" position="sticky" color="primary">
                <Toolbar>
                    {
                        saving ?
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h5" aria-label="close" noWrap>
                                    {intl.get('SCORM_PACKAGE_SAVING_TEXT')}
                                </Typography>
                            </div>
                            :
                            <div className="d-flex align-items-center w-100 overflow-hidden">
                                <Button className="button-sm" color="inherit" onClick={buttomClose} aria-label="close">
                                    <Icon ic="close" className="mr-3" />
                                    {intl.get('SCORM_PACKAGE_SAVE_BUTTON')}
                                </Button>
                                <Typography variant="h4" className="ml-3" noWrap>
                                    {module && module.title}
                                </Typography>
                            </div>
                    }
                    {
                        module.items?.length > 1
                            ?
                            <IconButton color="inherit" onClick={() => setOpenDrawer(true)} aria-label="open-drawer">
                                <Icon ic="menu_filled" />
                            </IconButton>
                            : null
                    }
                </Toolbar>
            </AppBar>
        )
    }

    function handleSelectedItem(item) {
        setSelectedItem(item);
        setNewlink(true);
        defineLink(scormState);
    }

    if (!selectedItem && module) {
        let i = 0;

        while (i < module.items.length && module.items[i].completed === 'Y') {
            i++;
        }
        if (i >= module.items.length) {
            setSelectedItem(module.items[0]);
        } else {
            setSelectedItem(module.items[i]);
        }
    }

    return (
        !redirect ?
            <Grid container id="scorm-package-page">
                <QrAppBar />
                <div id="scorm-package-content" className="embed-responsive embed-responsive-1by1">
                    <iframe allow="camera *; accelerometer;" allowvr="yes" title="scorm-package" id="scorm-package-content-iframe" className="embed-responsive-item" src={linkFrame} allowFullScreen />
                </div>
                <Drawer id="scorm-package-drawer" anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
                    <div className="drawer-dialog-header">
                        <IconButton onClick={() => setOpenDrawer(false)}>
                            <Icon ic="swipe_right" />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {
                            module.items?.map((item, index) => {
                                return (
                                    <ListItem button key={index} disabled={item.blocked === 'Y'} onClick={() => handleSelectedItem(item)}>
                                        <ListItemIcon>
                                            <Icon ic={`checkbox_${item.completed === 'Y' ? "checked" : "unchecked"}`} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Drawer>
            </Grid>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/scorm/${props.match.params.idScorm}/details`,
                state: {
                    origin: 'package'
                }
            }} />
    )
}
import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#EB5757', // Red[200]
            main: '#E62E2E', // Red[300]
            dark: '#B82424', // Red[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#5F5F5F', // Gray[800]
            main: '#19191A', // Gray[900]
            dark: '#000', // Black[400]
            contrastText: '#fff',
        },
        error: {
            light: '#AAAAAA', // Gray[400]
            main: '#969696', // Gray[500]
            dark: '#878787', // Gray[600]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)

import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { imageParser, numberToLetter } from '../../../utils/utils';
import {
    Container, Grid, Typography, Button, InputBase
} from '@material-ui/core';
import { Formik } from 'formik';
import QrVideoRecorder from '../../../components/QrVideoRecorder';
import QrImageUpload from '../../QrImageUpload';
import SnackBar from '../../../components/snackBar';
import { Icon } from '../../Images/Images';

export default function Answers(props) {
    const { question, sendAnswer, view, timeOut } = props;
    const help = useSelector(state => state.game.help);
    const { history } = useReactRouter();
    const [typedWord, setTypedWord] = useState([]);
    const [activeKey, setActiveKey] = useState([]);
    const [word, setWord] = useState([]);
    const [wordEmpty, setWordEmpty] = useState(true);
    const [answerAlert, setAnswerAlert] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackStatus, setSnackStatus] = useState("info");
    const [pressAnswer, setPressAnswer] = useState({});
    const [multiAnswers, setMultiAnswers] = useState([]);

    useEffect(() => {
        if (answerAlert) {
            let timer = setTimeout(() => {
                setSnackOpen(true);
                setSnackMessage(question.question.question_type === "SELECT_ANSWERS" ? intl.get("LABEL_ANSWER_CONFIRMATION_SELECT_ANSWERS") : intl.get("LABEL_ANSWER_CONFIRMATION"));
                setSnackStatus("info");
            }, 3000);

            return () => {
                setAnswerAlert(false);
                clearTimeout(timer);
            }
        }
    }, [answerAlert, question]);

    function pressButton(answer, question) {
        if (config.learn.answer_double_click) {
            if (pressAnswer.id === answer && pressAnswer.press === true) {
                return sendAnswer(answer, question);
            } else {
                setPressAnswer({ id: answer, press: true });
                setAnswerAlert(true);
            }
        } else {
            return sendAnswer(answer, question);
        }
    }

    const ButtonAnswer = () => {
        switch (question.question.question_type) {
            case "T_OR_F":
                return (
                    <Grid container spacing={2}>
                        {
                            question.question.answers.map((item, index) => {
                                return (
                                    <Grid item xs={6} key={index}>
                                        <Button
                                            variant="outlined"
                                            className={`btn-card __answer${pressAnswer.id === item.id && snackOpen ? " shake active" : pressAnswer.id === item.id ? " active" : ""}`}
                                            onClick={() => pressButton(item.id, question.question.id_question)}
                                        >
                                            <div className="answer-type-word">
                                                <Typography variant="body2" component="p">
                                                    {ReactHtmlParser(item.answer)}
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            case "FILL_WORD":
                let wordLength = question.question.character_length.map(Number).reduce((accum, curr) => accum + curr);
                let wordLengthArr = question.question.character_length.map(Number);
                let isEnabledBackspace = typedWord.length !== 0 ? true : false;
                let isEnabledSend = typedWord.length === wordLength ? true : false;
                props.secretWord(word);

                if (wordEmpty) {
                    setWord(() => createSecretWord());
                    setWordEmpty(false);
                }

                function createSecretWord(type, action) {
                    const oldArr = type === "new" ? [...typedWord, action] : type === "delete" ? typedWord.slice(0, -1) : [];
                    const newArr = [];
                    const splitArr = [];
                    let numSize = 0;

                    for (let i = 0; i < wordLength; i++) {
                        newArr[i] = oldArr[i] ? oldArr[i] : "";
                    }

                    if (wordLengthArr.length > 1) {
                        wordLengthArr.map((size, i) => {
                            if (i === 0) {
                                splitArr[i] = newArr.slice(0, size);
                            } else {
                                splitArr[i] = newArr.slice(numSize, numSize + size);
                            }

                            numSize = numSize + size;

                            return splitArr[i]
                        })

                        return [...splitArr];
                    } else {
                        return [[...newArr]];
                    }
                }

                function typeWord(letter, index) {
                    if (typedWord.length < wordLength) {
                        setActiveKey([...activeKey, index]);
                        setTypedWord([...typedWord, letter]);
                        setWord(() => createSecretWord("new", letter));
                    }
                }

                function backspace() {
                    if (typedWord.length !== 0) {
                        setActiveKey(activeKey.slice(0, -1));
                        setTypedWord(typedWord.slice(0, -1));
                        setWord(() => createSecretWord("delete"));
                    }
                }

                function sendSecretWordAnswer() {
                    let wordMergeArr = [];
                    let answer = '';

                    if (wordLengthArr.length > 1) {
                        wordMergeArr = word.map((item, index) => word[index].join(''));
                        answer = wordMergeArr.join(' ');
                    } else {
                        answer = word[0].join('');
                    }

                    return sendAnswer(answer, question.question.id_question);
                }

                return (
                    <Grid container justify="center">
                        <div className="answer-type-secret-word">
                            {
                                question.question.answers.map((item, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            className={`btn-card __answer${activeKey.includes(index) ? " active" : ""}`}
                                            disabled={activeKey.includes(index) ? true : false}
                                            onClick={() => typeWord(item.answer, index)}
                                        >
                                            <div className="answer-type-keyboard">
                                                <Typography variant="h3" component="p">
                                                    {item.answer}
                                                </Typography>
                                            </div>
                                        </Button>
                                    )
                                })
                            }

                            <Button
                                className={`btn-backspace btn-card __answer`}
                                variant="outlined"
                                onClick={() => backspace()}
                                disabled={!isEnabledBackspace}
                            >
                                <Icon ic="backspace" />
                            </Button>

                            <Button className={`btn-check btn-card __answer${isEnabledSend ? ' active' : ''}`}
                                variant="outlined"
                                onClick={() => sendSecretWordAnswer()}
                                disabled={!isEnabledSend}
                            >
                                <Icon ic="check" className="mr-2" /> {intl.get("BTN_SEND")}
                            </Button>
                        </div>
                    </Grid>
                )
            case "QUIZ_IMAGE_ANSWERS":
                return (
                    <Grid container spacing={2}>
                        {
                            question.question.answers.map((item, index) => {
                                return (
                                    <Grid item xs={6} key={index} className="answer-type-image">
                                        <Button
                                            variant="outlined"
                                            className={`btn-card __answer${pressAnswer.id === item.id && snackOpen ? " shake active" : pressAnswer.id === item.id ? " active" : ""}`}
                                            onClick={() => pressButton(item.id, question.question.id_question)}
                                        >
                                            <img src={imageParser(item.image_answer)} className="img-fluid rounded-lg" alt={`Answer ${index}`} />
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            case "OPEN_ANSWER":
                return (
                    <Formik
                        initialValues={{
                            open_answer: ''
                        }}
                    >
                        {
                            ({ values, handleBlur, handleChange }) => (
                                <Grid container justify="center" className="answer-type-open-answer" spacing={2}>
                                    <Grid item xs={12} className="btn-card card __answer">
                                        <InputBase
                                            fullWidth
                                            multiline
                                            id="open_answer"
                                            name="open_answer"
                                            placeholder={intl.get("LABEL_ENTER_YOUR_ANSWER")}
                                            value={values.open_answer}
                                            inputProps={{ 'aria-label': 'open_answer' }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} align="center">
                                        <Button
                                            className={`btn-check btn-card __answer${values.open_answer.length > 0 ? ' active' : ''}`}
                                            disabled={values.open_answer.length === 0}
                                            variant="outlined"
                                            onClick={() => sendAnswer(values.open_answer, question.question.id_question)}
                                        >
                                            <Icon ic="check" className="mr-2" /> {intl.get("BTN_SEND")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Formik>

                )
            case "GRADE_ANSWER":
                return (
                    <Grid container justify="center" spacing={2}>
                        {
                            question.question.answers.map((item, index) => {
                                return (
                                    <Grid item xs key={index}>
                                        <Button
                                            variant="outlined"
                                            className={`btn-card __answer${pressAnswer.id === item.id && snackOpen ? " shake active" : pressAnswer.id === item.id ? " active" : ""}`}
                                            onClick={() => pressButton(item.id, question.question.id_question)}
                                        >
                                            <div className="answer-type-word">
                                                <Typography variant="body2" component="p">
                                                    {ReactHtmlParser(item.answer)}
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            case "RANGE_ANSWER":
                return (
                    <Formik
                        initialValues={{
                            range_answer: ''
                        }}
                    >
                        {
                            ({ values, handleBlur, handleChange }) => (
                                <Grid container justify="center" className="answer-type-range-answer" spacing={2}>
                                    <Grid item xs={12} className="btn-card card __answer">
                                        <InputBase
                                            fullWidth
                                            id="range_answer"
                                            name="range_answer"
                                            type="number"
                                            placeholder={intl.get("LABEL_ENTER_YOUR_ANSWER")}
                                            value={values.range_answer}
                                            inputProps={{ 'aria-label': 'range_answer' }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} align="center">
                                        <Button
                                            className={`btn-check btn-card __answer${values.range_answer.length > 0 ? ' active' : ''}`}
                                            disabled={values.range_answer.length === 0}
                                            variant="outlined"
                                            onClick={() => sendAnswer(values.range_answer, question.question.id_question)}
                                        >
                                            <Icon ic="check" className="mr-2" /> {intl.get("BTN_SEND")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Formik>
                )
            case "VIDEO_ANSWER":
                return (
                    <QrVideoRecorder
                        idQuestion={question.question.id_question}
                        sendAnswer={sendAnswer}
                        upload={true}
                    />
                )
            case "IMAGE_ANSWER":
                return (
                    <QrImageUpload
                        idQuestion={question.question.id_question}
                        sendAnswer={sendAnswer}
                    />
                )
            case "SELECT_ANSWERS":
                function pressMultiAnswers(answer) {
                    setAnswerAlert(true)
                    if (multiAnswers.includes(answer)) {
                        return setMultiAnswers(multiAnswers.filter(item => item !== answer));
                    } else {
                        return setMultiAnswers([...multiAnswers, answer]);
                    }
                }

                return (
                    <Grid container spacing={2} justify="center" className="answer-type-multi-answer">
                        {
                            question.question.answers.map((item, index) => {
                                let history = help.answer_history && help.answer_history.find(help => help.answer === item.id);
                                let eliminate = help.answers_to_eliminate && help.answers_to_eliminate.includes(item.id);

                                return (
                                    <Grid item xs={12} key={index}>
                                        <Button
                                            variant="outlined"
                                            className={`btn-card __answer${multiAnswers.includes(item.id) ? " active" : ""} 
                                            ${view ? item.answer_right === "Y" ? "answer-view-success" : "answer-view-danger" : ""}
                                            `}
                                            onClick={() => pressMultiAnswers(item.id)}
                                            disabled={eliminate || view}
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                                <div className="answer-type-text">
                                                    <div className="answer-option">
                                                        <Icon ic={multiAnswers.includes(item.id) ? "checkbox_checked" : "checkbox_unchecked"} />
                                                    </div>
                                                    <Typography variant="body2" component="p">
                                                        {ReactHtmlParser(item.answer)}
                                                    </Typography>
                                                </div>
                                                {
                                                    history ?
                                                        <Typography variant="h5" component="span" className="answer-help">
                                                            {history.answer_percent}
                                                        </Typography>
                                                        : null
                                                }
                                            </div>
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={12} align="center">
                            <Button
                                className={`btn-check btn-card __answer${multiAnswers.length > 0 ? ' active' : ''}`}
                                disabled={multiAnswers.length === 0}
                                variant="outlined"
                                onClick={() => sendAnswer(multiAnswers.join(','), question.question.id_question)}
                            >
                                <Icon ic="check" className="mr-2" /> {intl.get("BTN_SEND")}
                            </Button>
                        </Grid>
                    </Grid>
                )
            default:
                return (
                    <Grid container spacing={2}>
                        {
                            question.question.answers.map((item, index) => {
                                let history = help.answer_history && help.answer_history.find(help => help.answer === item.id);
                                let eliminate = help.answers_to_eliminate && help.answers_to_eliminate.includes(item.id);

                                return (
                                    <Grid item xs={12} key={index}>
                                        <Button
                                            variant="outlined"
                                            className={`btn-card __answer${pressAnswer.id === item.id && snackOpen ? " shake active" : pressAnswer.id === item.id ? " active" : ""}
                                            ${view ? item.answer_right === "Y" ? "answer-view-success" : "answer-view-danger" : ""}
                                            `}
                                            onClick={() => pressButton(item.id, question.question.id_question)}
                                            disabled={eliminate || view}
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                                <div className="answer-type-text">
                                                    <div className="answer-option">
                                                        <Typography variant="h6" component="span">
                                                            {numberToLetter(view ? parseInt(item.id) : (index + 1))}
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="body2" component="p">
                                                        {ReactHtmlParser(item.answer)}
                                                    </Typography>
                                                </div>
                                                {
                                                    history ?
                                                        <Typography variant="h5" component="span" className="answer-help">
                                                            {history.answer_percent}
                                                        </Typography>
                                                        : null
                                                }
                                            </div>
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
        }
    }

    return (
        <Container maxWidth="md" className="qr-game-answers pt-4 pb-4">
            {
                !timeOut ?
                    <Grid container justify="center">
                        <Grid item xs={12} sm={10} lg={8}>
                            <ButtonAnswer />
                        </Grid>
                        <SnackBar
                            open={snackOpen}
                            message={snackMessage}
                            status={snackStatus}
                            time={3}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            closeSnack={() => setSnackOpen(false)}
                        />
                    </Grid>
                    :
                    <Redirect to={{
                        pathname: `${history.location.pathname.replace(/./g, '')}/exam`,
                        state: {
                            origin: 'questions'
                        }
                    }} />
            }
        </Container>
    )
}

import React from 'react';
import intl from 'react-intl-universal';
import { Dialog, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import Feedback from '../../../../components/Game/partials/Feedback';
import { Icon } from '../../../../components/Images/Images';

export default function DialogQuestionFeedback(props) {
    const { open, close, transition, exam, question, order } = props;

    return (
        <Dialog id="dialog-question-feedback" fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={close} TransitionComponent={transition}>
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("LABEL_FEEDBACK")}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {exam}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>

            {/* Feedback */}
            <Feedback
                question={question}
                order={order}
            />
        </Dialog>
    )
}
import React from 'react';
import useReactRouter from 'use-react-router';
import QrLayout from '../../components/QrLayout';
import { Menu } from '../../components/Menu';

export default function MenuMore(props) {
    const { history } = useReactRouter();

    return (
        <QrLayout
            id="more"
            history={history}
            appbar="none"
            bottomNavigation
        >
            <Menu more={true} />
        </QrLayout>
    )
}
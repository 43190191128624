import * as React from 'react';
import config from '../../../config';
import AssaiCharCourseFlashcardsFinishedStars2 from '../projects/assai/chars/CharCourseFlashcardsFinishedStars2';
import CarrefourCharCourseFlashcardsFinishedStars2 from '../projects/carrefour/chars/CharCourseFlashcardsFinishedStars2';

const project = config.app.name_project.toLowerCase()
let theme = require(`../../../assets/scss/projects/${project}/_export.scss`)

function CharCourseFlashcardsFinishedStars2(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                <defs>
                    <path id="prefix__b" d="M0 0h180v63H0z" />
                    <path id="prefix__d" d="M0 0h31.5v31.5H0z" />
                    <path id="prefix__f" d="M0 0h40.5v40.5H0z" />
                    <path id="prefix__h" d="M0 0h31.5v31.5H0z" />
                    <filter
                        x="-7.6%"
                        y="-6.7%"
                        width="115.2%"
                        height="113.5%"
                        filterUnits="objectBoundingBox"
                        id="prefix__a"
                    >
                        <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                        />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <circle fill="#CFD8DC" cx={90} cy={90} r={81} />
                    <path
                        d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                        fill="#000"
                        opacity={0.054}
                    />
                    <path
                        d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                        fill="#000"
                        opacity={0.15}
                    />
                    <path
                        d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                        fill="#FFF"
                        opacity={0.4}
                    />
                    <g filter="url(#prefix__a)" transform="translate(21 11)">
                        <path
                            d="M134.141 94.828L120.94 82.6c-4.303-3.984-11.998-4.022-16.01.248-4.01 4.274-5.886 12.616-1.582 16.6l16.72 7.742c4.3 3.98 10.594 6.86 14.6 2.586a10.522 10.522 0 00-.526-14.948M61.455 104.51c-4.3.747-8.035-.98-12.492-.345l-20.266-.098c-10.295 1.26-26.149 5.154-27.335-2.804-3.828-25.636 11.84-17.754 11.829-17.872-1.903-16.256 15.127-15.83 8.784-1.33l34.632 4.636"
                            fill="#FDC436"
                        />
                        <path
                            d="M110.569 79.757l-64.956 1.498c-11.553 22.996-.955 56.704.96 58.7l3.295 10.726c1.492 4.798 10.431 3.636 15.262 2.16 4.825-1.48 3.784-4.9 2.294-9.692l-1.084-3.506 16.964-.058h.824c-2.852 5.394-.699 14.828 4.293 15.566 4.998.738 13.539.904 14.282-4.054l1.903-17.716 1.192-43.814 2.547-8.916"
                            fill="#11496F"
                        />
                        <path
                            d="M101.037 80.564c4.488 0 3.253 19.312 3.9 22.636 2.063.124-.05 11.464-.05 11.464l-.597 13.564s-15.504 9.38-36.226 6.72c-16.281-2.09-26.062-9.358-26.062-9.358l-2.406-19.078s1.7-6.734 3.1-15.091c1.245-7.425.82-8.32 2.19-10.078l56.151-.779z"
                            fill="#46CA64"
                        />
                        <path
                            d="M112.708 104.99l19.72-13.536-9.604-8.04c-5.093-3.323-8.884-4.591-11.373-3.806-2.488.785-5.82 2.204-9.996 4.257l3.053 21.125 1.095 2.328.606-5.195 6.499 2.867z"
                            fill="#46CA64"
                        />
                        <path
                            d="M105.44 100.428l-.087 7.986s6.132 7.016 8.951-3.92c-.064.236-8.864-4.066-8.864-4.066zm2.531-2.434l5.232 1.734c10.475 4.496 18.987 9.408 16.617 12.168l-11.418 11.222c-5.584 6.262-15.314 8.68-20.238 4.352v.252c-1.188-1.512-14.004-3.51-4.994-10.576-4.245-.63 2.896-13.714 10.675-10.09 0 0-.385-9.89.06-10.44l4.066 1.378z"
                            fillOpacity={0.1}
                            fill="#000"
                        />
                        <path
                            d="M136.587 99.728c2.537 6.726-.592 8.378-2.96 11.146l-11.412 11.222c-5.585 6.262-15.388 10.866-20.31 6.536l-.51-.468c-7.965-6.02-13.428-4.98-4.419-12.04-8.224-.614 7.14-11.362 12.107-6.052 0 0 1.665-1.27 4.452-4.135 1.67-1.718 10.786-4.945 10.786-4.945"
                            fill="#FDC436"
                        />
                        <path
                            d="M37.383 46.356s.266 25.054.227 25.168c.173 4.124 3.921 13.44 15.192 15.098 13.466 1.976 43.074 3.106 43.074 3.106s12.687-1.508 13.418-15.022c.038.226-.14-25.62-.14-25.62l-71.771-2.73"
                            fillOpacity={0.1}
                            fill="#000"
                        />
                        <path
                            d="M33.295 42.991s-.292 25.124-.33 25.238c.096 4.13 3.29 15.356 16.547 15.49 14.648.158 49.731.524 49.731.524s15.955-.996 17.137-14.572c.039.232.786-27.94.786-27.94L33.853 39.75"
                            fill="#FDC436"
                        />
                        <path
                            d="M89.966.943c3.951.502 2.286.166 5.026.818 14.753 4.066 22.498 22.41 22.107 37.004l-.13 4.79c.157.104-83.592-1.954-83.56-2.098l.136-5.09C33.935 21.775 42.653 3.9 57.603.691c4.311-.928 9.342-.69 13.414 1.102 2.207.97 3.934 2.38 5.155 4.142 1.317-1.686 3.117-3 5.37-3.84C84.592.927 86.804.991 89.966.943"
                            fill="#2D2F2B"
                        />
                        <path
                            fill="#46CA64"
                            d="M44.984 83.185l-15.99-1.224-2.244 23.48 14.724 1.856z"
                        />
                        <path
                            fill="#212321"
                            opacity={0.08}
                            d="M38.285 106.88l3.847-24.068-2.223 24.067z"
                        />
                        <path
                            d="M87.738 65.962c-6.484.108-11.829-4.776-11.927-10.904-.103-6.128 5.074-11.184 11.559-11.286 6.49-.108 11.83 4.776 11.933 10.904.103 6.128-5.074 11.184-11.565 11.286"
                            fill="#212321"
                        />
                        <path
                            d="M91.3 57.088c-3.165.048-5.767-2.332-5.817-5.32-.055-2.988 2.472-5.454 5.638-5.508 3.162-.048 5.77 2.332 5.818 5.32.048 2.988-2.477 5.454-5.639 5.508"
                            fill="#FFF"
                        />
                        <path
                            d="M56.413 66.113c-6.49.102-11.831-4.776-11.934-10.908-.102-6.13 5.075-11.18 11.565-11.288 6.485-.106 11.829 4.778 11.928 10.904.102 6.128-5.075 11.184-11.56 11.292"
                            fill="#212321"
                        />
                        <path
                            d="M59.969 57.233c-3.162.048-5.77-2.332-5.818-5.32-.048-2.988 2.473-5.454 5.639-5.502 3.165-.054 5.77 2.326 5.818 5.314.048 2.988-2.473 5.454-5.639 5.508M60.066 69.041s22.609 4.232 38.451-5.18c.548-.322-4.452 16.266-22.874 16.568-12.161-1.84-14.985-10.72-15.577-11.388"
                            fill="#FFF"
                        />
                        <path
                            d="M60.066 69.041S76.576 83.58 98.55 63.89c.471-.42-2.988 14.86-19.11 17.078-18.658 2.57-18.78-11.258-19.373-11.926"
                            fill="#212321"
                        />
                        <path
                            d="M113.57 38.63l-35.343-.996.654-23.8c.107-3.924 2.05-6.084 4.924-7.14 3.298-1.206 7.449-1.108 10.812-.204 2.783.754 5.262 2.116 7.448 3.924-1.266 2.042-3.463 2.764-5.852 2.154-1.4-.36-2.678-.818-4.134-.978-4.768-.518-9.35 2.588-10.707 7.246-.622 2.014.373 3.656 1.559 3.97 1.309.344 2.676-.206 3.135-2.182 1.106-4.636 5.405-4 8.907-3.102 4.13 1.062 8.178-.23 10.691-3.418a32.108 32.108 0 013.01 4.544c-1.165.76-2.202 1.81-3.042 3.134-.794 1.25-1.363 2.648-2.183 3.86-1.559 2.31-4.076 2.854-6.56 1.772-1.326-.582-2.518-1.238-3.934-1.626-4.623-1.282-9.636 1.056-11.706 5.428-.924 1.89-.207 3.672.92 4.172 1.241.55 2.679.232 3.434-1.652 1.824-4.4 5.965-3.08 9.285-1.632 4.705 2.056 9.817.742 12.733-3.582.868-1.286 1.442-2.692 2.262-3.978.292-.464.616-.85.973-1.174 1.927 5.034 2.847 10.458 2.714 15.26m-75.518-2.126l35.338.994.656-23.798c.105-3.932-1.71-6.192-4.523-7.404-3.226-1.394-7.376-1.53-10.788-.812-2.815.592-5.368 1.814-7.65 3.5 1.154 2.11 3.309 2.95 5.725 2.476 1.42-.28 2.719-.662 4.183-.744 4.789-.246 9.195 3.114 10.296 7.836.506 2.046-.576 3.628-1.782 3.876-1.325.27-2.662-.356-3.009-2.354-.85-4.694-5.175-4.296-8.72-3.596-4.182.824-8.153-.69-10.487-4.012a31.983 31.983 0 00-3.258 4.362c1.124.83 2.102 1.932 2.87 3.3.723 1.294 1.218 2.726 1.963 3.98 1.436 2.396 3.915 3.08 6.454 2.138 1.357-.506 2.58-1.094 4.018-1.406 4.688-1.018 9.567 1.594 11.387 6.084.824 1.934.004 3.678-1.145 4.114-1.265.474-2.687.076-3.341-1.846-1.575-4.496-5.784-3.41-9.173-2.148-4.81 1.786-9.851.182-12.522-4.292-.797-1.336-1.292-2.774-2.037-4.104a5.45 5.45 0 00-.91-1.222c-2.204 4.916-3.416 10.28-3.545 15.078"
                            fill="#FFF"
                        />
                    </g>
                    <g>
                        <g transform="translate(0 117)">
                            <mask id="prefix__c" fill="#fff">
                                <use xlinkHref="#prefix__b" />
                            </mask>
                            <g mask="url(#prefix__c)">
                                <path
                                    d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714-.199.029-.405.013-.61-.052-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                    fill="#BD483B"
                                />
                                <path
                                    d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                    fill="#93382E"
                                />
                                <path
                                    d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                    fill="#E65748"
                                />
                            </g>
                        </g>
                        <g transform="rotate(-12 659.94 -89.693)">
                            <mask id="prefix__e" fill="#fff">
                                <use xlinkHref="#prefix__d" />
                            </mask>
                            <g mask="url(#prefix__e)" fill="#FDC436">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                        <g transform="translate(69.755 123.75)">
                            <mask id="prefix__g" fill="#fff">
                                <use xlinkHref="#prefix__f" />
                            </mask>
                            <g mask="url(#prefix__g)" fill="#FDC436">
                                <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                            </g>
                        </g>
                        <g transform="rotate(12 -554.159 616.797)">
                            <mask id="prefix__i" fill="#fff">
                                <use xlinkHref="#prefix__h" />
                            </mask>
                            <g mask="url(#prefix__i)" fill="#BD483B">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCourseFlashcardsFinishedStars2 medal={theme["silver"]} />
        )
    } else if (config.app.name_project === "CARREFOUR") {
        return (
            <CarrefourCharCourseFlashcardsFinishedStars2 medal={theme["silver"]} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (config.learn.trail.chars && config.learn.trail.type === "stars") {
            if (gender === "male") {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__b" d="M0 0h180v63H0z" />
                            <path id="prefix__d" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__f" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__h" d="M0 0h31.5v31.5H0z" />
                            <filter
                                x="-10%"
                                y="-8.8%"
                                width="119.9%"
                                height="117.5%"
                                filterUnits="objectBoundingBox"
                                id="prefix__a"
                            >
                                <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                                <feGaussianBlur
                                    stdDeviation={2}
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                />
                                <feColorMatrix
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                />
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1" />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle fill={theme["silver"]} cx={90} cy={90} r={81} />
                            <path
                                d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                                fill="#000"
                                opacity={0.054}
                            />
                            <path
                                d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                fill="#000"
                                opacity={0.15}
                            />
                            <path
                                d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                                fill="#FFF"
                                opacity={0.4}
                            />
                            <g filter="url(#prefix__a)" transform="translate(32 8)">
                                <path
                                    d="M50.049 89.466l-.093 6.126S26.886 101.34 7.418 84.56l9.31-5.408s10.98 10.904 33.321 10.313"
                                    className="svg-char-shirt-shade-1"
                                />
                                <path
                                    d="M17.488 74.556c1.876 6.05-.381 9.17-5.19 11.64C7.99 88.41 2.3 85.833.668 81.777c-2.092-5.2 1.002-9.04 5.394-11.076 4.66-2.16 9.91-1.034 11.425 3.856"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M17.077 73.515s-1.942-6.031-2.677-8.973c-.735-2.942-4.767-2.03-4.978 1.034-.21 3.063 1.683 6.214 3.09 7.487 1.408 1.27 4.19.764 4.565.452"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M.638 81.444c1.71 4.85 5.81 6.893 8.817 5.833 3.396-1.197 3.818-6.798 2.228-10.38-1.774-3.997-4.561-6.134-7.505-5.205-2.87.906-5.386 4.516-3.54 9.752"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M48.56 89.278s-3.51 13.273-4.598 27.154c0 0 4.597 2.03 17.147 2.03 6.843 0 14.669-.829 18.537-2.03 0 0-.482-8.489-3.491-26.007 0 0-4.086.584-12.783.584-8.698 0-14.812-1.73-14.812-1.73"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M60.18 98.808c1.44 2.962 3.957 9.744 4.127 11.758.068.804-4.176 4.85-5.864 5.13-1.465.245-5.618-3.664-5.863-4.886-.128-.645 2.93-9.285 4.083-11.94.722-1.66 2.729-1.687 3.518-.062zm-1.736-7.666a2.566 2.566 0 11-.002 5.133 2.566 2.566 0 01.002-5.133z"
                                    className="svg-char-necktie"
                                />
                                <path
                                    d="M75.86 90.358l.755 6.171s11.585 1.541 11.948 6.298c.288 3.77-10.562 5.28-10.562 5.28l1.632 8.173s16.474-2.891 16.726-12.699c.252-9.807-14.174-13.223-20.498-13.223"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M72.779 106.523c-4.55 2.83-2.227 7.29.696 10.387 2.845 3.014 6.08 4.412 9.807 1.257 3.27-2.766 1.76-7.67-.377-10.184-2.15-2.53-7.419-3.144-10.126-1.46"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M49.016 48.834c.256 9.051-5.143 13.321-12.358 13.525-7.217.204-12.172-13.02-12.61-18.965-.712-9.674 4.376-16.694 11.592-16.897 7.216-.204 13.12 13.286 13.376 22.337"
                                    className={`svg-char-${color}-hair-shade-2`}
                                />
                                <path
                                    d="M100.612 75.919c-3.144 5.374-9.104 6.582-12.562 5.363-3.443-1.218-1.614-4.957.063-9.77 1.675-4.815 5.834-7.73 9.285-6.511 3.451 1.218 5.81 6.481 3.214 10.918"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M97.091 75.038c-1.027 2.702-5.096 4.114-7.255 3.572-2.161-.542-1.282-2.736-.564-5.535.714-2.8 3.048-4.63 5.207-4.086 2.16.543 3.812 2.894 2.612 6.049"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M25.29 66.066c1.295 4.321 5.154 7.322 7.944 7.077 2.779-.25 2.285-3.689 2.142-7.97-.141-4.283-2.516-7.554-5.302-7.305-2.785.248-6.004 4.14-4.784 8.198"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M28.014 65.215c-.105 2.436 2.826 5.342 4.518 5.366 1.696.025 1.546-1.951 1.65-4.386.108-2.434-1.182-4.427-2.876-4.45-1.694-.024-3.188 1.037-3.292 3.47"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M50.274 90.177s-26.65-8.894-18.87-45.07c7.78-36.178 31.956-34.848 44.108-31.923 12.152 2.926 23.67 11.904 23.782 22.965.388 38.298-13.939 49.852-21.957 53.582-4.39 2.043-16.275 4.6-27.063.446"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M81.29 62.66c-.559 0-1.041-.427-1.113-1.012-.3-2.466-2.022-3.998-4.496-3.998-2.207 0-4.051 1.448-4.934 3.875-.218.598-.865.9-1.444.676-.58-.224-.874-.89-.657-1.488 1.224-3.367 3.854-5.376 7.035-5.376 3.584 0 6.286 2.42 6.723 6.024.077.633-.36 1.211-.974 1.291a1.134 1.134 0 01-.14.009M49.361 57.716c.107 3.773-1.915 6.891-4.518 6.965-2.602.073-4.797-2.926-4.904-6.699-.106-3.773 1.917-6.89 4.519-6.964 2.602-.073 4.797 2.925 4.903 6.698"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M47.87 54.641c.037 1.342-.682 2.451-1.608 2.478-.925.026-1.706-1.041-1.744-2.383-.038-1.343.681-2.451 1.607-2.477.926-.027 1.706 1.04 1.744 2.382"
                                    fill="#FFF"
                                />
                                <path
                                    d="M60.995 66.063a3.518 3.518 0 11-7.033.2 3.518 3.518 0 017.033-.2"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M52.175 77.208s17.725-4.523 29.24-6.357c0 0 1.427 10.74-10.672 13.879-15.227 3.95-18.568-7.522-18.568-7.522"
                                    className="svg-char-mouth"
                                />
                                <path
                                    d="M70.743 84.73c4.771-1.238 7.434-3.658 8.915-6.135-2.534-.905-6.001-1.171-9.71-.575-5.57.896-10.012 3.47-11.337 6.27 2.794 1.293 6.705 1.849 12.132.44"
                                    className="svg-char-mouth-tongue"
                                />
                                <path
                                    d="M96.06 64.674s-12.636-11.326-6.083-27.438c0 0-3.635-2.072-19.28 1.606-15.4 3.62-38.809 6.764-50.13-4.21-8.516-8.254-.388-17.51 7.934-12.33 0 0-4.699-.928-3.86-8.81.93-8.735 12.851-11.193 19.511-4.691 0 0-4.044-3.006-1.04-6.766 3.385-4.237 10.683-1.47 12.875 4.733 0 0 26.98-3.416 41.24 12.745 10.627 12.044 10.134 27.958-1.166 45.16"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M64.511 37.852c-11.031 2.16-26.7 2.13-36.551-.778-7.974-2.353-10.345-8.342-10.96-10.54-.281 2.42.695 5.315 3.567 8.098 11.32 10.974 34.73 7.83 50.13 4.21 15.645-3.678 19.28-1.606 19.28-1.606-6.058-3.967-19.764-.501-25.466.616M95.889 51.085c-5.935.38-7.747-3.604-7.747-3.604s.067.985.223 2.554c1.396 8.991 7.696 14.639 7.696 14.639 4.462-6.793 7.232-13.384 8.305-19.616-1.516 2.632-4.258 5.757-8.477 6.027"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M36.917 7.949c.215.92-1.083 2.01-2.898 2.433-1.817.425-3.463.023-3.679-.897-.213-.92 1.083-2.008 2.9-2.433 1.817-.424 3.462-.022 3.677.897"
                                    fillOpacity={0.1}
                                    fill="#FFF"
                                />
                            </g>
                            <g>
                                <g transform="translate(0 117)">
                                    <mask id="prefix__c" fill="#fff">
                                        <use xlinkHref="#prefix__b" />
                                    </mask>
                                    <g mask="url(#prefix__c)">
                                        <path
                                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714-.199.029-.405.013-.61-.052-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                            className="svg-ribbon-stars-back"
                                        />
                                        <path
                                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                            className="svg-ribbon-stars-shadow"
                                        />
                                        <path
                                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                            className="svg-ribbon-stars-front"
                                        />
                                    </g>
                                </g>
                                <g transform="rotate(-12 659.94 -89.693)">
                                    <mask id="prefix__e" fill="#fff">
                                        <use xlinkHref="#prefix__d" />
                                    </mask>
                                    <g mask="url(#prefix__e)" className="svg-stage-star">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                                <g transform="translate(69.755 123.75)">
                                    <mask id="prefix__g" fill="#fff">
                                        <use xlinkHref="#prefix__f" />
                                    </mask>
                                    <g mask="url(#prefix__g)" className="svg-stage-star">
                                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                    </g>
                                </g>
                                <g transform="rotate(12 -554.159 616.797)">
                                    <mask id="prefix__i" fill="#fff">
                                        <use xlinkHref="#prefix__h" />
                                    </mask>
                                    <g mask="url(#prefix__i)" className="svg-ribbon-stars-back">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            } else {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__b" d="M0 0h180v63H0z" />
                            <path id="prefix__d" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__f" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__h" d="M0 0h31.5v31.5H0z" />
                            <filter
                                x="-9.8%"
                                y="-8.8%"
                                width="119.6%"
                                height="117.5%"
                                filterUnits="objectBoundingBox"
                                id="prefix__a"
                            >
                                <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                                <feGaussianBlur
                                    stdDeviation={2}
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                />
                                <feColorMatrix
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                />
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1" />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle fill={theme["silver"]} cx={90} cy={90} r={81} />
                            <path
                                d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                                fill="#000"
                                opacity={0.054}
                            />
                            <path
                                d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                fill="#000"
                                opacity={0.15}
                            />
                            <path
                                d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                                fill="#FFF"
                                opacity={0.4}
                            />
                            <g filter="url(#prefix__a)" transform="translate(32 8)">
                                <path
                                    d="M73.205 90.206l.191 5.798s11.615 2.692 11.88 6.659c.25 3.772-10.562 5.28-10.562 5.28l1.632 8.172s16.473-2.89 16.724-12.698c.252-9.806-13.578-12.32-19.865-13.211"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M50.049 89.466l-.093 6.126S26.886 101.34 7.418 84.56l9.31-5.408s10.98 10.904 33.321 10.313"
                                    className="svg-char-shirt-shade-1"
                                />
                                <path
                                    d="M17.488 74.556c1.876 6.05-.381 9.17-5.19 11.64C7.99 88.41 2.3 85.833.668 81.777c-2.092-5.2 1.002-9.04 5.394-11.076 4.66-2.16 9.91-1.034 11.425 3.856"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M.638 81.444c1.71 4.85 5.81 6.893 8.817 5.833 3.396-1.197 3.818-6.798 2.228-10.38-1.774-3.997-4.561-6.134-7.505-5.205-2.87.906-5.386 4.516-3.54 9.752"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M17.077 73.515s-1.942-6.031-2.677-8.973c-.735-2.942-4.767-2.03-4.978 1.034-.21 3.063 1.683 6.214 3.09 7.487 1.408 1.27 4.19.764 4.565.452"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M49.93 89.359s-.578 11.933-4.08 25.855c0 0 3.73 2.085 14.006 2.255 5.602.092 12.023-.627 15.21-1.772 0 0-1.411-3.925-2.166-8.051-.772-4.22.726-9.94-.264-17.912 0 0-3.356.527-10.477.41-7.12-.118-12.228-.785-12.228-.785"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M76.054 115.925s-1.91-4.78-3.154-8.28c-.524-1.475 1.49-9.23.702-17.573 0 0-3.513.152-9.67.15-1.173 4.73-3.45 8.583-4.67 8.766-1.237.186-2.437-1.788-3.554-8.903-2.937-.21-5.12-1.319-5.12-1.319s-2.749 4.48-3.403 6.731c-.518 1.783.72 6.182.501 7.001-.746 2.788-.701 5.13-1.56 7.38-.666 1.74-2.388 3.15-2.684 5.015 0 0 5.337 3.047 16.55 3.232 6.114.101 12.735.017 16.062-2.2"
                                    className="svg-char-suit"
                                />
                                <path
                                    d="M106.558 17.949c0 9.653-8.056 17.48-17.994 17.48s-17.993-7.827-17.993-17.48c0-9.654 8.055-17.48 17.993-17.48 9.938 0 17.994 7.826 17.994 17.48"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M100.357 33.277c-4.435 7.68-15.764 9.442-25.304 3.934-9.54-5.509-13.68-16.2-9.245-23.88C70.242 5.65 81.57 3.889 91.11 9.397c9.54 5.509 13.68 16.2 9.246 23.88"
                                    className="svg-char-ribbon"
                                />
                                <path
                                    d="M106.558 17.949c0-3.533-1.082-6.818-2.936-9.567.264 2.117.482 7.409-3.233 10.8 0 0 .617 2.468-2.468 3.702l3.461 7.329c3.2-3.156 5.176-7.485 5.176-12.264"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M29.853 64.51s-6.786-11.414-4.627-28.996c2.16-17.583 16.97-29.174 36.4-29.921 10.903-.42 21.173 2.983 28.724 8.974 9.11 7.228 13.77 18.137 11.787 30.818-2.88 18.422-10.59 28.07-14.91 31.155-8.902 6.359-42.259 15.732-57.374-12.03"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M24.21 66.082c1.137 4.22 4.791 7.23 7.5 7.07 2.697-.166 2.313-3.51 2.292-7.66-.02-4.15-2.23-7.383-4.935-7.218-2.703.163-5.927 3.844-4.857 7.808"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M26.871 65.332c-.168 2.356 2.59 5.25 4.229 5.32 1.64.07 1.55-1.847 1.718-4.203.17-2.354-1.024-4.318-2.664-4.387-1.64-.07-3.116.917-3.283 3.27"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M96.802 77.348c-3.192 5.118-8.996 6.124-12.31 4.85-3.302-1.274-1.429-4.844.327-9.46 1.754-4.615 5.86-7.326 9.17-6.051 3.308 1.275 5.448 6.436 2.813 10.661"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M93.417 76.398c-1.069 2.589-5.049 3.845-7.124 3.262-2.078-.584-1.166-2.685-.394-5.376.768-2.693 3.077-4.4 5.154-3.814 2.077.585 3.612 2.907 2.364 5.928"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M47.744 90.112s-25.562-9.34-17.04-44.16c8.522-34.819 31.897-32.87 43.585-29.705C85.977 19.412 96.884 28.42 96.69 39.135 96.02 76.23 81.83 87.027 73.964 90.42c-4.307 1.858-15.886 4.01-26.22-.309"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M77.606 66.016a1.134 1.134 0 01-.98-1.133c.023-2.432-1.5-4.149-3.974-4.481-2.205-.296-4.237.861-5.436 3.096a1.132 1.132 0 11-1.995-1.07c1.663-3.1 4.553-4.696 7.732-4.269 3.584.482 5.969 3.192 5.936 6.745a1.131 1.131 0 01-1.283 1.112"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M58.786 67.055a3.408 3.408 0 11-6.817 0 3.408 3.408 0 016.817 0"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M98.024 33.972c2.468 20.358-5.128 32.457-5.128 32.457s-6.37-3.7-6.798-16.79c-21.383-1.228-32.87-14.058-36.789-19.46-2.626 3.935-9.329 12.367-21.307 17.588 0 0-2.667-13.976 6.215-19.105 1.693-4.772 6.127-11.073 17.537-15.05 17.149-5.975 43.803 0 46.27 20.36z"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M94.657 67.612c2.673-4.319 5.63-11.44 7.174-20.408-.065-.331-.105-.517-.105-.517s-1.414 9.563-14.987 8.637c0 0 1.363 8.972 7.918 12.288"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M46.114 59.152c-.004 3.656-2.05 6.619-4.573 6.616-2.522-.003-4.562-2.97-4.558-6.626.004-3.657 2.051-6.618 4.573-6.615 2.522.003 4.563 2.97 4.558 6.625"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M44.881 55.997c-.001 1.3-.73 2.354-1.626 2.353-.898 0-1.624-1.056-1.622-2.357.002-1.301.73-2.354 1.627-2.353.897.001 1.623 1.056 1.621 2.357"
                                    fill="#FFF"
                                />
                                <path
                                    d="M47.83 78.757s17.293-3.876 28.495-5.324c0 0 1.077 10.441-10.729 13.136C50.74 89.96 47.83 78.757 47.83 78.757"
                                    className="svg-char-mouth"
                                />
                                <path
                                    d="M65.596 86.569c4.656-1.063 7.303-3.331 8.808-5.687-2.428-.95-5.777-1.305-9.385-.834-5.42.71-9.794 3.076-11.158 5.75 2.67 1.331 6.44 1.98 11.735.77"
                                    className="svg-char-mouth-tongue"
                                />
                                <path
                                    d="M49.373 29.018c.036.058 3.561 5.764 11.398 11.147 7.23 4.964 19.722 10.658 38.152 9.608a.463.463 0 01.053.924 78.496 78.496 0 01-4.456.128c-16.3 0-27.56-5.278-34.314-9.926-7.984-5.494-11.479-11.161-11.623-11.4a.463.463 0 01.79-.48zm-4.915-1.2a.463.463 0 01.202.623l-.012.023c-.3.573-6 11.245-17.63 18.665a.46.46 0 01-.64-.141.463.463 0 01.141-.64c11.676-7.447 17.262-18.22 17.316-18.327a.463.463 0 01.623-.203zm-2.86-6.425c.243.079.376.34.297.583-.038.118-3.935 11.863-14.494 21.224a.46.46 0 01-.653-.04.463.463 0 01.039-.653c10.372-9.195 14.19-20.701 14.228-20.817a.46.46 0 01.582-.297zm4.363-.116c.035.05 3.67 5.016 12.154 9.769 7.835 4.389 21.535 9.49 42.26 8.855.257-.024.469.193.477.448a.464.464 0 01-.449.477c-1.191.037-2.361.054-3.506.054-19.032 0-31.798-4.85-39.29-9.059-8.64-4.853-12.249-9.796-12.398-10.004a.463.463 0 01.752-.54zm47.782-1.539a.463.463 0 01-.383.842c-22.961-10.447-45.25-3.294-45.47-3.22a.462.462 0 11-.291-.88c.226-.073 22.856-7.336 46.144 3.258zm-49.21-6.666c.551.835-.338 2.393-1.987 3.48-1.648 1.089-3.432 1.294-3.982.46-.55-.836.338-2.394 1.987-3.482 1.649-1.087 3.431-1.292 3.982-.458z"
                                    fillOpacity={0.1}
                                    fill="#FFF"
                                />
                                <path
                                    d="M70.314 106.36c-4.55 2.83-2.227 7.288.696 10.385 2.845 3.014 6.08 4.411 9.807 1.258 3.269-2.767 1.76-7.67-.377-10.184-2.15-2.53-7.418-3.144-10.126-1.46"
                                    className={`svg-char-${color}-skin`}
                                />
                            </g>
                            <g transform="translate(0 117)">
                                <mask id="prefix__c" fill="#fff">
                                    <use xlinkHref="#prefix__b" />
                                </mask>
                                <g mask="url(#prefix__c)">
                                    <path
                                        d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                        className="svg-ribbon-stars-back"
                                    />
                                    <path
                                        d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                        className="svg-ribbon-stars-shadow"
                                    />
                                    <path
                                        d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                        className="svg-ribbon-stars-front"
                                    />
                                </g>
                            </g>
                            <g transform="rotate(-12 659.94 -89.693)">
                                <mask id="prefix__e" fill="#fff">
                                    <use xlinkHref="#prefix__d" />
                                </mask>
                                <g mask="url(#prefix__e)" className="svg-stage-star">
                                    <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                </g>
                            </g>
                            <g transform="translate(69.755 123.75)">
                                <mask id="prefix__g" fill="#fff">
                                    <use xlinkHref="#prefix__f" />
                                </mask>
                                <g mask="url(#prefix__g)" className="svg-stage-star">
                                    <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                </g>
                            </g>
                            <g transform="rotate(12 -554.159 616.797)">
                                <mask id="prefix__i" fill="#fff">
                                    <use xlinkHref="#prefix__h" />
                                </mask>
                                <g mask="url(#prefix__i)" className="svg-ribbon-stars-back">
                                    <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            }
        } else {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <g fill="none" fillRule="evenodd" transform="translate(9 9)">
                        <circle cx={81} cy={81} r={81} fill="#CFD8DC" />
                        <path
                            fill="#000"
                            d="M25.436 22.063C12.594 63.282 18.062 97.178 41.84 123.75c25.015 27.954 60.482 28.762 106.402 2.422C133.698 147.784 109.009 162 81 162c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148Z"
                            opacity={0.054}
                        />
                        <path
                            fill="#000"
                            d="M81 150.75c38.522 0 69.75-31.228 69.75-69.75S119.522 11.25 81 11.25 11.25 42.478 11.25 81 42.478 150.75 81 150.75ZM81 162c-44.735 0-81-36.265-81-81S36.265 0 81 0s81 36.265 81 81-36.265 81-81 81Z"
                            opacity={0.15}
                        />
                        <path
                            fill="#FFF"
                            d="M117.055 28.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066Z"
                            opacity={0.4}
                        />
                    </g>
                </svg>
            )
        }
    }
}

export default CharCourseFlashcardsFinishedStars2

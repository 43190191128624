import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { actions as ExamActions } from '../../../redux/ducks/exam';
import { gotoTop } from '../../../utils/utils';
import {
    Container, Typography, List, Grid, Divider, Tabs, Tab,
    useMediaQuery, AppBar, Toolbar, IconButton
} from '@material-ui/core';
import QrLayout from '../../../components/QrLayout2';
import ExamListItem from './components/ExamListItem';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import { Icon } from '../../../components/Images/Images';
import { LoadingAvatarList } from '../../../components/Loading';

function tabsProps(index) {
    return {
        id: `appbar-tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid container
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </Grid>
    );
}

export default function ExamList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [didMount, setDidMount] = useState(false);
    const [value, setValue] = React.useState(0);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const exams = useSelector(state => state.exam.exams);
    const examsDone = useSelector(state => state.exam.examsDone);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(ExamActions.getExamList({}));
            dispatch(ExamActions.getExamListDone({}));
        }

        return () => {
            dispatch(ExamActions.reset());
            gotoTop();
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    function goToExam(item) {
        return () => history.push({
            pathname: `${props.match.path}/${item.id_exam}`,
            state: {
                origin: 'list'
            }
        });
    }

    function goBack() {
        let currentOrigin = "list";

        switch (origin) {
            case 'questions':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default __tabs" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('EXAM_TITLE')}
                        </Typography>
                    </div>
                </Toolbar>

                <Tabs value={value} onChange={handleChange} aria-label="tabs" variant={isScreenUpMd ? "standard" : "fullWidth"} className="appbar-default-tabs" centered>
                    <Tab label={intl.get("EXAM_LIST_TAB_1")} {...tabsProps(0)} />
                    <Tab label={intl.get("EXAM_LIST_TAB_2")} {...tabsProps(1)} />
                </Tabs>
            </AppBar>
        )
    }

    const ToDoList = () => {
        return (
            <List>
                {
                    exams.data.map((item, index) => {
                        return (
                            <div key={index}>
                                <ExamListItem
                                    action={goToExam(item)}
                                    item={item}
                                    status="to_do"
                                />
                                {
                                    index < exams.data.length - 1
                                        ?
                                        <Divider variant="inset" />
                                        : null
                                }
                            </div>
                        )
                    })
                }
            </List>
        )
    }

    const DoneList = () => {
        return (
            <List>
                {
                    examsDone.data.map((item, index) => {
                        return (
                            <div key={index}>
                                <ExamListItem
                                    action={goToExam(item)}
                                    item={item}
                                    status="done"
                                />
                                {
                                    index < exams.data.length - 1
                                        ?
                                        <Divider variant="inset" />
                                        : null
                                }
                            </div>
                        )
                    })
                }
            </List>
        )
    }

    return (
        <QrLayout id="exam-list">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <TabPanel value={value} index={0}>
                {
                    exams && exams.load === "finished" ?
                        !exams.error ?
                            exams.data.length !== 0 ?
                                <Container maxWidth="md" className="container-list">
                                    <Grid container justify="center">
                                        <Grid item xs={12}>
                                            <ToDoList />
                                        </Grid>
                                    </Grid>
                                </Container>
                                :
                                <EmptyContent icon={<Icon ic="play_exam" className="icon-xxl" />} text={intl.get("EMPTY_EXAM")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingAvatarList limit={8} />
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                {
                    examsDone && examsDone.load === "finished" ?
                        !examsDone.error ?
                            examsDone.data.length !== 0 ?
                                <Container maxWidth="md" className="container-list">
                                    <Grid container justify="center">
                                        <Grid item xs={12}>
                                            <DoneList />
                                        </Grid>
                                    </Grid>
                                </Container>
                                :
                                <EmptyContent icon={<Icon ic="play_exam" className="icon-xxl" />} text={intl.get("EMPTY_EXAM")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingAvatarList limit={8} />
                }
            </TabPanel>
        </QrLayout>
    )
}
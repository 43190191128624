import qs from 'qs';
import axios from 'axios';
import project from '../config';
import { generateNonce, timestamp } from '../utils/utils';


// const publicIp = require('public-ip');
var refreshingToken = false;
const chunkSize = 40000;

// async function getIpUser() {
//     return publicIp.v4({
//         fallbackUrls: [
//             'https://ifconfig.co/ip'
//         ]
//     }).then(ipv4 => {
//         return ipv4;
//     }, error => {
//         return "";
//     });
// }

function getBody() {
    let result = {
        'origin': 'REACT-APP',
        'language': process.env.REACT_APP_LANGUAGE_DEFAULT,
        'version': '1',
        'json_force_array': '1',
        'timestamp': timestamp(),
        'nonce': generateNonce(10)
    }
    if (localStorage.getItem('auth.state.user') && localStorage.getItem('auth.state.user').length > 0) {
        result.id_user = JSON.parse(localStorage.getItem('auth.state.user')).id_user;
        // (async () => {
        //     result.ip_user = await getIpUser();
        // })()
        result.language = JSON.parse(localStorage.getItem('auth.state.user')).language &&
            JSON.parse(localStorage.getItem('auth.state.user')).language.length > 0
            ? JSON.parse(localStorage.getItem('auth.state.user')).language : process.env.REACT_APP_LANGUAGE_DEFAULT
    }

    if (project.app.code !== '') {
        result[project.app.code] = '1';
    }

    return result;
}

function getHeader() {
    let jwt = localStorage.getItem('auth.state.jwt') || '';
    let result = {
        headers: {
            'accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'AUTH_JWT': jwt,
        }
    }
    return result;
}

function getHeaderPublic() {
    let result = {
        headers: {
            'accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    }
    return result;
}

function interceptors(basepath) {
    getBody()
    axios.interceptors.response.use(async response => {
        return response;
    }, async err => {
        var finalResponse = null;
        const originalReq = err.config;
        if (err.response.data.messages[0] === 'JWT_SESSION_EXPIRED' && !refreshingToken && !originalReq.__isRetryRequest) {
            originalReq._retry = true;
            refreshingToken = true;
            await axios.post(basepath + '/v3/app/login/jwt/refresh', qs.stringify({
                'origin': 'REACT-APP',
                'version': '1',
                'json_force_array': '1',
                'timestamp': timestamp(),
                'nonce': generateNonce(10),
                'language': JSON.parse(localStorage.getItem('auth.state.user')).language,
                'id_user': JSON.parse(localStorage.getItem('auth.state.user')).id_user,
                'token_refresh': localStorage.getItem('auth.state.refresh_token'),
                [project.app.code]: '1'

            }), {
                headers: {
                    'accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                }
            })
                .then(async response => {
                    if (response) {
                        localStorage.setItem('auth.state.jwt', response.data.data[0].token);
                        localStorage.setItem('auth.state.refresh_token', response.data.data[0].refresh_token);
                        err.config.headers.AUTH_JWT = response.data.data[0].token;
                        finalResponse = await new Promise((resolve, reject) => {
                            resolve(axios(err.config))
                        })
                    } else {
                        return (err);
                    }
                });
            refreshingToken = false;
            return (finalResponse);
        }
        else {
            if (err.response.status !== 200 && err.response.config.url.includes('rest/v3/app/login/jwt/refresh')) {
                localStorage.clear();
                window.location.href = "/"
            }
            return Promise.reject(err);
        }
    });
}

export const qrService = async (route, data) => {
    const basepath = process.env.REACT_APP_API_BASEPATH;
    const body_default = getBody();
    interceptors(basepath, body_default);
    const config = getHeader();
    delete config.headers['Content-Type'];

    if (route === '/v3/app/courses/certificate' || route === '/v1/app/scorm/user/certificate' || route === '/v1/app/classroom/training/certificate') {
        config.responseType = "blob";
    }

    // PARA ENVIAR IMAGENS É NECESSARIO CRIAR O FORMDATA
    if (data.photo || data.file) {
        const formData = new FormData();
        data.id_user = JSON.parse(localStorage.getItem('auth.state.user')).id_user;
        formData.append('id_user', data.id_user);

        //SE O PROJETO NAO TIVER CODE PASSA 1
        if (project.app.code !== '') {
            formData.append(project.app.code, '1');
        }

        // ESSE É PARA RESPOSTAS COM MIDIA
        if (data.file) {
            for (let start = 0; start < data.file.size; start += chunkSize) {
                const chunk = data.file.slice(start, start + chunkSize + 1, data.file.type);
                formData.append('file', chunk, data.file_name);
            }
            // PRINTA OS CHUNKS CRRIADOS
            // for (var value of formData.values()) {
            //     console.log(value);
            // }
        }

        // ESSE É PARA FOTO PERFIL
        if (data.photo) {
            formData.append('photo', data.photo, 'photo');
        }

        try {
            const response = await axios.post(basepath + route, formData, config);
            return response.data.data;
        } catch (error) {
            const { response } = error;

            if (!response || !response.data || !response.data.error) {
                return Promise.reject(response);
            }
            return Promise.reject(response.data);
        }
    } else {
        const dataRequest = qs.stringify({ ...body_default, ...data });
        try {
            // console.log('request ' + route, body_default)
            // console.log('request', basepath + route, body_default, data, config)
            const response = await axios.post(basepath + route, dataRequest, config);
            // console.log('response', response)
            return response.data.data ? response.data.data : response.data;
        } catch (error) {
            //  console.log('ERROR', error)
            const { response } = error;
            if (error && error.status === 200) {
                // console.log('ERROR', error)
                return error.data.data ? error.data.data : error.data;
            }
            if (!response || !response.data || !response.data.error) {
                return Promise.reject(response);
            }
            return Promise.reject(response.data);
        }
    }
}

export const qrServicePublic = async (route, data) => {
    const basepath = process.env.REACT_APP_API_BASEPATH;
    const body_default = getBody();
    interceptors(basepath, body_default);
    const config = getHeaderPublic();
    delete config.headers['Content-Type'];
    const dataRequest = qs.stringify({ ...body_default, ...data });
    
    try {
        // console.log('request ' + route, body_default)
        // console.log('request', basepath + route, body_default, data, config)
        const response = await axios.post(basepath + route, dataRequest, config);
        // console.log('response', response)
        return response.data.data ? response.data.data : response.data;
    } catch (error) {
        //  console.log('ERROR', error)
        const { response } = error;
        if (error && error.status === 200) {
            // console.log('ERROR', error)
            return error.data.data ? error.data.data : error.data;
        }
        if (!response || !response.data || !response.data.error) {
            return Promise.reject(response);
        }
        return Promise.reject(response.data);
    }
}

export const dataCombine = (data) => {
    const body_default = getBody();
    const dataRequest = qs.stringify({ ...body_default, ...data });
    const config = getHeader(dataRequest);

    return {
        data: dataRequest,
        headers: config
    }
}

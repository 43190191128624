import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#338ee1', // Blue[200]
            main: '#0172DA', // Blue[300]
            dark: '#0066c4', // Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#338ee1', // Dark Blue[200]
            main: '#0172DA', // Dark Blue[300]
            dark: '#0066c4', // Dark Blue[400]
            contrastText: '#fff',
        },
        error: {
            light: '#fe5460', // Red[200]
            main: '#FE2A39', // Red[300]
            dark: '#cb212d', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)

import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from './Images/Images';
import config from "../config";

function SectionCategoryGrid(props) {
    const { children, action } = props;

    return (
        <div className="category-grid">
            <Scrollbars
                autoHide
                autoHeight
                autoHeightMax={500}
                autoHideTimeout={1000}
                autoHideDuration={200}
            >
                <Grid container className="category-list">
                    {children}
                </Grid>
            </Scrollbars>

            {/* TODO - COLOCADO APENAS PARA VALIDACAO DO CARREFOUR (SE CARREFOUR NAO RETORNA) */}
            {config.app.name_project === 'CARREFOUR' ?
                null :
                <div className="category-grid-button">
                    <IconButton className="btn-primary" onClick={action}>
                        <Icon ic="arrow_right" />
                    </IconButton>
                </div>
            }
        </div>
    );
}

export default SectionCategoryGrid;

import * as React from "react"

function CharTrailFlashcardsFinishedStars3(props) {
    const { medal } = props

    return (
        <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
            <defs>
                <filter
                    x="-8.1%"
                    y="-9.3%"
                    width="116.1%"
                    height="118.6%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars3__a"
                >
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter
                    x="-7.9%"
                    y="-7.9%"
                    width="117.5%"
                    height="117.5%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars3__d"
                >
                    <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196213942 0"
                        in="shadowOffsetOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter
                    x="-6.2%"
                    y="-6.2%"
                    width="113.6%"
                    height="113.6%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars3__g"
                >
                    <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196213942 0"
                        in="shadowOffsetOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter
                    x="-7.9%"
                    y="-7.9%"
                    width="117.5%"
                    height="117.5%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars3__j"
                >
                    <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196213942 0"
                        in="shadowOffsetOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <path id="CharTrailFlashcardsFinishedStars3__b" d="M0 0h180v63H0z" />
                <path id="CharTrailFlashcardsFinishedStars3__e" d="M0 0h31.5v31.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars3__h" d="M0 0h40.5v40.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars3__k" d="M0 0h31.5v31.5H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <circle fill={medal} cx={90} cy={90} r={81} />
                <path
                    d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                    fill="#000"
                    opacity={0.054}
                />
                <path
                    d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                    fill="#000"
                    opacity={0.153}
                />
                <path
                    d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                    fill="#FFF"
                    opacity={0.398}
                />
                <g filter="url(#CharTrailFlashcardsFinishedStars3__a)" transform="translate(6 12)">
                    <path
                        d="M97.324 3.492C86.602-1.409 75.521-1.358 69.496 5.74c0 0-8.885 2.86-11.234 10.315-2.348 7.455.205 17.667-2.348 21.445-3.237 4.791-7.192 7.455-11.676 10.097-1.393.267-2.71.945-3.789 1.925-2.116.925-3.25 4.547-3.85 6.544-.44 1.464-1.06 2.568-2.415 3.384-2.545 1.534-7.55 1.196-10.94 4.731-5.826 4.47-3.712 11.37-7.45 13.53-.318.184-.656.339-.896.463-.245.07-1.2.407-1.85.59-2.514.712-5.071 1.2-7.422 2.397C2.974 82.51.928 85.02.362 87.97-.14 90.582.33 94.068.326 96.238c.041.216.057.439.042.666a31.601 31.601 0 00-.067 2.919c.013.468.037.936.073 1.403.023.287.053.573.082.858l.012.12a23.276 23.276 0 001.425 5.313l.008.02.024.05c.129.28.257.559.395.834a21.702 21.702 0 001.62 2.724c.108.155.219.307.33.46a21.375 21.375 0 002.706 2.812c.148.127.3.25.449.378.01.01.08.065.14.113l.12.091c.16.113.317.23.478.341a23.985 23.985 0 003.454 1.972c.11.052.258.117.328.148.085.034.17.069.255.1a24.469 24.469 0 002.867.91c.691.175 1.39.313 2.092.433.046.008.233.035.353.053l.149.017c.362.031.723.07 1.086.095 1.153.077 2.311.076 3.464.007 3.473-1.014 4.781-3.664 5.82-4.68-.14.161.251-.072.336-.166 2.84.045 6.76 2.672 10.587 1.194 2.936-1.133 3.876-3.726 5.113-4.815-.055.086.65-.324.497-.292.366-.129.758-.232 1.146-.232.317 0 2.411.455 3.804-1.037 3.642-.479 4.036-.713 8.787.297 1.068.228 2.889.266 4.39-.062 8.04-1.757 11.216-8.137 15.745-11.443 4.716-3.444 9.35-2.201 19.337-5.785 1.49-8.347 3.077-15.19 4.607-18.097 4.595-8.731 11.259-18.152 11.948-35.615.69-17.463-6.28-29.948-17.004-34.85"
                        fill="#6D4600"
                    />
                    <path
                        d="M143.28 102.679c-3.217-11.259-21.572-23.64-30.302-30.534l-30.534 6.637S50.994 91.42 43.41 102.68c-3.219 4.78-5.692 13.104-7.473 21.105h109.856c-.405-8-1.149-16.326-2.514-21.105"
                        fill="#EDB0CC"
                    />
                    <path
                        d="M102.133 44.374c-.516 1.562.897 11.21-2.933 17.5-4.289 7.049-13.21 9.478-20.717 9.172 0 0-10.634-.484-16.608-16.185C57.968 44.598 59.64 39.643 60.1 34.59c.459-5.055 2.5-8.1 3.6-10.493 1.302-2.836 2.22-6.051 2.22-6.051s2.375 2.909 13.557 3.215c11.183.306 17.233 4.443 18.613 7.506 1.377 3.064 1.532 5.514 1.532 5.514s1.2 4.743 2.51 10.093"
                        fill="#C6600C"
                    />
                    <path
                        d="M100.399 31.941s4.863-1.895 5.948 4.725c1.086 6.62-5.13 6.49-5.13 6.49l-.818-11.215z"
                        fill="#B75700"
                    />
                    <path
                        d="M68.004 56.505c.605 3.56 5.426 5.71 10.767 4.802 5.342-.909 9.18-4.532 8.574-8.093-.605-3.56-4.007.682-9.348 1.59-5.341.91-10.6-1.86-9.993 1.7"
                        fill="#FFF"
                    />
                    <path
                        d="M112.867 72.162s-5.668 13.94-17.31 14.757c-10.086.707-16.43-4.744-16.43-4.744L74.148 69.89c10.772 3.507 20.762-.968 25.05-8.016 3.831-6.29 2.418-15.94 2.934-17.5a676.851 676.851 0 012.239 9.362c1.684 7.352 8.495 18.425 8.495 18.425M75.147 39.49L77.8 49.492l-4.346 1.363c-1.12.351-2.157-.73-1.758-1.833l3.45-9.53z"
                        fill="#B75700"
                    />
                    <path
                        d="M66.417 88.545c1.854-5.326-.255-9.956-1.47-10.996-1.214-1.04-10.158-5.689-10.158-5.689s1.137-2.453.716-3.295c0 0 1.016.99 2.031 1.138 0 0-1.374 1.078-1.51 1.834-.135.756.683 1.275 1.401.928.718-.347 2.222-1.791 2.573-4.2.296-2.031-1.71-2.87-2.987-3.429-2.8-1.223-4.701-2.69-7.567.167-5.532 5.515-6.1 6.32-3.472 10.554 2.628 4.233 8.774 7.87 8.453 10-.32 2.13-8.357 9.494-13.479 16.532-2.273 3.123-14.687 16.266-19.154 23.915l26.698 2.268c11.785-14.653 16.817-36.543 17.925-39.727m28.911-1.581c-2.4-5.103-.788-9.928.311-11.09 1.1-1.16 9.508-6.72 9.508-6.72s-1.388-2.32-1.056-3.202c0 0-.907 1.091-1.902 1.344 0 0 1.48.93 1.693 1.667.214.737-.545 1.339-1.295 1.069-.75-.27-2.398-1.55-2.998-3.908-.507-1.99 1.4-3.033 2.612-3.722 2.656-1.51 4.394-3.168 7.542-.626 6.079 4.906 6.729 5.647 4.557 10.132-2.17 4.486-7.902 8.745-7.36 10.83.542 2.085 9.305 8.567 15.135 15.031 2.587 2.868 21.482 21.095 26.725 28.235l-31.488-1.406c-13.254-13.34-20.548-34.583-21.984-37.634"
                        fill="#C6600C"
                    />
                    <path
                        d="M87.399 38.28a4.874 4.874 0 00-3.19-.378c-.627.057-1.238.23-1.776.576-.727.467-1.281 1.196-1.44 2.038-.215.462-.36.958-.417 1.466-.028.25.233.406.428.228a11.733 11.733 0 013.05-2.024 11.743 11.743 0 012.604-.836c.218-.043.45-.058.643-.18.235-.15.272-.362.186-.541.065-.116.055-.279-.088-.349m-19.38 4.576c.039.053.076.107.11.164l-.094-.044a.244.244 0 00-.016-.12m-1.283-.51c.087.067.171.137.254.208-.122-.043-.243-.088-.365-.129.04-.023.075-.052.111-.08m-1.528-1.294l-.042-.01.062-.011-.02.02m3.575 2.178a.206.206 0 000-.15l-.026-.06-.001-.002a2.905 2.905 0 00-.5-.805c-.471-.697-1.044-1.325-1.87-1.597-1.21-.398-2.416.271-3.422.883-.182.112-.15.401.06.458 1.833.499 3.804.87 5.47 1.817.194.11.419-.07.368-.282a2.658 2.658 0 00-.08-.262"
                        fill="#000"
                    />
                    <path
                        d="M90.44 30.426c-.38-.235-.77-.455-1.167-.659a9.048 9.048 0 00-9.405-1.138c-.398.122-.792.268-1.182.442-.247.11-.306.47-.18.686.145.247.421.287.665.187a.51.51 0 00.165.029c3.315.01 6.7.038 9.874 1.07l.006.005a.463.463 0 00.407.136c.145.051.289.102.432.157.544.212.851-.628.386-.915m-27.001.537a.508.508 0 00.46-.106l.01-.009.985-.058.036.013c-.64.061-1.27.189-1.882.377.129-.076.259-.148.39-.217m5.085.428a.492.492 0 00-.055-.248 3.541 3.541 0 00-1.315-1.373 3.801 3.801 0 00-1.022-.487.483.483 0 00-.51-.187c-2.739.604-5.164 2.316-6.678 4.672-.308.477.397 1.022.786.607 1.839-1.958 4.638-2.96 7.302-2.537a.576.576 0 00.289-.03c.18.097.361.193.539.298.342.204.911-.145.736-.565-.022-.052-.049-.1-.072-.15"
                        fill="#6D4600"
                    />
                    <path
                        d="M86.997 49.15c.466.022.925-.06 1.388-.097.43-.035.865-.029 1.237.217.73.483.844 1.5.938 2.29.023.2.155.375.376.375.184 0 .4-.173.375-.376-.124-1.055-.304-2.19-1.214-2.87-.93-.695-2.053-.242-3.1-.29-.483-.022-.482.73 0 .752"
                        fill="#B75700"
                    />
                </g>
                <g transform="translate(0 117)">
                    <mask id="CharTrailFlashcardsFinishedStars3__c" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars3__b" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars3__c)">
                        <path
                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                            className="svg-ribbon-stars-back"
                        />
                        <path
                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-1.19-.193-2.992-.553-4.8-.96l-.57-.13c-2.94-.677-5.731-1.447-5.754-1.788-.018-.27 2.998-.736 6.182-1.115l.684-.08c3.307-.378 6.577-.64 6.626-.476z"
                            className="svg-ribbon-stars-shadow"
                        />
                        <path
                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                            className="svg-ribbon-stars-front"
                        />
                    </g>
                </g>
                <g filter="url(#CharTrailFlashcardsFinishedStars3__d)" transform="rotate(-12 659.94 -89.693)">
                    <mask id="CharTrailFlashcardsFinishedStars3__f" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars3__e" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars3__f)" className="svg-stage-star">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
                <g transform="translate(69.75 123.75)" filter="url(#CharTrailFlashcardsFinishedStars3__g)">
                    <mask id="CharTrailFlashcardsFinishedStars3__i" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars3__h" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars3__i)" className="svg-stage-star">
                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                    </g>
                </g>
                <g filter="url(#CharTrailFlashcardsFinishedStars3__j)" transform="rotate(12 -554.164 616.746)">
                    <mask id="CharTrailFlashcardsFinishedStars3__l" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars3__k" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars3__l)" className="svg-stage-star">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CharTrailFlashcardsFinishedStars3

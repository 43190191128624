import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useLastLocation } from 'react-router-last-location';
import QrLayout from '../../../components/QrLayout2';
import { Typography, Container, Button, Divider, Grid, ListItem, ListItemText } from '@material-ui/core';
import QrList from '../../../components/QrList';
import { Icon, Char } from '../../../components/Images/Images';

export default function StageComplete(props) {
    const { history } = useReactRouter();
    const lastLocation = useLastLocation();
    const stage = props.location.state && props.location.state.stage ? props.location.state.stage : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (lastLocation && (lastLocation.pathname.includes("result") && origin === "result")) {
            setRedirect(false);
        } else {
            setRedirect(true);
        }
    }, [lastLocation, origin]);

    const Header = () => {
        return (
            <Grid container id="trail-stage-complete-header" justify="center">
                <Container maxWidth="md">
                    <Grid container direction="column" alignItems="center" spacing={2}>
                        <div id="trail-stage-complete-header-title">
                            <Icon ic="checked" className="icon-lg mr-2" />
                            <Typography variant="h2" component="h2">
                                {intl.get("TRAIL_STAGE_RESULT_TITLE")}
                            </Typography>
                        </div>
                        <Grid item xs={12} sm={10} lg={8} id="trail-stage-complete-header-scenario">
                            <div className="scenario-char">
                                <Char type="trail-subcategory-finished" />
                            </div>
                            <div className="scenario-shadow"></div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }

    const ListResult = () => {
        return (
            stage.stage_result ?
                <Container maxWidth="md" className="pb-3">
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} sm={8} xl={10}>
                            <QrList>
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={intl.get("LABEL_STAGE")}
                                        secondary={stage.stage_name}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={intl.get("LABEL_STEPS")}
                                        secondary={stage.stage_result.total_user_stage_complete}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem disableGutters>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <div>
                                                <Typography component="span" variant="body1">
                                                    {intl.get("LABEL_STARS")}
                                                </Typography>
                                            </div>
                                        }
                                        secondary={
                                            <div className="d-flex flex-row align-items-center">
                                                <Icon ic="star_filled" className="svg-stage-star img-xs mr-1" />
                                                <Typography variant="body1" component="span">
                                                    {`${stage.stage_result.total_star_user} / ${stage.stage_result.total_star}`}
                                                </Typography>
                                            </div>
                                        }
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={intl.get("LABEL_CORRECT_ANSWERS")}
                                        secondary={`${stage.stage_result.total_right} / ${stage.stage_result.total_answered}`}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem disableGutters>
                                    <ListItemText
                                        primary={intl.get("LABEL_TOTAL_TIME")}
                                        secondary={stage.stage_result.total_time}
                                    />
                                </ListItem>
                            </QrList>
                        </Grid>
                    </Grid>
                </Container>
                : null
        )
    }

    return (
        !redirect ?
            <QrLayout id="trail-stage-complete" hideSidebar>
                {/* Header */}
                <Header />

                {/* List */}
                <ListResult />

                {/* Buttons */}
                <Container maxWidth="md" className="pb-4">
                    <Grid container justify="center">
                        <Grid item xs={12} sm={8} lg={6}>
                            <div id="bottom-bar" className="relative-bar">
                                <Button id="btn_trail_stage_result_next" variant="contained" className="btn-block" onClick={() => history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                                    state: {
                                        origin: 'complete'
                                    }
                                })}>
                                    {intl.get("BTN_CONTINUE")} <Icon ic="arrow_right" className="ml-2" />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                state: {
                    origin: 'complete'
                }
            }} />
    )
}
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionList from '../../../components/SectionList';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingSectionIconList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function KnowMoreSection(props) {
    const dispatch = useDispatch();
    const knowmore = useSelector(state => state.home.knowmore);
    const [didMount, setDidMount] = useState(false);
    const { history } = useReactRouter();

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            dispatch(HomeActions.getKnowmoreList({}));

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                iconType="icon"
                iconSectionColor="section-home"
                icon="info_popup"
                title={intl.get("KNOWMORE_TITLE")}
                subtitle={intl.get("KNOWMORE_SUBTITLE_SECTION")}
                className="pb-3"
            >
                {children}
            </SectionModule>
        )
    }

    return (
        knowmore && knowmore.load === "finished" ?
            !knowmore.error ?
                knowmore.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        <Section>
                            <SectionList seeAllButton={false}>
                                {
                                    knowmore.data.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <ListItem button onClick={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/news/${item.id_know_more}` })}>
                                                    <ListItemIcon>
                                                        <Icon ic="arrow_right" color="primary" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.title} />
                                                </ListItem>
                                                {
                                                    index < knowmore.data.length - 1
                                                        ?
                                                        <Divider variant="inset" component="li" className="mr-3" />
                                                        : null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </SectionList>
                        </Section>
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="info_popup" className="icon-xxl" />}
                                    text={intl.get("EMPTY_KNOWMORE")}
                                    padding={true}
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        <LoadingSectionIconList limit={4} />
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    <LoadingSectionIconList limit={4} />
                </Section>
            </div>
    )
};


import React from 'react';
import intl from 'react-intl-universal';
import { Button, Typography } from '@material-ui/core';
import { Icon } from './Images/Images';
import ImgDiploma from '../dist/img/svgr/ImgDiploma';

export default function ButtonDiploma(props) {
    const { action, unlock, text, textDisabled } = props;

    return (
        <Button variant="outlined" className="btn-card __subcategory" disabled={!unlock} onClick={action}>
            <div className="subcategory-content __image">
                <div className="d-flex align-items-center mr-3">
                    {
                        !unlock ?
                            <Icon ic="diploma" className="icon-lg" />
                            :
                            <ImgDiploma className="img-lg" />
                    }
                </div>
                <div className="text-left mr-3">
                    <Typography variant="body1" component="h4">
                        {intl.get("BTN_CERTIFICATE")}
                    </Typography>
                    <Typography variant="caption" component="p">
                        {
                            !unlock ?
                                textDisabled
                                :
                                text
                        }
                    </Typography>
                </div>
            </div>
            {
                !unlock ?
                    <Icon ic="lock" />
                    :
                    <Icon ic="download" color="lighter" />
            }
        </Button>
    )
}
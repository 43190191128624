import React, { useEffect, useState } from 'react';
import config from '../../../config';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { actions as FreeActions } from '../../../redux/ducks/free';
import { actions as GameActions } from '../../../redux/ducks/game';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import QrLayout from '../../../components/QrLayout2';
import Quiz from '../../../components/Game/Quiz';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import PointsBar from '../../../components/PointsBar';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

const FreeQuestion = (props) => {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const questions = useSelector(state => state.free.questions);
    const auth = useSelector(state => state.auth);
    const help = useSelector(state => state.game.help);
    const [didMount, setDidMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const idCategory = props.location.state && props.location.state.idCategory ? props.location.state.idCategory : '';
    const idLevel = props.location.state && props.location.state.idLevel ? props.location.state.idLevel : '';
    const category = props.location.state && props.location.state.category ? props.location.state.category : '';
    const subcategory = props.location.state && props.location.state.subcategory ? props.location.state.subcategory : '';
    const question = props.location.state && props.location.state.question ? props.location.state.question : '';
    const nextQuestion = props.location.state && props.location.state.nextQuestion ? props.location.state.nextQuestion : '';

    useEffect(() => {
        setDidMount(true);

        if (idCategory && idLevel) {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount && !(question && question.question)) {
            dispatch(FreeActions.setPlay({
                id_category: idCategory,
                id_level: idLevel
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(FreeActions.reset());
            dispatch(GameActions.reset());
        }

    }, [didMount, dispatch, idCategory, idLevel, question]);

    function goBack() {
        let currentOrigin = "question";

        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/free`,
            state: {
                origin: currentOrigin
            }
        })
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="absolute" color="primary">
                <Toolbar>
                    {
                        config.layout.navbar ?
                            <IconButton
                                color="inherit"
                                aria-label="close"
                                edge="start"
                                onClick={() => goBack()}
                            >
                                <Icon ic="close" />
                            </IconButton>
                            : null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {subcategory ? subcategory : category}
                        </Typography>
                        {
                            subcategory ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {category}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                    {
                        config.layout.points.neuros ?
                            <PointsBar type="neuros" value={help && help.user_neuros ? help.user_neuros : auth.user.neuros} />
                            : null
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const sendTo = (answer, idQuestion) => {
        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/free/question/result`,
            state: {
                idCategory: idCategory,
                idLevel: idLevel,
                activeLevel: question && question.question ? question.question.active_level : questions.data.question.active_level,
                idQuestion: idQuestion,
                answer: answer,
                category: category,
                subcategory: subcategory,
                questionText: question && question.question ? question.question.question : questions.data.question.question,
                origin: "question",
            }
        })
    }

    return (
        !redirect ?
            <QrLayout id="free-question">
                <QrAppBar />
                {
                    !nextQuestion || !(question && question.question) ?
                        questions && questions.load === "finished" ?
                            !questions.error ?
                                questions.data.length !== 0 ?
                                    <Quiz
                                        module="free"
                                        question={questions.data}
                                        sendAnswer={sendTo}
                                        gameType={1}
                                    />
                                    :
                                    <EmptyContent
                                        icon={<Icon ic="play_free" className="icon-xxl" />}
                                        text={intl.get("EMPTY_FREE_QUESTIONS")}
                                        button={true}
                                        buttonText={intl.get("BTN_CHANGE_CATEGORY")}
                                        buttonAction={() => history.push({
                                            pathname: `${props.match.path.replace(/./g, '')}/free`,
                                            state: {
                                                origin: "question"
                                            }
                                        })}
                                    />
                                :
                                <ErrorContent />
                            :
                            <LoadingContent />
                        :
                        <Quiz
                            module="free"
                            question={question}
                            sendAnswer={sendTo}
                            gameType={1}
                        />
                }
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/free`,
                state: {
                    origin: 'question'
                }
            }} />
    )
}
export default FreeQuestion;

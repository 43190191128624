import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import config from '../../../config';
import ScormList from './scorm.list';
import ScormThemesList from './themes.list';
import ScormDetails from './details';
import ScormPackage from './package';
import Survey from '../../../components/Survey';

export default function Scorm(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.SCORM && auth.modules.SCORM.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        auth.authenticated &&
        <Switch>
            <Route path={`${props.match.path}`} exact
                component={
                    (props) =>
                        config.learn.scorm.list === "themes" ?
                            <ScormThemesList {...props} />
                            :
                            <ScormList {...props} />
                }
            />

            <Route path={`${props.match.path}/:idTheme/list`} exact
                component={
                    (props) => <ScormList {...props} />
                }
            />

            <Route path={`${props.match.path}/:idScorm/details`} exact
                component={
                    (props) => <ScormDetails {...props} />
                }
                />
                
            <Route path={`${props.match.path}/:idScorm/package`} exact
                component={
                    (props) => <ScormPackage {...props} />
                }
            />

            <Route path={`${props.match.path}/:idScorm/survey`} exact component={
                (props) => <Survey {...props} />}
            />
        </Switch>
    )
};

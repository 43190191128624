import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import * as moment from 'moment';
import { getSecondToHours } from '../utils/utils';
import { Grid, Typography, LinearProgress, } from '@material-ui/core';
import ImgTimer from '../dist/img/svgr/ImgTimer';

export default function QrExamTimer({ finishDate, startDate, serverDate, timeOut, currentQuestion, totalQuestion }) {
    let timeTotal = moment(finishDate).diff(startDate, 'seconds');
    const [timeRemaining, setTimeRemaining] = useState("");
    const [timeRemainingSeconds, setTimeRemainingSeconds] = useState(parseInt(moment(finishDate).diff(serverDate, 'seconds')));
    const [timeRunning, setTimeRunning] = useState(parseInt(moment(serverDate).diff(startDate, 'seconds')));
    const [timeProgress, setTimeProgress] = useState(parseInt((timeRunning / timeTotal) * 100));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemainingSeconds((count) => count - 1);
            setTimeRunning((count) => count + 1);
            setTimeProgress(parseInt((timeRunning / timeTotal) * 100));
            setTimeRemaining(getSecondToHours(timeRemainingSeconds));
        }, 1000);

        if (timeProgress >= 100) {
            timeOut(true);
        }

        return () => clearInterval(interval);
    }, [timeRemainingSeconds, timeRemaining, timeRunning, timeProgress, timeTotal, timeOut]);

    return (
        <Grid container>
            <Grid container justify="center">
                <div className="question-timer-progress">
                    <div className="timer-image">
                        <ImgTimer className="img-md" />
                    </div>
                    <div className="w-100">
                        <LinearProgress variant="determinate" className="progress-bar" value={timeProgress} />
                    </div>
                </div>
            </Grid>
            <Grid container justify="center">
                <Typography variant="body2" component="p">
                    {`${intl.get("LABEL_QUESTION")} ${currentQuestion} / ${totalQuestion}`} - {timeRemaining}
                </Typography>
            </Grid>
        </Grid>
    )
}
import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useDispatch } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { actions as FeedsActions } from '../../redux/ducks/feeds';
import { UserAgentApplication } from 'msal';
import IntroLayout from './components/IntroLayout';
import { LoadingContent } from '../../components/Loading';

export default function Logout() {
    const dispatch = useDispatch();
    const { history } = useReactRouter();

    useEffect(() => {
        if (localStorage.getItem('auth.state.azure') && JSON.parse(localStorage.getItem('auth.state.azure')) === true) {
            const config = {
                auth: {
                    appID: process.env.REACT_APP_API_AZURE_ID,
                    redirectURI: process.env.REACT_APP_API_AZURE_REDIRECTURI,
                    postLogoutRedirectUri: process.env.REACT_APP_API_AZURE_LOGOUT_URL,
                }
            }
            const userAgentApplication = new UserAgentApplication(config);
            localStorage.setItem('auth.state.azure', false);
            userAgentApplication.logout();
        } else {
            dispatch(AuthActions.logout());
            dispatch(FeedsActions.logout());
            localStorage.removeItem('scrollPosition');
            history.push('/');
        }
    }, [dispatch, history]);

    return (
        <IntroLayout appbarMobile="default">
            <LoadingContent />
        </IntroLayout>
    )
}
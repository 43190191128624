import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as PeriodicActions } from '../../../redux/ducks/periodic';
import { useLastLocation } from 'react-router-last-location';
import moment from 'moment';
import QrLayout from '../../../components/QrLayout2';
import { Grid, Button } from '@material-ui/core';
import { Correct, Incorrect } from '../../../components/Game/partials/Solution';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingContent, LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function PeriodicQuestionResult(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const lastLocation = useLastLocation();
    const idQuestion = props.location.state && props.location.state.idQuestion ? props.location.state.idQuestion : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const answer = props.location.state && props.location.state.answer ? props.location.state.answer : '';
    const result = useSelector(state => state.periodic.result);
    const question = useSelector(state => state.periodic.question);
    const [didMount, setDidMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [nextQuestion, setNextQuestion] = useState(false);
    const [loadingData, setLoadindData] = useState(false);

    useEffect(() => {
        setDidMount(true);

        if ((lastLocation && lastLocation.pathname.includes("question")) && origin === "question") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount) {
            dispatch(PeriodicActions.sendAnswer({
                id_question: idQuestion,
                answer: answer
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(PeriodicActions.reset());
        }

    }, [didMount, dispatch, lastLocation, idQuestion, answer, origin]);

    async function loadNextQuestion() {
        setLoadindData(true);
        await dispatch(PeriodicActions.getNextQuestion({
            question_date: moment().format("YYYY-MM-DD")
        }));
        setLoadindData(false);
        setNextQuestion(true);
    }

    if ((question && question.load === "finished") && nextQuestion) {
        if (question.data.length !== 0) {
            return <Redirect to={{
                pathname: `${history.location.pathname.replace(/./g, '')}/periodic/${question.data.question.id_question}/question`,
                state: {
                    origin: 'result',
                    nextQuestion: question,
                    date: question.data.question.date
                }
            }} />
        } else {
            return <Redirect to={{
                pathname: `${history.location.pathname.replace(/./g, '')}/periodic`,
                state: {
                    origin: 'result'
                }
            }} />
        }
    }

    return (
        !redirect ?
            <QrLayout id="periodic-question-result">
                {
                    result && result.load === "finished" ?
                        !result.error ?
                            result.data.length !== 0 ?
                                result.data.result.correct === "Y" ?
                                    <Correct
                                        data={result.data}
                                        result={result.data.result}
                                        description={intl.get("GAME_SOLUTION_CORRECT_TEXT")}
                                    >
                                        <Grid container spacing={2} justify="center">
                                            <Grid item xs={6}>
                                                <Button
                                                    id="btn_periodic_result_exit"
                                                    variant="contained"
                                                    className="btn-block"
                                                    onClick={() => history.push({
                                                        pathname: `${props.match.path.replace(/./g, '')}/periodic`,
                                                        state: {
                                                            origin: 'result',
                                                        }
                                                    })}
                                                >
                                                    {intl.get("BTN_EXIT")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} align="center">
                                                {
                                                    !loadingData ?
                                                        <Button
                                                            id="btn_periodic_result_next_question"
                                                            variant="contained"
                                                            className="btn-block"
                                                            onClick={() => loadNextQuestion()}
                                                        >
                                                            {intl.get("BTN_NEXT")}
                                                        </Button>
                                                        :
                                                        <LoadingBounce size={24} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Correct>
                                    :
                                    <Incorrect
                                        data={result.data}
                                        result={result.data.result}
                                        description={intl.get("GAME_SOLUTION_INCORRECT_TEXT")}
                                    >
                                        <Grid container spacing={2} justify="center">
                                            <Grid item xs={6}>
                                                <Button
                                                    id="btn_periodic_result_exit"
                                                    variant="contained"
                                                    className="btn-block"
                                                    onClick={() => history.push({
                                                        pathname: `${props.match.path.replace(/./g, '')}/periodic`,
                                                        state: {
                                                            origin: 'result',
                                                        }
                                                    })}
                                                >
                                                    {intl.get("BTN_EXIT")}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} align="center">
                                                {
                                                    !loadingData ?
                                                        <Button
                                                            id="btn_periodic_result_next_question"
                                                            variant="contained"
                                                            className="btn-block"
                                                            onClick={() => loadNextQuestion()}
                                                        >
                                                            {intl.get("BTN_NEXT")}
                                                        </Button>
                                                        :
                                                        <LoadingBounce size={24} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Incorrect>
                                :
                                <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL_STEPS_CONTENT")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingContent />
                }
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/periodic`,
                state: {
                    origin: 'result'
                }
            }} />
    )
}
import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router'
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from './menu.list';
import Edit from './edit.profile';
import ChangePassword from './change.password';
import Contact from '../Faq/contact';
import HowToPlay from '../Faq/howtoplay';
import MyProfile from '../Faq/myprofile';

export default function Settings(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === (false || null)) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        auth.authenticated &&
        <Switch>
            <Route path={`${props.match.path}`} exact component={
                (props) => <Menu {...props} />}
            />
            <Route path={`${props.match.path}/edit-profile`} exact component={
                (props) => <Edit {...props} />}
            />
            <Route path={`${props.match.path}/change-password`} exact component={
                (props) => <ChangePassword {...props} />}
            />
            <Route path={`${props.match.path}/contact`} exact component={
                (props) => <Contact {...props} />}
            />
            <Route path={`${props.match.path}/help/how-to-play`} exact component={
                (props) => <HowToPlay {...props} />}
            />
            <Route path={`${props.match.path}/help/my-profile`} exact component={
                (props) => <MyProfile {...props} />}
            />
        </Switch>
    )
}

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { Grid, IconButton, InputBase, Container, AppBar, Toolbar, Typography } from '@material-ui/core';
import QrLayout from '../../../components/QrLayout2';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import CategoryItem from './components/CategoryItem';
import { LoadingCategoryList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ListTrails(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const theme = props.location.state && props.location.state.theme ? props.location.state.theme : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const { trails } = useSelector(state => state.trail);
    const [didMount, setDidMount] = useState(false);
    const [search, setSearch] = useState("");
    const [searchOpen, setSearchOpen] = useState(false);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            setTimeout(() => {
                dispatch(TrailActions.getTrailList({
                    id_course_theme: props.match.params.idTheme
                }));
            }, 500);
        }

        return () => {
            dispatch(TrailActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match]);

    useEffect(() => {
        setFilteredList(
            trails.data && trails.data.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, trails]);

    function goBack() {
        let currentOrigin = "list";

        switch (origin) {
            case 'themes':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        {
                            searchOpen ?
                                null
                                :
                                theme ?
                                    <div>
                                        <Typography variant="h4" noWrap>
                                            {theme.title}
                                        </Typography>
                                        <div className="w-100 d-flex overflow-hidden">
                                            <Typography variant="body2" noWrap>
                                                {intl.get("TRAIL_TITLE")}
                                            </Typography>
                                        </div>
                                    </div>
                                    :
                                    <Typography variant="h4" noWrap>
                                        {intl.get('TRAIL_TITLE')}
                                    </Typography>
                        }

                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(""); setSearchOpen(false); }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const TrailList = () => {
        if (filteredList && filteredList.length > 0) {
            return (
                filteredList.map((item, index) => {
                    return (
                        <Grid item key={index} xs={6} sm={3} md={4} lg={3}>
                            <CategoryItem
                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course}`, state: { origin: "list", idTheme: props.match.params.idTheme } })}
                                bgColor={item.bg_color}
                                image={item.image}
                                title={item.title}
                                currentStars={item.current_stars}
                                totalStars={item.total_stars}
                                currentStep={item.current_step}
                                totalStep={item.total_step}
                                complete={item.complete}
                                obligatory={item.obligatory}
                                available={item.available}
                                dependency={item.dependency_course_name ? item.dependency_course_name : null}
                                dateRelease={item.date_course_release ? item.date_course_release : null}
                            />
                        </Grid>
                    )
                })
            )
        } else {
            return (
                <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            )
        }
    }

    return (
        <QrLayout id="trail-list">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="lg" className="pt-3 pb-3">
                {
                    trails && trails.load === "finished" ?
                        !trails.error ?
                            trails.data.length !== 0 ?
                                <Grid container spacing={2} className="category-list __fluid">
                                    <TrailList />
                                </Grid>
                                :
                                <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingCategoryList limit={8} />
                }
            </Container>
        </QrLayout>
    )
}

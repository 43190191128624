import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import intl from 'react-intl-universal';
import config from '../config';
import { useSelector, useDispatch } from 'react-redux';
import { actions as QrLayoutActions } from '../redux/ducks/qr_layout';
import { actions as NotificationActions } from '../redux/ducks/notification_module';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Hidden, Drawer, Typography, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Appbar from './Appbar';
import { Menu, MobileBottomNavigation } from './Menu';
import FeedList from '../scenes/Feeds/list';
import NotificationsList from '../scenes/Notifications/list.notifications';
import { Icon, Logo } from './Images/Images';

const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }
}));

export default function QrLayout(props) {
    const auth = useSelector(state => state.auth);
    const menuOpen = useSelector(state => state.qrLayout.menuOpen);
    const sidebarOpen = useSelector(state => state.qrLayout.sidebarOpen);
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const [fadeProfile, setFadeProfile] = useState(null);
    const scrollbarMenu = useRef(null);
    const scrollbarFeeds = useRef(null);
    let scrollPosition = localStorage.getItem('scrollPosition') ? parseInt(localStorage.getItem('scrollPosition')) : 0;

    useEffect(() => {
        scrollbarFeeds.current && scrollbarFeeds.current.view.scroll({
            top: scrollPosition
        });
    });

    window.onunload = function () {
        localStorage.removeItem('scrollPosition');
    }

    const handleMenuDrawer = () => {
        dispatch(QrLayoutActions.menuDrawer({
            menuOpen: !menuOpen
        }));

        setFadeProfile(!menuOpen);
    };

    const handleSidebarDrawer = () => {
        dispatch(QrLayoutActions.sidebarDrawer({
            sidebarOpen: !sidebarOpen
        }))

        setFadeProfile(!menuOpen);
    };

    function handleScrollFrame(e) {
        return localStorage.setItem('scrollPosition', e.scrollTop);
    }

    function RenderSidebarContent() {
        if (config.feeds.enabled) {
            return <FeedList type="sidebar" />
        }
        return <NotificationsList />
    }

    function RenderLeftContent() {
        return (
            <Grid container id="qr-left_content">
                <Scrollbars
                    ref={scrollbarMenu}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    renderView={props => (
                        <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
                    )}
                >
                    <Grid container className="qr-nav">
                        <Grid item xs={12} className="qr-nav_header">
                            <div className="mr-3">
                                <IconButton onClick={handleMenuDrawer}>
                                    <Icon ic="menu_filled" />
                                </IconButton>
                            </div>
                            <div className="qr-nav_logo">
                                <Logo type={themeMode === "DARK" ? "main-clean" : "main-color"} />
                            </div>
                        </Grid>
                        <Menu setOpenDrawer={menuOpen} fade={menuOpen === fadeProfile ? true : false} />
                    </Grid>
                </Scrollbars>
            </Grid>
        )
    }

    function RenderRightContent() {
        return (
            <Grid container id="qr-right_content">
                <Scrollbars
                    ref={scrollbarFeeds}
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    onScrollFrame={(e) => handleScrollFrame(e)}
                    renderView={props => (
                        <div id="feeds" {...props} style={{ ...props.style, overflowX: 'hidden' }} />
                    )}
                >
                    <Grid container className="pt-1">
                        <RenderSidebarContent />
                    </Grid>
                </Scrollbars>
            </Grid>
        )
    }

    function RenderMiddleContent(props) {
        const { children, id, bottomNavigation } = props;

        return (
            <main id={id} className="qr-main" role="main">
                <Appbar {...props} />
                <div className="qr-main_content">
                    {children}
                    {(() => {
                        if (isMobile && bottomNavigation) {
                            return <MobileBottomNavigation />
                        }
                    })()}
                </div>
            </main>
        )
    }

    return (
        <div id="qr-root" className={themeMode === "DARK" ? "__dark" : "__light"}>
            <Hidden smDown>
                <Drawer
                    id="qr-left"
                    variant="permanent"
                    anchor="left"
                    open={menuOpen}
                    className={`${menuOpen ? "__open" : "__close"} 
                    ${clsx(classes.drawer, {
                        [classes.drawerOpen]: menuOpen,
                        [classes.drawerClose]: !menuOpen,
                    })}`}
                >
                    <RenderLeftContent />
                </Drawer>
            </Hidden>
            <div id="qr-wrap">
                <div id="qr-middle" className={`sb__${props.hideSidebar ? 'hidden' : sidebarOpen ? 'open' : 'close'} menu__${menuOpen ? 'open' : 'close'}`}>
                    <RenderMiddleContent {...props} />
                </div>
                <Hidden mdDown mdUp={props.hideSidebar}>
                    <aside id="qr-right" className={sidebarOpen ? "__open" : "__close"}>
                        <Grid container id="qr-right_header" direction="row" justify="space-between" alignItems="center">
                            {
                                sidebarOpen ?
                                    <Typography variant="h4" component="h3" className="ml-2">
                                        {
                                            config.feeds.enabled ?
                                                intl.get("RIGHT_PRINCIPAL_COLUMN_TITLE")
                                                :
                                                intl.get("MENU_NOTIFICATIONS")
                                        }
                                    </Typography>
                                    : null
                            }
                            <div>
                                {
                                    sidebarOpen && !config.feeds.enabled ?
                                        <IconButton
                                            onClick={() => dispatch(NotificationActions.updateAll(true))}
                                        >
                                            <Icon ic="double_tick" />
                                        </IconButton>
                                        : null
                                }
                                <IconButton onClick={handleSidebarDrawer}>
                                    <Icon ic={sidebarOpen ? "swipe_right" : config.feeds.enabled ? "feed_filled" : "bell_filled"} />
                                </IconButton>
                            </div>
                        </Grid>
                        <Drawer
                            id="qr-right_drawer"
                            variant="persistent"
                            anchor="right"
                            open={sidebarOpen}
                        >
                            <RenderRightContent />
                        </Drawer>
                    </aside>
                </Hidden>
            </div>
        </div>
    )
}
import React, { useState } from 'react';
import config from '../../config';
import Question from './partials/Question';
import Answers from './partials/Answers';
import Help from './partials/Help';

export default function Quiz(props) {
    const [secretWord, setSecretWord] = useState([]);
    const [timeOut, setTimeOut] = useState(false);
    const [helpGame, setHelpGame] = useState({});

    return (
        <div className={`w-100${config.layout.points.neuros && props.question.help && !props.view ? " __game-help-bar" : ""}`}>
            <Question secretWord={secretWord} timeOut={(time) => setTimeOut(time)} {...props} />
            <Answers secretWord={(word) => setSecretWord(word)} timeOut={timeOut} help={helpGame} {...props} />
            {
                config.layout.points.neuros && props.question.help && !props.view ?
                    <Help help={(help) => setHelpGame(help)} {...props} />
                    : null
            }
        </div>
    )
}
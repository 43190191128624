import React                             from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import FeedCardHeader                    from '../FeedCardHeader'
import FeedCardFooter                    from '../FeedCardFooter'
import QrVideoPlayer                     from '../../../../components/QrVideoPlayer'

export default function CardVideo(props) {
  const { description, reference } = props;

  return (
    <Card className="card card-video">
      <FeedCardHeader {...props} />
      <div className="embed-responsive embed-responsive-16by9">
          <QrVideoPlayer link={JSON.parse(reference).link} />
      </div>
      <CardContent>
        <Typography variant="body2" component="p">
          {description}
        </Typography>
      </CardContent>
      <FeedCardFooter {...props} />
    </Card>
  )
}

import * as React from "react"

function CharTrailFlashcardsFinishedStars2(props) {
    const { medal } = props

    return (
        <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
            <defs>
                <path id="CharTrailFlashcardsFinishedStars2__b" d="M0 0h180v63H0z" />
                <path id="CharTrailFlashcardsFinishedStars2__e" d="M0 0h31.5v31.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars2__h" d="M0 0h40.5v40.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars2__j" d="M0 0h31.5v31.5H0z" />
                <filter
                    x="-12%"
                    y="-9.6%"
                    width="124%"
                    height="119.2%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars2__a"
                >
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter
                    x="-7.9%"
                    y="-7.9%"
                    width="117.5%"
                    height="117.5%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars2__d"
                >
                    <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196213942 0"
                        in="shadowOffsetOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter
                    x="-6.2%"
                    y="-6.2%"
                    width="113.6%"
                    height="113.6%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars2__g"
                >
                    <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196213942 0"
                        in="shadowOffsetOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <circle fill={medal} cx={90} cy={90} r={81} />
                <path
                    d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                    fill="#000"
                    opacity={0.054}
                />
                <path
                    d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                    fill="#000"
                    opacity={0.15}
                />
                <path
                    d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                    fill="#FFF"
                    opacity={0.4}
                />
                <g filter="url(#CharTrailFlashcardsFinishedStars2__a)" transform="translate(38 8)">
                    <path
                        d="M90.325 110.521c-2.4-1.977-4.662-3.637-6.727-5.183-20.18 14.872-48.685 8.92-59.71-.817-8.369 6.154-16.355 8.491-19.614 19.365H100c-2.131-5.861-5.718-10.104-9.675-13.365"
                        fill="#37BC9B"
                    />
                    <path
                        d="M83.598 105.338c-20.18 14.872-48.685 8.92-59.71-.816.4-.296.762-.66 1.204-.914 15.704-9.02 13.59-20.745 12.985-34.876l32.014.902s-.603 13.83 1.51 22.548c1.538 6.332 6.524 9.048 11.997 13.156"
                        fill="#893700"
                    />
                    <path
                        d="M59.087 16.725C61.548 7.195 53.373.267 43.405.267S31.613 6.68 26.629 10.19s-11.67 3.267-14.101 5.688c-2.432 2.42-.852 6.172 2.43 5.93 0 0-4.862 3.388-9.481 3.267-4.62-.121-6.93 3.147-4.012 5.204 2.917 2.057 6.078 2.42 6.078 2.42s-4.62-.605-4.376 1.936c.243 2.542 8.145 2.905 8.145 2.905s-4.012.605-1.702 3.146c2.31 2.542 8.388 3.389 13.372 2.662 4.984-.726 10.333-3.267 10.333-3.267l25.772-23.356z"
                        fill="#E289A9"
                    />
                    <path
                        d="M77.235 51.913c0 16.84-11.582 30.035-22.706 29.732-11.124-.303-23.62-13.803-23.62-30.643s7.62-30.643 23.62-30.34c17.24.328 22.706 14.41 22.706 31.25"
                        fill="#A34708"
                    />
                    <path
                        d="M67.34 71.856c.473-1.281.992-2.49.538-3.875-.428-1.31-1.701-2.092-3.052-2.09-1.295.003-2.506.623-3.629 1.196-1.469.749-2.874 1.622-4.368 2.323-1.64.768-3.262 1.114-5.07 1.148-1.792.035-3.692-.131-5.434.388-1.406.42-2.656 1.489-2.778 3.023-.06.752.18 1.432.643 2.02.245.312.526.547.847.782.211.152.43.296.652.43a.682.682 0 00.234.214c-.375.87.181 1.825.93 2.12a4.32 4.32 0 001.259 1.668c2.203 1.76 6.133 1.623 8.777 1.342 2.913-.31 6.272-1.044 8.64-2.862 1.137-.872 2.033-2.143 2.12-3.612.089-1.518-.86-2.725-.31-4.215m-.772-29.173c-2.403-.132-4.736.64-7.13.638-.23 0-1.205.1-1.355-.138-.007-.012-.014-.01-.02 0 .005-.046.01-.097.018-.157.016-.137.21-.34.29-.446.662-.892 1.46-1.599 1.72-2.726.426-1.855-.754-3.77-2.386-4.606-1.9-.975-4.226-.557-6.077.314-1.757.828-3.222 2.363-3.453 4.341a4.428 4.428 0 00.694 2.993c-.417-.02-.835-.05-1.252-.085-1.273-.109-2.546-.262-3.827-.241-2.164.036-4.312.54-6.003 1.94-2.904 2.406-3.784 6.943-1.7 10.142 2.141 3.288 6.257 4.548 9.903 3.166 1.942-.736 3.687-1.918 5.749-2.315a1.41 1.41 0 00.673-.313 31.431 31.431 0 014.429-.078c2.574.135 4.992.813 7.52 1.218 2.135.341 4.392.32 6.256-.918 1.575-1.046 2.744-2.724 3.065-4.593.767-4.458-2.843-7.901-7.114-8.136"
                        fill="#F99B67"
                    />
                    <path
                        d="M42.767 49.218a1 1 0 01-1.003.998 1 1 0 01-1.003-.998 1 1 0 011.003-.999 1 1 0 011.003.999m24.252 0a1 1 0 01-1.003.998 1 1 0 01-1.003-.998 1 1 0 011.003-.999 1 1 0 011.003.999"
                        fill="#080202"
                    />
                    <path
                        d="M54.369 48.769l-3.853 9.01 2.877.838c.606.177 1.25.167 1.85-.027l2.932-.95-3.806-8.871z"
                        fill="#FF915A"
                    />
                    <path
                        d="M76.211 45.594s6.021-1.524 6.34 6.42c.32 7.943-7.005 6.889-7.005 6.889l.665-13.309z"
                        fill="#9E4400"
                    />
                    <path
                        d="M31.75 45.594s-6.02-1.524-6.34 6.42c-.318 7.943 7.006 6.889 7.006 6.889l-.665-13.309z"
                        fill="#FF915A"
                    />
                    <path
                        d="M31.704 51.76s2.067-12.708 3.89-17.427c1.824-4.72 6.291-9.44 18.57-9.44 12.277 0 17.17 5.325 18.629 10.287 1.459 4.962 3.282 15.853 3.282 15.853s3.16-5.567 1.338-15.611c-1.824-10.045-7.902-21.057-22.855-20.815-14.952.242-20.544 7.624-22.854 16.942-2.488 10.039 0 20.21 0 20.21"
                        fill="#E9A5BE"
                    />
                    <path
                        d="M67.93 91.792s3.921-1.509 7.66-4.527c3.737-3.018 6.29-10.03 8.296-13.67 2.006-3.639 2.918-6.036 1.732-5.325-1.185.71-5.653 6.834-5.653 6.834s4.012-10.918 2.827-11.095c-1.185-.178-4.924 9.853-4.924 9.853s2.736-9.942 1.642-10.12c-1.094-.177-3.739 8.61-3.739 8.61s.912-4.26.821-5.858c-.091-1.598-.73-.71-1.185-.089-.456.622-4.285 11.806-6.747 13.937-2.462 2.13-11.25 6.63-12.126 10.208-1.408 5.75-2.386 19.167-2.923 33.336h19.635c-2.57-15.27-5.315-32.094-5.315-32.094"
                        fill="#893700"
                    />
                    <path
                        d="M83.274 73.358c-.347-.569-.844-1.025-1.305-1.498-.577-.592-.635-.912-.775-1.721-.252-1.452-1.379-2.773-3.012-2.362-1.394.351-2.52 1.953-3.39 2.992-1.046 1.246-2.008 2.56-2.967 3.872-1.945 2.66-3.923 5.221-6.568 7.238-2.885 2.2-6.047 4.052-8.183 7.064-1.997 2.817-2.815 6.244-3.355 9.61-.505.759-.85 1.579-.72 2.572.078.59.372 1.11.87 1.353.325.327.804.493 1.39.314 1.545-.47 2.955-1.201 4.195-2.238.611-.51 1.123-1.086 1.621-1.704.254-.316.802-1.246 1.285-1.296.474-.048 1.318.715 1.778.881.676.244 1.388.314 2.09.117 1.43-.4 2.385-1.696 2.888-3.016.273-.716.384-1.507.705-2.202.336-.727.9-1.13 1.565-1.541 1.341-.83 2.64-1.66 3.87-2.65a27.423 27.423 0 006.19-7.093c1.46-2.402 3.515-5.929 1.828-8.692"
                        fill="#F99B67"
                    />
                    <path
                        d="M50.549 90.55c-.876-3.578-9.665-8.078-12.126-10.208-2.462-2.13-6.29-13.315-6.747-13.937-.456-.621-1.094-1.508-1.185.09-.091 1.597.82 5.858.82 5.858s-2.644-8.788-3.738-8.61c-1.094.177 1.641 10.119 1.641 10.119s-3.738-10.03-4.923-9.853c-1.185.177 2.827 11.095 2.827 11.095s-4.468-6.124-5.653-6.835c-1.185-.71-.274 1.687 1.732 5.326 2.006 3.64 4.559 10.652 8.297 13.67 3.738 3.019 7.658 4.527 7.658 4.527s-2.746 16.824-5.315 32.094h19.635c-.537-14.169-1.515-27.587-2.923-33.336"
                        fill="#893700"
                    />
                    <path
                        d="M49.14 86.348a1.23 1.23 0 00-.46-.33c-.778-2.092-2.566-3.415-4.68-4.11-1.335-.439-2.724-.616-4.055-.955a3.992 3.992 0 00-.066-.852c-.308-1.633-1.532-2.699-2.731-3.73-2.35-2.021-4.334-4.382-5.86-7.077a26.646 26.646 0 01-1.84-4.009c-.455-1.242-1.15-2.705-2.73-2.569-.318.027-.59.096-.828.196-.778-.39-1.847-.197-2.099.886-.737 3.167.483 5.976 1.613 8.88.557 1.43 1.034 2.868.987 4.42-.05 1.638-.392 3.25.212 4.838.932 2.455 3.441 3.842 5.947 4.174 3.1.411 6.202-.749 9.284-.24 1.15.189 2.656.742 3.074 1.953.467 1.352-1.15 2.52-2.006 3.308-1.197 1.101-2.397 2.2-3.596 3.3-1.127 1.035-2.362 2.106-2.795 3.635-.803 2.843 1.448 4.607 3.941 5.204 2.936.704 5.802.814 8.193-1.273 4.551-3.972 4.255-11.31.495-15.65m14.393-45.043c1.935 0 1.938-2.994 0-2.994-1.935 0-1.938 2.994 0 2.994m-18.234-3.82c1.29 0 1.292-1.996 0-1.996-1.29 0-1.292 1.996 0 1.996m.501 2.946c.805 0 .807-1.247 0-1.247s-.809 1.247 0 1.247m3.501 17.515c.484 0 .485-.748 0-.748-.483 0-.484.748 0 .748m-2.446 7.281c2.742 0 2.746-4.241 0-4.241-2.741 0-2.746 4.241 0 4.241m20.903 1.023c1.774 0 1.777-2.745 0-2.745-1.774 0-1.777 2.745 0 2.745m-1.723-3.197c1.29 0 1.292-1.996 0-1.996-1.29 0-1.293 1.996 0 1.996m3.835-1.592c.807 0 .808-1.247 0-1.247-.806 0-.807 1.247 0 1.247"
                        fill="#F99B67"
                    />
                    <path
                        d="M42.893 63.573c-.022 4.033 4.887 7.328 10.964 7.36 6.076.033 11.02-3.21 11.042-7.243.021-4.033-4.56-.024-10.636-.057-6.077-.032-11.348-4.093-11.37-.06"
                        fill="#FFF"
                    />
                    <path
                        d="M40.295 108.101c1.936 0 1.939-2.994 0-2.994-1.935 0-1.938 2.994 0 2.994m24.758-5.239c1.935 0 1.938-2.994 0-2.994-1.936 0-1.939 2.994 0 2.994m-3.929.631c1.13 0 1.13-1.747 0-1.747-1.129 0-1.13 1.747 0 1.747m-16.233 2.753c.967 0 .97-1.497 0-1.497-.967 0-.97 1.497 0 1.497m-.667 4.081c.806 0 .808-1.247 0-1.247-.806 0-.808 1.247 0 1.247m18.456-3.542c.807 0 .808-1.247 0-1.247-.806 0-.807 1.247 0 1.247m-1.927 3.565c.646 0 .647-.998 0-.998-.644 0-.646.998 0 .998m3.855 2.163c.484 0 .485-.749 0-.749-.484 0-.485.749 0 .749m1.705-6.051c.484 0 .484-.748 0-.748s-.485.748 0 .748m-18.457 5.852c.323 0 .323-.499 0-.499s-.323.5 0 .5m1.482-6.273c.323 0 .324-.499 0-.499-.322 0-.323.499 0 .499m-2.075 3.201c.645 0 .646-.998 0-.998-.645 0-.646.998 0 .998"
                        fill="#F99B67"
                    />
                    <path
                        d="M71.905 42.284c-3.444-1.475-7.505-1.228-10.715.725-.504.306-.122 1.056.37.918.09.041.198.055.318.028a34.15 34.15 0 019.774-.74c.476.032.712-.734.253-.93m-25.237.673l-.119-.023c-2.645-1.778-6.006-2.383-9.115-1.668-.467.107-.49.87 0 .963l8.723 1.644c.061.042.124.082.184.125.22.158.559.037.686-.18a.467.467 0 000-.482c-.015-.177-.125-.335-.359-.38"
                        fill="#843703"
                    />
                </g>
                <g transform="translate(0 117)">
                    <mask id="CharTrailFlashcardsFinishedStars2__c" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars2__b" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars2__c)">
                        <path
                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                            className="svg-ribbon-stars-back"
                        />
                        <path
                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-1.19-.193-2.992-.553-4.8-.96l-.57-.13c-2.94-.677-5.731-1.447-5.754-1.788-.018-.27 2.998-.736 6.182-1.115l.684-.08c3.307-.378 6.577-.64 6.626-.476z"
                            className="svg-ribbon-stars-shadow"
                        />
                        <path
                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                            className="svg-ribbon-stars-front"
                        />
                    </g>
                </g>
                <g filter="url(#CharTrailFlashcardsFinishedStars2__d)" transform="rotate(-12 659.94 -89.693)">
                    <mask id="CharTrailFlashcardsFinishedStars2__f" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars2__e" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars2__f)" className="svg-stage-star">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
                <g transform="translate(69.755 123.75)" filter="url(#CharTrailFlashcardsFinishedStars2__g)">
                    <mask id="CharTrailFlashcardsFinishedStars2__i" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars2__h" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars2__i)" className="svg-stage-star">
                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                    </g>
                </g>
                <g transform="rotate(12 -554.159 616.797)">
                    <mask id="CharTrailFlashcardsFinishedStars2__k" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars2__j" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars2__k)" className="svg-ribbon-stars-back">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CharTrailFlashcardsFinishedStars2

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import { useSelector, useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { actions as FreeActions } from '../../../redux/ducks/free';
import { imageParser } from '../../../utils/utils';
import { Container, Grid, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import QrLayout from '../../../components/QrLayout2';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import ButtonSubcategory from './components/ButtonSubcategory';
import { LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

const FreeSubcategoriesList = (props) => {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [didMount, setDidMount] = useState(false);
    const subcategories = useSelector(state => state.free.subcategories);
    const category = props.location.state && props.location.state.category ? props.location.state.category : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(FreeActions.getListSubcategories({
                id_category: props.match.params.idSubcategory ? props.match.params.idSubcategory : props.match.params.idCategory
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(FreeActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    function goBack() {
        let currentOrigin = "subcategories";

        switch (origin) {
            case 'categories':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/free`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'subcategories':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/free`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'children':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/free/${props.match.params.idCategory}`,
                    state: {
                        category: category,
                        origin: currentOrigin
                    }
                })
            case 'question':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/free`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const Header = () => {
        return (
            <div id="free-subcategories-header"
                style={{
                    backgroundColor: category.bgcolor,
                    backgroundImage: `url(${category.category_image ? imageParser(category.category_image) : null})`
                }}
            >
            </div>
        )
    }

    const Subcategories = () => {
        return (
            subcategories.data.categories.map((item, index) => {
                return (
                    <Grid item key={index} xs={12} sm={10} xl={12}>
                        <ButtonSubcategory
                            category={category}
                            item={item}
                            path={props.match.path}
                        />
                    </Grid>
                )
            })
        )
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {category.name}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <QrLayout id="free-subcategories">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Header />
            <Container maxWidth="md" className="pt-3 pb-4">
                {
                    subcategories && subcategories.load === "finished" ?
                        !subcategories.error ?
                            subcategories.data.length !== 0 ?
                                <Grid container justify="center" spacing={2}>
                                    <Subcategories />
                                </Grid>
                                :
                                <EmptyContent icon={<Icon ic="play_free" className="icon-xxl" />} text={intl.get("EMPTY_FREE")} />
                            :
                            <ErrorContent />
                        :
                        <Grid container justify="center" className="pt-5">
                            <LoadingBounce />
                        </Grid>
                }
            </Container>
        </QrLayout>
    )
}
export default FreeSubcategoriesList;
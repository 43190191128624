import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import FreeCategoriesList from './categories.list';
import FreeSubcategoriesList from './subcategories.list';
import FreeQuestion from './question';
import FreeQuestionResult from './question.result';

export default function Free(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.FREE && auth.modules.FREE.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        auth.authenticated &&
        <Switch>
            <Route path={`${props.match.path}`} exact component={(props) =>
                <FreeCategoriesList {...props} />}
            />
            <Route path={`${props.match.path}/question`} exact component={(props) =>
                <FreeQuestion {...props} />}
            />
            <Route path={`${props.match.path}/question/result`} exact component={(props) =>
                <FreeQuestionResult {...props} />}
            />
            <Route path={`${props.match.path}/:idCategory`} exact component={(props) =>
                <FreeSubcategoriesList {...props} />}
            />
            <Route path={`${props.match.path}/:idCategory/:idSubcategory`} exact component={(props) =>
                <FreeSubcategoriesList {...props} />}
            />
        </Switch>
    )
};

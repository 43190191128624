import React from 'react';
import config from '../../../config';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { yiq } from 'yiq';
import { Icon } from '../../../components/Images/Images';

export default function ActivityInfoCard(props) {
    const { type, title, total } = props;
    const project = config.app.name_project.toLowerCase();
    let theme = require(`../../../assets/scss/projects/${project}/_export.scss`);

    return (
        <Grid item xs={12} sm>
            <Card className={`activities-info-card section-play-${type}`}>
                <CardContent>
                    <Grid container direction="row" alignItems="center" justify="space-between" className="flex-nowrap">
                        <div className="card-content">
                            <div className="card-icon">
                                <Icon ic={`play_${type}`} color={yiq(theme[`play-${type}`], { threshold: 225, colors: { light: "white", dark: "primary" } })} />
                            </div>
                            <Typography variant="body2" component="h4" className={`mt-2 font-weight-bold ${yiq(theme[`play-${type}`], { threshold: 225, colors: { light: "text-white", dark: "text-dark" } })}`}>
                                {title}
                            </Typography>
                            <Typography variant="h3" component="div" className={`${yiq(theme[`play-${type}`], { threshold: 225, colors: { light: "text-white", dark: "text-dark" } })}`}>
                                {total}
                            </Typography>
                        </div>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import * as yup from 'yup';
import sha256 from 'crypto-js/sha256';
import md5 from 'crypto-js/md5';
import {
    Container, Grid, TextField, InputAdornment, IconButton, Button,
    AppBar, Toolbar, Typography
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import QrLayout from '../../components/QrLayout2';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function ChangePassword(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const [snackOpen, setsnackOpen] = useState(false);
    const [passwordData, setPasswordData] = useState({});
    const [passStatus, setPassStatus] = useState("error");
    const [passMesage, setPassMesage] = useState(intl.get('ERROR_CHANGE_PASSWORD'));
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const regex = config.login_method.password.complex ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%])[a-zA-Z\d]/ : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/;

    const validations = yup.object().shape({
        current_password: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD')),
        new_password: yup.string()
            .min(config.login_method.password.min, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .matches(regex, () => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`)
            .required(() => `${intl.get(config.login_method.password.complex ? 'ERROR_PASSWORD_REQUIREMENTS_COMPLEX' : 'ERROR_PASSWORD_REQUIREMENTS')} ${config.login_method.password.min} ${intl.get('ERROR_PASSWORD_CHARACTERS')}`),
        retype_password: yup.string()
            .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            .oneOf([yup.ref('new_password'), null], () => intl.get('ERROR_PASSWORD_NOT_MATCH'))
    });

    const changePassword = function (data) {
        setLoading(true);
        setPasswordData(data);
    }

    useEffect(() => {
        if (Object.keys(passwordData).length > 0) {
            dispatch(AuthActions.updatePassword(
                {
                    new_pass: sha256(md5(passwordData.new_password).toString(), "new_password").toString(),
                    old_pass: sha256(md5(passwordData.current_password).toString(), "password").toString()
                }
            ));
            setPasswordData({});
        }
    }, [passwordData, dispatch, auth.user.id_user]);

    if (auth.passwordSuccess === 'success') {
        setLoading(false);
        setPassStatus("success");
        setsnackOpen(true);
        setPassMesage(intl.get('SUCCESS_CHANGE_PASSWORD'));
        auth.passwordSuccess = "";
    } else if (auth.passwordSuccess.includes('info')) {
        setLoading(false);
        setPassStatus("info");
        setsnackOpen(true);
        setPassMesage(intl.get('INFO_CHANGE_PASSWORD'));
        auth.passwordSuccess = "";
    }

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("SETTINGS_CHANGE_PASSWORD_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <QrLayout id="settings-change-password" hideSidebar>
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="md" className="pt-4 pb-4">
                <Formik
                    validationSchema={validations}
                    initialValues={{
                        current_password: '',
                        new_password: '',
                        retype_password: ''
                    }}
                    onSubmit={(data) => { changePassword(data) }}
                >
                    {
                        ({ handleBlur, handleChange, handleSubmit, errors, touched }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Grid container justify="center" spacing={3}>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="current_password"
                                            id="current_password"
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            label={intl.get('LABEL_CURRENT_PASSWORD')}
                                            variant={config.layout.input_variant}
                                            defaultValue={""}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showCurrentPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            error={(errors.current_password && touched.current_password)}
                                            helperText={(errors.current_password && touched.current_password) && errors.current_password}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="new_password"
                                            id="new_password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            label={intl.get('LABEL_NEW_PASSWORD')}
                                            variant={config.layout.input_variant}
                                            defaultValue={""}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showNewPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            error={(errors.new_password && touched.new_password)}
                                            helperText={(errors.new_password && touched.new_password) && errors.new_password}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="retype_password"
                                            id="retype_password"
                                            type={showConfirmNewPassword ? 'text' : 'password'}
                                            label={intl.get('LABEL_RETYPE_NEW_PASSWORD')}
                                            variant={config.layout.input_variant}
                                            defaultValue={""}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showConfirmNewPassword ? <Icon ic="visible" color="light" /> : <Icon ic="invisible" color="light" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            error={(errors.retype_password && touched.retype_password)}
                                            helperText={(errors.retype_password && touched.retype_password) && errors.retype_password}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        {
                                            !loading
                                                ?
                                                <Button
                                                    id="btn_settings_save"
                                                    variant="contained"
                                                    className="btn-block"
                                                    type="submit"
                                                    onSubmit={() => this.onSubmit()}
                                                >
                                                    {intl.get('BTN_SAVE')}
                                                </Button>
                                                :
                                                <Grid container alignItems="center" justify="center">
                                                    <LoadingBounce />
                                                </Grid>
                                        }
                                    </Grid>
                                    <SnackBar open={snackOpen} message={passMesage} status={passStatus} time={2} closeSnack={() => setsnackOpen(false)} />
                                </Grid>
                            </Form>
                        )
                    }
                </Formik >
            </Container>
        </QrLayout>
    )
}

import React from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import config from '../../config';
import QrLayout from '../../components/QrLayout';
import ScrollToTop from '../../components/ScrollToTop';
import { actions as NotificationActions } from '../../redux/ducks/notification_module';
import NotificationsList from './list.notifications';
import { IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Icon } from '../../components/Images/Images';

export default function Notifications(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <QrLayout
            id="notifications"
            appbar={config.feeds.enabled && isMobile ? 'fixed' : 'main'}
            history={history}  // Historico da rota
            bottomNavigation={!config.feeds.enabled}
            rightIcon={
                <IconButton
                    onClick={() => dispatch(NotificationActions.updateAll(true))}
                >
                    <Icon ic="double_tick" />
                </IconButton>
            }
            page={intl.get('MENU_NOTIFICATIONS')}
            sub={config.feeds.enabled && isMobile ? null : intl.get('SUBMENU_NOTIFICATIONS')}
        >
            <ScrollToTop />
            <Switch>
                <Route path={`${props.match.path}`} exact component={(props) =>
                    <NotificationsList {...props} />} />
            </Switch>
        </QrLayout >
    )
};
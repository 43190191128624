import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../../config";
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import QrLayout from '../../../components/QrLayout2';
import { AppBar, Toolbar, Container, Typography, Grid, IconButton, Button, useMediaQuery } from '@material-ui/core';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import ButtonStep from './components/ButtonStep';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function StepList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const { steps } = useSelector(state => state.trail);
    const [didMount, setDidMount] = useState(false);
    const isScreenUpSm = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(TrailActions.getStepsList({
                id_stage: props.match.params.idStage
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(TrailActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    function goBack() {
        let currentOrigin = "steps";

        switch (origin) {
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'intro':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'materials':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'statistics':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/statistics`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'result':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'questions':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'solution':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'complete':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        {
                            steps && steps.load === "finished" ?
                                !steps.error ?
                                    <>
                                        <Typography variant="h4" noWrap>
                                            {steps.data.stage_name}
                                        </Typography>
                                        <div className="w-100 d-flex overflow-hidden">
                                            {
                                                config.learn.trail.type === "stars" ?
                                                    <>
                                                        <div className="ic-subtitle mr-1">
                                                            <Icon ic="star_filled" />
                                                        </div>
                                                        <Typography variant="body2" noWrap>
                                                            {`${steps.data.current_stars} / ${steps.data.total_stars}`}
                                                        </Typography>
                                                    </>
                                                    :
                                                    <Typography variant="body2" noWrap>
                                                        {`${steps.data.current_steps} / ${steps.data.total_steps}`}
                                                    </Typography>
                                            }
                                        </div>
                                    </>
                                    : null
                                : null
                        }
                    </div>
                    {
                        config.app.name_project === "FLEURY" && isScreenUpSm ?
                            <Button color="inherit" onClick={() => {
                                history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/materials`,
                                    state: {
                                        steps: steps.data,
                                        origin: 'steps'
                                    }
                                })
                            }}>
                                {intl.get("LABEL_MATERIALS")} <Icon ic="book_stack_filled" className="ml-2" />
                            </Button>
                            :
                            <IconButton aria-label="materials"
                                onClick={() => {
                                    history.push({
                                        pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/materials`,
                                        state: {
                                            steps: steps.data,
                                            origin: 'steps'
                                        }
                                    })
                                }}>
                                <Icon ic="book_stack_filled" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const Steps = () => {
        return (
            steps.data.steps.map((item, index) => {
                return (
                    <Grid item key={index} xs={12} sm={10} xl={12}>
                        <ButtonStep
                            action={() => history.push({
                                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/intro`,
                                state:
                                {
                                    step: item,
                                    origin: 'steps'
                                }
                            })}
                            type={item.step_type}
                            available={item.available}
                            cleared={item.step_cleared}
                            grade={parseInt(item.cleared_grade)}
                            number={item.step_number}
                            numberDescription={item.step_number_description}
                            name={item.step_name}
                            dependency={item.dependency_step_name ? item.dependency_step_name : null}
                            dateRelease={item.date_step_release ? item.date_step_release : null}
                        />
                        {
                            (index < steps.data.steps.length - 1)
                                ?
                                <div className={`step-bar ${item.step_cleared === 'Y' ? '__active' : '__disabled'}`}></div>
                                :
                                null
                        }
                    </Grid>
                )
            })
        )
    }

    return (
        <QrLayout id="trail-steps" hideSidebar>
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            {
                steps && steps.load === "finished" ?
                    !steps.error ?
                        steps.data.length !== 0 ?
                            <Container maxWidth="md" className="pt-3 pb-4">
                                <Grid container justify="center">
                                    <Steps />
                                </Grid>
                            </Container>
                            :
                            <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL_STEPS")} />
                        :
                        <ErrorContent />
                    :
                    <LoadingContent />
            }
        </QrLayout>
    )
}

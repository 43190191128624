import React, { useEffect, useState } from 'react';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { currentLocale } from '../../../router/Router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as PeriodicActions } from '../../../redux/ducks/periodic';
import { getPeriodicTitle } from '../../../utils/utils';
import moment from 'moment';
import QrLayout from '../../../components/QrLayout2';
import { AppBar, Toolbar, Container, Typography, Grid, IconButton, Divider, Button } from '@material-ui/core';
import PlayLatest from './components/PlayLatest';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingPeriodic } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function PeriodicMonthly(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const list = useSelector(state => state.periodic.list);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [didMount, setDidMount] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [loadingData, setLoadindData] = useState(false);
    const [havePreviousYear, setHavePreviousYear] = useState(false);
    moment.locale(currentLocale);

    useEffect(() => {
        setDidMount(true);

        if (didMount && !hasData) {
            dispatch(PeriodicActions.getList({
                initial_date: moment(date).subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
                final_date: moment(date).endOf('year').format('YYYY-MM-DD'),
            }));
        }

        return () => {
            dispatch(PeriodicActions.reset());
            setHasData(true);
            setDidMount(false);
        }
    }, [didMount, dispatch, date, hasData]);

    function goBack() {
        let currentOrigin = "periodic";

        switch (origin) {
            case 'question':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'result':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    async function getNewYear(year) {
        setLoadindData(true);
        await dispatch(PeriodicActions.getList({
            initial_date: moment(year).subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
            final_date: moment(year).endOf('year').format('YYYY-MM-DD'),
        }));
        setLoadindData(false);
    }

    function changeYear(action) {
        switch (action) {
            case "previous":
                setDate(moment(date).subtract(1, 'years').format('YYYY-MM-DD'));
                getNewYear(moment(date).subtract(1, 'years').format('YYYY-MM-DD'));
                break;
            case "next":
                setDate(moment(date).add(1, 'years').format('YYYY-MM-DD'));
                getNewYear(moment(date).add(1, 'years').format('YYYY-MM-DD'));
                break;
            default:
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {getPeriodicTitle(auth.modules.PERIODIC_QUESTION.SCHEDULE)}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const HeaderCalendar = () => {
        return (
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs align="left">
                    {
                        havePreviousYear ?
                            <IconButton onClick={() => changeYear('previous')}>
                                <Icon ic="arrow_left" />
                            </IconButton>
                            : null
                    }
                </Grid>
                <Grid item xs align="center">
                    <Typography variant="h5" component="h5" noWrap>
                        {moment(date).format("YYYY")}
                    </Typography>
                </Grid>
                <Grid item xs align="right">
                    {
                        moment(date).format("YYYY") < moment().format("YYYY") ?
                            <IconButton onClick={() => changeYear('next')}>
                                <Icon ic="arrow_right" />
                            </IconButton>
                            : null
                    }
                </Grid>
            </Grid>
        )
    }

    const ContentCalendar = () => {
        let pos = list.data.length - 1;
        setTimeout(() => setHavePreviousYear(list.data.length > 1 ? true : false), 0);

        return (
            <Grid container spacing={2} className="pt-2 pb-3">
                {
                    list.data[pos].schedule.map((item, index) => {
                        return (
                            <Grid key={index} item xs={6} sm={4} md={3}>
                                <Button
                                    variant="outlined"
                                    className={`btn-card${item.question && (item.question.answered === "Y" || item.question.answered_by_sms === "Y") ? item.question.answered_right === "Y" ? " btn-outline-success" : " btn-outline-danger" : ""}`}
                                    disabled={!item.question}
                                    onClick={() => history.push({
                                        pathname: `${props.match.path.replace(/./g, '')}/periodic/${item.question.id_question}/question`,
                                        state: {
                                            origin: 'periodic',
                                            date: item.question.question_date
                                        }
                                    })}
                                >
                                    {item.display}
                                </Button>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }

    return (
        <QrLayout id="periodic-monthly">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }

            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={10} xl={12}>
                        <HeaderCalendar />
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <Divider />
                    </Grid>
                    {
                        !loadingData && (list && list.load === "finished") ?
                            !list.error ?
                                <>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <ContentCalendar />
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <PlayLatest />
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12} sm={10} xl={12}>
                                    <ErrorContent type="section" />
                                </Grid>
                            :
                            <LoadingPeriodic type={auth.modules.PERIODIC_QUESTION.SCHEDULE} />
                    }
                </Grid>
            </Container>
        </QrLayout>
    )
}
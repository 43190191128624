import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProfileActions } from '../../redux/ducks/profile';
import { goToTopAnimation } from '../../utils/utils';
import QrLayout from '../../components/QrLayout2';
import {
    AppBar, Toolbar, Container, Typography, Grid,
    IconButton, List, Divider
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ListItemStatistics from '../../components/ListItemStatistics';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function ListTrail(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const trailList = useSelector(state => state.profile.trailList);
    const [didMount, setDidMount] = useState(false);
    const [page, setPage] = useState(1);
    const nameUser = props.location.state && props.location.state.nameUser ? props.location.state.nameUser : '';
    const itemsPerPage = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(ProfileActions.getTrailList({
                id_user: props.match.params.id,
                limit: 0,
                page: 0,
                order_list: 'progress asc'
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(ProfileActions.reset());
        }
    }, [didMount, dispatch, props.match]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('PROFILE_CARD_TRAIL_TITLE')}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {nameUser ? nameUser : intl.get('PROFILE_TITLE')}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const TrailList = () => {
        return (
            <List component="nav" className="w-100">
                {
                    trailList.data.courses
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((item, index) => {
                            return (
                                <div key={index}>
                                    <ListItemStatistics
                                        type="trail"
                                        image={item.image ? item.image : ""}
                                        bgColor={item.bg_color}
                                        title={item.course_name}
                                        progress={config.learn.trail.type === "stars" ? `${item.current_stars} / ${item.total_stars}` : `${item.step_complete} / ${item.total_step}`}
                                        completed={item.course_complete === "Y" ? true : false}
                                        gutters={true}
                                        action={() => history.push({
                                            pathname: `${history.location.pathname.replace(/./g, '')}/trail/${item.course_id}`,
                                            state: {
                                                origin: "profile"
                                            }
                                        })}
                                    />
                                    <Divider />
                                </div>
                            )
                        })
                }
            </List>
        )
    }

    return (
        <QrLayout id="profile-trail">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            {
                trailList && trailList.load === "finished" ?
                    !trailList.error ?
                        trailList.data.courses.length !== 0 ?
                            <Container maxWidth="md" className="container-list pb-3">
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <TrailList />
                                    </Grid>
                                    {
                                        trailList.data.courses.length > itemsPerPage ?
                                            <Grid item xs={12} sm={10} xl={12}>
                                                <Pagination
                                                    count={Math.ceil(trailList.data.courses.length / itemsPerPage)}
                                                    page={page}
                                                    onChange={(e, value) => { setPage(value); goToTopAnimation(); }}
                                                    defaultPage={1}
                                                    color="primary"
                                                />
                                            </Grid>
                                            : null
                                    }
                                </Grid>
                            </Container>
                            :
                            <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL")} />
                        :
                        <ErrorContent />
                    :
                    <LoadingContent />
            }
        </QrLayout>
    )
}
import AppBar          from '@material-ui/core/AppBar'
import Dialog          from '@material-ui/core/Dialog'
import IconButton      from '@material-ui/core/IconButton'
import Slide           from '@material-ui/core/Slide'
import Toolbar         from '@material-ui/core/Toolbar'
import Typography      from '@material-ui/core/Typography'
import CloseIcon       from '@material-ui/icons/Close'
import React           from 'react'
import intl            from 'react-intl-universal'
import { imageParser } from '../utils/utils'
import QrVideoPlayer   from './QrVideoPlayer'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoDialog(props) {

    if (props.type === 'link') {
        return (
            <Dialog fullScreen id="terms" open={props.isFilter} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className="appbar appbar-default position-relative">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className="flex-fill ml-3">
                            {intl.get("HOME_DIALOG_TITLE_LINK")}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <iframe
                    title="viewer"
                    className="library-details"
                    src={props.data.reference && props.data.reference.length !== 0
                        ? JSON.parse(props.data.reference).link
                        : null
                    }
                />
            </Dialog>
        )
    }

    if (props.data.blob) {
        return (
            <Dialog fullScreen id="terms" open={props.isFilter} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className="appbar appbar-default position-relative">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className="flex-fill ml-3">
                            {intl.get("HOME_DIALOG_TITLE_LINK")}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <iframe
                    title="viewer"
                    className="library-details"
                    src={imageParser({ blob: props.data.blob })}>
                </iframe>
            </Dialog>
        )
    }

    if (props.type === 'video') {
        return (
            <Dialog fullScreen id="terms" open={props.isFilter} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className="appbar appbar-default position-relative">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className="flex-fill ml-3">
                            {intl.get("HOME_DIALOG_TITLE_LINK")}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="library-details embed-responsive embed-responsive-16by9">
                        <QrVideoPlayer link={props.data.reference.link} />
                    </div>
                </div>
            </Dialog>
        )
    }
}

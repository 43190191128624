import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Divider } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from '../../Learn/Free/components/CategoryItem';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingCategoryGrid } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function FreeSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const free = useSelector(state => state.home.free);
    const [didMount, setDidMount] = useState(false);
    const limit = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            dispatch(HomeActions.getFreeList({
                limit: limit,
                page: 1
            }));

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                iconType="play"
                iconSectionColor="section-play-free"
                icon="free"
                title={intl.get("FREE_TITLE")}
                subtitle={intl.get("FREE_SUBTITLE_SECTION")}
                action={() => history.push('/free')}
            >
                {children}
            </SectionModule>
        )
    }

    const SectionContent = () => {
        return (
            <Section>
                <SectionCategoryGrid action={() => history.push('/free')}>
                    {
                        free.data.map((item, index) => {
                            return (
                                <CategoryItem
                                    key={index}
                                    item={item}
                                    origin="home"
                                    path={props.match.path}
                                />
                            )
                        })
                    }
                </SectionCategoryGrid>
            </Section>
        )
    }

    return (
        free && free.load === "finished" ?
            !free.error ?
                free.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        <SectionContent />
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="brain_filled" className="icon-xxl" />}
                                    text={intl.get("EMPTY_FREE")}
                                    padding={true}
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        <LoadingCategoryGrid action={() => history.push('/free')} limit={limit} />
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    <LoadingCategoryGrid action={() => history.push('/free')} limit={limit} />
                </Section>
            </div>
    )
};


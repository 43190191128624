import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useLastLocation } from 'react-router-last-location';
import { useSelector, useDispatch } from 'react-redux';
import { actions as FreeActions } from '../../../redux/ducks/free';
import QrLayout from '../../../components/QrLayout2';
import { Button } from '@material-ui/core';
import { Correct, Incorrect } from '../../../components/Game/partials/Solution';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function FreeQuestionResult(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const lastLocation = useLastLocation();
    const result = useSelector(state => state.free.result);
    const [didMount, setDidMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const idCategory = props.location.state && props.location.state.idCategory ? props.location.state.idCategory : '';
    const idLevel = props.location.state && props.location.state.idLevel ? props.location.state.idLevel : '';
    const activeLevel = props.location.state && props.location.state.activeLevel ? props.location.state.activeLevel : '';
    const idQuestion = props.location.state && props.location.state.idQuestion ? props.location.state.idQuestion : '';
    const answer = props.location.state && props.location.state.answer ? props.location.state.answer : '';
    const category = props.location.state && props.location.state.category ? props.location.state.category : '';
    const subcategory = props.location.state && props.location.state.subcategory ? props.location.state.subcategory : '';
    const questionText = props.location.state && props.location.state.questionText ? props.location.state.questionText : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if ((lastLocation && lastLocation.pathname.includes("question")) && origin === "question") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount) {
            dispatch(FreeActions.setAnswer({
                id_question: idQuestion,
                id_category: idCategory,
                answer: answer,
                id_level: activeLevel
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(FreeActions.reset());
        }

    }, [didMount, dispatch, origin, idCategory, idQuestion, activeLevel, answer, lastLocation]);

    return (
        !redirect ?
            <QrLayout id="free-question-result">
                {
                    result && result.load === "finished" ?
                        !result.error ?
                            result.data.length !== 0 ?
                                result.data.result.correct === "Y" ?
                                    <Correct
                                        data={result.data}
                                        result={result.data.result}
                                        description={result.data.result.message}
                                        reportIDQuestion={idQuestion}
                                        reportQuestion={questionText}
                                        reportType="single"
                                        gameType={1}
                                    >
                                        <Button id="btn_free_question_result_next" variant="contained" className="btn-block-xs w-50"
                                            onClick={() => history.push({
                                                pathname: `${props.match.path.replace(/./g, '')}/free/question`,
                                                state: {
                                                    origin: 'result',
                                                    idCategory: idCategory,
                                                    idLevel: idLevel,
                                                    category: category,
                                                    subcategory: subcategory,
                                                    question: result.data,
                                                    nextQuestion: result.data.result.finished_category === "N" ? true : false
                                                }
                                            })}>
                                            {intl.get("BTN_CONTINUE")}
                                        </Button>
                                    </Correct>
                                    :
                                    <Incorrect
                                        data={result.data}
                                        result={result.data.result}
                                        description={result.data.result.message}
                                        reportIDQuestion={idQuestion}
                                        reportQuestion={questionText}
                                        reportType="single"
                                        gameType={1}
                                    >
                                        <Button id="btn_free_question_result_next" variant="contained" className="btn-block-xs w-50"
                                            onClick={() => history.push({
                                                pathname: `${props.match.path.replace(/./g, '')}/free/question`,
                                                state: {
                                                    origin: 'result',
                                                    idCategory: idCategory,
                                                    idLevel: idLevel,
                                                    category: category,
                                                    subcategory: subcategory,
                                                    question: result.data,
                                                    nextQuestion: result.data.result.finished_category === "N" ? true : false
                                                }
                                            })}>
                                            {intl.get("BTN_CONTINUE")}
                                        </Button>
                                    </Incorrect>
                                :
                                <EmptyContent icon={<Icon ic="play_free" className="icon-xxl" />} text={intl.get("EMPTY_FREE")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingContent />
                }
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/free`,
                state: {
                    origin: 'result'
                }
            }} />
    )
}
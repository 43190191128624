import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { scormService } from '../../../services/scorm.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as ScormActions } from '../../../redux/ducks/scorm';
import * as moment from 'moment';
import { getTimer, imageParser } from '../../../utils/utils';
import ReactHtmlParser from 'react-html-parser';
import QrLayout from '../../../components/QrLayout2';
import EmptyContent from '../../../components/EmptyContent';
import {
    Grid, Typography, Container, Button, CircularProgress, Tooltip,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    AppBar, Toolbar, IconButton
} from '@material-ui/core';
import ErrorContent from '../../../components/ErrorContent';
import ButtonSurvey from '../../../components/ButtonSurvey';
import ButtonCertificate from '../../../components/ButtonCertificate';
import { LoadingContent, LoadingBounce } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ScormDetails(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const { details } = useSelector(state => state.scorm);
    const [didMount, setDidMount] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [scormPackage, setScormPackage] = useState(null);
    const theme = props.location.state && props.location.state.theme ? props.location.state.theme : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            setTimeout(() => {
                dispatch(ScormActions.getDetails({
                    id_scorm: props.match.params.idScorm
                }));
            }, 500);

        return () => {
            dispatch(ScormActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match]);

    async function downloadCertificate() {
        let ret;
        setLoadingButton(true);
        try {
            ret = await scormService.certificate({
                format: "pdf",
                id_scorm: details.data.scorm.id_scorm
            });
            let link = document.createElement("a");
            link.download = "Certificado.pdf";
            link.href = URL.createObjectURL(new Blob([ret], { type: 'application/pdf' }));
            link.click();
            setLoadingButton(false);
        }
        catch (err) {
            console.log(err);
        }
    }

    function goBack() {
        let currentOrigin = "details";

        switch (origin) {
            case 'home':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/home`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/scorm`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'package':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/scorm`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'scorm_list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/scorm/${theme.id_category}/list`,
                    state: {
                        theme: theme,
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                </Toolbar>
            </AppBar>
        )
    }

    const Header = () => {
        let banner = details.data.scorm.banner ? details.data.scorm.banner : details.data.scorm.image;

        return (
            <Grid container id="scorm-details-header" style={{ backgroundImage: `url(${imageParser(banner)})` }}></Grid>
        )
    }

    const ButtonCertificateScorm = () => {
        let isUnlock = details.data.scorm.certificate_available && details.data.scorm.certificate_available === "Y" ? true : false;

        return (
            loadingButton ?
                <Grid container alignItems="center" justify="center">
                    <LoadingBounce />
                </Grid>
                :
                <ButtonCertificate
                    action={() => downloadCertificate()}
                    unlock={isUnlock}
                    text={intl.get('SCORM_CERTIFICATE_TEXT')}
                    textDisabled={
                        details.data.scorm.completed === "Y" && details.data.scorm.has_survey === "Y" && (details.data.scorm.survey_answered && details.data.scorm.survey_answered === "N") ?
                            intl.get('SCORM_CERTIFICATE_TEXT_SURVEY_DISABLED')
                            : intl.get('SCORM_CERTIFICATE_TEXT_DISABLED')
                    }
                />
        )
    }

    const Survey = () => {
        return (
            details.data.scorm.has_survey === "Y" ?
                <ButtonSurvey
                    action={() => history.push({
                        pathname: `${props.match.path.replace(/./g, '')}/scorm/${details.data.scorm.id_scorm}/survey`,
                        state: {
                            data: details.data.scorm,
                            idName: 'id_scorm',
                            title: details.data.scorm.title,
                            origin: 'scorm'
                        }
                    })}
                    answered={details.data.scorm.survey_answered === "Y"}
                    unlock={details.data.scorm.completed === "Y" && details.data.scorm.survey_answered === "N"}
                    textUnlock={intl.get('SCORM_SURVEY_TEXT_UNLOCK')}
                    textAnswered={intl.get('SCORM_SURVEY_TEXT_ANSWERED')}
                    textDisabled={intl.get('SCORM_SURVEY_TEXT_DISABLED')}
                />
                : null
        )
    }

    const TimerLabel = (props) => {
        const { time, size } = props;
        var myMoment = null;

        if (time !== "") {
            myMoment = moment(time, "H:mm");
        }

        return (
            time && myMoment && (myMoment.hour() !== 0 || myMoment.minutes() !== 0) ?
                <div className="scorm-time">
                    <Icon ic="clock" color="lighter" className={`icon-${size} mr-1`} />
                    <Typography variant="body2" component="p" className="fs-md font-weight-bold">
                        {getTimer(time)}
                    </Typography>
                </div>
                : null
        );
    }

    const BestViewLabel = (props) => {
        const { bestView, size } = props;

        return (
            <div className="scorm-best-view">
                <Tooltip title={intl.get("LABEL_LARGE_SCREEN")} placement="top" arrow>
                    <div className="scorm-best-view-icon mr-2">
                        <Icon ic="laptop_outline" color={bestView === "A" || bestView === "D" ? "primary" : "lighter"} className={`icon-${size}`} />
                    </div>
                </Tooltip>
                <Tooltip title={intl.get("LABEL_SMALL_SCREEN")} placement="top" arrow>
                    <div className="scorm-best-view-icon">
                        <Icon ic="mobile_outline" color={bestView === "A" || bestView === "M" ? "primary" : "lighter"} className={`icon-${size}`} />
                    </div>
                </Tooltip>
            </div>
        );
    }

    const PackageList = () => {
        let progress = 100 * parseInt(details.data.scorm.total_package_complete) / parseInt(details.data.scorm.total_package);

        return (
            details.data.scorm_package.length > 0 ?
                <Grid container id="scorm-details-package">
                    <Grid container id="scorm-details-package-info">
                        <Grid item xs={12} md={6} className="scorm-progress order-2 order-md-1">
                            <div className="circular-progress">
                                <div className="progress">
                                    <CircularProgress className="progress-inactive" variant="determinate" value={100} size={64} thickness={3} />
                                    <CircularProgress className="progress-active" variant="determinate" value={progress} size={64} thickness={3} />
                                </div>
                                <div className="circular-progress-content">
                                    <Typography variant="body2" component="span">
                                        {`${details.data.scorm.total_package_complete} / ${details.data.scorm.total_package}`}
                                    </Typography>
                                </div>
                            </div>
                            <Typography variant="subtitle2" component="h2">
                                {intl.get("SCORM_LESSONS_SUBHEADER")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className="order-1 order-md-2">
                            <Grid container className="h-100">
                                {
                                    details.data.scorm.workload_total ?
                                        <Grid item xs={6} className="scorm-package-info">
                                            <div className="scorm-package-info-content">
                                                <Typography variant="body2" component="p" className="mb-1">
                                                    {intl.get("SCORM_LESSONS_WORKLOAD")}
                                                </Typography>
                                                <TimerLabel time={details.data.scorm.workload_total} size={"xxs"} />
                                            </div>
                                        </Grid>
                                        : null
                                }
                                {
                                    details.data.scorm.best_view_scorm ?
                                        <Grid item xs={6} className="scorm-package-info">
                                            <div className="scorm-package-info-content">
                                                <Typography variant="body2" component="p" className="mb-1">
                                                    {intl.get("SCORM_LESSONS_BESTVIEW")}
                                                </Typography>
                                                <BestViewLabel bestView={details.data.scorm.best_view_scorm} size={"sm"} />
                                            </div>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="scorm-init-line"></div>
                    <Grid container id="scorm-details-package-list">
                        {
                            details.data.scorm_package.map((item, index) =>
                                <Grid
                                    item
                                    key={index}
                                    xs={12}
                                    className={`scorm-package-item ${item.blocked === 'Y' ? '__disabled' : item.completed === 'Y' ? '__completed' : '__available'}`}
                                    onClick={item.blocked === 'N' ? () => { setOpenAlert(true); setScormPackage(item) } : null}
                                >
                                    <div className="scorm-package-item-circle">
                                        {
                                            item.completed === 'Y' ?
                                                <Icon ic="check" className="icon-xxs" />
                                                : null
                                        }
                                    </div>
                                    <div className="scorm-package-item-content">
                                        <div className="scorm-item-text">
                                            <Typography variant="body1" component="h4">
                                                {item.number_module}. {item.title}
                                            </Typography>
                                            {
                                                item.description ?
                                                    <Typography variant="body2" component="p">
                                                        {item.description}
                                                    </Typography>
                                                    : null
                                            }
                                            <div className="scorm-item-info">
                                                <TimerLabel time={item.workload} size={"xxs"} />
                                                <BestViewLabel bestView={item.best_view_module} size={"xs"} />
                                            </div>
                                        </div>
                                        <div className="scorm-item-button">
                                            {
                                                item.blocked === 'Y' ?
                                                    <Icon ic="lock" color="lighter" />
                                                    :
                                                    <Button variant="outlined" className="btn-card circle" onClick={() => { setOpenAlert(true); setScormPackage(item) }}>
                                                        <Icon ic="start" color="primary" className="icon-xxs" />
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                    <div className="scorm-package-item-line"></div>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                :
                <EmptyContent type="section" icon={<Icon ic="play_scorm" className="icon-xxl" />} text={intl.get("EMPTY_SCORMS")} />
        )
    }

    return (
        <QrLayout id="scorm-details">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            {
                details && details.load === "finished" ?
                    !details.error ?
                        details.data.length !== 0 ?
                            <div>
                                <Header />
                                <Container maxWidth="md" className="pt-3 pb-3">
                                    <Grid container spacing={2} justify="center">
                                        <Grid item xs={12} sm={10} xl={12}>
                                            <Typography variant="h3" component="h1">
                                                {details.data.scorm.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={10} xl={12}>
                                            <Typography variant="body2" component="div">
                                                {ReactHtmlParser(details.data.scorm.description)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={10} xl={12}>
                                            <ButtonCertificateScorm />
                                        </Grid>
                                        <Grid item xs={12} sm={10} xl={12}>
                                            {/* Survey */}
                                            <Survey />
                                        </Grid>
                                        <Grid item xs={12} sm={10} xl={12}>
                                            <PackageList />
                                        </Grid>
                                    </Grid>
                                    <Dialog open={openAlert} onClose={() => setOpenAlert(false)} aria-labelledby="dialog-info-scorm">
                                        <DialogTitle>
                                            {intl.get("SCORM_INFO_DIALOG_TITLE")}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {intl.get("SCORM_INFO_DIALOG_TEXT")}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setOpenAlert(false)} color="primary">
                                                {intl.get("BTN_CANCEL")}
                                            </Button>
                                            <Button
                                                onClick={() => history.push({
                                                    pathname: `${props.match.path.replace(/./g, '')}/scorm/${details.data.scorm.id_scorm}/package`,
                                                    state: {
                                                        idScorm: details.data.scorm.id_scorm,
                                                        module: scormPackage,
                                                        origin: 'details'
                                                    }
                                                })}
                                                color="primary"
                                            >
                                                {intl.get("BTN_START")}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Container>
                            </div>
                            :
                            <EmptyContent icon={<Icon ic="play_scorm" className="icon-xxl" />} text={intl.get("EMPTY_SCORMS")} />
                        :
                        <ErrorContent />
                    :
                    <LoadingContent />
            }
        </QrLayout>
    )
}

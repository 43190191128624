import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fill-rule="evenodd">
                <path fill="#FFF" fill-rule="nonzero" d="M0 0h512v512H0z" />
                <path d="m324.564 277.11-82.192 51.262c-4.327 2.583-8.651 3.876-13.41 3.876-4.327 0-8.22-.863-12.112-3.017-8.22-4.31-12.978-12.49-12.978-21.969V204.74c0-9.047 4.758-17.229 12.978-21.968 8.22-4.309 17.737-4.309 25.522.862l82.192 51.262c7.354 4.739 11.68 12.491 11.68 21.109 0 8.611-4.326 16.797-11.68 21.103v.002Zm57.103-139.57C363.93 106.954 331.054 88 295.58 88h-79.595c-35.474 0-68.35 18.955-86.085 49.537l-39.798 68.925c-17.737 30.586-17.737 68.491 0 99.076l39.798 68.925C147.638 405.046 180.513 424 215.986 424h80.028c35.474 0 68.348-18.955 86.085-49.537l39.798-68.925c17.737-30.586 17.737-68.491 0-99.076l-40.23-68.922Z" fill="#00B74F" />
            </g>
        </svg>
    )
}

export default LogoAppIcon

import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#1E5BC6" d="M0 0h512v512H0z" />
                <path
                    d="M342.02 160.8c17.933 0 33.218 10.507 33.268 19.151.008 1.893-.72 3.686-2.306 5.274-8.5 8.205-11.824 16.42-11.849 23.662-.055 13.829 11.939 24.12 22.997 24.12 15.2 0 24.165-11.959 24.165-27.42 0-14.649-6.249-26.873-13.043-36.638a2.269 2.269 0 01-.388-1.24c.005-.77.452-1.405 1.134-1.405.413 0 .896.221 1.418.745l76.338 73.833c3.809 3.236 6.246 7.04 6.246 12.475 0 5.413-2.434 9.231-6.246 12.49l-76.338 73.807c-.525.529-1.008.748-1.418.748-.677 0-1.129-.63-1.134-1.396 0-.392.12-.838.388-1.238 6.797-9.779 13.043-21.988 13.043-36.65 0-15.471-8.965-27.418-24.165-27.418-11.058 0-23.052 10.299-22.997 24.124.025 7.243 3.35 15.45 11.849 23.662 1.586 1.58 2.314 3.39 2.306 5.28-.05 8.63-15.335 19.132-33.268 19.132-29.883 0-41.559-41.784-41.559-92.275 0-50.475 11.676-92.823 41.559-92.823zm-57.307 5.564c.723 0 1.224.638 1.2 1.427-.022.466-.219.98-.671 1.427-17.914 22.273-30.948 48.3-30.948 84.41 0 36.088 13.034 61.614 30.948 83.871.452.452.649.976.672 1.43.02.772-.478 1.43-1.2 1.43-.404 0-.877-.2-1.367-.702l-76.319-73.807a23.452 23.452 0 01-2.26-2.204v11.272c-.09 8.22-.216 13.212-.226 15.448v.27c0 1.49.087 2.885.225 4.229v43.11a72 72 0 01-5.3 1.198c-3.91.587-7.469 1.186-10.404 1.186-16.958 0-20.399-11.004-23.817-26.776-.29.359-.577.71-.867 1.057l-.436.519c-1.974 2.33-4.136 4.61-7.903 8.242-11.11 10.405-26.3 16.958-41.972 16.664-17.895-.335-33.394-4.535-45.506-17.883-16.608-18.303-12.815-46.601-11.524-68.833.819-14.084 1.058-22.211.767-30.45l-.036-.952a227.16 227.16 0 00-.28-5.344c-.688-10.844-7.905-20.574-24.241-18.813-6.789-16.934 15.749-27.715 28.384-30.192 4.005-.611 7.693-1.222 11.098-1.222 17.874 0 24.674 11.145 24.674 27.797l-.317 54.197c0 40.21 5.578 60.703 31.507 60.703 28.326 0 36.077-25.295 36.077-48.277v-43.864c0-15.067-7.14-20.915-23.464-19.153-6.776-16.935 15.713-27.716 28.408-30.193 3.97-.611 7.692-1.222 11.12-1.222 14.676 0 21.912 7.531 24.032 19.449v57.26a23.05 23.05 0 012.261-2.188l76.32-73.833c.49-.499.962-.696 1.365-.688z"
                    fill="#FFF"
                />
            </g>
        </svg>
    )
}

export default LogoAppIcon

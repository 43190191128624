import React, { useState, forwardRef } from 'react';
import intl from 'react-intl-universal';
import { examDateTime } from '../../../../utils/utils';
import ReactHtmlParser from 'react-html-parser';
import {
    Container, Typography, Grid, ListItem, ListItemText,
    Divider, Button, Slide, IconButton
} from '@material-ui/core';
import DialogExamRules from './DialogExamRules';
import DialogQuestionFeedback from './DialogQuestionFeedback';
import QrList from '../../../../components/QrList';
import { Ribbon, ExamGrade } from '../../../../components/Images/Images';
import ImgSunrise1 from '../../../../dist/img/svgr/ImgSunrise1';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExamResult(props) {
    const { item } = props;
    const [openRules, setOpenRules] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [question, setQuestion] = useState({});
    const [exam, setExam] = useState('');
    const [order, setOrder] = useState('');

    const Header = () => {
        return (
            <Grid container id="exam-details-header" justify="center" className="appbar-custom-content __result">
                <div className="sunrise">
                    <ImgSunrise1 />
                </div>
                <Container maxWidth="md">
                    <Grid container justify="center">
                        <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                            <Ribbon
                                type="straight-filled"
                                text={intl.get("EXAM_RESULT_RIBBON")}
                            />
                        </Grid>
                        <Grid item xs={12} className="scenario-content" align="center">
                            <div className="shine"></div>
                            <div className="scenario-content-title">
                                <Typography variant="h5" component="h5">
                                    {intl.get("LABEL_AVERAGE")}
                                </Typography>
                            </div>
                            <div className="scenario-content-circle">
                                <div className="scenario-content-circle-text">
                                    <Typography variant="h3" component="h3">
                                        {parseInt(item.session.average)}%
                                    </Typography>
                                </div>
                                <ExamGrade />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }

    const handleOpenFeedback = (data, order) => {
        setQuestion(data);
        setExam(item.name);
        setOrder(order);
        setOpenFeedback(true);
    };

    return (
        <div>
            <Header />
            <Container maxWidth="md" className="pt-3 pb-4 __bottom-bar">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} xl={12}>
                        <Grid container justify="center" spacing={2}>
                            {
                                item.description ?
                                    <Grid item xs={12}>
                                        <Typography variant="body2" component="div">
                                            {ReactHtmlParser(item.description)}
                                        </Typography>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <QrList>
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_THEME")} secondary={item.name} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText
                                            primary={intl.get("LABEL_CORRECT_ANSWERS")}
                                            secondary={`${item.session.total_question_correct} / ${item.total_question}`}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography variant="body1" component="span" className="fs-sm">
                                                    {intl.get("LABEL_TOTAL_TIME")}
                                                </Typography>
                                            }
                                            secondary={
                                                <div className="d-flex flex-column align-items-end">
                                                    <Typography variant="body2" component="span" className="fs-md font-weight-bold">
                                                        {item.session.time_spent ? item.session.time_spent : "-"}
                                                    </Typography>
                                                    <Typography variant="body2" component="span">
                                                        {item.duration}
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_AVAILABLE")} secondary={examDateTime(item.exam_start_date)} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <ListItemText primary={intl.get("EXAM_FINISH")} secondary={examDateTime(item.exam_end_date)} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters>
                                        <Grid container spacing={2} id="exam-result-feedback">
                                            <Grid item xs={12}>
                                                <Typography variant="body1" component="p" className="fs-sm pl-2 pt-3">
                                                    {intl.get("LABEL_FEEDBACK")}
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={12} justify="center" className="flex-wrap">
                                                {
                                                    item.session.answered_questions.map((question, i) => {
                                                        return (
                                                            <div key={i} className="p-2">
                                                                <IconButton
                                                                    aria-label={question.id_question}
                                                                    className={`btn-feedback MuiButton-root MuiButton-outlined ${question.correct ? question.correct === "Y" ? "btn-outline-success" : "btn-outline-danger" : "btn-outline-lighter"}`}
                                                                    onClick={() => handleOpenFeedback(question, parseInt(i + 1))}
                                                                >
                                                                    {parseInt(i + 1)}
                                                                </IconButton>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </QrList>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <div id="bottom-bar" className="relative-bar">
                                    <Grid container justify="center">
                                        <Grid item xs={12} lg={8}>
                                            <Button id="btn_exam_details_rules" variant="contained" className="btn-block" onClick={() => setOpenRules(true)}>
                                                {intl.get("BTN_RULE")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {/* Dialog Rules */}
            <DialogExamRules
                open={openRules}
                close={() => setOpenRules(false)}
                transition={Transition}
                item={item}
            />

            {/* Dialog Feedback */}
            <DialogQuestionFeedback
                open={openFeedback}
                close={() => setOpenFeedback(false)}
                transition={Transition}
                exam={exam}
                question={question}
                order={order}
            />
        </div>
    )
}
import * as React from "react"

function CharTrailFlashcardsFinishedStars1(props) {
    const { medal } = props

    return (
        <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
            <defs>
                <path id="CharTrailFlashcardsFinishedStars1__b" d="M0 0h180v63H0z" />
                <path id="CharTrailFlashcardsFinishedStars1__e" d="M0 0h31.5v31.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars1__g" d="M0 0h40.5v40.5H0z" />
                <path id="CharTrailFlashcardsFinishedStars1__i" d="M0 0h31.5v31.5H0z" />
                <filter
                    x="-10.8%"
                    y="-9.4%"
                    width="121.7%"
                    height="118.8%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars1__a"
                >
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <filter
                    x="-7.9%"
                    y="-7.9%"
                    width="117.5%"
                    height="117.5%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailFlashcardsFinishedStars1__d"
                >
                    <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196213942 0"
                        in="shadowOffsetOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <circle fill={medal} cx={90} cy={90} r={81} />
                <path
                    d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                    fill="#000"
                    opacity={0.05}
                />
                <path
                    d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                    fill="#000"
                    opacity={0.15}
                />
                <path
                    d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                    fill="#FFF"
                    opacity={0.2}
                />
                <g filter="url(#CharTrailFlashcardsFinishedStars1__a)" transform="translate(35 7)">
                    <path
                        d="M7.14 44.048c-2.217 1.751-.99 5.55 2.219 5.662 3.327.117 5.417 1.588 7.238 2.86 3.677 2.568 6.92.905 8.814-.817 3.21-2.919 6.118-1.038 8.347-1.109 3.677-.117 6.012-3.91 6.012-3.91l-2.685-21.715L7.141 44.048z"
                        fill="#EDE4BC"
                    />
                    <path
                        d="M103.044 105.395C93.763 94.89 82.03 89.635 77.828 83.857c-4.203-5.78-36.599-2.627-36.599-2.627-10.156 6.83-26.091 3.502-35.722 16.636C2.72 101.664 1.03 115.405.049 127.75h110.413c-1.166-8.702-3.347-17.747-7.418-22.356"
                        fill="#46ACC3"
                    />
                    <path
                        d="M59.817 89.484c-.523-1.218-2.56-1.511-2.56-1.511s-1.19 1.68-.667 2.898c.523 1.217 1.67 1.894 2.56 1.511.892-.382 1.19-1.68.667-2.898m21.958 25.641c-.424 1.255-.022 2.525.897 2.835.919.31 2.007-.456 2.431-1.712.424-1.256-.896-2.835-.896-2.835s-2.008.456-2.432 1.712M33.814 98.509c.016-1.326-1.728-2.42-1.728-2.42s-1.769 1.054-1.784 2.379c-.015 1.325.758 2.408 1.728 2.42.97.011 1.769-1.054 1.784-2.38m28.934 19.632c.442-1.25.059-2.525-.856-2.849-.914-.323-2.014.428-2.456 1.677-.442 1.25.856 2.848.856 2.848s2.014-.427 2.456-1.677m38.719-2.042c-1.325-.038-2.45 1.687-2.45 1.687s1.025 1.787 2.35 1.824c1.325.038 2.421-.718 2.449-1.687.027-.97-1.025-1.786-2.35-1.824m-61.493 4.573c.955-.172 1.54-1.368 1.306-2.673-.234-1.304-2.153-2.052-2.153-2.052s-1.539 1.368-1.305 2.672c.234 1.305 1.198 2.224 2.152 2.052m1.307-38.624c.07-.282.095-.561.089-.828l-.14.013a21.723 21.723 0 01-3.006 1.678c.345.614.787 1.058.787 1.058s1.96-.633 2.27-1.92m37.138 2.726c.13.164.294.288.48.376a16.38 16.38 0 01-.71-.822c.049.161.124.312.23.446m-32.95 18.798s.85 1.875 2.164 2.04c1.315.163 2.479-.484 2.599-1.447.12-.962-.85-1.875-2.164-2.04-1.315-.163-2.599 1.447-2.599 1.447m51.542-3.548c.269.082.536.125.794.136a80.075 80.075 0 00-2.432-2.083c.23.61.747 1.674 1.639 1.947m-78.828 20.043c.956-.16 1.554-1.35 1.335-2.657-.219-1.307-2.129-2.076-2.129-2.076s-1.554 1.35-1.335 2.657c.22 1.307 1.172 2.236 2.129 2.076m56.807-17.631c.148-.958-.793-1.9-2.103-2.102-1.31-.202-2.64 1.37-2.64 1.37s.794 1.9 2.104 2.102c1.31.202 2.492-.411 2.64-1.37m-62.894-8.222v4.047c.515.313 1.083.391 1.56.155.868-.431 1.094-1.743.504-2.93-.389-.784-1.412-1.127-2.064-1.272m-.577-.096s-1.094 1.744-.504 2.93c.267.538.655.955 1.08 1.213v-4.047c-.336-.074-.575-.096-.575-.096m-5.403 18.783c.64-.729.35-2.029-.646-2.903-.996-.873-2.962-.262-2.962-.262s-.35 2.029.646 2.903c.996.874 2.323.991 2.962.262"
                        fill="#7FD4BF"
                    />
                    <path
                        d="M45.082 63.719c-.018 5.37-1.07 11.121-3.853 17.511 0 0 7.18 10.157 17.687 9.456 10.506-.7 18.912-6.83 18.912-6.83s-2.102-18.736 1.226-40.45L45.082 63.719z"
                        fill="#B75F0F"
                    />
                    <path
                        d="M33.875 26.42c.573 12.606 5.078 43.778 20.138 44.829 15.06 1.05 25.04-27.843 25.04-27.843S81.856 12.061 63.469 9.26c-18.387-2.803-30.295 1.75-29.594 17.16"
                        fill="#C66618"
                    />
                    <path
                        d="M110.389 127.217c-.244.182-.485.36-.724.534h.796l-.072-.534"
                        fill="#E87950"
                    />
                    <path
                        d="M24.8 119.85s-10.528-7.5-22.613-10.764C1.251 114.641.556 121.382.05 127.751h24.372l.38-7.9zm22.582-79.329l2.842 11.446-4.135 1.486c-1.066.383-2.095-.867-1.743-2.118l3.036-10.814z"
                        fill="#B75F0F"
                    />
                    <path
                        d="M61.075 39.145a1.226 1.226 0 11-2.451 0 1.226 1.226 0 012.451 0m-21.48 2.685a1.226 1.226 0 11-2.452 0 1.226 1.226 0 012.452 0"
                        fill="#080202"
                    />
                    <path
                        d="M65.687 12.411s-1.051 4.67-5.604 5.837c-4.553 1.168-5.837-2.568-9.222.117-3.386 2.685-.818 3.97-4.32 6.07-3.502 2.102-5.253-1.167-8.055 1.518-2.802 2.685-.234 7.705-6.07 10.623-3.317 1.659-7.238-1.867-12.025 1.518-2.564 1.814-3.094 6.246-6.42 8.289-3.422 2.101-7.414 1.75-7.414 1.75s-1.044-1.411-.584-3.501c.876-3.97 4.378-4.028 4.728-8.406.295-3.675-.829-8.538 2.219-10.857 4.027-3.064 8.23-2.918 8.347-7.354.116-4.436 3.677-9.34 8.23-9.34 5.606 0 7.938-2.1 10.273-4.903C42.105.97 47.008.153 52.261 2.838 57.34 5.434 56.91.623 62.184.037c4.203-.467 9.223 3.619 9.223 7.238 0 0 7.355-1.285 10.74 4.319 3.386 5.604 3.269 17.861-1.284 22.531 0 0-1.868-7.004-4.67-5.72-2.093.96-1.284 4.436-.583 6.887.7 2.452.233 3.853.233 3.853l-1.984.7s-.117-5.253-2.335-7.588-7.822-5.136-7.939-8.989c-.117-3.852 2.102-10.857 2.102-10.857"
                        fill="#FFF7D7"
                    />
                    <path
                        d="M75.444 28.616s5.468-2.387 7.01 5.126c1.541 7.513-5.565 7.647-5.565 7.647l-1.445-12.773z"
                        fill="#B75F0F"
                    />
                    <path
                        d="M67.307 33.92a8.795 8.795 0 00-4.998-3.914c-2.148-.652-4.483-.373-6.46.673a8.714 8.714 0 00-2.851 2.442c-.094.124-.092.288-.036.433.01.029.018.058.033.087l.868 1.763c.182.369.695.301.894 0 1.062-1.615 3.032-2.387 4.904-2.474 2.46-.114 4.828.833 7.062 1.75.435.179.794-.402.584-.76M38.45 36.966a.568.568 0 00.06.088 4.517 4.517 0 00-.258.074c.053-.045.104-.093.16-.135.012-.01.025-.018.038-.027m3.193-.936a1.469 1.469 0 00-.156-.195.556.556 0 00-.269-.22 1.61 1.61 0 00-1.11-.223c-.049.007-.096.02-.144.03a3.566 3.566 0 00-1.952.592c-1.058.7-1.703 1.83-2.202 2.964-.112.256-.075.555.186.708.254.148.551.047.707-.186a3.623 3.623 0 012.787-1.564c.637-.03 1.361.239 1.893-.243.366-.332.403-.81.311-1.262a.445.445 0 00-.051-.401"
                        fill="#EDE4BC"
                    />
                    <path
                        d="M47.398 58.192c.003-.038.008-.06.001 0h-.001m-.008-.003c-.002-.04 0-.027.003 0h-.003m.348-1.487a1.68 1.68 0 00-2.056.993c-.355.91.267 1.957 1.168 2.216a.918.918 0 00.74-.126c.527-.119.974-.53 1.167-1.082.292-.834-.186-1.74-1.019-2.001"
                        fill="#FFF"
                    />
                    <path
                        d="M71.817 40.004a.393.393 0 00-.08-.048l-.012-.33-.031-.81.112.368a.324.324 0 00-.029.153l.04.667m1.481 1.462c-.125-.569-.33-1.127-.5-1.684l-1.2-3.946c-.006-.02-.015-.038-.023-.056-.06-.456-.79-.446-.77.04l.134 3.553.07 1.84c.018.458.001.947.15 1.388.303.894 1.349 1.115 1.934.333.32-.427.316-.968.205-1.468"
                        fill="#78ECFF"
                    />
                </g>
                <g transform="translate(0 117)">
                    <mask id="CharTrailFlashcardsFinishedStars1__c" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars1__b" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars1__c)">
                        <path
                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                            className="svg-ribbon-stars-back"
                        />
                        <path
                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-1.19-.193-2.992-.553-4.8-.96l-.57-.13c-2.94-.677-5.731-1.447-5.754-1.788-.018-.27 2.998-.736 6.182-1.115l.684-.08c3.307-.378 6.577-.64 6.626-.476z"
                            className="svg-ribbon-stars-shadow"
                        />
                        <path
                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                            className="svg-ribbon-stars-front"
                        />
                    </g>
                </g>
                <g filter="url(#CharTrailFlashcardsFinishedStars1__d)" transform="rotate(-12 659.94 -89.693)">
                    <mask id="CharTrailFlashcardsFinishedStars1__f" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars1__e" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars1__f)" className="svg-stage-star">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
                <g transform="translate(69.755 123.75)">
                    <mask id="CharTrailFlashcardsFinishedStars1__h" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars1__g" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars1__h)" className="svg-ribbon-stars-back">
                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                    </g>
                </g>
                <g transform="rotate(12 -554.159 616.797)">
                    <mask id="CharTrailFlashcardsFinishedStars1__j" fill="#fff">
                        <use xlinkHref="#CharTrailFlashcardsFinishedStars1__i" />
                    </mask>
                    <g mask="url(#CharTrailFlashcardsFinishedStars1__j)" className="svg-ribbon-stars-back">
                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CharTrailFlashcardsFinishedStars1

import * as React from 'react';
import config from '../../../config';
import AssaiCharCourseFlashcardsFinishedStars3 from '../projects/assai/chars/CharCourseFlashcardsFinishedStars3';
import CarrefourCharCourseFlashcardsFinishedStars3 from '../projects/carrefour/chars/CharCourseFlashcardsFinishedStars3';

const project = config.app.name_project.toLowerCase()
let theme = require(`../../../assets/scss/projects/${project}/_export.scss`)

function CharCourseFlashcardsFinishedStars3(props) {
    if (config.app.name_project === "QRANIO") {
        return (
            <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                <defs>
                    <path id="prefix__b" d="M0 0h180v63H0z" />
                    <path id="prefix__d" d="M0 0h31.5v31.5H0z" />
                    <path id="prefix__f" d="M0 0h40.5v40.5H0z" />
                    <path id="prefix__h" d="M0 0h31.5v31.5H0z" />
                    <filter
                        x="-8.1%"
                        y="-8%"
                        width="116.2%"
                        height="116%"
                        filterUnits="objectBoundingBox"
                        id="prefix__a"
                    >
                        <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur
                            stdDeviation={2}
                            in="shadowOffsetOuter1"
                            result="shadowBlurOuter1"
                        />
                        <feColorMatrix
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                            in="shadowBlurOuter1"
                            result="shadowMatrixOuter1"
                        />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <circle fill="#FFD137" cx={90} cy={90} r={81} />
                    <path
                        d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                        fill="#000"
                        opacity={0.054}
                    />
                    <path
                        d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                        fill="#000"
                        opacity={0.153}
                    />
                    <path
                        d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                        fill="#FFF"
                        opacity={0.398}
                    />
                    <g filter="url(#prefix__a)" transform="translate(37 11)">
                        <path
                            d="M73.554 73.28l13.174-16.044s3.826-.236 18.887-14.925c-4.082-9.164-9.315-17.432-2.96-19.486 4.702-1.52 10.572 16.054 10.572 16.054 5.005-10.513 2.52-20.18 6.986-19.698 7.521.81 3.305 19.115 3.305 19.115.234.123 13.627 7.998-1.567 19.717l-.28.284c-6.55 4.931-22.355 19.572-33.046 25.282l-7.674.09c-5.465-5.536-7.2-11.468-7.397-10.388z"
                            fill="#FDC436"
                        />
                        <path
                            d="M93.643 51.654l9.831 22.482s-5.388 3.557-7.798 5.186c-4.88 3.298-6.82 4.46-7.27 4.724.274.75.565 1.442.873 2.058 4.163 8.321 3.503 16.511 3.503 23.842 0 7.33-4.485 13.753-4.485 13.753s-5.38 4.701-20.496 6.655c-19.238 2.487-33.636-6.283-33.636-6.283l-.866-12.654-.4-13.149-3.135-9.415-3.718-9.64 2.225.17 55.347-14.997c-.501-2.857-.834-4.92-.834-4.92l10.859-7.812z"
                            fill="#46CA64"
                        />
                        <path
                            d="M33.569 81.647c3.724 3.995 5.467 11.796 1.472 15.52l-10.336 4.816c2.383 2.453 5.008 5.117 5.008 5.117 4.612-4.968 18.875 5.083 11.24 5.654 8.366 6.603 3.294 5.63-4.101 11.259l-.473.437c-4.57 4.046-13.671-.257-18.856-6.114l-10.6-10.488a19.31 19.31 0 00-.547-.612 6.425 6.425 0 01-.42-.413 10.041 10.041 0 01-.414-.472c-1.31-1.407-2.447-2.859-2.212-5.517a9.865 9.865 0 013.115-7.987l12.262-11.433c3.994-3.725 11.138-3.76 14.862.233z"
                            fill="#FDC436"
                        />
                        <path
                            d="M6.898 90.31l21.435 10.771 4.567-2.813s11.783-.838 9.825-11.643c-.35-1.924-12.005-8.813-12.005-8.813l-10.33 1.727L15.167 84l-8.269 6.31z"
                            fill="#46CA64"
                        />
                        <path
                            d="M15.564 75.499c1.699 3.57 6.523 11.304 19.932 9.282C51.51 82.368 73.882 75.5 76.414 74.668c2.531-.83 7.436-7.753 7.01-20.475-.364-10.867-9.931-35.781-9.931-35.781L5.315 35.319s-.363 24.236 10.25 40.18z"
                            fill="#000"
                            opacity={0.1}
                        />
                        <path
                            d="M8.601 71.868c1.096 3.7 6.845 13.075 19.452 10.123 13.933-3.26 47.303-11.073 47.303-11.073s14.894-4.6 12.725-17.107c.091.198-5.794-23.26-5.794-23.26L2.804 49.047s5.81 22.71 5.797 22.821z"
                            fill="#FDC436"
                        />
                        <path
                            d="M60.445 58.428c6.027 1.586 12.186-1.59 13.758-7.093 1.571-5.504-2.04-11.25-8.068-12.837-6.026-1.585-12.186 1.591-13.757 7.093-1.571 5.503 2.041 11.251 8.067 12.837"
                            fill="#212321"
                        />
                        <path
                            d="M60.779 48.101c2.939.773 5.943-.776 6.71-3.46.765-2.683-.996-5.485-3.936-6.26-2.938-.772-5.942.777-6.708 3.46s.994 5.486 3.934 6.26"
                            fill="#FFF"
                        />
                        <path
                            d="M29.686 65.052c6.027 1.585 12.187-1.59 13.758-7.094 1.57-5.502-2.041-11.25-8.069-12.836-6.026-1.586-12.186 1.59-13.757 7.092-1.57 5.504 2.041 11.252 8.068 12.838z"
                            fill="#212321"
                        />
                        <path
                            d="M30.02 54.725c2.939.773 5.942-.776 6.709-3.46.766-2.683-.996-5.486-3.935-6.259-2.94-.773-5.943.776-6.709 3.458-.767 2.685.995 5.487 3.934 6.26"
                            fill="#FFF"
                        />
                        <path
                            d="M47.18.682c3.905-.479 2.23-.386 5.01-.448 15.105.183 26.931 14.9 30.066 28.153l1.028 4.353c.173.056-80.498 17.966-80.505 17.827l-1.093-4.619c-3.134-13.252.912-31.43 14.454-37.856 3.9-1.852 8.776-2.824 13.103-2.17 2.347.355 4.34 1.22 5.933 2.517.854-1.83 2.262-3.437 4.22-4.729 2.633-1.772 4.771-2.237 7.785-3.028"
                            fill="#212321"
                        />
                        <path
                            d="M77.681 29.384L44.258 36.67l-5.112-21.613c-.844-3.568.459-5.961 2.904-7.578 2.807-1.855 6.726-2.729 10.106-2.69 2.79.035 5.45.691 7.935 1.818-.697 2.133-2.589 3.292-4.976 3.294-1.403 0-2.709-.117-4.119.077-4.6.636-8.157 4.5-8.31 9.011-.1 1.96 1.229 3.212 2.422 3.219 1.311.008 2.463-.804 2.416-2.693-.075-4.438 4.114-4.857 7.619-4.86 4.13 0 7.622-2.1 9.214-5.554a30.221 30.221 0 013.922 3.396c-.914.96-1.633 2.145-2.105 3.535-.443 1.308-.642 2.705-1.12 3.984-.907 2.446-3.138 3.52-5.734 3.115-1.386-.215-2.658-.53-4.081-.555-4.653-.083-8.8 3.182-9.688 7.609-.417 1.916.69 3.359 1.868 3.55 1.294.208 2.569-.416 2.826-2.286.654-4.39 4.861-4.159 8.324-3.62 4.91.763 9.4-1.608 11.099-6.18.503-1.36.705-2.76 1.162-4.111.164-.485.377-.913.634-1.282 3.02 4.092 5.187 8.77 6.217 13.13M6.26 44.952l33.423-7.286-5.111-21.613c-.844-3.569-3.095-5.186-6.03-5.631-3.363-.51-7.292.327-10.323 1.764-2.5 1.188-4.606 2.882-6.34 4.928 1.589 1.635 3.815 1.897 5.97.908 1.266-.581 2.391-1.228 3.749-1.638 4.436-1.333 9.383.678 11.55 4.683.971 1.726.336 3.407-.737 3.907-1.179.551-2.584.297-3.39-1.427-1.93-4.034-5.897-2.675-9.059-1.224-3.726 1.712-7.818 1.265-10.809-1.19a28.793 28.793 0 00-2.01 4.689c1.255.486 2.44 1.257 3.49 2.315.987.997 1.797 2.173 2.8 3.129 1.923 1.83 4.415 1.875 6.575.433 1.154-.768 2.16-1.581 3.432-2.193 4.157-2.002 9.367-.777 12.16 2.847 1.239 1.555.888 3.315-.09 3.976-1.072.724-2.5.69-3.576-.89-2.565-3.688-6.255-1.736-9.136.186-4.084 2.723-9.2 2.446-12.788-.973-1.068-1.017-1.88-2.198-2.9-3.226a5.429 5.429 0 00-1.148-.895c-.883 4.945-.732 10.061.298 14.42M66.847 58.843S46.8 69.572 28.82 65.771c-.62-.13 9.384 13.526 26.913 8.21 10.92-5.371 10.766-14.35 11.114-15.138"
                            fill="#FFF"
                        />
                        <path
                            d="M66.847 58.843S55.855 77.148 28.8 65.806c-.582-.243 7.554 12.682 23.516 9.82 18.464-3.31 14.183-15.994 14.531-16.783"
                            fill="#212321"
                        />
                        <path
                            d="M112.061 43.582l5.47-.283c2.966-.154 5.465 1.526 5.58 3.753.114 2.226-2.197 4.156-5.163 4.309l-5.47.283c-2.966.153-5.464-1.527-5.579-3.753-.116-2.227 2.196-4.156 5.162-4.31"
                            fillOpacity={0.1}
                            fill="#000"
                        />
                        <path
                            d="M109.424 40.35l5.47-.283c2.966-.154 5.464 1.527 5.58 3.753.114 2.227-2.197 4.157-5.164 4.31l-5.469.283c-2.966.154-5.464-1.527-5.579-3.753-.116-2.227 2.196-4.156 5.162-4.31"
                            fill="#FDC436"
                        />
                        <path
                            fill="#000"
                            opacity={0.08}
                            d="M33.231 97.601l-6.835-15.389 5.486 16.665zM82.773 66.894l5.467 17.224.952-.476z"
                        />
                    </g>
                    <g>
                        <g transform="translate(0 117)">
                            <mask id="prefix__c" fill="#fff">
                                <use xlinkHref="#prefix__b" />
                            </mask>
                            <g mask="url(#prefix__c)">
                                <path
                                    d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714-.199.029-.405.013-.61-.052-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                    fill="#BD483B"
                                />
                                <path
                                    d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                    fill="#93382E"
                                />
                                <path
                                    d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                    fill="#E65748"
                                />
                            </g>
                        </g>
                        <g transform="rotate(-12 659.94 -89.693)">
                            <mask id="prefix__e" fill="#fff">
                                <use xlinkHref="#prefix__d" />
                            </mask>
                            <g mask="url(#prefix__e)" fill="#FDC436">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                        <g transform="translate(69.75 123.75)">
                            <mask id="prefix__g" fill="#fff">
                                <use xlinkHref="#prefix__f" />
                            </mask>
                            <g mask="url(#prefix__g)" fill="#FDC436">
                                <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                            </g>
                        </g>
                        <g transform="rotate(12 -554.164 616.746)">
                            <mask id="prefix__i" fill="#fff">
                                <use xlinkHref="#prefix__h" />
                            </mask>
                            <g mask="url(#prefix__i)" fill="#FDC436">
                                <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    } else if (config.app.name_project === "ASSAI") {
        return (
            <AssaiCharCourseFlashcardsFinishedStars3 medal={theme["gold"]} />
        )
    } else if (config.app.name_project === "CARREFOUR") {
        return (
            <CarrefourCharCourseFlashcardsFinishedStars3 medal={theme["gold"]} />
        )
    } else {
        let color = config.layout.char.color;
        let gender = config.layout.char.gender;
        let randomColor = ['black', 'white'];
        let randomGender = ['female', 'male'];
        let randomColorIndex = Math.floor(Math.random() * randomColor.length);
        let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

        if (color === 'random') {
            color = randomColor[randomColorIndex];
        }

        if (gender === 'random') {
            gender = randomGender[randomGenderIndex];
        }

        if (config.learn.trail.chars && config.learn.trail.type === "stars") {
            if (gender === "male") {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__b" d="M0 0h180v63H0z" />
                            <path id="prefix__d" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__f" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__h" d="M0 0h31.5v31.5H0z" />
                            <filter
                                x="-9.6%"
                                y="-8.8%"
                                width="119.2%"
                                height="117.5%"
                                filterUnits="objectBoundingBox"
                                id="prefix__a"
                            >
                                <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                                <feGaussianBlur
                                    stdDeviation={2}
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                />
                                <feColorMatrix
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                />
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1" />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle fill={theme["gold"]} cx={90} cy={90} r={81} />
                            <path
                                d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                                fill="#000"
                                opacity={0.054}
                            />
                            <path
                                d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                fill="#000"
                                opacity={0.153}
                            />
                            <path
                                d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                                fill="#FFF"
                                opacity={0.398}
                            />
                            <g filter="url(#prefix__a)" transform="translate(41 8)">
                                <path
                                    d="M102.275 62.825s-7.855-2.534-11.656-5.574c0 0-8.05 24.645-32.42 32.049l1.041 7.42s24.563-1.713 43.035-33.895"
                                    className="svg-char-shirt-shade-1"
                                />
                                <path
                                    d="M15.344 95.552c1.092 5.354-1.111 7.84-5.45 9.548-3.887 1.53-8.55-1.17-9.603-4.79-1.35-4.642 2.214-7.052 6.16-8.423 4.184-1.454 8.011-.661 8.893 3.665zm73.25-44.635c1.012-5.596 5.919-6.725 11.348-4.97 5.119 1.654 9.776 4.655 8.169 10.672-1.254 4.69-7.183 8.299-12.23 6.438-5.635-2.078-8.543-5.215-7.288-12.14z"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M11.592 93.464c.194.061.372.14.542.224-1.29.61-3.769 2.027-3.058 3.641 1.14 2.586 3.736 1.426 5.324.334.013.172.015.278.015.278-1.02 1.136-4.498 3.715-6.432.223-1.408-2.542 3.609-4.7 3.609-4.7zm81.774-45.342s6.521 2.638 4.78 5.95c-2.392 4.552-6.944 1.328-8.288-.105 0 0-.001-.137.012-.358 2.075 1.36 5.45 2.781 6.844-.581.87-2.099-2.36-3.853-4.04-4.602.216-.114.444-.22.692-.304z"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M58.508 89.3s4.194 14.546 5.291 28.531c0 0-4.632 2.045-17.276 2.045-6.894 0-14.78-.834-18.677-2.045 0 0 .238-2.85.951-8.329-1.719 2.729-3.716 5-5.7 5.264-5.7.76-15.582-9.122-15.582-9.122 1.9-2.28 6.461-6.46 6.461-6.46s5.7 6.46 8.742 6.46c3.04 0 9.106-15.419 9.106-15.419l.12.56c1.013.337 4.883 1.432 12.3 1.432 8.762 0 14.264-2.917 14.264-2.917z"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M45.88 99.38c.726-1.668 2.748-1.726 3.543-.084 1.272 2.624 4.71 11.195 4.608 11.85-.195 1.24-4.213 5.348-5.7 5.162-1.709-.212-6.15-4.108-6.116-4.92.088-2.034 2.34-8.966 3.664-12.008zm1.428-7.79a2.585 2.585 0 11.213 5.167 2.585 2.585 0 01-.213-5.166z"
                                    className="svg-char-necktie"
                                />
                                <path
                                    d="M58.367 49.17c0 9.055 5.517 13.171 12.735 13.171 7.22 0 11.8-13.358 12.07-19.313.439-9.69-4.846-16.564-12.065-16.564-7.219 0-12.74 13.651-12.74 22.706"
                                    className={`svg-char-${color}-hair-shade-2`}
                                />
                                <path
                                    d="M10.46 66.696c3.416-1.316 7.655 1.481 9.466 6.247 1.813 4.764 3.746 8.45.338 9.764-3.421 1.316-9.413.278-12.708-5.006-2.72-4.362-.51-9.69 2.904-11.005zm67.097-9.03c2.79.17 6.118 3.968 5.013 8.06-1.173 4.356-4.945 7.464-7.741 7.298-2.785-.171-2.388-3.623-2.367-7.907.021-4.284 2.302-7.621 5.095-7.451z"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M13.49 70.6c2.144-.603 4.529 1.158 5.321 3.938.797 2.778 1.738 4.946-.407 5.55-2.142.602-6.25-.695-7.354-3.368-1.288-3.119.298-5.515 2.44-6.12zm66.333-5.648c.174 2.433-2.674 5.42-4.365 5.492-1.694.072-1.6-1.907-1.773-4.338-.176-2.43 1.057-4.458 2.75-4.53 1.692-.071 3.215.947 3.388 3.376z"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M58.277 90.532s26.387-9.642 17.59-45.585S42.94 11.015 30.876 14.283C18.81 17.55 7.55 26.849 7.752 37.91c.692 38.293 15.34 49.438 23.46 52.94 4.446 1.918 16.398 4.14 27.065-.318"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M31.63 53.153c2.603 0 4.713 3.059 4.713 6.833s-2.11 6.833-4.713 6.833c-2.604 0-4.713-3.059-4.713-6.833s2.11-6.833 4.713-6.833zm31.355-1.928c2.603 0 4.714 3.06 4.714 6.834 0 3.774-2.11 6.834-4.714 6.834-2.603 0-4.712-3.06-4.712-6.834 0-3.774 2.109-6.834 4.712-6.834z"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M29.741 54.194c.926 0 1.676 1.088 1.676 2.43 0 1.344-.75 2.431-1.676 2.431-.926 0-1.676-1.087-1.676-2.43 0-1.343.75-2.431 1.676-2.431zm30.968-.968c.926 0 1.676 1.088 1.676 2.431 0 1.342-.75 2.431-1.676 2.431-.926 0-1.677-1.089-1.677-2.431 0-1.343.75-2.431 1.677-2.431z"
                                    fill="#FFF"
                                />
                                <path
                                    d="M46.879 66.73a3.518 3.518 0 107.038 0 3.519 3.519 0 00-7.038 0"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M56.01 77.622s-17.846-4.02-29.407-5.529c0 0-1.124 10.777 11.06 13.572 15.331 3.52 18.348-8.043 18.348-8.043"
                                    className="svg-char-mouth"
                                />
                                <path
                                    d="M37.662 85.665c-4.804-1.102-7.534-3.447-9.085-5.88 2.509-.977 5.966-1.34 9.69-.85 5.594.74 10.106 3.187 11.51 5.948-2.756 1.371-6.65 2.037-12.115.782"
                                    className="svg-char-mouth-tongue"
                                />
                                <path
                                    d="M11.788 66.332s12.313-11.678 5.307-27.6c0 0 3.575-2.173 19.318 1.062 15.496 3.184 38.984 5.667 49.992-5.623 8.278-8.491-.107-17.514-8.28-12.1 0 0 4.67-1.061 3.61-8.916C80.557 4.45 68.572 2.33 62.098 9.016c0 0 3.958-3.12.85-6.793-3.504-4.14-10.722-1.168-12.738 5.094 0 0-27.066-2.653-40.864 13.905-10.282 12.34-9.34 28.233 2.441 45.11"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M42.568 38.63c11.088 1.848 26.75 1.375 36.515-1.81 7.904-2.577 10.105-8.63 10.657-10.844.35 2.41-.544 5.332-3.335 8.195-11.008 11.29-34.496 8.808-49.992 5.623-15.743-3.235-19.318-1.061-19.318-1.061 5.944-4.136 19.742-1.059 25.473-.103M11.576 52.743c5.944.212 7.642-3.82 7.642-3.82s-.039.986-.15 2.558c-1.142 9.028-7.28 14.85-7.28 14.85-4.652-6.664-7.607-13.174-8.855-19.373 1.59 2.588 4.418 5.634 8.643 5.785"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M69.308 7.96c-.19.926 1.138 1.978 2.966 2.35 1.827.374 3.462-.074 3.65-1 .189-.925-1.138-1.977-2.966-2.35-1.828-.373-3.461.075-3.65 1"
                                    fillOpacity={0.1}
                                    fill="#FFF"
                                />
                            </g>
                            <g>
                                <g transform="translate(0 117)">
                                    <mask id="prefix__c" fill="#fff">
                                        <use xlinkHref="#prefix__b" />
                                    </mask>
                                    <g mask="url(#prefix__c)">
                                        <path
                                            d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714-.199.029-.405.013-.61-.052-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                            className="svg-ribbon-stars-back"
                                        />
                                        <path
                                            d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                            className="svg-ribbon-stars-shadow"
                                        />
                                        <path
                                            d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                            className="svg-ribbon-stars-front"
                                        />
                                    </g>
                                </g>
                                <g transform="rotate(-12 659.94 -89.693)">
                                    <mask id="prefix__e" fill="#fff">
                                        <use xlinkHref="#prefix__d" />
                                    </mask>
                                    <g mask="url(#prefix__e)" className="svg-stage-star">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                                <g transform="translate(69.75 123.75)">
                                    <mask id="prefix__g" fill="#fff">
                                        <use xlinkHref="#prefix__f" />
                                    </mask>
                                    <g mask="url(#prefix__g)" className="svg-stage-star">
                                        <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                    </g>
                                </g>
                                <g transform="rotate(12 -554.164 616.746)">
                                    <mask id="prefix__i" fill="#fff">
                                        <use xlinkHref="#prefix__h" />
                                    </mask>
                                    <g mask="url(#prefix__i)" className="svg-stage-star">
                                        <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            } else {
                return (
                    <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                        <defs>
                            <path id="prefix__b" d="M0 0h180v63H0z" />
                            <path id="prefix__d" d="M0 0h31.5v31.5H0z" />
                            <path id="prefix__f" d="M0 0h40.5v40.5H0z" />
                            <path id="prefix__h" d="M0 0h31.5v31.5H0z" />
                            <filter
                                x="-9.7%"
                                y="-8.8%"
                                width="119.4%"
                                height="117.5%"
                                filterUnits="objectBoundingBox"
                                id="prefix__a"
                            >
                                <feOffset dx={-1} in="SourceAlpha" result="shadowOffsetOuter1" />
                                <feGaussianBlur
                                    stdDeviation={2}
                                    in="shadowOffsetOuter1"
                                    result="shadowBlurOuter1"
                                />
                                <feColorMatrix
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                    in="shadowBlurOuter1"
                                    result="shadowMatrixOuter1"
                                />
                                <feMerge>
                                    <feMergeNode in="shadowMatrixOuter1" />
                                    <feMergeNode in="SourceGraphic" />
                                </feMerge>
                            </filter>
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <circle fill={theme["gold"]} cx={90} cy={90} r={81} />
                            <path
                                d="M34.436 31.062c-12.842 41.22-7.374 75.115 16.404 101.688 25.015 27.954 60.482 28.762 106.402 2.422C142.698 156.784 118.009 171 90 171c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148z"
                                fill="#000"
                                opacity={0.054}
                            />
                            <path
                                d="M90 159.75c38.522 0 69.75-31.228 69.75-69.75S128.522 20.25 90 20.25 20.25 51.478 20.25 90 51.478 159.75 90 159.75zM90 171c-44.735 0-81-36.265-81-81S45.265 9 90 9s81 36.265 81 81-36.265 81-81 81z"
                                fill="#000"
                                opacity={0.153}
                            />
                            <path
                                d="M126.055 37.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066z"
                                fill="#FFF"
                                opacity={0.398}
                            />
                            <g filter="url(#prefix__a)" transform="translate(36 12)">
                                <path
                                    d="M101.692 61.105s-7.956-2.566-11.806-5.646c0 0-7.572 24.768-31.57 33.88l.898 7.828s25.538-4.235 42.478-36.062"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M87.833 49.042c-1.272 7.015 1.674 10.194 7.383 12.298 5.113 1.885 11.119-1.77 12.389-6.522 1.628-6.095-3.09-9.136-8.275-10.81-5.5-1.778-10.47-.634-11.497 5.034"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M96.06 51.18c.881-2.125-2.392-3.903-4.093-4.661.219-.116.45-.223.701-.309 0 0 6.606 2.673 4.842 6.028-2.423 4.612-7.034 1.346-8.396-.106 0 0 0-.14.012-.364 2.102 1.38 5.522 2.819 6.934-.587"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M35.451 89.601s-5.334 14.256-8.414 14.256-8.855-6.545-8.855-6.545-4.62 4.235-6.545 6.545c0 0 10.01 10.01 15.785 9.24 5.342-.712 10.78-15.785 10.78-15.785l-2.75-7.71z"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M19.568 93.633c1.106 5.424-1.126 7.942-5.522 9.672-3.937 1.55-8.66-1.184-9.727-4.851-1.368-4.704 2.243-7.144 6.239-8.533 4.239-1.474 8.116-.67 9.01 3.712"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M13.218 95.433c-.72-1.635 1.79-3.07 3.097-3.688a3.666 3.666 0 00-.549-.227s-5.082 2.187-3.655 4.761c1.96 3.538 5.482.925 6.515-.225 0 0-.001-.109-.015-.282-1.61 1.106-4.24 2.281-5.393-.339"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M59.319 89.563s-.713 14.611 3.34 26.306c0 0-3.84 1.997-14.25 1.855-5.676-.078-12.157-1.003-15.348-2.26 0 0 3.404-6.197 4.295-10.352.91-4.25-2.287-7.821-1.04-15.864 0 0 3.382.637 10.596.735 7.214.1 12.407-.42 12.407-.42"
                                    className="svg-char-shirt"
                                />
                                <path
                                    d="M32.98 116.675s2.384-9.308 3.75-12.813c.36-.919-1.23-3.78-1.562-7.187-.201-2.074.363-3.948 1.25-6.875 0 0 2.462.022 8.696.208 1.044 4.826 3.23 8.797 4.461 9.02 1.247.225 2.523-1.737 3.871-8.907 2.981-.122 5.226-1.179 5.226-1.179s2.646 4.62 3.239 6.92c.47 1.822-.86 6.152-.736 7.002.613 4.205 1 5.462 1.871 8.026.362 1.062.95 3.67 1.497 5.16 0 0-4.375 3.437-15.833 3.645-7.926.145-15.97-2.407-15.73-3.02"
                                    className="svg-char-suit"
                                />
                                <path
                                    d="M.004 18.392c.199 9.778 8.52 17.539 18.586 17.334 10.065-.205 18.063-8.298 17.865-18.076C36.255 7.872 27.934.111 17.868.316 7.803.521-.196 8.614.004 18.392"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M6.601 33.79c4.65 7.688 16.16 9.238 25.71 3.462s13.522-16.69 8.872-24.378c-4.65-7.688-16.16-9.238-25.71-3.462S1.952 26.102 6.601 33.79"
                                    className="svg-char-ribbon"
                                />
                                <path
                                    d="M.004 18.392a17.261 17.261 0 012.777-9.75c-.225 2.15-.335 7.514 3.497 10.873 0 0-.574 2.512 2.576 3.698L5.5 30.707C2.193 27.577.102 23.233.004 18.392"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M78.656 63.97s6.638-11.7 4.089-29.464C80.195 16.742 64.955 5.307 45.26 4.95c-11.052-.2-21.385 3.46-28.909 9.682C7.272 22.142 2.779 33.287 5.048 46.09c3.296 18.6 11.306 28.213 15.742 31.25 9.15 6.256 43.13 15.06 57.866-13.37"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M84.404 65.446c-1.064 4.298-4.703 7.423-7.45 7.316-2.736-.113-2.415-3.507-2.48-7.71-.065-4.205 2.107-7.525 4.85-7.414 2.742.11 6.082 3.772 5.08 7.808"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M81.693 64.742c.22 2.383-2.515 5.371-4.173 5.475-1.66.105-1.608-1.839-1.826-4.22-.222-2.382.948-4.396 2.607-4.5 1.659-.104 3.174.865 3.392 3.245"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M11.11 78.355c3.339 5.118 9.239 6.017 12.57 4.658 3.317-1.358 1.346-4.936-.528-9.575-1.87-4.638-6.086-7.299-9.412-5.94-3.324 1.36-5.385 6.632-2.63 10.857"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M14.52 77.323c1.136 2.6 5.192 3.79 7.282 3.156 2.093-.634 1.125-2.743.289-5.453-.834-2.711-3.208-4.392-5.3-3.756-2.09.635-3.598 3.018-2.272 6.053"
                                    className={`svg-char-${color}-skin-shade-2`}
                                />
                                <path
                                    d="M61.063 90.271s25.699-9.988 16.349-45.079c-9.35-35.092-32.986-32.636-44.76-29.189C20.88 19.45 10.019 28.799 10.437 39.647c1.445 37.56 16.04 48.203 24.077 51.478 4.4 1.793 16.173 3.734 26.55-.854"
                                    className={`svg-char-${color}-skin`}
                                />
                                <path
                                    d="M29.682 60.926c.076 3.704 2.207 6.663 4.76 6.611 2.555-.052 4.564-3.096 4.489-6.8-.076-3.703-2.207-6.662-4.761-6.61-2.554.052-4.563 3.096-4.488 6.8"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M31 57.419c.028 1.318.785 2.37 1.695 2.352.908-.019 1.622-1.101 1.595-2.419-.026-1.317-.784-2.37-1.693-2.352-.909.019-1.623 1.102-1.596 2.419"
                                    fill="#FFF"
                                />
                                <path
                                    d="M60.41 58.408c.075 3.704 2.206 6.664 4.76 6.612 2.555-.052 4.564-3.097 4.489-6.8-.076-3.703-2.207-6.663-4.761-6.61-2.555.051-4.563 3.095-4.488 6.798"
                                    className={`svg-char-${color}-eyes`}
                                />
                                <path
                                    d="M61.5 55.419c.028 1.317.785 2.37 1.694 2.352.909-.019 1.623-1.102 1.596-2.419-.026-1.318-.784-2.37-1.693-2.352-.909.019-1.623 1.1-1.596 2.419"
                                    fill="#FFF"
                                />
                                <path
                                    d="M49.404 67.145a3.452 3.452 0 106.905-.141 3.453 3.453 0 00-6.905.14"
                                    className={`svg-char-${color}-skin-shade-1`}
                                />
                                <path
                                    d="M58.582 77.65s-17.591-3.59-28.966-4.838c0 0-.887 10.596 11.123 13.096 15.114 3.147 17.843-8.258 17.843-8.258"
                                    className="svg-char-mouth"
                                />
                                <path
                                    d="M40.739 85.908c-4.737-.986-7.462-3.232-9.032-5.588 2.441-1.01 5.827-1.435 9.49-1.028 5.504.614 9.98 2.925 11.413 5.607-2.677 1.4-6.484 2.132-11.871 1.01"
                                    className="svg-char-mouth-tongue"
                                />
                                <path
                                    d="M8.978 34.445c2.08-20.671 28.953-27.274 46.445-21.575 11.639 3.792 16.26 10.083 18.074 14.88 9.102 5.013 6.689 19.224 6.689 19.224-12.24-5.041-19.203-13.443-21.945-17.376l-.013.02c-3.87 5.564-15.239 18.768-36.846 20.451-.165 13.267-6.54 17.146-6.54 17.146s-7.944-12.098-5.864-32.77z"
                                    className={`svg-char-${color}-hair`}
                                />
                                <path
                                    d="M13.083 68.449c-2.797-4.32-5.938-11.47-7.688-20.522.06-.337.096-.526.096-.526s1.63 9.656 15.358 8.439c0 0-1.195 9.115-7.766 12.609"
                                    className={`svg-char-${color}-hair-shade-1`}
                                />
                                <path
                                    d="M58.153 28.425a.468.468 0 01.81.47c-.141.245-3.564 6.058-11.538 11.787-6.958 5-18.758 10.777-36.126 10.777-.962 0-1.944-.017-2.94-.054a.47.47 0 01-.452-.486.475.475 0 01.486-.45c18.699.687 31.225-5.342 38.446-10.52 7.826-5.612 11.28-11.465 11.314-11.524zm4.953-1.317a.468.468 0 01.635.192c.058.108 5.938 10.904 17.917 18.207a.468.468 0 11-.488.8c-12.206-7.44-18.197-18.454-18.256-18.564a.468.468 0 01.192-.635zm2.765-6.566a.468.468 0 01.596.289c.04.116 4.155 11.7 14.841 20.79a.47.47 0 01-.608.715c-10.887-9.264-15.077-21.08-15.118-21.198a.47.47 0 01.289-.596zm-4.421-.028a.469.469 0 01.772.531c-.147.214-3.7 5.296-12.351 10.39-7.802 4.593-21.37 10.008-41.958 10.008-.398 0-.8-.001-1.204-.006a.469.469 0 01.005-.937c21.009.218 34.78-5.234 42.626-9.842 8.495-4.988 12.074-10.093 12.11-10.144zm-48.43-.574c12.213-5.859 24.247-6.704 32.189-6.381 8.61.35 14.424 2.113 14.481 2.13a.469.469 0 01-.276.897c-.226-.07-22.946-6.855-45.989 4.2a.467.467 0 01-.625-.22.469.469 0 01.22-.626zm49.705-7.766c.541-.857 2.35-.686 4.043.381 1.692 1.069 2.624 2.628 2.085 3.486-.541.856-2.352.685-4.043-.384-1.693-1.066-2.626-2.627-2.085-3.483z"
                                    fillOpacity={0.1}
                                    fill="#FFF"
                                />
                            </g>
                            <g transform="translate(0 117)">
                                <mask id="prefix__c" fill="#fff">
                                    <use xlinkHref="#prefix__b" />
                                </mask>
                                <g mask="url(#prefix__c)">
                                    <path
                                        d="M43.808 24.552c.333.337.53.83.542 1.363l.627 27.143c.024 1.008-.608 1.896-1.445 2.033-10.843 1.783-21.789 4.422-32.535 7.843-.204.065-.41.081-.61.052-.407-.06-.782-.31-1.035-.713-.378-.6-.413-1.425-.091-2.101a512.392 512.392 0 017.321-14.757 275.733 275.733 0 00-9.692-9.43c-.476-.442-.69-1.181-.55-1.905.139-.723.607-1.303 1.206-1.494 11.575-3.685 23.366-6.528 35.046-8.448a1.38 1.38 0 011.216.414zm94.124-.414c11.683 1.92 23.474 4.763 35.047 8.448.598.19 1.067.771 1.206 1.494.14.724-.074 1.463-.55 1.904a276.3 276.3 0 00-9.692 9.43c2.511 4.863 4.97 9.82 7.321 14.758.322.676.286 1.501-.09 2.1-.254.404-.63.654-1.037.714a1.358 1.358 0 01-.61-.052c-10.744-3.421-21.691-6.06-32.534-7.843-.837-.137-1.469-1.026-1.446-2.033l.628-27.143c.012-.532.208-1.026.541-1.363a1.38 1.38 0 011.216-.414z"
                                        className="svg-ribbon-stars-back"
                                    />
                                    <path
                                        d="M135.59 50.718c8.798.362 13.184.733 13.157 1.114-.04.57-7.073 2.83-10.552 3.358a3.099 3.099 0 01-.46.034c-.505 0-.997-.122-1.37-.348-.484-.294-.907-1.292-.775-1.708v-2.45zm-90.646-.077v2.512c0 .544-.198 1.429-.652 1.723-.349.226-.81.348-1.283.348-.144 0-.29-.011-.433-.034-3.26-.527-11.086-2.307-11.124-2.877-.037-.572 13.392-2.008 13.492-1.672z"
                                        className="svg-ribbon-stars-shadow"
                                    />
                                    <path
                                        d="M31.908 52.28c-.335.157-.698.087-.999-.194-.3-.28-.514-.746-.587-1.283l-4.055-29.698c-.137-1.007.242-2.011.87-2.305 20.446-9.545 41.508-14.384 62.6-14.384 21.093 0 42.155 4.84 62.6 14.385.63.293 1.009 1.297.871 2.305l-4.055 29.697c-.073.538-.286 1.003-.587 1.284a.94.94 0 01-.641.276.84.84 0 01-.358-.082 1024.271 1024.271 0 01-57.83 1.635"
                                        className="svg-ribbon-stars-front"
                                    />
                                </g>
                            </g>
                            <g transform="rotate(-12 659.94 -89.693)">
                                <mask id="prefix__e" fill="#fff">
                                    <use xlinkHref="#prefix__d" />
                                </mask>
                                <g mask="url(#prefix__e)" className="svg-stage-star">
                                    <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                </g>
                            </g>
                            <g transform="translate(69.75 123.75)">
                                <mask id="prefix__g" fill="#fff">
                                    <use xlinkHref="#prefix__f" />
                                </mask>
                                <g mask="url(#prefix__g)" className="svg-stage-star">
                                    <path d="M20.25 31.528l8.855 5.327c1.397.84 3.12-.409 2.75-1.991l-2.35-10.043 7.824-6.757c1.234-1.064.574-3.084-1.051-3.22l-10.3-.871-4.03-9.478c-.636-1.493-2.76-1.493-3.396 0l-4.03 9.478-10.3.87c-1.625.137-2.285 2.157-1.051 3.22l7.825 6.758-2.35 10.043c-.372 1.582 1.352 2.831 2.749 1.991l8.855-5.327z" />
                                </g>
                            </g>
                            <g transform="rotate(12 -554.164 616.746)">
                                <mask id="prefix__i" fill="#fff">
                                    <use xlinkHref="#prefix__h" />
                                </mask>
                                <g mask="url(#prefix__i)" className="svg-stage-star">
                                    <path d="M15.75 24.521l6.887 4.144c1.087.654 2.427-.318 2.139-1.549l-1.828-7.81 6.086-5.256c.96-.828.446-2.399-.818-2.505l-8.011-.677-3.134-7.372c-.495-1.161-2.147-1.161-2.642 0l-3.134 7.372-8.011.677c-1.264.106-1.777 1.677-.818 2.505l6.086 5.256-1.828 7.81c-.288 1.231 1.052 2.203 2.139 1.549l6.887-4.144z" />
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            }
        } else {
            return (
                <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
                    <g fill="none" fillRule="evenodd" transform="translate(9 9)">
                        <circle cx={81} cy={81} r={81} fill="#FFD137" />
                        <path
                            fill="#000"
                            d="M25.436 22.063C12.594 63.282 18.062 97.178 41.84 123.75c25.015 27.954 60.482 28.762 106.402 2.422C133.698 147.784 109.009 162 81 162c-44.735 0-81-36.265-81-81 0-22.815 9.433-43.427 24.61-58.148Z"
                            opacity={0.054}
                        />
                        <path
                            fill="#000"
                            d="M81 150.75c38.522 0 69.75-31.228 69.75-69.75S119.522 11.25 81 11.25 11.25 42.478 11.25 81 42.478 150.75 81 150.75ZM81 162c-44.735 0-81-36.265-81-81S36.265 0 81 0s81 36.265 81 81-36.265 81-81 81Z"
                            opacity={0.153}
                        />
                        <path
                            fill="#FFF"
                            d="M117.055 28.037c-10.283-7.152-23.258-10.577-38.927-10.274 12.268 4.053 23.154 9.69 32.66 16.913 9.506 7.223 17.893 14.698 25.159 22.427-2.312-12.225-8.61-21.914-18.892-29.066Z"
                            opacity={0.398}
                        />
                    </g>
                </svg>
            )
        }
    }
}

export default CharCourseFlashcardsFinishedStars3

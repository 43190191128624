import React from 'react';
import intl from 'react-intl-universal';
import ReactHtmlParser from 'react-html-parser';
import { imageParser } from '../../../utils/utils';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import QrVideoPlayer from '../../../components/QrVideoPlayer';

export default function Feedback(props) {
    const { question, order } = props;

    const Header = () => {
        return (
            <Grid container justify="center" className={`feedback-header appbar-custom-content ${question.correct ? question.correct === "Y" ? "__correct" : "__incorrect" : ""}`}>
                <Container maxWidth="md">
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} sm={10} lg={8}>
                            <Typography variant="body2" component="p" align="center">
                                {`${intl.get("LABEL_QUESTION")} ${order}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={8}>
                            <Grid container className="qr-game-question-content" alignItems="center" justify="center" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="div">
                                        {ReactHtmlParser(question.question)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }

    const Answer = (props) => {
        const { type, subtitle, children } = props;

        return (
            <Grid item xs={12} sm={8} xl={10}>
                <Box p={2} className={`feedback-box ${type ? type === "correct" ? "__correct" : "__incorrect" : "__disabled"}`}>
                    <Grid container spacing={1}>
                        {
                            subtitle ?
                                <Grid item xs={12}>
                                    <Typography variant="caption" component="h5">
                                        {subtitle}
                                    </Typography>
                                </Grid>
                                : null
                        }
                        {
                            children
                        }
                    </Grid>
                </Box>
            </Grid>
        )
    }

    return (
        <div className="qr-game-feedback">
            {/* Header */}
            <Header />

            {/* Content */}
            <Grid container>
                <Container maxWidth="md" className="pt-3 pb-4">
                    <Grid container justify="center" spacing={2}>
                        {
                            question.feedback_review ?
                                <Grid item xs={12} sm={8} xl={10}>
                                    <Alert icon={false} severity="info" className="rounded mt-3">
                                        {ReactHtmlParser(question.feedback_review)}
                                    </Alert>
                                </Grid>
                                : null
                        }
                        {
                            question.correct ?

                                // Se houver correção
                                question.correct === "Y" ?
                                    <Answer
                                        type="correct"
                                        subtitle={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                                    >
                                        {
                                            question.question_type === "FILL_WORD" || question.question_type === "OPEN_ANSWER" ?
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" component="div">
                                                        {ReactHtmlParser(question.user_answer)}
                                                    </Typography>
                                                </Grid>
                                                : question.question_type === "VIDEO_ANSWER" ?
                                                    <Grid item xs={12} align="center">
                                                        <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                                            <QrVideoPlayer link={question.user_answer_media.source} />
                                                        </div>
                                                    </Grid>
                                                    : question.question_type === "IMAGE_ANSWER" ?
                                                        <Grid item xs={12} align="center">
                                                            <img src={imageParser(question.user_answer)} alt="Answer Selected" />
                                                        </Grid>
                                                        : question.question_type === "SELECT_ANSWERS" && Array.isArray(question.user_answer) ?
                                                            question.user_answer.map((item) =>
                                                                question.answers.filter(answer => answer.id === item).map((answer, i) => {
                                                                    return (
                                                                        answer.image_answer ?
                                                                            <Grid key={i} item xs={12} align="center">
                                                                                <img src={imageParser(answer.image_answer)} alt="Answer Selected" />
                                                                            </Grid>
                                                                            :
                                                                            <Grid key={i} item xs={12}>
                                                                                <Typography variant="body1" component="div">
                                                                                    {ReactHtmlParser(answer.answer)}
                                                                                </Typography>
                                                                            </Grid>
                                                                    )
                                                                })
                                                            )
                                                            :
                                                            question.answers.filter(answer => answer.id === question.user_answer).map((answer, i) => {
                                                                return (
                                                                    answer.image_answer ?
                                                                        <Grid key={i} item xs={12} align="center">
                                                                            <img src={imageParser(answer.image_answer)} alt="Answer Selected" />
                                                                        </Grid>
                                                                        :
                                                                        <Grid key={i} item xs={12}>
                                                                            <Typography variant="body1" component="div">
                                                                                {ReactHtmlParser(answer.answer)}
                                                                            </Typography>
                                                                        </Grid>
                                                                )
                                                            })
                                        }
                                    </Answer>
                                    :
                                    <>
                                        <Answer
                                            type="incorrect"
                                            subtitle={intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                                        >
                                            {
                                                question.question_type === "FILL_WORD" || question.question_type === "OPEN_ANSWER" ?
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(question.user_answer)}
                                                        </Typography>
                                                    </Grid>
                                                    : question.question_type === "VIDEO_ANSWER" ?
                                                        <Grid item xs={12} align="center">
                                                            <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                                                <QrVideoPlayer link={question.user_answer_media.source} />
                                                            </div>
                                                        </Grid>
                                                        : question.question_type === "IMAGE_ANSWER" ?
                                                            <Grid item xs={12} align="center">
                                                                <img src={imageParser(question.user_answer)} alt="Answer Selected" />
                                                            </Grid>
                                                            : question.question_type === "SELECT_ANSWERS" && Array.isArray(question.user_answer) ?
                                                                question.user_answer.map((item) =>
                                                                    question.answers.filter(answer => answer.id === item).map((answer, i) => {
                                                                        return (
                                                                            answer.image_answer ?
                                                                                <Grid key={i} item xs={12} align="center">
                                                                                    <img src={imageParser(answer.image_answer)} alt="Answer Selected" />
                                                                                </Grid>
                                                                                :
                                                                                <Grid key={i} item xs={12}>
                                                                                    <Typography variant="body1" component="div">
                                                                                        {ReactHtmlParser(answer.answer)}
                                                                                    </Typography>
                                                                                </Grid>
                                                                        )
                                                                    })
                                                                )
                                                                :
                                                                question.answers.filter(answer => answer.id === question.user_answer).map((answer, i) => {
                                                                    return (
                                                                        answer.image_answer ?
                                                                            <Grid key={i} item xs={12} align="center">
                                                                                <img src={imageParser(answer.image_answer)} alt="Answer Selected" />
                                                                            </Grid>
                                                                            :
                                                                            <Grid key={i} item xs={12}>
                                                                                <Typography variant="body1" component="div">
                                                                                    {ReactHtmlParser(answer.answer)}
                                                                                </Typography>
                                                                            </Grid>
                                                                    )
                                                                })
                                            }
                                        </Answer>
                                        <Answer
                                            type="correct"
                                            subtitle={intl.get("GAME_SOLUTION_ANSWER_CORRECT_SUBTITLE")}
                                        >
                                            {
                                                question.question_type === "FILL_WORD" || question.question_type === "OPEN_ANSWER" ?
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(question.correct_answer)}
                                                        </Typography>
                                                    </Grid>
                                                    : question.question_type === "VIDEO_ANSWER" ?
                                                        <Grid item xs={12} align="center">
                                                            <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                                                <QrVideoPlayer link={question.correct_answer_media.source} />
                                                            </div>
                                                        </Grid>
                                                        : question.question_type === "IMAGE_ANSWER" ?
                                                            <Grid item xs={12} align="center">
                                                                <img src={imageParser(question.correct_answer)} alt="Answer Selected" />
                                                            </Grid>
                                                            :
                                                            question.answers.filter(answer => answer.answer_right === "Y").map((answer, i) => {
                                                                return (
                                                                    answer.image_answer ?
                                                                        <Grid key={i} item xs={12} align="center">
                                                                            <img src={imageParser(answer.image_answer)} alt="Answer Selected" />
                                                                        </Grid>
                                                                        :
                                                                        <Grid key={i} item xs={12}>
                                                                            <Typography variant="body1" component="div">
                                                                                {ReactHtmlParser(answer.answer)}
                                                                            </Typography>
                                                                        </Grid>
                                                                )
                                                            })
                                            }
                                        </Answer>
                                    </>
                                :
                                // Se ainda não houver correção
                                question.user_answer ?
                                    <Answer
                                        subtitle={intl.get("GAME_SOLUTION_ANSWER_SENT_SUBTITLE")}
                                    >
                                        {
                                            question.question_type === "VIDEO_ANSWER" ?
                                                <Grid item xs={12} align="center">
                                                    <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                                                        <QrVideoPlayer link={question.user_answer_media.source} />
                                                    </div>
                                                </Grid>
                                                : question.question_type === "IMAGE_ANSWER" ?
                                                    <Grid item xs={12} align="center">
                                                        <img src={imageParser(question.user_answer)} alt="Answer Sent" />
                                                    </Grid>
                                                    : question.question_type === "SELECT_ANSWERS" && Array.isArray(question.user_answer) ?
                                                        question.user_answer.map((item) =>
                                                            question.answers.filter(answer => answer.id === item).map((answer, i) => {
                                                                return (
                                                                    <Grid key={i} item xs={12}>
                                                                        <Typography variant="body1" component="div">
                                                                            {ReactHtmlParser(answer.answer)}
                                                                        </Typography>
                                                                    </Grid>
                                                                )
                                                            })
                                                        )
                                                        :
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" component="div">
                                                                {ReactHtmlParser(question.user_answer)}
                                                            </Typography>
                                                        </Grid>
                                        }
                                    </Answer>
                                    :
                                    question.answers ?
                                        question.answers.map((answer, i) => {
                                            return (
                                                <Answer
                                                    key={i}
                                                    type={answer.answer_right === "Y" ? "correct" : "incorrect"}
                                                    subtitle={answer.answer_right === "Y" ? intl.get("GAME_SOLUTION_ANSWER_CORRECT_SUBTITLE") : ""}
                                                >
                                                    {
                                                        answer.image_answer ?
                                                            <Grid key={i} item xs={12} align="center">
                                                                <img src={imageParser(answer.image_answer)} alt="Answer" />
                                                            </Grid>
                                                            :
                                                            <Grid key={i} item xs={12}>
                                                                <Typography variant="body1" component="div">
                                                                    {ReactHtmlParser(answer.answer)}
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                </Answer>
                                            )
                                        })
                                        :
                                        <Answer>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" component="p">
                                                    {intl.get("EMPTY_OPEN_ANSWER")}
                                                </Typography>
                                            </Grid>
                                        </Answer>
                        }
                    </Grid>
                </Container>
            </Grid>
        </div>
    )
}

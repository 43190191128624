import { classroomService } from '../../services/classroom.service';

// CONST
export const Types = {
    LIST: 'classroom_course/LIST',
    DETAILS: 'classroom_course/DETAILS',
    CLEAR: 'classroom_course/CLEAR',
    PRESENCE: 'classroom_course/PRESENCE',
    SUBSCRIBE: 'classroom_course/SUBSCRIBE',
    UNSUBSCRIBE: 'classroom_course/UNSUBSCRIBE',
    CERTIFICATE: 'classroom_course/CERTIFICATE',
    TICKET: 'classroom_course/TICKET',
    ERROR: 'classroom_course/ERROR',
    SUCCESS: 'classroom_course/SUCCESS',
};




// INITIAL STATE

const initialState = {
    list: [],
    listUpcoming: [],
    listPrevious: [],
    status: '',
    error: null,
    subscribed: null,
    unsubscribed: null,
    download: [],
    details: [],
    ticket: [],
    errorLoading: false,
    presence: null,
}



// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.LIST:
            return {
                ...state,
                list: action.payload.list,
                listUpcoming: action.payload.listUpcoming,
                listPrevious: action.payload.listPrevious,
                status: action.payload.status
            }
        case Types.DETAILS:
            return {
                ...state,
                details: action.payload.details,
                status: action.payload.status
            }
        case Types.CLEAR:
            return {
                ...state,
                list: action.payload.list,
                listUpcoming: action.payload.listUpcoming,
                listPrevious: action.payload.listPrevious,
                details: action.payload.details,
                ticket: action.payload.ticket,
                error: action.payload.error,
                errorLoading: action.payload.errorLoading,
                status: action.payload.status
            }
        case Types.PRESENCE:
            return {
                ...state,
                status: action.payload.status,
                presence: action.payload.presence,
            }
        case Types.SUBSCRIBE:
            return {
                ...state,
                status: action.payload.status,
                subscribed: action.payload.subscribed
            }
        case Types.UNSUBSCRIBE:
            return {
                ...state,
                status: action.payload.status,
                unsubscribed: action.payload.unsubscribed
            }
        case Types.CERTIFICATE:
            return {
                ...state,
                status: action.payload.status,
                download: action.payload.download
            }
        case Types.TICKET:
            return {
                ...state,
                status: action.payload.status,
                ticket: action.payload.ticket
            }
        case Types.ERROR:
            return {
                ...state,
                error: action.payload.error,
                errorLoading: action.payload.errorLoading,
            }
        case Types.SUCCESS:
            return {
                ...state,
                presence: action.payload.presence,
            }
        default:
            return state
    }
}


// ACTIONS
function list(params) {
    return dispatch => {
        return classroomService.list(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    list: data.list_training,
                    status: 'finished'
                }
            });
        }).catch(() => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    errorLoading: true,
                }
            });
        })
    }
}

function listUpcoming(params) {
    return dispatch => {
        return classroomService.listUpcoming(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    listUpcoming: data.list_training_class,
                    status: 'finished'
                }
            });
        }).catch(() => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    errorLoading: true,
                }
            });
        })
    }
}

function listPrevious(params) {
    return dispatch => {
        return classroomService.listPrevious(params).then(data => {
            dispatch({
                type: Types.LIST,
                payload: {
                    listPrevious: data.list_training,
                    status: 'finished'
                }
            });
        }).catch(() => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    errorLoading: true,
                }
            });
        })
    }
}

function details(params) {
    return dispatch => {
        return classroomService.details(params).then(data => {
            dispatch({
                type: Types.DETAILS,
                payload: {
                    details: data.training,
                    status: 'finished'
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    errorLoading: true,
                }
            });
        })
    }
}

function presence(params) {
    return dispatch => {
        return classroomService.presence(params).then(data => {
            dispatch({
                type: Types.PRESENCE,
                payload: {
                    presence: true,
                    status: 'finished',
                }
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente')
            dispatch({
                type: Types.ERROR,
                payload: {
                    error: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            });
        })
    }
}

function subscribe(params) {
    return dispatch => {
        return classroomService.subscription(params).then(data => {
            dispatch({
                type: Types.SUBSCRIBE,
                payload: {
                    subscribed: data.subscribed ? true : false,
                    status: 'finished',
                }
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
            dispatch({
                type: Types.ERROR,
                payload: {
                    error: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            });
        })
    }
}

function unsubscribe(params) {
    return dispatch => {
        return classroomService.unsubscribe(params).then(data => {
            dispatch({
                type: Types.UNSUBSCRIBE,
                payload: {
                    unsubscribed: data.unsubscribed ? true : false,
                    status: 'finished',
                }
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
            dispatch({
                type: Types.ERROR,
                payload: {
                    error: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            });
        })
    }
}

function certificate(params) {
    return dispatch => {
        return classroomService.certificate(params).then(data => {
            console.log(data)
            dispatch({
                type: Types.CERTIFICATE,
                payload: {
                    download: data,
                    status: 'finished',
                }
            });
        }).catch(err => {
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
            dispatch({
                type: Types.ERROR,
                payload: {
                    error: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            });
        })
    }
}

function ticketDetails(params) {
    return dispatch => {
        return classroomService.getTicket(params).then(data => {
            dispatch({
                type: Types.TICKET,
                payload: {
                    ticket: data.training,
                    status: 'finished',
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    errorLoading: true,
                }
            });
        })
    }
}

function clear() {
    return dispatch => {
        dispatch({
            type: Types.CLEAR,
            payload: {
                list: [],
                listUpcoming: [],
                listPrevious: [],
                status: '',
                details: [],
                download: [],
                ticket: [],
                error: null,
                errorLoading: false
            }
        });
    }
}

function clearError() {
    return dispatch => {
        dispatch({
            type: Types.ERROR,
            payload: {
                error: null,
            }
        });
    }
}

function clearSuccess() {
    return dispatch => {
        dispatch({
            type: Types.SUCCESS,
            payload: {
                presence: null
            }
        });
    }
}

export const actions = {
    list,
    listUpcoming,
    listPrevious,
    details,
    clear,
    presence,
    subscribe,
    unsubscribe,
    certificate,
    ticketDetails,
    clearError,
    clearSuccess
}

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import { useSelector, useDispatch } from 'react-redux';
import { imageParser, numberMask } from '../../../utils/utils';
import { actions as GameActions } from '../../../redux/ducks/game';
import ReactHtmlParser from 'react-html-parser';
import {
    Container, Grid, Typography, Box, ListItem,
    ListItemText, Divider, Card, CardContent, Button
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import QrList from '../../QrList';
import DialogReportError from './DialogReportError';
import SnackBar from '../../snackBar';
import { LoadingBounce } from '../../Loading';
import { Icon, Points } from '../../Images/Images';
import ImgStepAnswered from '../../../dist/img/svgr/ImgStepAnswered';

export function Correct(props) {
    const dispatch = useDispatch();
    const { data, result, children, description, gameType, reportType, reportQuestion, reportIDQuestion } = props;
    const auth = useSelector(state => state.auth);
    const reasons = useSelector(state => state.game.reasons);
    const report = useSelector(state => state.game.report);
    const [didMount, setDidMount] = useState(false);
    const [openDialogReport, setOpenDialogReport] = useState(false);
    const [loadingReport, setLoadingReport] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [reportSent, setReportSent] = useState(false);
    const [reportSuccess, setReportSuccess] = useState(false);

    useEffect(() => {
        if (data && data.user && (data.user.total_points || data.user.qi_credit || data.user.neuros)) {
            auth.user.qi_credit = data.user.qi_credit;
            auth.user.total_points = data.user.total_points;
            auth.user.neuros = data.user.neuros;
            localStorage.setItem('auth.state.user', JSON.stringify(auth.user));
        }
    }, [auth.user, data]);

    useEffect(() => {
        setDidMount(true);

        if (reportType && didMount) {
            dispatch(GameActions.getReportList({
                report_type: reportType
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(GameActions.reset());
        }
    }, [didMount, dispatch, reportType]);

    async function sendReport(data) {
        setOpenDialogReport(false);
        setLoadingReport(true);
        await dispatch(GameActions.setReport({
            id_game_type: gameType,
            id_question: reportIDQuestion,
            reason: data.reason,
            observation: data.message
        }));
        setLoadingReport(false);
        setReportSent(true);
    }

    if (reportSent && (report && report.load === "finished")) {
        setReportSent(false);
        if (!report.error) {
            setReportSuccess(true);
        } else {
            setSnackOpen(true);
        }
    }

    return (
        <Grid container className="qr-game-solution">
            <Grid container alignItems="center" className="solution-header __correct">
                <Container maxWidth="md">
                    <Grid container alignItems="center" justify="center" spacing={2}>
                        <Grid item xs={12} sm={8} xl={10}>
                            <Grid container direction="row" alignItems="center" justify="center" wrap="nowrap">
                                <Icon ic="check" className="icon-lg mr-2" />
                                <Typography variant="h1" component="h1">
                                    {intl.get("GAME_SOLUTION_CORRECT_TITLE")}
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            description ?
                                <Grid item xs={12} sm={8} xl={10} align="center">
                                    <Typography variant="body1" component="p">
                                        {description}
                                    </Typography>
                                </Grid>
                                : null
                        }
                    </Grid>
                </Container>
            </Grid>
            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    {
                        // PONTUAÇÃO
                        data && (data.result.points_result || (data.result.qis_result && config.layout.points.qis)) ?
                            <Grid item xs={12} sm={8} xl={10}>
                                <Card>
                                    <CardContent className="pt-0 pb-0">
                                        <QrList>
                                            {
                                                data.result.points_result ?
                                                    <ListItem disableGutters>
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <div>
                                                                    <Typography component="span" variant="body1">
                                                                        {intl.get("LABEL_XP")}
                                                                    </Typography>
                                                                    <Typography component="span" variant="body1"
                                                                        className={`${data.result.points_result > 0 ? "text-success" : "text-lighter"} ml-2`}
                                                                    >
                                                                        {data.result.points_result > 0 ? "+" : ""}
                                                                        {numberMask(data.result.points_result)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            secondary={
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <Points type="xp" className="img-xs mr-1" />
                                                                    <Typography variant="body1" className="text-xp" component="span">
                                                                        {numberMask(data.user.total_points)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                    : null
                                            }
                                            {data.result.points_result && (data.result.qis_result && config.layout.points.qis) ? <Divider /> : null}
                                            {
                                                data.result.qis_result && config.layout.points.qis ?
                                                    <ListItem disableGutters>
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <div>
                                                                    <Typography component="span" variant="body1">
                                                                        {intl.get("LABEL_QIS")}
                                                                    </Typography>
                                                                    <Typography component="span" variant="body1"
                                                                        className={`${data.result.qis_result > 0 ? "text-success" : "text-lighter"} ml-2`}
                                                                    >
                                                                        {data.result.qis_result > 0 ? "+" : ""}
                                                                        {numberMask(data.result.qis_result)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            secondary={
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <Points type="qis" className="img-xs mr-1" />
                                                                    <Typography variant="body1" className="text-qis" component="span">
                                                                        {numberMask(data.user.qi_credit)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                    : null
                                            }
                                        </QrList>
                                    </CardContent>
                                </Card>
                            </Grid>
                            : null
                    }
                    {
                        result?.user_sent_answer || result?.image_user_sent_answer ?
                            <Grid item xs={12} sm={8} xl={10}>
                                <Box p={2} className="solution-box __correct">
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" component="h5">
                                                {intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                                            </Typography>
                                        </Grid>
                                        {
                                            result.image_user_sent_answer ?
                                                <Grid item xs={12} align="center">
                                                    <img src={imageParser(result.image_user_sent_answer)} alt="Answer Selected" />
                                                </Grid>
                                                :
                                                Array.isArray(result.user_sent_answer) ?
                                                    result.user_sent_answer.map((item, index) =>
                                                        <Grid key={index} item xs={12}>
                                                            <Typography variant="body1" component="div">
                                                                {ReactHtmlParser(item)}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                    :
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(result.user_sent_answer)}
                                                        </Typography>
                                                    </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            : null
                    }
                    {
                        reportType ?
                            <Grid item xs={12} sm={8} xl={10} align="center">
                                {
                                    !loadingReport ?
                                        !reportSuccess ?
                                            <Button
                                                color="primary"
                                                onClick={() => setOpenDialogReport(true)}
                                            >
                                                <Icon ic="error" className="icon-xs mr-2" />
                                                {intl.get('BTN_REPORT_ERROR')}
                                            </Button>
                                            :
                                            <Alert severity="success" className="text-left rounded-lg">
                                                <AlertTitle>{intl.get('REPORT_ERROR_SEND_SUCCESS_TITLE')}</AlertTitle>
                                                {intl.get('REPORT_ERROR_SEND_SUCCESS_TEXT')}
                                            </Alert>
                                        :
                                        <LoadingBounce />
                                }
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} sm={8} xl={10}>
                        <div id="bottom-bar" className="relative-bar">
                            {children}
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <DialogReportError open={openDialogReport} close={() => setOpenDialogReport(false)} question={reportQuestion} reasons={reasons} sendReport={sendReport} />
            <SnackBar
                open={snackOpen}
                message={intl.get('ERROR_LOAD_FILE')}
                status="error"
                time={4}
                closeSnack={() => setSnackOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </Grid>
    )
}

export function Incorrect(props) {
    const dispatch = useDispatch();
    const { data, result, children, description, gameType, reportType, reportQuestion, reportIDQuestion } = props;
    const auth = useSelector(state => state.auth);
    const reasons = useSelector(state => state.game.reasons);
    const report = useSelector(state => state.game.report);
    const [didMount, setDidMount] = useState(false);
    const [openDialogReport, setOpenDialogReport] = useState(false);
    const [loadingReport, setLoadingReport] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [reportSent, setReportSent] = useState(false);
    const [reportSuccess, setReportSuccess] = useState(false);

    useEffect(() => {
        if (data && data.user && (data.user.total_points || data.user.qi_credit || data.user.neuros)) {
            auth.user.qi_credit = data.user.qi_credit;
            auth.user.total_points = data.user.total_points;
            auth.user.neuros = data.user.neuros;
            localStorage.setItem('auth.state.user', JSON.stringify(auth.user));
        }
    }, [auth.user, data]);

    useEffect(() => {
        setDidMount(true);

        if (reportType && didMount) {
            dispatch(GameActions.getReportList({
                report_type: reportType
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(GameActions.reset());
        }
    }, [didMount, dispatch, reportType]);

    async function sendReport(data) {
        setOpenDialogReport(false);
        setLoadingReport(true);
        await dispatch(GameActions.setReport({
            id_game_type: gameType,
            id_question: reportIDQuestion,
            reason: data.reason,
            observation: data.message
        }));
        setLoadingReport(false);
        setReportSent(true);
    }

    if (reportSent && (report && report.load === "finished")) {
        setReportSent(false);
        if (!report.error) {
            setReportSuccess(true);
        } else {
            setSnackOpen(true);
        }
    }

    return (
        <Grid container className="qr-game-solution">
            <Grid container alignItems="center" className="solution-header __incorrect">
                <Container maxWidth="md">
                    <Grid container alignItems="center" justify="center" spacing={2}>
                        <Grid item xs={12} sm={8} xl={10}>
                            <Grid container direction="row" alignItems="center" justify="center" wrap="nowrap">
                                <Icon ic="multiply" className="icon-lg mr-2" />
                                <Typography variant="h1" component="h1">
                                    {intl.get("GAME_SOLUTION_INCORRECT_TITLE")}
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            description ?
                                <Grid item xs={12} sm={8} xl={10} align="center">
                                    <Typography variant="body1" component="p">
                                        {description}
                                    </Typography>
                                </Grid>
                                : null
                        }
                    </Grid>
                </Container>
            </Grid>
            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    {
                        // PONTUAÇÃO
                        data && (data.result.points_result || (data.result.qis_result && config.layout.points.qis)) ?
                            <Grid item xs={12} sm={8} xl={10}>
                                <Card>
                                    <CardContent className="pt-0 pb-0">
                                        <QrList>
                                            {
                                                data.result.points_result ?
                                                    <ListItem disableGutters>
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <div>
                                                                    <Typography component="span" variant="body1">
                                                                        {intl.get("LABEL_XP")}
                                                                    </Typography>
                                                                    <Typography component="span" variant="body1"
                                                                        className={`${data.result.points_result > 0 ? "text-success" : "text-lighter"} ml-2`}
                                                                    >
                                                                        {data.result.points_result > 0 ? "+" : ""}
                                                                        {numberMask(data.result.points_result)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            secondary={
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <Points type="xp" className="img-xs mr-1" />
                                                                    <Typography variant="body1" className="text-xp" component="span">
                                                                        {numberMask(data.user.total_points)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                    : null
                                            }
                                            {data.result.points_result && (data.result.qis_result && config.layout.points.qis) ? <Divider /> : null}
                                            {
                                                data.result.qis_result && config.layout.points.qis ?
                                                    <ListItem disableGutters>
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <div>
                                                                    <Typography component="span" variant="body1">
                                                                        {intl.get("LABEL_QIS")}
                                                                    </Typography>
                                                                    <Typography component="span" variant="body1"
                                                                        className={`${data.result.qis_result > 0 ? "text-success" : data.result.qis_result === 0 ? "text-lighter" : "text-danger"} ml-2`}
                                                                    >
                                                                        {data.result.qis_result > 0 ? "+" : ""}
                                                                        {numberMask(data.result.qis_result)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                            secondary={
                                                                <div className="d-flex flex-row align-items-center">
                                                                    <Points type="qis" className="img-xs mr-1" />
                                                                    <Typography variant="body1" className="text-qis" component="span">
                                                                        {numberMask(data.user.qi_credit)}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                    : null
                                            }
                                        </QrList>
                                    </CardContent>
                                </Card>
                            </Grid>
                            : null
                    }
                    {
                        result?.user_sent_answer || result?.image_user_sent_answer ?
                            <Grid item xs={12} sm={8} xl={10}>
                                <Box p={2} className="solution-box __incorrect">
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" component="h5">
                                                {intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                                            </Typography>
                                        </Grid>
                                        {
                                            result.image_user_sent_answer ?
                                                <Grid item xs={12} align="center">
                                                    <img src={imageParser(result.image_user_sent_answer)} alt="Answer Selected" />
                                                </Grid>
                                                :
                                                Array.isArray(result.user_sent_answer) ?
                                                    result.user_sent_answer.map((item, index) =>
                                                        <Grid key={index} item xs={12}>
                                                            <Typography variant="body1" component="div">
                                                                {ReactHtmlParser(item)}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                    :
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(result.user_sent_answer)}
                                                        </Typography>
                                                    </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            :
                            null
                    }
                    {
                        result?.correct_answer || result?.image_correct_answer ?
                            <Grid item xs={12} sm={8} xl={10}>
                                <Box p={2} className="solution-box __correct">
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" component="h5">
                                                {intl.get("GAME_SOLUTION_ANSWER_CORRECT_SUBTITLE")}
                                            </Typography>
                                        </Grid>
                                        {
                                            result.image_correct_answer ?
                                                <Grid item xs={12} align="center">
                                                    <img src={imageParser(result.image_correct_answer)} alt="Answer Correct" />
                                                </Grid>
                                                :
                                                Array.isArray(result.correct_answer) ?
                                                    result.correct_answer.map((item, index) =>
                                                        <Grid key={index} item xs={12}>
                                                            <Typography variant="body1" component="div">
                                                                {ReactHtmlParser(item)}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                    :
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(result.correct_answer)}
                                                        </Typography>
                                                    </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            : null
                    }
                    {
                        reportType ?
                            <Grid item xs={12} sm={8} xl={10} align="center">
                                {
                                    !loadingReport ?
                                        !reportSuccess ?
                                            <Button
                                                color="primary"
                                                onClick={() => setOpenDialogReport(true)}
                                            >
                                                <Icon ic="error" className="icon-xs mr-2" />
                                                {intl.get('BTN_REPORT_ERROR')}
                                            </Button>
                                            :
                                            <Alert severity="success" className="text-left rounded-lg">
                                                <AlertTitle>{intl.get('REPORT_ERROR_SEND_SUCCESS_TITLE')}</AlertTitle>
                                                {intl.get('REPORT_ERROR_SEND_SUCCESS_TEXT')}
                                            </Alert>
                                        :
                                        <LoadingBounce />
                                }
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} sm={8} xl={10}>
                        <div id="bottom-bar" className="relative-bar">
                            {children}
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <DialogReportError open={openDialogReport} close={() => setOpenDialogReport(false)} question={reportQuestion} reasons={reasons} sendReport={sendReport} />
            <SnackBar
                open={snackOpen}
                message={intl.get('ERROR_SENT_REPORT')}
                status="error"
                time={4}
                closeSnack={() => setSnackOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            />
        </Grid>
    )
}

export function Continue(props) {
    const { children, result } = props;

    return (
        <Grid container className="qr-game-solution">
            <Grid container alignItems="center" className="solution-header __continue">
                <Container maxWidth="md">
                    <Grid container alignItems="center" justify="center" spacing={2}>
                        <Grid item xs={12} sm={8} xl={10} align="center">
                            <ImgStepAnswered />
                            <Typography variant="h2" component="h1" className="pt-3 pb-3">
                                {intl.get("GAME_SOLUTION_CONTINUE_TITLE")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container alignItems="center" justify="center" spacing={2}>
                    {
                        result?.user_sent_answer || result?.image_user_sent_answer ?
                            <Grid item xs={12} sm={8} xl={10}>
                                <Box p={2} className="solution-box __incorrect">
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" component="h5">
                                                {intl.get("GAME_SOLUTION_ANSWER_SELECTED_SUBTITLE")}
                                            </Typography>
                                        </Grid>
                                        {
                                            result.image_user_sent_answer ?
                                                <Grid item xs={12} align="center">
                                                    <img src={imageParser(result.image_user_sent_answer)} alt="Answer Selected" />
                                                </Grid>
                                                :
                                                Array.isArray(result.user_sent_answer) ?
                                                    result.user_sent_answer.map((item, index) =>
                                                        <Grid key={index} item xs={12}>
                                                            <Typography variant="body1" component="div">
                                                                {ReactHtmlParser(item)}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                    :
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(result.user_sent_answer)}
                                                        </Typography>
                                                    </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            :
                            null
                    }
                    {
                        result?.correct_answer || result?.image_correct_answer ?
                            <Grid item xs={12} sm={8} xl={10}>
                                <Box p={2} className="solution-box __correct">
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" component="h5">
                                                {intl.get("GAME_SOLUTION_ANSWER_CORRECT_SUBTITLE")}
                                            </Typography>
                                        </Grid>
                                        {
                                            result.image_correct_answer ?
                                                <Grid item xs={12} align="center">
                                                    <img src={imageParser(result.image_correct_answer)} alt="Answer Correct" />
                                                </Grid>
                                                :
                                                Array.isArray(result.correct_answer) ?
                                                    result.correct_answer.map((item, index) =>
                                                        <Grid key={index} item xs={12}>
                                                            <Typography variant="body1" component="div">
                                                                {ReactHtmlParser(item)}
                                                            </Typography>
                                                        </Grid>
                                                    )
                                                    :
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" component="div">
                                                            {ReactHtmlParser(result.correct_answer)}
                                                        </Typography>
                                                    </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} sm={8} xl={10} align="center">
                        {children}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}
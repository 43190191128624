import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { useLastLocation } from 'react-router-last-location';
import QrLayout from '../../../components/QrLayout2';
import {
    AppBar, Toolbar, Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, Button, Typography, IconButton
} from '@material-ui/core';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';
import Quiz from '../../../components/Game/Quiz';

export default function StepQuestions(props) {
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(false);
    const { history } = useReactRouter();
    const step = props.location.state && props.location.state.step ? props.location.state.step : '';
    const session = props.location.state && props.location.state.session ? props.location.state.session : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const question = props.location.state && props.location.state.question ? props.location.state.question : '';
    const { questions } = useSelector(state => state.trail);
    const [redirect, setRedirect] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const lastLocation = useLastLocation();

    useEffect(() => {
        setDidMount(true);

        if (lastLocation && (lastLocation.pathname.includes("flashcards") || lastLocation.pathname.includes("solution") || (lastLocation.pathname.includes("intro") && (origin === "exam" || origin === "check")))) {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        // Pega a primeira pergunta quando vem dos Flashcards
        if (didMount && !question) {
            dispatch(TrailActions.getStepFlashcardsQuestion({
                id_step: session.id_step,
                id_session: session.id_session
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(TrailActions.reset());
        }

    }, [didMount, dispatch, session, lastLocation, question, origin]);

    function goBack() {
        let currentOrigin = "questions";

        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
            state: {
                origin: currentOrigin
            }
        })
    }

    const sendTo = (answer, idQuestion) => {
        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions/solution`,
            state: {
                origin: "questions",
                step: step,
                session: session,
                idQuestion: idQuestion,
                answer: answer,
                stepType: session.step_type
            }
        })
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    {
                        config.layout.navbar ?
                            <IconButton
                                color="inherit"
                                aria-label="close"
                                edge="start"
                                onClick={() => setOpenAlert(true)}
                            >
                                <Icon ic="close" />
                            </IconButton>
                            : null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {step.step_name}
                        </Typography>
                        {
                            step.step_number_description ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {step.step_number_description}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        !redirect ?
            <QrLayout id="trail-steps-questions" hideSidebar>
                <QrAppBar />
                {
                    !question ?
                        questions && questions.load === "finished" ?
                            !questions.error ?
                                questions.data.length !== 0 ?
                                    <Quiz
                                        module="trail"
                                        question={questions.data}
                                        sendAnswer={sendTo}
                                        stepType={session.step_type}
                                    />
                                    :
                                    <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL_STEPS_CONTENT")} />
                                :
                                <ErrorContent />
                            :
                            <LoadingContent />
                        :
                        <Quiz
                            module="trail"
                            question={question}
                            sendAnswer={sendTo}
                            stepType={session.step_type}
                        />
                }
                <Dialog
                    open={openAlert}
                    onClose={() => setOpenAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {intl.get("TRAIL_STEP_FLASHCARD_QUESTION_ALERT_TITLE")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {intl.get(session.step_type === "CHECK" || session.step_type === "EXAM" ? "TRAIL_STEP_CONTINUE_QUESTION_ALERT_TEXT" : "TRAIL_STEP_FLASHCARD_QUESTION_ALERT_TEXT")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAlert(false)} color="primary">
                            {intl.get("BTN_CANCEL")}
                        </Button>
                        <Button onClick={() => goBack()} color="primary">
                            {intl.get("BTN_YES")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                state: {
                    origin: 'questions'
                }
            }} />
    )
}
import React from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import useReactRouter from 'use-react-router';
import ReactHtmlParser from 'react-html-parser';
import { Container, Typography, Grid, Button, Box } from '@material-ui/core';
import { Icon, Char } from '../../../../components/Images/Images';

export default function IntroFlahscards(props) {
    const { history } = useReactRouter();
    const { step } = props.location.state;

    return (
        <Grid container id="trail-steps-intro-flashcards">
            {
                config.learn.trail.vector_images ?
                    <Grid container alignItems="flex-end" justify="center" className="appbar-custom-content">
                        <div className={`blackboard${!config.learn.trail.chars ? " mb-4" : ""}`}>
                            <div className="blackboard-text">
                                {
                                    step.has_questions === "Y" ?
                                        <>
                                            <Typography variant="body1" component="h3" className="mb-2">
                                                {intl.get("TRAIL_STEP_INTRO_FLASHCARD_TEXT")}
                                            </Typography>
                                            <Typography variant="h2" component="p">
                                                {step.subtitle}
                                            </Typography>
                                        </>
                                        :
                                        <Typography variant="h4" component="p" className="mb-2">
                                            {intl.get("TRAIL_STEP_INTRO_FLASHCARD_NO_QUESTIONS_TEXT")}
                                        </Typography>
                                }
                            </div>
                        </div>
                        <Char type="trail-intro-flashcards" className="char-intro-flashcards" />
                    </Grid>
                    :
                    <Grid container alignItems="flex-end" justify="center" className="appbar-custom-content __no-vector">
                        <Container maxWidth="md">
                            <Grid container alignItems="center" justify="center" className="pb-5">
                                <Grid item xs={12} align="center">
                                    <Icon ic="flashcards" color="white" className="icon-xxl" />
                                </Grid>
                                {
                                    step.has_questions === "Y" ?
                                        <>
                                            <Grid item xs={12} align="center">
                                                <Typography variant="h3" component="h2">
                                                    {intl.get("TRAIL_STEP_INTRO_FLASHCARD_TITLE")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="center">
                                                <Typography variant="body1" component="p">
                                                    {intl.get("TRAIL_STEP_INTRO_FLASHCARD_NO_VECTOR_TEXT")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="center">
                                                <Typography variant="h4" component="div" className="__questions">
                                                    {step.subtitle}
                                                </Typography>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12} align="center">
                                                <Typography variant="h3" component="h2">
                                                    {intl.get("TRAIL_STEP_INTRO_FLASHCARD_TITLE")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} align="center">
                                                <Typography variant="body1" component="p">
                                                    {intl.get("TRAIL_STEP_INTRO_FLASHCARD_NO_QUESTIONS_TEXT")}
                                                </Typography>
                                            </Grid>
                                        </>
                                }
                            </Grid>
                        </Container>
                    </Grid>
            }
            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    {
                        step.bonus_multiplier
                            ?
                            <Grid item xs={12} sm={10} xl={12}>
                                <Box p={2} className="highlight-box">
                                    <Grid container alignItems="center" className="mb-1">
                                        <Icon ic="star_filled" color="yellow" className="icon-xs mr-1" />
                                        <Typography variant="body1" component="h5">
                                            {intl.get("TRAIL_STEP_INTRO_BONUS_TITLE")}
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body2" component="p">
                                        {intl.get("TRAIL_STEP_INTRO_BONUS_TEXT")}
                                        <span className="badge badge-pill badge-success ml-1">
                                            {Math.round(step.bonus_multiplier) + 'x'}
                                        </span>
                                    </Typography>
                                </Box>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} sm={10} xl={12}>
                        <Typography variant="body2" component="div" className="fs-md">
                            {ReactHtmlParser(step.description)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <div id="bottom-bar" className="relative-bar">
                            <Button id="btn_trail_step_intro_flashcards" variant="contained" className="btn-block-xs w-50"
                                onClick={() => {
                                    history.push({
                                        pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/flashcards`,
                                        state:
                                        {
                                            step: step,
                                            session: null,
                                            origin: 'intro'
                                        }
                                    })
                                }}>
                                {intl.get("BTN_TRAIL_START")}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}

import React from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import {
    Container, Grid, Typography, Divider, Avatar, AppBar, Toolbar,
    IconButton, List, ListItem, ListItemAvatar, ListItemText
} from '@material-ui/core';
import QrLayout from '../../components/QrLayout2';
import { Icon, Char } from '../../components/Images/Images';

export default function MyProfile(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const modules = Object.keys(auth.modules).length !== 0 ? auth.modules : false;

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FAQ_MY_PROFILE_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <QrLayout id="faq-my-profile">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="md" className="pt-4 pb-3">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={10} xl={12}>
                        <Typography variant="body2" component="p">
                            {intl.get('FAQ_MY_PROFILE_TEXT')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <List disablePadding>
                            {
                                modules && modules.CONNECTIONS.ENABLED === 'Y' ?
                                    <>
                                        <ListItem disableGutters alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar aria-label="category" className="section-friends">
                                                    <Icon ic="happy_filled" />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={intl.get('FAQ_MY_PROFILE_CONNECTIONS_SUBTITLE')}
                                                secondary={intl.get('FAQ_MY_PROFILE_CONNECTIONS_TEXT')}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </>
                                    : null
                            }
                            {
                                modules && modules.RANKING.ENABLED === 'Y' ?
                                    <>
                                        <ListItem disableGutters alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar aria-label="category" className="section-ranking">
                                                    <Icon ic="trophy_filled" />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={intl.get('FAQ_MY_PROFILE_RANKING_SUBTITLE')}
                                                secondary={intl.get('FAQ_MY_PROFILE_RANKING_TEXT')}
                                            />
                                        </ListItem>
                                        <div className="text-center mt-2">
                                            <Char type="settings-help-ranking" className="char-help-ranking" />
                                        </div>
                                        <Divider />
                                    </>
                                    : null
                            }
                            {
                                modules && modules.achievements.ENABLED === 'Y' ?
                                    <>
                                        <ListItem disableGutters alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar aria-label="category" className="section-achievements">
                                                    <Icon ic="medal_filled" />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={intl.get('FAQ_MY_PROFILE_ACHIEVEMENTS_SUBTITLE')}
                                                secondary={intl.get('FAQ_MY_PROFILE_ACHIEVEMENTS_TEXT')}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </>
                                    : null
                            }
                            {
                                modules && modules.PRIZES.ENABLED === 'Y'
                                    ?
                                    <>
                                        <ListItem disableGutters alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar aria-label="category" className="section-my-redeems">
                                                    <Icon ic="gift_filled" />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={intl.get('FAQ_MY_PROFILE_MY_REDEEMS_SUBTITLE')}
                                                secondary={intl.get('FAQ_MY_PROFILE_MY_REDEEMS_TEXT')}
                                            />
                                        </ListItem>
                                    </>
                                    : null
                            }
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </QrLayout>
    )
}

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import config from '../../../config';
import { trailService } from '../../../services/trail.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { yiq } from 'yiq';
import { imageParser, getTimer, feedDateTime } from '../../../utils/utils';
import ReactHtmlParser from 'react-html-parser';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    AppBar, Toolbar, Container, Grid, Typography,
    IconButton, Tooltip, Button, TextField, Slide,
    Dialog, List, ListItem, ListItemAvatar, ListItemText,
    ListItemSecondaryAction, Divider, Link, DialogContent
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import QrLayout from '../../../components/QrLayout2';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import ButtonSubcategory from './components/ButtonSubcategory';
//import ButtonExam from './components/ButtonExam';
import ButtonSurvey from './components/ButtonSurvey';
import ButtonCertificate from '../../../components/ButtonCertificate';
import RatingBar from './components/RatingBar';
import SnackBar from '../../../components/snackBar';
import { LoadingContent, LoadingBounce, LoadingMore } from '../../../components/Loading';
import { Icon, Rating } from '../../../components/Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TrailDetails(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const idTheme = props.location.state && props.location.state.idTheme ? props.location.state.idTheme : '';
    const details = useSelector(state => state.trail.details);
    const ratingSubmit = useSelector(state => state.trail.ratingSubmit);
    const ratingComments = useSelector(state => state.trail.ratingComments);
    const pageRatingComments = useSelector(state => state.trail.pageRatingComments);
    const hasMoreRatingComments = useSelector(state => state.trail.hasMoreRatingComments);
    const [didMount, setDidMount] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingButtonRating, setLoadingButtonRating] = useState(false);
    const [openRating, setOpenRating] = useState(false);
    const [loadRatingComments, setLoadRatingComments] = useState(false);
    const [rating, setRating] = useState({});
    const [ratingUser, setRatingUser] = useState({});
    const [ratingUserEdit, setRatingUserEdit] = useState(false);
    const [getRating, setGetRating] = useState(false);
    const [ratingValue, setRatingValue] = useState(null);
    const [ratingMessage, setRatingMessage] = useState("");
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(TrailActions.getDetails({
                id_course: props.match.params.idTrail
            }));
        }

        return () => {
            dispatch(TrailActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match]);

    useEffect(() => {
        if (!getRating && (details?.data?.course_ratings && details?.data?.course_has_rating === "Y")) {
            setGetRating(true);
            setRating(details.data.course_ratings);
            setRatingUser(details.data.course_user_ratings);
            setRatingMessage(details.data.course_user_ratings.course_user_rating_comment);
            setRatingValue(details?.data?.course_user_ratings?.course_user_rating_star ? details.data.course_user_ratings.course_user_rating_star.toString() : null)
        }
    }, [getRating, details]);

    function goBack() {
        let currentOrigin = "details";

        switch (origin) {
            case 'themes':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'list':
                return history.push({
                    pathname: config.learn.trail.list === "themes" && idTheme ? `${props.match.path.replace(/./g, '')}/trail/${idTheme}/list` : `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'steps':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'materials':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'intro':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'questions':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'solution':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'result':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'complete':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    async function downloadCertificate() {
        let ret;
        setLoadingButton(true);
        try {
            ret = await trailService.certificate({
                format: "pdf",
                id_course: details.data.id_course
            });
            let link = document.createElement("a");
            link.download = "Certificado.pdf";
            link.href = URL.createObjectURL(new Blob([ret], { type: 'application/pdf' }));
            link.click();
            setLoadingButton(false);
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleRating = (event, rating) => {
        setRatingValue(rating);
    };

    const handleOpenRating = async () => {
        setOpenRating(true);

        if (parseInt(rating.course_total_comments_ratings) > 0 && config.learn.trail.rating_comments) {
            dispatch(TrailActions.getRatingComments({
                id_course: props.match.params.idTrail,
                page: 1,
                limit: 10
            }));
        }
    }

    const handleCloseRating = async () => {
        setOpenRating(false)
        dispatch(TrailActions.resetRatingComments({}));
    }

    async function sendRating() {
        setLoadingButtonRating(true);
        dispatch(TrailActions.setRatingSubmit({
            id_course: props.match.params.idTrail,
            rating_star: ratingValue,
            rating_comment: ratingMessage
        }));
    }

    const fetchMoreRatingComments = async () => {
        let pageNumber = pageRatingComments;
        pageNumber++;

        dispatch(TrailActions.getLoadmoreRatingComments({
            id_course: props.match.params.idTrail,
            limit: 10,
            page: pageNumber
        }));

        dispatch(TrailActions.setPageRatingComments({
            page: pageNumber
        }));
    }

    useEffect(() => {
        if (loadingButtonRating && ratingSubmit?.data?.success === "ok") {
            setLoadingButtonRating(false);
            setRating(ratingSubmit.data.course_ratings)
            setRatingUser(ratingSubmit.data.course_user_ratings);
            setSnackStatus("success");
            setSnackOpen(true);
            setSnackMessage(intl.get("SUCCESS_TRAIL_USER_RATING"));
            setLoadRatingComments(true);
            setRatingMessage(ratingSubmit.data.course_user_ratings.course_user_rating_comment);
            setRatingUserEdit(false);
            dispatch(TrailActions.resetRatingComments({}));
            dispatch(TrailActions.resetRatingSubmit({}));
        }

        if (loadingButtonRating && ratingSubmit?.error) {
            setLoadingButtonRating(false);
            setSnackStatus("error");
            setSnackOpen(true);
            setSnackMessage(ratingSubmit.messages);
            dispatch(TrailActions.resetRatingSubmit({}));
        }
    }, [loadingButtonRating, ratingSubmit, dispatch]);

    useEffect(() => {
        if (loadRatingComments && config.learn.trail.rating_comments) {
            setLoadRatingComments(false);
            dispatch(TrailActions.getRatingComments({
                id_course: props.match.params.idTrail,
                page: 1,
                limit: 10
            }));
        }
    }, [loadRatingComments, ratingComments, dispatch, props.match.params.idTrail]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden"></div>
                    {/*
                    #### Não disponível para a nova versão ####
                    <IconButton aria-label="statistic"
                        onClick={() => {
                            history.push({
                                pathname: `${props.match.path.replace(/./g, '')}/trail/${details.data.id_course}/statistics`,
                                state: {
                                    trail: details.data,
                                    origin: "details"
                                }
                            })
                        }}>
                        <Icon ic="doughnut_chart" />
                    </IconButton>
                    */}
                </Toolbar>
            </AppBar>
        )
    }

    const Header = () => {
        const InfoBlock = (props) => {
            const { icon, title, value } = props;

            return (
                <Grid item xs={4} className={`info-block ${details.data.banner && Object.keys(details.data.banner).length > 0 ? "text-white"
                    : (yiq(details.data.bg_color, { threshold: 225, colors: { light: "text-white", dark: "text-overlay" } }))
                    }`}>
                    <Icon ic={icon} />
                    <div className="ml-2">
                        <Typography variant="caption" component="p">
                            {title}
                        </Typography>
                        <Typography variant="h6" component="p">
                            {value}
                        </Typography>
                    </div>
                </Grid>
            )
        }

        return (
            <div id="trail-details-header" className={details.data.banner && Object.keys(details.data.banner).length > 0 ? "__image" : ""}
                style={{
                    backgroundColor: details.data.bg_color,
                    backgroundImage: `url(${imageParser(details.data.banner && Object.keys(details.data.banner).length > 0 ? details.data.banner : details.data.image)})`
                }}
            >
                <div id="trail-details-header-info">
                    <Container maxWidth="md">
                        <Grid container justify="center">
                            <Grid item xs={12} sm={8} xl={10}>
                                <Grid container alignItems="center" justify="space-between">
                                    {
                                        config.learn.trail.type === "stars" ?
                                            <InfoBlock
                                                icon="star_filled"
                                                title={intl.get("TRAIL_INFO_STARS_LABEL")}
                                                value={details.data.current_stars + ' / ' + details.data.total_stars}
                                            />
                                            :
                                            <InfoBlock
                                                icon="circle_filled"
                                                title={intl.get("TRAIL_INFO_STAGES_LABEL")}
                                                value={details.data.current_steps + ' / ' + details.data.total_steps}
                                            />
                                    }
                                    <InfoBlock
                                        icon="compass"
                                        title={intl.get("TRAIL_INFO_PROGRESS_LABEL")}
                                        value={details.data.course_progress + '%'}
                                    />
                                    <InfoBlock
                                        icon="stages"
                                        title={intl.get("TRAIL_INFO_LEVELS_LABEL")}
                                        value={details.data.current_stages + " / " + details.data.total_stages}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        )
    }

    const Subcategories = () => {
        return (
            details.data.stages.map((item, index) => {
                return (
                    <Grid item key={index} xs={12} sm={10} xl={12}>
                        <ButtonSubcategory
                            action={() => history.push({
                                pathname: `${props.match.path.replace(/./g, '')}/trail/${details.data.id_course}/${item.id_stage}`,
                                state: {
                                    stage: item,
                                    origin: 'details'
                                }
                            })}
                            idTrail={details.data.id_course}
                            item={item}
                        />
                    </Grid>
                )
            })
        )
    }

    // #### Ainda não disponível para esta versão ####
    /* const Exam = () => {
        return (
            details.data.mock_exams.length !== 0 ?
                <Grid item xs={12} sm={10} xl={12}>
                    <ButtonExam
                        action={() => history.push({
                            pathname: `${props.match.path}/list`,
                            state: {
                                trail: details.data,
                                origin: 'details'
                            }
                        })}
                        time={details.data.mock_exam_time}
                        questions={details.data.mock_exam_questions}
                        requiredStars={details.data.mock_exams_required_stars}
                        currentStars={details.data.current_stars}
                    />
                </Grid>
                : null
        )
    } */

    const Survey = () => {
        return (
            details.data.has_survey === "Y" ?
                <Grid item xs={12} sm={10} xl={12}>
                    <ButtonSurvey
                        action={() => history.push({
                            pathname: `${props.match.path.replace(/./g, '')}/trail/${details.data.id_course}/survey`,
                            state: {
                                data: details.data,
                                idName: 'id_course',
                                title: details.data.title,
                                origin: 'trail'
                            }
                        })}
                        item={details.data}
                    />
                </Grid>
                : null
        )
    }

    const Certificate = () => {
        return (
            <Grid item xs={12} sm={10} xl={12}>
                {
                    loadingButton ?
                        <Grid container alignItems="center" justify="center">
                            <LoadingBounce />
                        </Grid>
                        :
                        <ButtonCertificate
                            action={() => downloadCertificate()}
                            unlock={details.data.certificate_available && details.data.certificate_available === "Y"}
                            text={intl.get('TRAIL_STAGES_CERTIFICATE_TEXT')}
                            textDisabled={
                                parseInt(details.data.course_progress) === 100 && details.data.has_survey === "Y" && (details.data.survey_answered && details.data.survey_answered === "N") ?
                                    intl.get('TRAIL_STAGES_CERTIFICATE_TEXT_SURVEY_DISABLED')
                                    : intl.get('TRAIL_STAGES_CERTIFICATE_TEXT_DISABLED')
                            }
                        />
                }
            </Grid>
        )
    }

    return (
        <>
            <QrLayout id="trail-details" hideSidebar>
                {
                    config.layout.navbar ?
                        <QrAppBar />
                        : null
                }
                {
                    details && details.load === "finished" ?
                        !details.error ?
                            details.data.length !== 0 ?
                                <div>
                                    {/* Header */}
                                    <Header />

                                    {/* Content */}
                                    <Container maxWidth="md" className="pt-3 pb-4">
                                        <Grid container justify="center" spacing={2}>
                                            <Grid item xs={12} sm={10} xl={12}>
                                                <div className="d-flex align-items-start justify-content-between">
                                                    <Typography variant="h3" component="h1">
                                                        {details.data.title}
                                                    </Typography>
                                                    {
                                                        /* Rating */
                                                        rating && details.data.course_has_rating === "Y" ?
                                                            <Link
                                                                component="button"
                                                                variant="h6"
                                                                onClick={handleOpenRating}
                                                                className="btn-trail-rating"
                                                            >
                                                                {
                                                                    Math.round(rating.course_rating_average) < 1 ?
                                                                        <Icon ic="rating2" color="primary" className="icon-xs mr-1" />
                                                                        :
                                                                        <Rating type={Math.round(rating.course_rating_average).toString()} className="img-xs mr-1" />
                                                                }
                                                                {`${rating.course_rating_average ? rating.course_rating_average : "-"}`}
                                                                <span className="fs-xs ml-1"> / 5</span>
                                                            </Link>
                                                            : null
                                                    }
                                                </div>
                                            </Grid>
                                            {
                                                details.data.description
                                                    ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        <Typography variant="body2" component="div">
                                                            {ReactHtmlParser(details.data.description)}
                                                        </Typography>
                                                    </Grid>
                                                    : null
                                            }

                                            {/* Certificate */}
                                            {
                                                !details.data.has_certificate || details.data.has_certificate === "Y" ?
                                                    <Certificate />
                                                    : null
                                            }

                                            <Grid item xs={12} sm={10} xl={12} className="pb-0">
                                                <div className="subheader-content justify-content-between">
                                                    <Typography variant="subtitle2" component="h3">
                                                        {intl.get("TRAIL_STAGES_SUBHEADER")}
                                                    </Typography>
                                                    <div className="d-flex">
                                                        {
                                                            config.learn.trail.workload && details.data.workload ?
                                                                <div className="mr-2 ml-2">
                                                                    <Typography variant="caption" component="div">
                                                                        {intl.get("LABEL_WORKLOAD")}
                                                                    </Typography>
                                                                    <div className="subheader-content-info">
                                                                        <Icon ic="clock" color="lighter" className="icon-xxs mr-1" />
                                                                        <Typography variant="body2" component="p" className="font-weight-bold">
                                                                            {getTimer(details.data.workload)}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            config.learn.trail.best_view && details.data.best_view ?
                                                                <div className="mr-2 ml-2">
                                                                    <Typography variant="caption" component="div">
                                                                        {intl.get("LABEL_BEST_VIEW")}
                                                                    </Typography>
                                                                    <div className="subheader-content-info">
                                                                        <Tooltip title={intl.get("LABEL_LARGE_SCREEN")} placement="top" arrow>
                                                                            <div className="d-flex align-items-center justify-content-center">
                                                                                <Icon ic="laptop_outline" color={details.data.best_view === "A" || details.data.best_view === "D" ? "primary" : "lighter"} className="icon-xs" />
                                                                            </div>
                                                                        </Tooltip>
                                                                        <Tooltip title={intl.get("LABEL_SMALL_SCREEN")} placement="top" arrow>
                                                                            <div className="d-flex align-items-center justify-content-center">
                                                                                <Icon ic="mobile_outline" color={details.data.best_view === "A" || details.data.best_view === "M" ? "primary" : "lighter"} className="icon-xs ml-1" />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </Grid>

                                            {/* Subcategories */}
                                            <Subcategories />

                                            {/* Survey */}
                                            <Survey />

                                        </Grid>
                                    </Container>
                                </div>
                                :
                                <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingContent />
                }
            </QrLayout>
            <Dialog
                id="dialog-trail-rating"
                open={openRating}
                onClose={handleCloseRating}
                aria-labelledby="dialog-trail-rating"
                TransitionComponent={Transition}
                fullScreen
            >
                <AppBar className="appbar appbar-default" position="sticky">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseRating} aria-label="close">
                            <Icon ic="close" />
                        </IconButton>
                        <div className="w-100 overflow-hidden">
                            <Typography variant="h4" noWrap>
                                {intl.get("TRAIL_RATING_TITLE")}
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent id="rating-wrap">
                    <Container maxWidth="md" className="rating-content pt-3 pb-4">
                        <Grid container justify="center">
                            <Grid item xs={12} className="rating-info">
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={10}>
                                        <Grid container justify="center" spacing={1}>
                                            <Grid item xs={12} align="center" className="pt-2 pb-0">
                                                {
                                                    Math.round(rating.course_rating_average) < 1 ?
                                                        <Icon ic="rating2" color="primary" className="icon-xxl" />
                                                        :
                                                        <Rating type={Math.round(rating.course_rating_average).toString()} className="img-xxl" />
                                                }
                                            </Grid>
                                            <Grid item xs={12} className="pt-0">
                                                <Typography variant="h3" component="h4" align="center">
                                                    {`${rating.course_rating_average ? rating.course_rating_average : "-"}`} <span className="fs-md"> / 5</span>
                                                </Typography>
                                            </Grid>
                                            <RatingBar type="5" value={rating.course_rating_total_star_5} total={rating.course_rating_total} />
                                            <RatingBar type="4" value={rating.course_rating_total_star_4} total={rating.course_rating_total} />
                                            <RatingBar type="3" value={rating.course_rating_total_star_3} total={rating.course_rating_total} />
                                            <RatingBar type="2" value={rating.course_rating_total_star_2} total={rating.course_rating_total} />
                                            <RatingBar type="1" value={rating.course_rating_total_star_1} total={rating.course_rating_total} />
                                            {
                                                details?.data?.course_complete === "Y" ?
                                                    <Grid container justify="center" spacing={2}>
                                                        <Grid item xs={12} sm={10} className="mt-3">
                                                            <Divider />
                                                        </Grid>
                                                        {
                                                            !ratingUserEdit && ratingUser.course_user_rating_star ?
                                                                <Grid item xs={12} sm={10}>
                                                                    <Typography component="h6" variant="h6">
                                                                        {intl.get("LABEL_YOUR_REVIEW")}:
                                                                    </Typography>
                                                                    <List disablePadding>
                                                                        <ListItem disableGutters>
                                                                            <ListItemAvatar>
                                                                                <Rating type={ratingUser.course_user_rating_star.toString()} className="img-lg" />
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={
                                                                                    ratingUser.course_user_rating_comment ?
                                                                                        ratingUser.course_user_rating_comment
                                                                                        :
                                                                                        <Typography
                                                                                            component="p"
                                                                                            variant="body2"
                                                                                        >
                                                                                            <i>{intl.get('LABEL_NO_COMMENT')}</i>
                                                                                        </Typography>
                                                                                }
                                                                                secondary={feedDateTime(ratingUser.course_user_rating_date)}
                                                                            />
                                                                            <ListItemSecondaryAction onClick={() => setRatingUserEdit(true)}>
                                                                                <IconButton edge="end" aria-label={intl.get('LABEL_EDIT')}>
                                                                                    <Icon ic="edit" />
                                                                                </IconButton>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    </List>
                                                                </Grid>
                                                                :
                                                                !loadingButtonRating ?
                                                                    <>
                                                                        <Grid item xs={12} sm={10} align="center">
                                                                            <Typography variant="h5" component="h5">
                                                                                {intl.get('TRAIL_RATING_FEEDBACK_TITLE')}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={10} align="center">
                                                                            <ToggleButtonGroup
                                                                                value={ratingValue}
                                                                                exclusive
                                                                                onChange={handleRating}
                                                                                aria-label="text alignment"
                                                                            >
                                                                                <ToggleButton value="1" aria-label="rating1">
                                                                                    <Rating type="1" className="img-lg" />
                                                                                </ToggleButton>
                                                                                <ToggleButton value="2" aria-label="rating2">
                                                                                    <Rating type="2" className="img-lg" />
                                                                                </ToggleButton>
                                                                                <ToggleButton value="3" aria-label="rating3">
                                                                                    <Rating type="3" className="img-lg" />
                                                                                </ToggleButton>
                                                                                <ToggleButton value="4" aria-label="rating4">
                                                                                    <Rating type="4" className="img-lg" />
                                                                                </ToggleButton>
                                                                                <ToggleButton value="5" aria-label="rating5">
                                                                                    <Rating type="5" className="img-lg" />
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={10} align="center">
                                                                            <TextField
                                                                                name="feedback"
                                                                                type="text"
                                                                                maxlength="255"
                                                                                defaultValue={ratingMessage}
                                                                                onChange={(e) => setRatingMessage(e.target.value)}
                                                                                label={intl.get('LABEL_GIVE_YOUR_FEEDBACK')}
                                                                                variant={config.layout.input_variant}
                                                                                fullWidth
                                                                                multiline
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6} align="center" className="mb-1">
                                                                            <Button id="btn_trail_rating_send" variant="contained" className="btn-block" disabled={ratingValue ? false : true} onClick={() => sendRating()}>
                                                                                {intl.get("BTN_SEND")}
                                                                            </Button>
                                                                        </Grid>
                                                                    </>
                                                                    :
                                                                    <Grid item xs={12} sm={10} align="center" className="pt-5 pb-5">
                                                                        <LoadingBounce />
                                                                    </Grid>
                                                        }
                                                    </Grid>
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                config.learn.trail.rating_comments ?
                                    <Grid item xs={12} className="rating-comments">
                                        <div className="subheader-content justify-content-between">
                                            <Typography variant="subtitle2" component="h3">
                                                {`${intl.get("LABEL_COMMENTS")} (${rating.course_total_comments_ratings ? rating.course_total_comments_ratings : 0})`}
                                            </Typography>
                                        </div>
                                        {
                                            parseInt(rating.course_total_comments_ratings) > 0 ?
                                                ratingComments && ratingComments.load === "finished" ?
                                                    !ratingComments.error ?
                                                        ratingComments.data.length !== 0 ?
                                                            <List disablePadding>
                                                                <InfiniteScroll
                                                                    dataLength={ratingComments.data.length}
                                                                    next={fetchMoreRatingComments}
                                                                    hasMore={(ratingComments.data.length >= 10) && hasMoreRatingComments ? hasMoreRatingComments : false}
                                                                    scrollableTarget="rating-wrap"
                                                                    loader={<LoadingMore />}
                                                                >
                                                                    {
                                                                        ratingComments.data.map((item, index) => {
                                                                            return (
                                                                                <ListItem key={index} disableGutters divider={(ratingComments.data.length - 1) === index ? false : true}>
                                                                                    <ListItemAvatar>
                                                                                        <Rating type={item.course_user_rating_star.toString()} className="img-lg" />
                                                                                    </ListItemAvatar>
                                                                                    <ListItemText
                                                                                        primary={item.course_user_rating_comment}
                                                                                        secondary={`${item.user_first_name} ${item.user_last_name} - ${feedDateTime(item.course_user_rating_date)}`}
                                                                                    />
                                                                                </ListItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </InfiniteScroll>
                                                            </List>
                                                            :
                                                            <EmptyContent type="section" icon={<Icon ic="speech_filled" className="icon-xxl" color="lighter" />} text={intl.get("EMPTY_COMMENTS")} />
                                                        :
                                                        <ErrorContent />
                                                    :
                                                    <Grid container justify="center">
                                                        <LoadingBounce />
                                                    </Grid>
                                                :
                                                <EmptyContent type="section" icon={<Icon ic="speech_filled" className="icon-xxl" color="lighter" />} text={intl.get("EMPTY_COMMENTS")} />
                                        }
                                    </Grid>
                                    : null
                            }
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
            <SnackBar
                open={snackOpen}
                message={snackMessage}
                status={snackStatus}
                time={4}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                closeSnack={() => setSnackOpen(false)}
            />
        </>
    )
}

import React from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import {
    Grid, Typography, Divider, Avatar, Container, AppBar, Toolbar,
    IconButton, List, ListItem, ListItemAvatar, ListItemText
} from '@material-ui/core';
import QrLayout from '../../components/QrLayout2';
import { Icon, Points, GameHelp } from '../../components/Images/Images';

export default function HowToPlay(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const modules = Object.keys(auth.modules).length !== 0 ? auth.modules : false;

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FAQ_HOW_TO_PLAY_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const PointsHelpInfo = () => {
        return (
            <Grid container justify="center" spacing={2} className="pb-3">
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="h3" component="h2" className="text-heading">
                        {intl.get('FAQ_HOW_TO_PLAY_POINTS_TITLE')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <List disablePadding>
                        {
                            config.layout.points.qis ?
                                <>
                                    <ListItem disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar aria-label="category" className="section-help">
                                                <Points type="qis" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={intl.get('FAQ_HOW_TO_PLAY_QIS_SUBTITLE')}
                                            secondary={`${intl.get('FAQ_HOW_TO_PLAY_QIS_TEXT_1')}
                                                ${modules && modules.FREE.ENABLED === 'Y' ? intl.get('FAQ_HOW_TO_PLAY_QIS_TEXT_2') : ''}
                                                ${modules && modules.PRIZES.ENABLED === 'Y' ? intl.get('FAQ_HOW_TO_PLAY_QIS_TEXT_3') : ''}
                                            `}
                                        />
                                    </ListItem>
                                    <Divider />
                                </>
                                : null
                        }
                        <ListItem disableGutters alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar aria-label="category" className="section-help">
                                    <Points type="xp" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={intl.get('FAQ_HOW_TO_PLAY_XP_SUBTITLE')}
                                secondary={`${intl.get('FAQ_HOW_TO_PLAY_XP_TEXT_1')}
                                    ${modules && modules.PRIZES.ENABLED === 'Y' ? intl.get('FAQ_HOW_TO_PLAY_XP_TEXT_2') : ''}
                                    ${modules && modules.RANKING.ENABLED === 'Y' ? intl.get('FAQ_HOW_TO_PLAY_XP_TEXT_3') : ''}
                                `}
                            />
                        </ListItem>
                        {
                            config.layout.points.neuros ?
                                <>
                                    <Divider />
                                    <ListItem disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar aria-label="category" className="section-help">
                                                <Points type="neuros" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={intl.get('FAQ_HOW_TO_PLAY_NEUROS_SUBTITLE')}
                                            secondary={`${intl.get('FAQ_HOW_TO_PLAY_NEUROS_TEXT_1')}
                                                ${modules && modules.APP.USER_PREMIUM === 'Y' ? intl.get('FAQ_HOW_TO_PLAY_NEUROS_TEXT_2') : "."}
                                            `}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar aria-label="category" className="section-help">
                                                <GameHelp type="time" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={intl.get('FAQ_HOW_TO_PLAY_STOP_TIME_SUBTITLE')}
                                            secondary={intl.get('FAQ_HOW_TO_PLAY_STOP_TIME_TEXT')}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar aria-label="category" className="section-help">
                                                <GameHelp type="eliminate-1" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={intl.get('FAQ_HOW_TO_PLAY_ELIMINATE_1_SUBTITLE')}
                                            secondary={intl.get('FAQ_HOW_TO_PLAY_ELIMINATE_1_TEXT')}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar aria-label="category" className="section-help">
                                                <GameHelp type="eliminate-2" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={intl.get('FAQ_HOW_TO_PLAY_ELIMINATE_2_SUBTITLE')}
                                            secondary={intl.get('FAQ_HOW_TO_PLAY_ELIMINATE_2_TEXT')}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar aria-label="category" className="section-help">
                                                <GameHelp type="answered" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={intl.get('FAQ_HOW_TO_PLAY_ANSWERED_SUBTITLE')}
                                            secondary={intl.get('FAQ_HOW_TO_PLAY_ANSWERED_TEXT')}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar aria-label="category" className="section-help">
                                                <GameHelp type="skip" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={intl.get('FAQ_HOW_TO_PLAY_SKIP_SUBTITLE')}
                                            secondary={intl.get('FAQ_HOW_TO_PLAY_SKIP_TEXT')}
                                        />
                                    </ListItem>
                                </>
                                : null
                        }
                    </List>
                </Grid>
            </Grid>
        )
    }

    const TrailInfo = () => {
        return (
            <Grid container justify="center" spacing={2} className="pb-3">
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="h3" component="h2" className="text-heading">
                        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TITLE')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_1')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body1" component="h4" className="mt-2 mb-2">
                        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_LIBRARY_SUBTITLE')}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_LIBRARY_TEXT')}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_2')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_3')}
                    </Typography>
                </Grid>
                {
                    config.app.name_project === 'FLEURY' ?
                        <>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_4')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_5')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_6')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_7')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_8')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_9')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_10')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_11')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="h3" component="h2" className="text-heading">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_12')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_TRAIL_TEXT_13')}
                                </Typography>
                            </Grid>
                        </>
                        : null
                }
                {/*<Grid item xs={12} sm={10} xl={12}>F*/}
                {/*    <Typography variant="body1" component="h4" className="mt-2 mb-2">*/}
                {/*        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_EXAM_SUBTITLE')}*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="body2" component="p" className="mb-3">*/}
                {/*        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_EXAM_TEXT_1')}*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="body2" component="p">*/}
                {/*        {intl.get('FAQ_HOW_TO_PLAY_TRAIL_EXAM_TEXT_2')}*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
            </Grid>
        )
    }

    const FreeInfo = () => {
        return (
            <Grid container justify="center" spacing={2} className="pb-3">
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="h3" component="h2" className="text-heading">
                        {intl.get('FAQ_HOW_TO_PLAY_FREE_TITLE')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_FREE_TEXT_1')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_FREE_TEXT_2')}
                    </Typography>
                </Grid>
                {
                    config.app.name_project === 'FLEURY' ?
                        <>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_FREE_TEXT_3')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_FREE_TEXT_4')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} xl={12}>
                                <Typography variant="body2" component="p">
                                    {intl.get('FAQ_HOW_TO_PLAY_FREE_TEXT_5')}
                                </Typography>
                            </Grid>
                        </>
                        : null
                }
            </Grid>
        )
    }

    function DuelInfo() {
        return (
            <Grid container justify="center" spacing={2} className="pb-3">
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="h3" component="h2" className="text-heading">
                        {intl.get('FAQ_HOW_TO_PLAY_DUELS_TITLE')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_DUELS_TEXT_1')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_DUELS_TEXT_2')}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    function PeriodicQuestionInfo() {
        return (
            <Grid container justify="center" spacing={2} className="pb-3">
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="h3" component="h2" className="text-heading">
                        {intl.get('FAQ_HOW_TO_PLAY_PERIODIC_TITLE')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_PERIODIC_TEXT_1')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} xl={12}>
                    <Typography variant="body2" component="p">
                        {intl.get('FAQ_HOW_TO_PLAY_PERIODIC_TEXT_2')}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <QrLayout id="faq-how-to-play">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="md" className="pt-4 pb-3">
                <PointsHelpInfo />
                {
                    modules && modules.TRAIL.ENABLED === 'Y'
                        ?
                        <TrailInfo />
                        : null
                }
                {
                    modules && modules.FREE.ENABLED === 'Y'
                        ?
                        <FreeInfo />
                        : null
                }
                {
                    modules && modules.DUEL.ENABLED === 'Y'
                        ?
                        <DuelInfo />
                        : null
                }
                {
                    modules && modules.PERIODIC_QUESTION.ENABLED === 'Y'
                        ?
                        <PeriodicQuestionInfo />
                        : null
                }
            </Container>
        </QrLayout >
    )
}

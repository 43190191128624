import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { Container, Grid, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import QrLayout from '../../components/QrLayout2';
import { LoadingContent } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

var HtmlToReactParser = require('html-to-react').Parser;

export default function PrivacyPolicies(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const privacyPolitics = useSelector(state => state.auth.privacyPolitics);

    useEffect(() => {
        dispatch(AuthActions.getPrivacyPolitics({
            language: auth.user.language,
            type: 'PRIVACY_STATEMENT'
        }));
    }, [auth, dispatch]);

    function getPrivacy() {
        var htmlInput = privacyPolitics.data;
        var htmlToReactParser = new HtmlToReactParser();
        var reactElement = htmlToReactParser.parse(htmlInput);
        return (reactElement);
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FAQ_INFO_PRIVACY_POLICIES_TITLE")}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {intl.get("FAQ_INFO_TITLE")}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <QrLayout id="faq-privacy">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="md" className="pt-4 pb-3">
                {
                    !getPrivacy() ?
                        <LoadingContent />
                        :
                        <Grid container justify="center">
                            <Grid item xs={12} sm={10} xl={12}>
                                {getPrivacy()}
                            </Grid>
                        </Grid>
                }
            </Container>
        </QrLayout>
    )
}

import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import config from '../config';

const docViewerStyle = `
.docViewerClass #pdf-download {
    display: ${config.layout.files.download ? '' : 'none'};
}`;

export default function Viewer(props) {
    const { data, fileName } = props;

    const docs = [
        {
            uri: data,
            fileName: fileName ? fileName.split(' ').join('-').toLowerCase() : null
        },
    ];

    const config = {
        header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true
        }
    }

    return (
        <>
            <style type="text/css">{docViewerStyle}</style>
            <DocViewer
                config={config}
                pluginRenderers={DocViewerRenderers}
                documents={docs}
                className="docViewerClass"
                style={{ minWidth: '100%', minHeight: '90%' }} />
        </>
    );
}
import { qrService } from './base.service'

function getExamList(params) {
    return qrService('/v4/app/exam/list', params)
}

function getExamListDone(params) {
    return qrService('/v4/app/exam/list/done', params)
}

function getDetails(params) {
    return qrService('/v4/app/exam/get', params)
}

function getQuestions(params) {
    return qrService('/v4/app/exam/play', params)
}

function getExamAnswer(params) {
    return qrService('/v4/app/exam/answer', params)
}

export const examService = {
    getExamList,
    getExamListDone,
    getDetails,
    getQuestions,
    getExamAnswer
}
import React from 'react';
import intl from 'react-intl-universal';
import { Grid, Dialog, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import EmptyContent from '../../../../components/EmptyContent';
import Viewer from '../../../../components/DocViewer';
import { Icon } from '../../../../components/Images/Images';

export default function DialogMaterial(props) {
    const { open, close, transition, material } = props;

    function MaterialContent() {
        if (material.mime_type.includes("video/quicktime")) {
            return (
                <video controls>
                    <source src={material.content} type="video/mp4" />
                </video>
            )
        } else if (material.mime_type.includes("video/")) {
            return (
                <iframe
                    title="viewer"
                    className="library-details"
                    src={material.content + "#toolbar=0"}>
                </iframe>
            )
        } else if (material.mime_type.includes("audio/")) {
            return (
                <audio controls>
                    <source src={material.content} type={material.mime_type} />
                </audio>
            )
        } else {
            return (
                <Viewer data={material.content} fileName={material.title} />
            )
        }
    }

    return (
        <Dialog id="classroom-dialog-material" fullScreen open={open} onClose={close} TransitionComponent={transition}>
            <AppBar className="appbar appbar-default" position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {material.title}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Grid container justify="center" alignItems="center" className="h-100">
                {
                    material.content ?
                        MaterialContent()
                        :
                        <EmptyContent icon={<Icon ic="attachment" className="icon-xxl" />} text={intl.get("EMPTY_LIBRARY")} />
                }
            </Grid>
        </Dialog>
    )
};

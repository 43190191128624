import React from 'react';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { getStorage, deleteStorage } from '../utils/storageHandler';
import { actions as classroom_courseActions } from '../redux/ducks/classroom_course';
import QrLayout from '../components/QrLayout';
import { LoadingBounce } from './Loading';
import { Grid } from '@material-ui/core';

export default function RedirectComponent(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    let storageURL = getStorage('url');
    
    if ( (storageURL && (storageURL.length !== 0 )) || (props && props.location && props.location.search)) {
        let urlParams;
        
        if(storageURL && storageURL.length !== 0 ){
            urlParams = new URLSearchParams(storageURL);
        }else{
            urlParams = new URLSearchParams(props.location.search);
        }

        try {
            dispatch(classroom_courseActions.presence({
                id_class: urlParams.get("cod"),
                id_training: urlParams.get("t"),
                presence: 'Y'
            }))
        } catch (err) {
            console.log('err', err)
        }

        history.push({
            pathname: `/classroom/${urlParams.get("t")}/ticket/${urlParams.get("cod")}`,
            state: { class_id: urlParams.get("cod"), id_training: urlParams.get("t") }
        });

        if (storageURL && storageURL.length !== 0) {
            deleteStorage('url')
        }
    }
    return (
        <QrLayout history={history} id="home">
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={12} >
                    <LoadingBounce />
                </Grid>
            </Grid>
        </QrLayout>

    )
};

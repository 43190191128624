import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import countries from 'i18n-iso-countries';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { actions as SettingsActions } from '../../redux/ducks/settings';
import * as yup from 'yup';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { getDateFormat } from '../../utils/utils';
import {
    Grid, TextField, Container, Button, MenuItem,
    AppBar, Toolbar, IconButton, Typography
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import QrLayout from '../../components/QrLayout2';
import Cropper from '../../components/Cropper/Cropper';
import SelectAvatar from '../../components/SelectAvatar';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export default function EditProfile(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const user = useSelector(state => state.auth.user);
    const auth = useSelector(state => state.auth);
    const settings = useSelector(state => state.settings);
    const [loading, setLoading] = useState(false);
    const [didMount, setDidMount] = useState(true);
    const [snackOpen, setSnackOpen] = useState(false);
    const [updateStatus, setUpdateStatus] = useState('error');
    const [updateMessage, setUpdateMessage] = useState(intl.get('ERROR_UPDATE_DATA'));
    const [dialogImagePicker, setDialogImagePicker] = useState(false);
    const [img, setImg] = useState({
        imageSrc: null,
        crop: { x: 0, y: 0 },
        zoom: 1,
    });
    let language = user.language.substr(0, 2);
    let locale = require(`date-fns/locale/${language}`);
    let haveAvatar = config.settings.edit_profile.avatar.enabled;
    let haveFirstName = config.settings.edit_profile.first_name.enabled;
    let haveLastName = config.settings.edit_profile.last_name.enabled;
    let haveBirthdate = config.login_method.complete_data.form.birthdate.enabled;
    let haveCountry = config.login_method.complete_data.form.country.enabled;
    let haveBrand = config.login_method.complete_data.form.brand.enabled;
    let haveRole = config.login_method.complete_data.form.role.enabled;
    let haveRoleFamily = config.login_method.complete_data.form.role_family.enabled;
    let haveRoleGroup = config.login_method.complete_data.form.role_group.enabled;
    let havePosition = config.login_method.complete_data.form.position.enabled;
    let haveRegional = config.login_method.complete_data.form.regional.enabled;
    let haveSubarea = config.login_method.complete_data.form.subarea.enabled;
    let haveBoard = config.login_method.complete_data.form.board.enabled;
    let haveExecutiveBoard = config.login_method.complete_data.form.executive_board.enabled;
    let haveManaging = config.login_method.complete_data.form.managing.enabled;
    let haveCostCenter = config.login_method.complete_data.form.cost_center.enabled;
    let haveEnterprise = config.login_method.complete_data.form.enterprise.enabled;
    let haveButton = haveFirstName || haveLastName || haveBirthdate || haveCountry || haveBrand || haveRole || haveRoleFamily
        || haveRoleGroup || havePosition || haveRegional || haveSubarea || haveBoard || haveExecutiveBoard || haveManaging
        || haveCostCenter || haveEnterprise;

    const validations = yup.object().shape({
        fname: haveFirstName ?
            config.settings.edit_profile.first_name.required && yup.string()
                .min(3, () => intl.get('ERROR_MIN_CHARACTERS_REQUIRED'))
                .max(10, () => intl.get('ERROR_MAX_CHARACTERS_REQUIRED'))
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        lname: haveLastName ?
            config.settings.edit_profile.last_name.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        birth_date: haveBirthdate ?
            config.login_method.complete_data.form.birthdate.required && yup.date()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
                .typeError(() => intl.get('ERROR_INVALID_DATE'))
                .nullable()
            :
            yup.date().typeError(() => intl.get('ERROR_INVALID_DATE')).nullable(),
        country: haveCountry ?
            config.login_method.complete_data.form.country.required && yup.string()
                .required(() => intl.get('ERROR_SELECT_COUNTRY'))
            :
            null,
        mark_id: haveBrand ?
            config.login_method.complete_data.form.brand.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        role_id: haveRole ?
            config.login_method.complete_data.form.role.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        role_family_id: haveRoleFamily ?
            config.login_method.complete_data.form.role_family.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        role_group_id: haveRoleGroup ?
            config.login_method.complete_data.form.role_group.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        position_id: havePosition ?
            config.login_method.complete_data.form.position.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        regional_id: haveRegional ?
            config.login_method.complete_data.form.regional.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        sub_area_id: haveSubarea ?
            config.login_method.complete_data.form.subarea.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        board_id: haveBoard ?
            config.login_method.complete_data.form.board.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        executive_board_id: haveExecutiveBoard ?
            config.login_method.complete_data.form.executive_board.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        management_id: haveManaging ?
            config.login_method.complete_data.form.managing.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        cost_center_id: haveCostCenter ?
            config.login_method.complete_data.form.cost_center.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
        external_enterprise_id: haveEnterprise ?
            config.login_method.complete_data.form.enterprise.required && yup.string()
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            null,
    })

    const countryList = Object.keys(countries.getNames(language)).map((country) => {
        return {
            name: countries.getName(country, language),
            code: countries.getAlpha2Code(countries.getName(country, language), language)
        }
    });

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            if (haveBrand) {
                dispatch(SettingsActions.getBrandList({
                    limit: 0
                }));
            }

            if (haveRole) {
                dispatch(SettingsActions.getRoleList({
                    limit: 0
                }));
            }

            if (haveRoleFamily) {
                dispatch(SettingsActions.getRoleFamilyList({
                    limit: 0
                }));
            }

            if (haveRoleGroup) {
                dispatch(SettingsActions.getRoleGroupList({
                    limit: 0
                }));
            }

            if (havePosition) {
                dispatch(SettingsActions.getPositionList({
                    limit: 0
                }));
            }

            if (haveRegional) {
                dispatch(SettingsActions.getRegionalList({
                    limit: 0
                }));
            }

            if (haveSubarea) {
                dispatch(SettingsActions.getSubareaList({
                    limit: 0
                }));
            }

            if (haveBoard) {
                dispatch(SettingsActions.getBoardList({
                    limit: 0
                }));
            }

            if (haveExecutiveBoard) {
                dispatch(SettingsActions.getExecutiveBoardList({
                    limit: 0
                }));
            }

            if (haveManaging) {
                dispatch(SettingsActions.getManagingList({
                    limit: 0
                }));
            }

            if (haveCostCenter) {
                dispatch(SettingsActions.getCostCenterList({
                    limit: 0
                }));
            }

            if (haveEnterprise) {
                dispatch(SettingsActions.getExternalEnterpriseList({
                    limit: 0
                }));
            }
        }
        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch, haveBrand, haveRole, haveRoleFamily, haveRoleGroup, havePosition,
        haveRegional, haveSubarea, haveBoard, haveExecutiveBoard, haveManaging, haveCostCenter, haveEnterprise]);

    useEffect(() => {
        if (loading && auth.profileSuccess) {
            setLoading(false);
            auth.profileSuccess = false;
            setUpdateStatus('success');
            setSnackOpen(true);
            setUpdateMessage(intl.get('SUCCESS_UPDATE_DATA'));
        }
    }, [loading, auth.profileSuccess]);

    useEffect(() => {
        if (loading && auth.profileError?.error) {
            setLoading(false);
            setUpdateStatus('error');
            setSnackOpen(true);
            setUpdateMessage(auth.profileError.messages);
            dispatch(AuthActions.resetProfileUpdateError({}));
        }
    }, [loading, auth.profileError, dispatch]);

    function imgHandler(imageDataUrl) {
        setImg({
            imageSrc: imageDataUrl,
            crop: { x: 0, y: 0 },
            zoom: 1,
        })
        setDialogImagePicker(true)
    }

    const sendUpdateProfile = (data) => {
        setLoading(true);
        dispatch(AuthActions.updateProfile({
            fname: data.fname,
            lname: data.lname,
            birth_date: data.birth_date ? getDateFormat(data.birth_date) : '',
            country: data.country,
            gender: data.gender,
            id_user: data.id_user,
            mark_id: data.mark_id,
            role_id: data.role_id,
            role_family_id: data.role_family_id,
            role_group_id: data.role_group_id,
            position_id: data.position_id,
            regional_id: data.regional_id,
            sub_area_id: data.sub_area_id,
            board_id: data.board_id,
            executive_board_id: data.executive_board_id,
            management_id: data.management_id,
            cost_center_id: data.cost_center_id,
            external_enterprise_id: data.external_enterprise_id,
        }));
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("SETTINGS_EDIT_PROFILE_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <QrLayout id="settings-edit-profile" hideSidebar>
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="md" className="pt-4 pb-4">
                <Grid container spacing={2}>
                    {
                        haveAvatar ?
                            <Grid item xs={12} align="center">
                                <Typography variant="caption" component="p" className="mb-2">
                                    {intl.get("LABEL_AVATAR_PROFILE")}
                                </Typography>
                                <SelectAvatar alt={user.fname} blob={user.image ? user.image : null} imageDataUrl={(img) => imgHandler(img)} />
                                {dialogImagePicker && <Cropper img={img.imageSrc} closeModal={(closeModal) => {
                                    setDialogImagePicker(closeModal)
                                    setImg({})
                                }} />}
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <Formik
                            validationSchema={validations}
                            initialValues={{
                                fname: user.fname ? user.fname : '',
                                lname: user.lname ? user.lname : '',
                                birth_date: user.birth_date ? moment(user.birth_date) : null,
                                country: user.country ? user.country : '',
                                gender: user.gender ? user.gender : '',
                                id_user: user.id_user,
                                mark_id: user.information?.mark_id ? user.information.mark_id : '',
                                role_id: user.information?.role_id ? user.information.role_id : '',
                                role_family_id: user.information?.role_family_id ? user.information.role_family_id : '',
                                role_group_id: user.information?.role_group_id ? user.information.role_group_id : '',
                                position_id: user.information?.position_id ? user.information.position_id : '',
                                regional_id: user.information?.regional_id ? user.information.regional_id : '',
                                sub_area_id: user.information?.sub_area_id ? user.information.sub_area_id : '',
                                board_id: user.information?.board_id ? user.information.board_id : '',
                                executive_board_id: user.information?.executive_board_id ? user.information.executive_board_id : '',
                                management_id: user.information?.management_id ? user.information.management_id : '',
                                cost_center_id: user.information?.center_id ? user.information.center_id : '',
                                external_enterprise_id: user.information?.external_enterprise_id ? user.information.external_enterprise_id : '',
                            }}
                            onSubmit={data => { sendUpdateProfile(data) }}
                        >
                            {
                                ({ handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Grid container justify="center" spacing={3}>
                                            {
                                                haveFirstName ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        <TextField
                                                            name="fname"
                                                            type="text"
                                                            label={intl.get('LABEL_NAME')}
                                                            variant={config.layout.input_variant}
                                                            defaultValue={values.fname}
                                                            value={values.fname}
                                                            fullWidth
                                                            required={config.settings.edit_profile.first_name.required}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={(errors.fname && touched.fname)}
                                                            helperText={(errors.fname && touched.fname) && errors.fname}
                                                        />
                                                    </Grid>
                                                    : null
                                            }
                                            {
                                                haveLastName ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        <TextField
                                                            name="lname"
                                                            type="text"
                                                            label={intl.get('LABEL_LASTNAME')}
                                                            variant={config.layout.input_variant}
                                                            defaultValue={values.lname}
                                                            fullWidth
                                                            required={config.settings.edit_profile.last_name.required}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={(errors.lname && touched.lname)}
                                                            helperText={(errors.lname && touched.lname) && errors.lname}
                                                        />
                                                    </Grid>
                                                    : null
                                            }
                                            {
                                                haveBirthdate ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                                            <KeyboardDatePicker
                                                                fullWidth
                                                                required={config.login_method.complete_data.form.birthdate.required}
                                                                id="birth_date"
                                                                name="birth_date"
                                                                margin="none"
                                                                label={intl.get('LABEL_BIRTHDAY')}
                                                                inputVariant={config.layout.input_variant}
                                                                format="dd/MM/yyyy"
                                                                value={values.birth_date}
                                                                onBlur={handleBlur}
                                                                onChange={value => setFieldValue("birth_date", value)}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': intl.get('LABEL_BIRTHDAY'),
                                                                }}
                                                                okLabel={intl.get('BTN_CONFIRM')}
                                                                clearLabel={intl.get('BTN_CLEAR')}
                                                                cancelLabel={intl.get('BTN_CANCEL')}
                                                                error={(errors.birth_date && touched.birth_date)}
                                                                helperText={(errors.birth_date && touched.birth_date) && errors.birth_date}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                    : null
                                            }
                                            {
                                                haveCountry ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        <TextField
                                                            select
                                                            fullWidth
                                                            required={config.login_method.complete_data.form.country.required}
                                                            name="country"
                                                            label={intl.get('LABEL_COUNTRY')}
                                                            variant={config.layout.input_variant}
                                                            defaultValue={values.country ? values.country : ""}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={(errors.country && touched.country)}
                                                            helperText={(errors.country && touched.country) && errors.country}
                                                        >
                                                            {countryList.map((country) => (
                                                                <MenuItem
                                                                    value={country.code}
                                                                    key={country.code}
                                                                >
                                                                    {country.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    : null
                                            }
                                            {
                                                // * Diretoria *
                                                haveBoard ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.board && settings.board.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.board.required}
                                                                    name="board_id"
                                                                    label={intl.get('LABEL_BOARD')}
                                                                    defaultValue={values.board_id ? values.board_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.board_id && touched.board_id)}
                                                                    helperText={(errors.board_id && touched.board_id) && errors.board_id}
                                                                >
                                                                    {

                                                                        settings.board.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.board_id}
                                                                                key={item.board_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="board_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_BOARD')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.board.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Diretoria Executiva *
                                                haveExecutiveBoard ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.executiveBoard && settings.executiveBoard.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.executive_board.required}
                                                                    name="executive_board_id"
                                                                    label={intl.get('LABEL_EXECUTIVE_BOARD')}
                                                                    defaultValue={values.executive_board_id ? values.executive_board_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.executive_board_id && touched.executive_board_id)}
                                                                    helperText={(errors.executive_board_id && touched.executive_board_id) && errors.executive_board_id}
                                                                >
                                                                    {
                                                                        settings.executiveBoard.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.executive_board_id}
                                                                                key={item.executive_board_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="executive_board_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_EXECUTIVE_BOARD')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.executive_board.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Sub-area *
                                                haveSubarea ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.subarea && settings.subarea.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.subarea.required}
                                                                    name="sub_area_id"
                                                                    label={intl.get('LABEL_UNITY')}
                                                                    defaultValue={values.sub_area_id ? values.sub_area_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.sub_area_id && touched.sub_area_id)}
                                                                    helperText={(errors.sub_area_id && touched.sub_area_id) && errors.sub_area_id}
                                                                >
                                                                    {

                                                                        settings.subarea.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.sub_area_id}
                                                                                key={item.sub_area_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="sub_area_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_UNITY')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.subarea.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Gerência *
                                                haveManaging ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.managing && settings.managing.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.managing.required}
                                                                    name="management_id"
                                                                    label={intl.get('LABEL_MANAGING')}
                                                                    defaultValue={values.management_id ? values.management_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.management_id && touched.management_id)}
                                                                    helperText={(errors.management_id && touched.management_id) && errors.management_id}
                                                                >
                                                                    {
                                                                        settings.managing.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.management_id}
                                                                                key={item.management_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="management_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_MANAGING')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.managing.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Centro de Custo *
                                                haveCostCenter ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.costCenter && settings.costCenter.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.cost_center.required}
                                                                    name="cost_center_id"
                                                                    label={intl.get('LABEL_COST_CENTER')}
                                                                    defaultValue={values.cost_center_id ? values.cost_center_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.cost_center_id && touched.cost_center_id)}
                                                                    helperText={(errors.cost_center_id && touched.cost_center_id) && errors.cost_center_id}
                                                                >
                                                                    {
                                                                        settings.costCenter.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.cost_center_id}
                                                                                key={item.cost_center_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="cost_center_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_COST_CENTER')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.cost_center.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Empresa *
                                                haveEnterprise ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.externalEnterprise && settings.externalEnterprise.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.enterprise.required}
                                                                    name="external_enterprise_id"
                                                                    label={intl.get('LABEL_ENTERPRISE')}
                                                                    defaultValue={values.external_enterprise_id ? values.external_enterprise_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.external_enterprise_id && touched.external_enterprise_id)}
                                                                    helperText={(errors.external_enterprise_id && touched.external_enterprise_id) && errors.external_enterprise_id}
                                                                >
                                                                    {
                                                                        settings.externalEnterprise.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.enterprise_id}
                                                                                key={item.enterprise_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="external_enterprise_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_ENTERPRISE')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.enterprise.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Marca *
                                                haveBrand ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.brand && settings.brand.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.brand.required}
                                                                    name="mark_id"
                                                                    label={intl.get('LABEL_BRAND')}
                                                                    defaultValue={values.mark_id ? values.mark_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.mark_id && touched.mark_id)}
                                                                    helperText={(errors.mark_id && touched.mark_id) && errors.mark_id}
                                                                >
                                                                    {
                                                                        settings.brand.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.brand_id}
                                                                                key={item.brand_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="mark_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_BRAND')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.brand.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Cargo *
                                                haveRole ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.role && settings.role.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.role.required}
                                                                    name="role_id"
                                                                    label={intl.get('LABEL_ROLE')}
                                                                    defaultValue={values.role_id ? values.role_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.role_id && touched.role_id)}
                                                                    helperText={(errors.role_id && touched.role_id) && errors.role_id}
                                                                >
                                                                    {
                                                                        settings.role.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.role_id}
                                                                                key={item.role_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="role_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_ROLE')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.role.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Cargo Família *
                                                haveRoleFamily ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.roleFamily && settings.roleFamily.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.role_family.required}
                                                                    name="role_family_id"
                                                                    label={intl.get('LABEL_ROLE_FAMILY')}
                                                                    defaultValue={values.role_family_id ? values.role_family_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.role_family_id && touched.role_family_id)}
                                                                    helperText={(errors.role_family_id && touched.role_family_id) && errors.role_family_id}
                                                                >
                                                                    {
                                                                        settings.roleFamily.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.family_id}
                                                                                key={item.family_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="role_family_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_ROLE_FAMILY')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.role_family.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Cargo Grupo *
                                                haveRoleGroup ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.roleGroup && settings.roleGroup.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.role_group.required}
                                                                    name="role_group_id"
                                                                    label={intl.get('LABEL_ROLE_GROUP')}
                                                                    defaultValue={values.role_group_id ? values.role_group_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.role_group_id && touched.role_group_id)}
                                                                    helperText={(errors.role_group_id && touched.role_group_id) && errors.role_group_id}
                                                                >
                                                                    {
                                                                        settings.roleGroup.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.role_group_id}
                                                                                key={item.role_group_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="role_group_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_ROLE_GROUP')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.role_group.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Função *
                                                havePosition ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.position && settings.position.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.position.required}
                                                                    name="position_id"
                                                                    label={intl.get('LABEL_POSITION')}
                                                                    defaultValue={values.position_id ? values.position_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.position_id && touched.position_id)}
                                                                    helperText={(errors.position_id && touched.position_id) && errors.position_id}
                                                                >

                                                                    {
                                                                        settings.position.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.position_id}
                                                                                key={item.position_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="position_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_POSITION')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.position.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                // * Regional *
                                                haveRegional ?
                                                    <Grid item xs={12} sm={10} xl={12}>
                                                        {
                                                            settings.regional && settings.regional.load === "finished" ?
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.regional.required}
                                                                    name="regional_id"
                                                                    label={intl.get('LABEL_REGIONAL')}
                                                                    defaultValue={values.regional_id ? values.regional_id : ""}
                                                                    variant={config.layout.input_variant}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={(errors.regional_id && touched.regional_id)}
                                                                    helperText={(errors.regional_id && touched.regional_id) && errors.regional_id}
                                                                >
                                                                    {
                                                                        settings.regional.data.map((item) => (
                                                                            <MenuItem
                                                                                value={item.regional_id}
                                                                                key={item.regional_id}
                                                                            >
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </TextField>
                                                                :
                                                                <TextField
                                                                    name="regional_id"
                                                                    type="text"
                                                                    label={intl.get('LABEL_REGIONAL')}
                                                                    variant={config.layout.input_variant}
                                                                    fullWidth
                                                                    required={config.login_method.complete_data.form.regional.required}
                                                                    value={intl.get('LABEL_LOADING')}
                                                                    disabled
                                                                />
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {
                                                haveButton ?
                                                    <Grid item xs={12} sm={6} lg={4}>
                                                        {
                                                            !loading
                                                                ?
                                                                <Button
                                                                    id="btn_settings_save"
                                                                    variant="contained"
                                                                    className="btn-block"
                                                                    type="submit"
                                                                >
                                                                    {intl.get('BTN_SAVE')}
                                                                </Button>
                                                                :
                                                                <Grid container alignItems="center" justify="center">
                                                                    <LoadingBounce />
                                                                </Grid>
                                                        }
                                                    </Grid>
                                                    : null
                                            }
                                        </Grid>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Grid>
                    <SnackBar
                        open={snackOpen}
                        message={updateMessage}
                        status={updateStatus}
                        time={5}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        closeSnack={() => setSnackOpen(false)}
                    />
                </Grid>
            </Container>
        </QrLayout>
    )
}

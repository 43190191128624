import * as React from 'react';
import config from '../../../config';
import CarrefourCharCourseIntroValidation from '../projects/carrefour/chars/CharCourseIntroValidation';

function CharCourseIntroValidation(props) {
  if (config.app.name_project === "QRANIO") {
    return (
      <svg width={200} height={172} viewBox="0 0 200 172" {...props}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M121.878 136.089s3.643 19.11 2.602 33.444h-7.287s.78-18.05-2.082-30.79l6.767-2.654zm-39.438-.956s-3.534 19.37-2.492 33.445l7.416.514s-.914-18.237 1.947-30.747l-6.871-3.212z"
            className="svg-char-pants"
          />
          <path
            d="M116.555 168.39s4.416 1.238 7.625-.768c0 0 5.714 1.444 6.22 4.517 0 0-1.082.96-5.21 1.184-3.943.214-8.88-.67-9.123-1.315 0 0 .14-2.193.488-3.618"
            className="svg-char-shoes"
          />
          <path
            d="M82.933 135.512c1.113-1.876 37.734-1.68 38.599 0 .864 1.68.875 6.974-20.03 7.266-20.887-.663-19.18-6.236-18.57-7.266"
            className="svg-char-pants"
          />
          <path
            d="M87.4 107.422s-3.611 13.226-4.778 28.364c0 0 4.924 2.214 18.367 2.214 7.329 0 15.712-.903 19.856-2.214 0 0-.6-10.213-3.823-29.32 0 0-4.294 1.594-13.61 1.594-9.316 0-15.056-2.549-15.056-2.549"
            className="svg-char-shirt"
          />
          <path
            d="M102.141 116.334c1.523 3.128 4.189 10.289 4.37 12.416.071.847-4.423 5.12-6.21 5.416-1.551.258-5.95-3.87-6.208-5.16-.136-.68 3.102-9.803 4.323-12.605.765-1.754 2.89-1.781 3.725-.067zm-1.363-8.912a2.867 2.867 0 110 5.734 2.867 2.867 0 010-5.734z"
            className="svg-char-necktie"
          />
          <path
            d="M116.594 105.943s14.775 1.957 16.576 16.428c.973 7.82-5.719 12.762-5.719 12.762l-4.025-7.166s3.52-3.344 2.285-7.277c-2.285-7.277-8.689-7.718-8.689-7.718l-.428-7.03z"
            className="svg-char-shirt"
          />
          <path
            d="M87.768 168.42s-4.428 1.147-7.609-.798c0 0-5.227 1.01-6.137 4.399 0 0 .6 1.183 5.292 1.325 3.911.118 8.8-.849 9.042-1.476 0 0-.105-1.75-.588-3.45"
            className="svg-char-shoes"
          />
          <path
            d="M123.254 127.489s3.47 2.083 4.28 7.579c0 0-5.727 6.05-9.663.77-2.762-3.705 1.794-8.22 5.383-8.35"
            className="svg-char-white-skin"
          />
          <path
            d="M123.822 127.812s-1.992-3.083-3.487-1.206c-1.494 1.877 2.49 3.216 2.49 3.216l.997-2.01z"
            className="svg-char-white-skin"
          />
          <path
            d="M88.356 105.511s-15.183 1.118-17.103 16.796c-.94 7.684 5.22 12.826 5.22 12.826l3.741-7.042s-3.08-3.353-1.265-8.16c2.568-6.8 8.416-7.545 8.416-7.545l.99-6.875z"
            className="svg-char-shirt"
          />
          <path
            d="M80.187 127.489s-3.435 2.093-4.254 7.616c0 0 6.11 5.962 9.708.774 2.654-3.825-1.818-8.26-5.454-8.39"
            className="svg-char-white-skin"
          />
          <path
            d="M80.711 127.39s1.992-3.083 3.487-1.206c1.495 1.877-2.49 3.216-2.49 3.216l-.997-2.01z"
            className="svg-char-white-skin"
          />
          <path
            d="M87.4 62.462c0 9.888-5.945 14.382-13.723 14.382-7.779 0-12.715-14.587-13.005-21.09-.473-10.582 5.221-18.087 13-18.087 7.778 0 13.728 14.907 13.728 24.795"
            className="svg-char-white-hair-shade-2"
          />
          <path
            d="M141.495 92.873c-3.586 5.692-10.108 6.811-13.832 5.393-3.709-1.416-1.605-5.387.368-10.52 1.971-5.133 6.585-8.147 10.303-6.729 3.717 1.417 6.121 7.157 3.161 11.856"
            className="svg-char-white-skin-shade-1"
          />
          <path
            d="M137.56 92.17c-1.155 2.88-5.453 4.278-7.695 3.629-2.244-.65-1.259-2.988-.426-5.983.83-2.996 3.325-4.894 5.567-4.244 2.243.652 3.901 3.235 2.553 6.597"
            className="svg-char-white-skin-shade-2"
          />
          <path
            d="M60.885 80.591c1.299 4.604 5.476 7.89 8.572 7.713 3.083-.18 2.644-3.828 2.62-8.356-.024-4.528-2.55-8.054-5.641-7.875-3.09.179-6.774 4.194-5.55 8.518"
            className="svg-char-white-skin-shade-1"
          />
          <path
            d="M63.52 79.528c-.19 2.62 2.902 5.837 4.738 5.914 1.838.078 1.736-2.053 1.924-4.671.191-2.618-1.147-4.802-2.985-4.878-1.836-.077-3.49 1.02-3.678 3.635"
            className="svg-char-white-skin-shade-2"
          />
          <path
            d="M87.31 106.524s-28.492-10.415-18.993-49.24c9.498-38.826 35.552-36.653 48.579-33.124 13.027 3.53 25.184 13.575 24.968 25.522-.748 41.364-16.563 53.403-25.33 57.186-4.802 2.072-17.707 4.471-29.225-.344"
            className="svg-char-white-skin"
          />
          <path
            d="M120.844 73.5c0 3.958-2.352 7.167-5.255 7.167s-5.256-3.209-5.256-7.167c0-3.958 2.353-7.167 5.256-7.167s5.255 3.209 5.255 7.167"
            className="svg-char-white-eyes"
          />
          <path
            d="M119.822 69.867c0 1.584-.855 2.866-1.911 2.866S116 71.451 116 69.867 116.855 67 117.91 67c1.057 0 1.912 1.283 1.912 2.867"
            fill="#FFF"
          />
          <path
            d="M87.4 71.589c0 3.957-2.352 7.167-5.256 7.167-2.902 0-5.255-3.21-5.255-7.167 0-3.959 2.353-7.167 5.255-7.167 2.904 0 5.256 3.208 5.256 7.167"
            className="svg-char-white-eyes"
          />
          <path
            d="M85.822 67.867c0 1.582-.855 2.866-1.91 2.866-1.056 0-1.912-1.284-1.912-2.866 0-1.584.856-2.867 1.911-2.867 1.056 0 1.911 1.283 1.911 2.867"
            fill="#FFF"
          />
          <path
            d="M99.822 81.622a3.822 3.822 0 11-7.643.001 3.822 3.822 0 017.643 0"
            className="svg-char-white-skin-shade-1"
          />
          <path
            d="M89.311 93.293s19.118-4.318 31.504-5.937c0 0 1.204 11.571-11.847 14.573-16.426 3.778-19.657-8.636-19.657-8.636"
            className="svg-char-mouth"
          />
          <path
            d="M109.106 101.892c5.197-1.245 8.15-3.893 9.827-6.642-2.713-1.104-6.453-1.514-10.481-.96-6.052.835-10.933 3.6-12.452 6.72 2.982 1.548 7.194 2.3 13.106.882"
            className="svg-char-mouth-tongue"
          />
          <path
            d="M137.252 80.354s-13.295-12.618-5.73-29.822c0 0-3.86-2.348-20.86 1.147-16.732 3.44-42.095 6.123-53.98-6.076-8.94-9.176.115-18.924 8.94-13.075 0 0-5.043-1.147-3.897-9.634 1.27-9.407 14.211-11.698 21.202-4.472 0 0-4.274-3.371-.917-7.34 3.783-4.474 11.576-1.263 13.753 5.504 0 0 29.226-2.867 44.125 15.025 11.103 13.333 10.085 30.507-2.636 48.743"
            className="svg-char-white-hair"
          />
          <path
            d="M103.907 50.634c-11.947 2.033-28.824 1.513-39.346-1.99-8.517-2.837-10.888-9.497-11.483-11.933-.378 2.651.587 5.867 3.594 9.017 11.86 12.422 37.17 9.691 53.868 6.187 16.964-3.559 20.816-1.168 20.816-1.168-6.405-4.55-21.273-1.164-27.449-.113m33.609 15.288c-6.278.23-8.072-4.146-8.072-4.146s.042 1.07.16 2.777c1.206 9.795 7.688 16.114 7.688 16.114 4.914-7.232 8.035-14.295 9.352-21.023-1.678 2.809-4.666 6.114-9.128 6.278"
            className="svg-char-white-hair-shade-1"
          />
          <path
            d="M74.957 17.841c.217.98-1.308 2.093-3.408 2.486-2.1.396-3.978-.079-4.195-1.057-.216-.979 1.309-2.092 3.409-2.487 2.1-.394 3.977.08 4.194 1.058"
            fillOpacity={0.1}
            fill="#FFF"
          />
        </g>
      </svg>
    )
  } else if (config.app.name_project === "CARREFOUR") {
    return (
      <CarrefourCharCourseIntroValidation {...props} />
    )
  } else {
    let color = config.layout.char.color;
    let gender = config.layout.char.gender;
    let randomColor = ['black', 'white'];
    let randomGender = ['female', 'male'];
    let randomColorIndex = Math.floor(Math.random() * randomColor.length);
    let randomGenderIndex = Math.floor(Math.random() * randomGender.length);

    if (color === 'random') {
      color = randomColor[randomColorIndex];
    }

    if (gender === 'random') {
      gender = randomGender[randomGenderIndex];
    }

    if (gender === "male") {
      return (
        <svg width={200} height={172} viewBox="0 0 200 172" {...props}>
          <g fill="none" fillRule="evenodd">
            <path
              d="M121.878 136.089s3.643 19.11 2.602 33.444h-7.287s.78-18.05-2.082-30.79l6.767-2.654zm-39.438-.956s-3.534 19.37-2.492 33.445l7.416.514s-.914-18.237 1.947-30.747l-6.871-3.212z"
              className="svg-char-pants"
            />
            <path
              d="M116.555 168.39s4.416 1.238 7.625-.768c0 0 5.714 1.444 6.22 4.517 0 0-1.082.96-5.21 1.184-3.943.214-8.88-.67-9.123-1.315 0 0 .14-2.193.488-3.618"
              className="svg-char-shoes"
            />
            <path
              d="M82.933 135.512c1.113-1.876 37.734-1.68 38.599 0 .864 1.68.875 6.974-20.03 7.266-20.887-.663-19.18-6.236-18.57-7.266"
              className="svg-char-pants"
            />
            <path
              d="M87.4 107.422s-3.611 13.226-4.778 28.364c0 0 4.924 2.214 18.367 2.214 7.329 0 15.712-.903 19.856-2.214 0 0-.6-10.213-3.823-29.32 0 0-4.294 1.594-13.61 1.594-9.316 0-15.056-2.549-15.056-2.549"
              className="svg-char-shirt"
            />
            <path
              d="M102.141 116.334c1.523 3.128 4.189 10.289 4.37 12.416.071.847-4.423 5.12-6.21 5.416-1.551.258-5.95-3.87-6.208-5.16-.136-.68 3.102-9.803 4.323-12.605.765-1.754 2.89-1.781 3.725-.067zm-1.363-8.912a2.867 2.867 0 110 5.734 2.867 2.867 0 010-5.734z"
              className="svg-char-necktie"
            />
            <path
              d="M116.594 105.943s14.775 1.957 16.576 16.428c.973 7.82-5.719 12.762-5.719 12.762l-4.025-7.166s3.52-3.344 2.285-7.277c-2.285-7.277-8.689-7.718-8.689-7.718l-.428-7.03z"
              className="svg-char-shirt"
            />
            <path
              d="M87.768 168.42s-4.428 1.147-7.609-.798c0 0-5.227 1.01-6.137 4.399 0 0 .6 1.183 5.292 1.325 3.911.118 8.8-.849 9.042-1.476 0 0-.105-1.75-.588-3.45"
              className="svg-char-shoes"
            />
            <path
              d="M123.254 127.489s3.47 2.083 4.28 7.579c0 0-5.727 6.05-9.663.77-2.762-3.705 1.794-8.22 5.383-8.35"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M123.822 127.812s-1.992-3.083-3.487-1.206c-1.494 1.877 2.49 3.216 2.49 3.216l.997-2.01z"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M88.356 105.511s-15.183 1.118-17.103 16.796c-.94 7.684 5.22 12.826 5.22 12.826l3.741-7.042s-3.08-3.353-1.265-8.16c2.568-6.8 8.416-7.545 8.416-7.545l.99-6.875z"
              className="svg-char-shirt"
            />
            <path
              d="M80.187 127.489s-3.435 2.093-4.254 7.616c0 0 6.11 5.962 9.708.774 2.654-3.825-1.818-8.26-5.454-8.39"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M80.711 127.39s1.992-3.083 3.487-1.206c1.495 1.877-2.49 3.216-2.49 3.216l-.997-2.01z"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M87.4 62.462c0 9.888-5.945 14.382-13.723 14.382-7.779 0-12.715-14.587-13.005-21.09-.473-10.582 5.221-18.087 13-18.087 7.778 0 13.728 14.907 13.728 24.795"
              className={`svg-char-${color}-hair-shade-2`}
            />
            <path
              d="M141.495 92.873c-3.586 5.692-10.108 6.811-13.832 5.393-3.709-1.416-1.605-5.387.368-10.52 1.971-5.133 6.585-8.147 10.303-6.729 3.717 1.417 6.121 7.157 3.161 11.856"
              className={`svg-char-${color}-skin-shade-1`}
            />
            <path
              d="M137.56 92.17c-1.155 2.88-5.453 4.278-7.695 3.629-2.244-.65-1.259-2.988-.426-5.983.83-2.996 3.325-4.894 5.567-4.244 2.243.652 3.901 3.235 2.553 6.597"
              className={`svg-char-${color}-skin-shade-2`}
            />
            <path
              d="M60.885 80.591c1.299 4.604 5.476 7.89 8.572 7.713 3.083-.18 2.644-3.828 2.62-8.356-.024-4.528-2.55-8.054-5.641-7.875-3.09.179-6.774 4.194-5.55 8.518"
              className={`svg-char-${color}-skin-shade-1`}
            />
            <path
              d="M63.52 79.528c-.19 2.62 2.902 5.837 4.738 5.914 1.838.078 1.736-2.053 1.924-4.671.191-2.618-1.147-4.802-2.985-4.878-1.836-.077-3.49 1.02-3.678 3.635"
              className={`svg-char-${color}-skin-shade-2`}
            />
            <path
              d="M87.31 106.524s-28.492-10.415-18.993-49.24c9.498-38.826 35.552-36.653 48.579-33.124 13.027 3.53 25.184 13.575 24.968 25.522-.748 41.364-16.563 53.403-25.33 57.186-4.802 2.072-17.707 4.471-29.225-.344"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M120.844 73.5c0 3.958-2.352 7.167-5.255 7.167s-5.256-3.209-5.256-7.167c0-3.958 2.353-7.167 5.256-7.167s5.255 3.209 5.255 7.167"
              className={`svg-char-${color}-eyes`}
            />
            <path
              d="M119.822 69.867c0 1.584-.855 2.866-1.911 2.866S116 71.451 116 69.867 116.855 67 117.91 67c1.057 0 1.912 1.283 1.912 2.867"
              fill="#FFF"
            />
            <path
              d="M87.4 71.589c0 3.957-2.352 7.167-5.256 7.167-2.902 0-5.255-3.21-5.255-7.167 0-3.959 2.353-7.167 5.255-7.167 2.904 0 5.256 3.208 5.256 7.167"
              className={`svg-char-${color}-eyes`}
            />
            <path
              d="M85.822 67.867c0 1.582-.855 2.866-1.91 2.866-1.056 0-1.912-1.284-1.912-2.866 0-1.584.856-2.867 1.911-2.867 1.056 0 1.911 1.283 1.911 2.867"
              fill="#FFF"
            />
            <path
              d="M99.822 81.622a3.822 3.822 0 11-7.643.001 3.822 3.822 0 017.643 0"
              className={`svg-char-${color}-skin-shade-1`}
            />
            <path
              d="M89.311 93.293s19.118-4.318 31.504-5.937c0 0 1.204 11.571-11.847 14.573-16.426 3.778-19.657-8.636-19.657-8.636"
              className="svg-char-mouth"
            />
            <path
              d="M109.106 101.892c5.197-1.245 8.15-3.893 9.827-6.642-2.713-1.104-6.453-1.514-10.481-.96-6.052.835-10.933 3.6-12.452 6.72 2.982 1.548 7.194 2.3 13.106.882"
              className="svg-char-mouth-tongue"
            />
            <path
              d="M137.252 80.354s-13.295-12.618-5.73-29.822c0 0-3.86-2.348-20.86 1.147-16.732 3.44-42.095 6.123-53.98-6.076-8.94-9.176.115-18.924 8.94-13.075 0 0-5.043-1.147-3.897-9.634 1.27-9.407 14.211-11.698 21.202-4.472 0 0-4.274-3.371-.917-7.34 3.783-4.474 11.576-1.263 13.753 5.504 0 0 29.226-2.867 44.125 15.025 11.103 13.333 10.085 30.507-2.636 48.743"
              className={`svg-char-${color}-hair`}
            />
            <path
              d="M103.907 50.634c-11.947 2.033-28.824 1.513-39.346-1.99-8.517-2.837-10.888-9.497-11.483-11.933-.378 2.651.587 5.867 3.594 9.017 11.86 12.422 37.17 9.691 53.868 6.187 16.964-3.559 20.816-1.168 20.816-1.168-6.405-4.55-21.273-1.164-27.449-.113m33.609 15.288c-6.278.23-8.072-4.146-8.072-4.146s.042 1.07.16 2.777c1.206 9.795 7.688 16.114 7.688 16.114 4.914-7.232 8.035-14.295 9.352-21.023-1.678 2.809-4.666 6.114-9.128 6.278"
              className={`svg-char-${color}-hair-shade-1`}
            />
            <path
              d="M74.957 17.841c.217.98-1.308 2.093-3.408 2.486-2.1.396-3.978-.079-4.195-1.057-.216-.979 1.309-2.092 3.409-2.487 2.1-.394 3.977.08 4.194 1.058"
              fillOpacity={0.1}
              fill="#FFF"
            />
          </g>
        </svg>
      )
    } else {
      return (
        <svg width={200} height={172} viewBox="0 0 200 172" {...props}>
          <g fill="none" fillRule="evenodd">
            <path
              d="M107.312 103.941s14.862 3.71 14.758 17.324c-.061 8.081-5.87 11.774-5.87 11.774l-3.727-7.185s2.5-3.7 2.121-7.763c-.575-6.183-7.865-8.885-7.865-8.885l.583-5.265zm-23.814-.027s-14.87 3.677-14.798 17.29c.043 8.082 5.843 11.788 5.843 11.788l3.744-7.177s-2.491-3.706-2.104-7.767c.59-6.182 7.886-8.868 7.886-8.868l-.571-5.266z"
              className="svg-char-shirt"
            />
            <path
              d="M108.693 133.711s2.55 19.498 3.855 33.6l-6.85.29s-1.387-18.513-3.562-32.256l6.557-1.634z"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M105.615 167.05s3.87.913 6.761-1.052c0 0 5.697 1.156 6.234 4.041 0 0-.466.93-4.28 1.333-3.601.38-8.737-.539-8.977-1.143 0 0 .015-1.597.262-3.18"
              className="svg-char-shoes"
            />
            <path
              d="M84.515 133.684s-2.596 19.491-3.932 33.592l6.85.303s1.429-18.509 3.636-32.247l-6.554-1.648z"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M87.516 167.029s-3.871.905-6.758-1.067c0 0-5.7 1.143-6.244 4.027 0 0 .464.93 4.278 1.343 3.6.389 8.738-.52 8.978-1.122 0 0-.01-1.599-.254-3.181"
              className="svg-char-shoes"
            />
            <path
              d="M80.257 131.802s-2.057 7.912-2.748 14.92c-.06.61 7.006 2.548 17.285 2.734 9.747.176 19.874-1.99 19.794-2.691-.9-7.916-2.422-13.97-2.422-13.97s-15.88-3.243-31.91-.993"
              className="svg-char-pants"
            />
            <path
              d="M83.894 104.431s-.437 13.129-4.074 28.494c0 0 4.161 2.23 15.529 2.243 6.198.006 13.288-.893 16.793-2.206 0 0-1.628-4.291-2.532-8.814-.926-4.628.634-10.94-.598-19.689 0 0-3.702.637-11.58.628-7.877-.01-13.538-.656-13.538-.656"
              className="svg-char-shirt"
            />
            <path
              d="M113.239 133.196s-1.813-5.994-3.402-9.274c-.203-.421.923-10.627-.658-19.448 0 0-2.975.567-9.784.668-1.218 5.22-2.99 12.662-4.337 12.883-1.364.226-3.406-5.091-4.762-12.893-3.253-.182-5.686-1.365-5.686-1.365s-2.963 4.972-3.649 7.458c-.542 1.97.724 6.758.674 7.689-.231 4.297-.689 5.652-1.6 8.14-.707 1.925-2.588 3.504-2.884 5.559 0 0 5.956 3.259 18.36 3.274 6.764.007 12.493-.21 17.728-2.691"
              className="svg-char-suit"
            />
            <path
              d="M112.914 125.204s3.381 2.097 3.688 7.591c0 0-6.73 5.573-9.796.161-2.26-3.991 2.527-7.948 6.108-7.752"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M112.914 125.204s-1.773-3.099-3.465-1.462c-1.692 1.634 2.27 3.27 2.27 3.27l1.195-1.808zm-35.067-.04s-3.386 2.09-3.706 7.583c0 0 6.718 5.589 9.796.183 2.27-3.986-2.509-7.954-6.09-7.766"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M77.847 125.164s1.78-3.094 3.468-1.454c1.688 1.638-2.277 3.265-2.277 3.265l-1.191-1.81z"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M145.383 25.242c-.012 10.615-8.934 19.21-19.927 19.198-10.992-.012-19.894-8.628-19.882-19.243.012-10.615 8.934-19.21 19.926-19.197 10.993.012 19.895 8.628 19.883 19.242"
              className={`svg-char-${color}-hair`}
            />
            <path
              d="M138.503 42.09c-4.915 8.439-17.448 10.361-27.995 4.292-10.546-6.068-15.111-17.83-10.196-26.268 4.914-8.44 17.448-10.362 27.995-4.293 10.546 6.068 15.111 17.83 10.196 26.269"
              className="svg-char-ribbon"
            />
            <path
              d="M145.383 25.242a18.657 18.657 0 00-3.236-10.522c.289 2.328.523 8.147-3.59 11.871 0 0 .68 2.715-2.735 4.067l3.82 8.063c3.543-3.465 5.735-8.223 5.74-13.479"
              className={`svg-char-${color}-hair-shade-1`}
            />
            <path
              d="M60.474 76.342s-7.492-12.558-5.081-31.888c2.411-19.33 18.81-32.056 40.302-32.854 12.061-.447 23.418 3.307 31.763 9.903 10.068 7.96 15.208 19.96 13 33.902-3.21 20.252-11.751 30.85-16.533 34.238-9.856 6.98-46.766 17.244-63.45-13.3"
              className={`svg-char-${color}-hair`}
            />
            <path
              d="M54.23 78.063c1.252 4.643 5.29 7.958 8.287 7.784 2.984-.179 2.564-3.856 2.546-8.419-.018-4.564-2.458-8.121-5.45-7.944-2.991.177-6.56 4.22-5.382 8.58"
              className={`svg-char-${color}-skin-shade-1`}
            />
            <path
              d="M57.175 77.243c-.19 2.59 2.858 5.776 4.671 5.854 1.815.08 1.716-2.029 1.905-4.618.192-2.589-1.127-4.75-2.94-4.828-1.814-.078-3.448 1.005-3.636 3.592"
              className={`svg-char-${color}-skin-shade-2`}
            />
            <path
              d="M134.516 90.543c-3.538 5.624-9.96 6.723-13.624 5.316-3.65-1.404-1.574-5.327.374-10.4 1.946-5.073 6.492-8.048 10.15-6.642 3.659 1.406 6.02 7.084 3.1 11.726"
              className={`svg-char-${color}-skin-shade-1`}
            />
            <path
              d="M130.773 89.495c-1.186 2.845-5.59 4.22-7.885 3.576-2.298-.644-1.286-2.952-.43-5.911.853-2.96 3.41-4.833 5.707-4.187 2.295.646 3.992 3.201 2.608 6.522"
              className={`svg-char-${color}-skin-shade-2`}
            />
            <path
              d="M80.233 104.517S51.968 94.213 61.439 55.939c9.471-38.275 35.326-36.103 48.25-32.608 12.924 3.495 24.979 13.414 24.751 25.195-.789 40.788-16.499 52.641-25.204 56.363-4.767 2.037-17.578 4.388-29.003-.372"
              className={`svg-char-${color}-skin`}
            />
            <path
              d="M113.875 72.018c-.005 4.02-2.269 7.276-5.059 7.273-2.789-.003-5.046-3.264-5.041-7.285.004-4.02 2.269-7.277 5.058-7.273 2.79.003 5.047 3.264 5.042 7.285"
              className={`svg-char-${color}-eyes`}
            />
            <path
              d="M112.593 68.591c-.001 1.43-.807 2.588-1.8 2.588-.991-.001-1.795-1.16-1.793-2.592.002-1.43.808-2.589 1.8-2.587.992.001 1.795 1.162 1.793 2.591"
              fill="#FFF"
            />
            <path
              d="M80.277 69.926c-.005 4.02-2.269 7.278-5.059 7.274-2.789-.003-5.046-3.265-5.041-7.285.004-4.02 2.269-7.277 5.058-7.273 2.79.003 5.047 3.264 5.042 7.284"
              className={`svg-char-${color}-eyes`}
            />
            <path
              d="M78.593 66.592c-.001 1.429-.807 2.587-1.799 2.587-.992-.001-1.796-1.162-1.794-2.592.002-1.43.808-2.588 1.8-2.587.992.001 1.795 1.16 1.793 2.592"
              fill="#FFF"
            />
            <path
              d="M92.476 79.177c-.003 2.071-1.693 3.746-3.775 3.744-2.083-.002-3.77-1.682-3.767-3.753.002-2.069 1.693-3.746 3.775-3.744 2.083.003 3.77 1.683 3.767 3.753"
              className={`svg-char-${color}-skin-shade-1`}
            />
            <path
              d="M82.677 90.768s19.129-4.262 31.52-5.854c0 0 1.19 11.48-11.868 14.444-16.434 3.729-19.652-8.59-19.652-8.59"
              className="svg-char-mouth"
            />
            <path
              d="M102.329 99.358c5.15-1.17 8.078-3.663 9.743-6.253-2.687-1.044-6.391-1.435-10.382-.917-5.996.78-10.834 3.382-12.342 6.321 2.952 1.465 7.123 2.179 12.981.849"
              className="svg-char-mouth-tongue"
            />
            <path
              d="M135.922 42.85c2.704 22.388-5.714 35.682-5.714 35.682s-7.041-4.076-7.498-18.47c-23.653-1.377-36.342-15.5-40.67-21.444-2.911 4.325-10.336 13.587-23.592 19.313 0 0-2.933-15.372 6.9-21 1.879-5.245 6.792-12.167 19.417-16.524 18.977-6.55 48.453.054 51.157 22.443z"
              className={`svg-char-${color}-hair`}
            />
            <path
              d="M132.154 79.835c2.963-4.745 6.242-12.571 7.962-22.43-.071-.365-.115-.569-.115-.569s-1.577 10.513-16.589 9.478c0 0 1.496 9.866 8.742 13.52"
              className={`svg-char-${color}-hair-shade-1`}
            />
            <path
              d="M82.113 37.343c.037.062 3.93 6.342 12.593 12.27 7.991 5.467 21.8 11.75 42.19 10.613a.508.508 0 11.058 1.015 86.725 86.725 0 01-4.833.136c-18.08 0-30.562-5.83-38.04-10.957-8.826-6.051-12.685-12.287-12.844-12.55a.506.506 0 01.172-.698.513.513 0 01.704.171zm-5.437-1.326a.508.508 0 01.224.685c-.062.12-6.372 12.197-19.541 20.526a.512.512 0 01-.706-.157.507.507 0 01.157-.702c12.936-8.182 19.117-20.012 19.178-20.13a.512.512 0 01.688-.222zm-3.155-7.068a.508.508 0 01.327.641c-.042.13-4.368 13.04-16.06 23.318a.513.513 0 01-.721-.044.506.506 0 01.044-.718c11.485-10.097 15.723-22.744 15.764-22.872a.512.512 0 01.646-.325zm4.825-.123c.04.055 4.055 5.52 13.434 10.757 8.661 4.836 23.801 10.461 46.735 9.79a.52.52 0 01.527.494.511.511 0 01-.497.523c-1.27.037-2.516.055-3.738.055-21.13 0-35.29-5.363-43.59-10.01-9.552-5.347-13.538-10.787-13.702-11.016a.506.506 0 01.117-.71.513.513 0 01.714.117zm52.858-1.632a.508.508 0 11-.425.925c-25.386-11.515-50.048-3.679-50.294-3.598a.508.508 0 11-.32-.966c.25-.081 25.294-8.04 51.04 3.639zm-54.426-7.391c.609.918-.377 2.63-2.202 3.824-1.825 1.195-3.797 1.418-4.406.5-.607-.92.378-2.631 2.203-3.826 1.825-1.193 3.797-1.416 4.405-.498z"
              fillOpacity={0.1}
              fill="#FFF"
            />
          </g>
        </svg>
      )
    }
  }
}

export default CharCourseIntroValidation

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { currentLocale } from '../../../router/Router';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as PeriodicActions } from '../../../redux/ducks/periodic';
import { actions as GameActions } from '../../../redux/ducks/game';
import moment from 'moment';
import { getFullDate, getPeriodicTitle } from '../../../utils/utils';
import QrLayout from '../../../components/QrLayout2';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';
import Quiz from '../../../components/Game/Quiz';
import PointsBar from '../../../components/PointsBar';

export default function Question(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const help = useSelector(state => state.game.help);
    const question = useSelector(state => state.periodic.question);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const date = props.location.state && props.location.state.date ? props.location.state.date : '';
    const nextQuestion = props.location.state && props.location.state.nextQuestion ? props.location.state.nextQuestion : "";
    const [didMount, setDidMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [getQuestion, setQuestion] = useState(false);
    let periodicQuestion = nextQuestion.length !== 0 ? nextQuestion : (question && question.load === "finished") && question;
    moment.locale(currentLocale);

    useEffect(() => {
        setDidMount(true);

        if (origin === "home" || origin === "feeds" || origin === "notifications" || origin === "periodic" || origin === "result") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount && !nextQuestion && !getQuestion) {
            dispatch(PeriodicActions.getQuestion({
                question_date: date,
            }));
            setQuestion(true);
        }

        return () => {
            dispatch(PeriodicActions.reset());
            dispatch(GameActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, date, origin, nextQuestion, getQuestion]);

    function goBack() {
        let currentOrigin = "question";

        switch (origin) {
            case 'periodic':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/periodic`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'result':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/periodic`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const sendTo = (answer, idQuestion) => {
        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/periodic/${props.match.params.idPeriodic}/question/result`,
            state: {
                origin: "question",
                idQuestion: idQuestion,
                answer: answer
            }
        })
    }

    const QrAppBar = () => {
        function generateDateTitle() {
            switch (auth.modules.PERIODIC_QUESTION.SCHEDULE) {
                case "DAILY":
                    return getFullDate(periodicQuestion.data.question.date);
                case "WEEKLY":
                    return (
                        `${moment(periodicQuestion.data.question.date).startOf('week').format('DD')} - 
                        ${moment(periodicQuestion.data.question.date).endOf('week').format('DD')}
                        ${moment(periodicQuestion.data.question.date).format('MMM. YYYY')}`
                    );
                case "MONTHLY":
                    return moment(periodicQuestion.data.question.date).format('MMMM YYYY');
                default:
                    return;
            }
        }

        return (
            periodicQuestion && periodicQuestion.load === "finished" ?
                <AppBar className={`appbar appbar-${periodicQuestion.error ? "default" : "transparent"}`} position="absolute" color="primary">
                    <Toolbar>
                        {
                            config.layout.navbar ?
                                <IconButton
                                    color="inherit"
                                    aria-label="close"
                                    edge="start"
                                    onClick={() => goBack()}
                                >
                                    <Icon ic="close" />
                                </IconButton>
                                : null
                        }
                        {
                            periodicQuestion.data.length !== 0 ?
                                <>
                                    <div className="w-100 overflow-hidden">
                                        <Typography variant="h4" noWrap>
                                            {generateDateTitle()}
                                        </Typography>
                                        <div className="w-100 d-flex overflow-hidden">
                                            <Typography variant="body2" component="p" noWrap>
                                                {getPeriodicTitle(auth.modules.PERIODIC_QUESTION.SCHEDULE)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <>
                                        {
                                            config.layout.points.neuros && periodicQuestion.data.question.answered === "N" ?
                                                <PointsBar type="neuros" value={help && help.user_neuros ? help.user_neuros : auth.user.neuros} />
                                                : null
                                        }
                                    </>
                                </>
                                : null
                        }
                    </Toolbar>
                </AppBar>
                : null
        )
    }

    return (
        !redirect || ((periodicQuestion && (periodicQuestion.load === "finished" && periodicQuestion.data.length === 0)) && getQuestion) ?
            <QrLayout id="periodic-question">
                <QrAppBar />
                {
                    periodicQuestion && periodicQuestion.load === "finished" ?
                        !periodicQuestion.error ?
                            periodicQuestion.data.length !== 0 ?
                                <Quiz
                                    module="periodic"
                                    question={periodicQuestion.data}
                                    sendAnswer={sendTo}
                                    gameType={3}
                                    view={periodicQuestion.data.question.answered === "Y" ? true : false}
                                />
                                :
                                <EmptyContent icon={<Icon ic="play_periodic_question_filled" className="icon-xxl" />} text={intl.get("EMPTY_PERIODIC_QUESTION")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingContent />
                }
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/periodic`,
                state: {
                    origin: 'question'
                }
            }} />
    )
}
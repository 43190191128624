import * as React from "react"

function LogoMainColor(props) {
    return (
        <svg width={492} height={128} viewBox="0 0 492 128" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M457.561 35.2c15.836 0 28.44 7.584 28.44 25.009v54.855h-8.403v-9.196C470.65 113.45 461.925 117 451.098 117c-13.411 0-27.307-9.197-27.307-25.975 0-16.941 13.735-25.815 27.307-25.815 10.827 0 19.713 3.55 26.5 11.133V60.53c0-11.616-9.372-17.908-21.006-17.908-9.695 0-17.29 3.388-24.399 11.455l-4.685-5.809c8.079-8.712 17.128-13.068 30.053-13.068zm-75.938.001c13.572 0 21.328 5.324 27.63 13.23l-5.817 5.163c-5.656-7.745-12.765-10.81-21.328-10.81-18.26 0-29.409 14.52-29.409 33.236 0 18.715 11.15 33.397 29.409 33.397 8.563 0 15.672-3.227 21.328-10.971l5.817 5.163c-6.302 8.066-14.058 13.39-27.63 13.39-22.945 0-37.972-18.23-37.972-40.979 0-22.75 15.027-40.819 37.972-40.819zm-190.801-.933c6.988 0 10.434 3.581 11.443 9.248v42.367c-.044 4.076-.107 6.482-.107 7.474 0 .709.042 1.372.107 2.011v20.499c-.89.236-1.744.425-2.523.569-1.862.28-3.557.564-4.955.564-8.075 0-9.714-5.232-11.34-12.731-1.142 1.407-2.193 2.557-4.385 4.668-5.29 4.947-12.524 8.063-19.987 7.924-8.522-.16-15.902-2.157-21.67-8.504-7.91-8.702-6.103-22.158-5.488-32.729.356-6.125.482-9.88.395-13.475l-.02-.717a103.685 103.685 0 00-.16-3.28c-.328-5.156-3.765-9.783-11.544-8.945-3.232-8.052 7.5-13.178 13.517-14.357 1.907-.29 3.663-.58 5.285-.58 8.511 0 11.75 5.299 11.75 13.217l-.152 25.77c0 19.12 2.656 28.864 15.004 28.864 13.489 0 17.18-12.029 17.18-22.956V58.312c0-7.164-3.4-9.944-11.174-9.107-3.226-8.052 7.483-13.178 13.528-14.356 1.89-.29 3.663-.58 5.296-.58zm131.18 2.87v77.926H313.6V37.137h8.402zM262.866 35.2c15.835 0 24.56 7.744 24.56 25.33v54.533h-8.402V62.306c0-15.005-7.594-19.522-18.905-19.522-10.018 0-20.36 6.292-25.368 13.391v58.889h-8.402V37.137h8.402v11.778c5.655-6.776 16.804-13.714 28.115-13.714zm191.14 36.624c-12.926 0-21.328 8.228-21.328 19.36 0 10.972 8.402 19.2 21.329 19.2 9.372 0 18.097-3.55 23.59-10.648V82.635c-5.493-7.1-14.218-10.81-23.59-10.81zM317.802 11c3.555 0 6.463 2.904 6.463 6.453 0 3.55-2.908 6.454-6.463 6.454-3.394 0-6.464-2.904-6.464-6.454 0-3.549 3.07-6.453 6.464-6.453z"
                    fill="#001762"
                />
                <path
                    d="M75.554 34.248c3.221-.249 6.07.378 8.536 2.84 2.478 2.475 3.1 5.312 2.838 8.518l-1.132 68.31c.003.45-.123.754-.31.934-.33.33-.848.266-1.197-.103a1.382 1.382 0 01-.344-.955C81.96 95.51 76.04 77.74 59.587 61.312 43.144 44.892 25.575 39.21 7.272 37.234a1.41 1.41 0 01-.956-.346c-.362-.341-.435-.866-.105-1.195.183-.184.49-.308.942-.303zM100.033 11c3.646 0 6.63 2.905 6.63 6.454 0 3.55-2.984 6.453-6.63 6.453-3.48 0-6.63-2.903-6.63-6.453 0-3.55 3.15-6.454 6.63-6.454z"
                    fill="#0091D2"
                />
            </g>
        </svg>
    )
}

export default LogoMainColor

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { imageParser } from '../../../utils/utils';
import {
    CardActionArea, CardMedia, Grid, IconButton,
    MobileStepper, Typography, Divider
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingBanner } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function HighlightsSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const highlights = useSelector(state => state.home.highlights);
    const [didMount, setDidMount] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    useEffect(() => {
        setDidMount(true);

        if (didMount)
            dispatch(HomeActions.getHighlightList({}));

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    return (
        highlights && highlights.load === "finished" ?
            !highlights.error ?
                highlights.data.length !== 0 ?
                    <div>
                        {props.position === 0 ? null : <Divider variant="middle" />}
                        <div className="p-3">
                            <div className="qr-banner">
                                <AutoPlaySwipeableViews
                                    className="qr-banner-slider"
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    interval={7000}
                                    enableMouseEvents
                                    autoplay={true}
                                >
                                    {
                                        highlights.data.map((item, index) => {
                                            return (
                                                <div className="qr-banner-content" key={item + index}>
                                                    <CardActionArea onClick={() => item.url ?
                                                        window.open(item.url, '_blank')
                                                        :
                                                        history.push({ pathname: `${props.match.path.replace(/./g, '')}/news/highlight/${item.id_highlight}` })
                                                    }>
                                                        {
                                                            item.title ?
                                                                <div className={`qr-banner-title section-feeds${!config.home.highlight.show_mobile.title ? " d-none d-sm-block" : ""}`}>
                                                                    <Typography component="h4" noWrap>
                                                                        <Icon ic="feed_filled" className="icon-xxs mr-2" /> {item.title}
                                                                    </Typography>
                                                                </div>
                                                                : null
                                                        }
                                                        <Grid container className={`qr-banner-text${highlights.data.length > 1 ? " with-stepper" : ""}${!config.home.highlight.show_mobile.text ? " d-none d-sm-block" : ""}`}>
                                                            <Typography variant="h4" component="p">{item.description}</Typography>
                                                        </Grid>
                                                        <div className={`qr-banner-filter${!config.home.highlight.show_mobile.title && !config.home.highlight.show_mobile.text ? " d-none d-sm-block" : ""}`}></div>
                                                        <CardMedia
                                                            className='qr-banner-image '
                                                            image={imageParser(item.image)}
                                                            title={intl.get("MENU_HIGHLIGHT")}
                                                        />
                                                    </CardActionArea>
                                                </div>
                                            )
                                        })
                                    }
                                </AutoPlaySwipeableViews>
                                {highlights.data.length > 1 ?
                                    <div className="qr-banner-stepper">
                                        <MobileStepper
                                            steps={highlights.data.length}
                                            position="static"
                                            variant="dots"
                                            activeStep={activeStep}
                                            nextButton={
                                                <IconButton onClick={handleNext} disabled={activeStep === highlights.data.length - 1} aria-label="Next">
                                                    {theme.direction === 'rtl' ? <Icon ic="arrow_left" /> : <Icon ic="arrow_right" />}
                                                </IconButton>
                                            }
                                            backButton={
                                                <IconButton onClick={handleBack} disabled={activeStep === 0} aria-label="Back">
                                                    {theme.direction === 'rtl' ? <Icon ic="arrow_right" /> : <Icon ic="arrow_left" />}
                                                </IconButton>
                                            }
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            {props.position === 0 ? null : <Divider variant="middle" />}
                            <div className="p-3">
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="feed_filled" className="icon-xxl" />}
                                    text={intl.get("EMPTY_HIGHLIGHT")}
                                    padding={true}
                                />
                            </div>
                        </div>
                        : null
                :
                <div>
                    {props.position === 0 ? null : <Divider variant="middle" />}
                    <div className="p-3">
                        <LoadingBanner />
                    </div>
                </div>
            :
            <div>
                {props.position === 0 ? null : <Divider variant="middle" />}
                <div className="p-3">
                    <LoadingBanner />
                </div>
            </div>
    )
};

import { qrService } from './base.service'

function login(params) {
    return qrService('/v3/app/login/jwt/user', params)
}

function signup(params) {
    return qrService('/v4/app/signup', params)
}

function getAzureSaml(params) {
    return qrService('/v1/app/auth/azure/saml', params)
}

function signupLogin(params) {
    return qrService('/v4/app/signup/login', params)
}

function getEnterprise(params) {
    return qrService('/v4/app/signup/enterprise', params)
}

function getCode(params) {
    return qrService('/v1/app/login/activation/getcode', params)
}

function setRecoverPassword(params) {
    return qrService('/v1/app/profile/password/forgot', params)
}

function updateProfile(params) {
    return qrService('/v2/app/profile/update', params)
}

function updatePassword(params) {
    return qrService('/v2/app/profile/password/change', params)
}

function updateExpiredPassword(params) {
    return qrService('/v2/app/profile/password/update', params)
}

function updatePhoto(params) {
    return qrService('/v3/app/profile/photo/update', params)
}

function jwt(params) {
    return qrService('/v3/app/login/jwt', params)
}

function jwtRefresh(params) {
    return qrService('v3/app/login/jwt/refresh', params)
}

function classroomProfileList(params) {
    return qrService('/v3/app/profile/classroom', params)
}

function scormProfileList(params) {
    return qrService('/v3/app/profile/scorm', params)
}

function classroomManagerList(params) {
    return qrService('/v3/app/profile/manager/classroom', params)
}

function scormManagerList(params) {
    return qrService('/v3/app/profile/manager/scorm', params)
}

function getPrivacyPolitics(params) {
    return qrService('/v1/app/help', params);
}

function updateTermsOfUse(params) {
    return qrService('/v3/app/profile/terms/accept', params)
}

function updatePreferences(params) {
    return qrService('/v3/app/profile/update/preferences', params)
}

function setFirstAccess(params) {
    return qrService('/v1/app/profile/password/first-access', params)
}

function getAuthQuestionList(params) {
    return qrService('/v1/app/oauth/question/list', params);
}

function getAuthUserQuestionList(params) {
    return qrService('/v1/app/oauth/user/question/list', params);
}

function setAuthUserAnswerUpdate(params) {
    return qrService('/v1/app/oauth/user/answer/update', params);
}

function getBrandList(params) {
    return qrService('/v1/app/brand/list', params);
}

function getRoleList(params) {
    return qrService('/v1/app/role/list', params);
}

function getRoleFamilyList(params) {
    return qrService('/v1/app/role/family/list', params);
}

function getRoleGroupList(params) {
    return qrService('/v1/app/role/group/list', params);
}

function getPositionList(params) {
    return qrService('/v1/app/position/list', params);
}

function getRegionalList(params) {
    return qrService('/v1/app/regional/list', params);
}

function getSubareaList(params) {
    return qrService('/v1/app/subarea/list', params);
}

function getBoardList(params) {
    return qrService('/v1/app/board/list', params);
}

function getExecutiveBoardList(params) {
    return qrService('/v1/app/executive-board/list', params);
}

function getManagingList(params) {
    return qrService('/v1/app/managing/list', params);
}

function getCostCenterList(params) {
    return qrService('/v1/app/cost-center/list', params);
}

function getExternalEnterpriseList(params) {
    return qrService('/v1/app/external-enterprise/list', params);
}

export const userService = {
    login,
    getAzureSaml,
    signup,
    signupLogin,
    getEnterprise,
    setRecoverPassword,
    getCode,
    updateProfile,
    updatePassword,
    updateExpiredPassword,
    updatePhoto,
    jwt,
    jwtRefresh,
    classroomProfileList,
    getPrivacyPolitics,
    scormProfileList,
    classroomManagerList,
    scormManagerList,
    updateTermsOfUse,
    updatePreferences,
    setFirstAccess,
    getAuthQuestionList,
    getAuthUserQuestionList,
    setAuthUserAnswerUpdate,
    getBrandList,
    getRoleList,
    getRoleFamilyList,
    getRoleGroupList,
    getPositionList,
    getRegionalList,
    getSubareaList,
    getBoardList,
    getExecutiveBoardList,
    getManagingList,
    getCostCenterList,
    getExternalEnterpriseList,
}
import * as React from "react"

function CharTrailIntroValidation(props) {
    return (
        <svg width={200} height={172} viewBox="0 0 200 172" {...props}>
            <defs>
                <filter
                    x="-7.3%"
                    y="-7.2%"
                    width="114.5%"
                    height="114.4%"
                    filterUnits="objectBoundingBox"
                    id="CharTrailIntroValidation__a"
                >
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g
                filter="url(#CharTrailIntroValidation__a)"
                transform="translate(18 6)"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    d="M153.576 136.015c-12.47-18.09-20.985-21.77-26.156-27.29-5.17-5.519-8.516-25.45-18.552-26.676-10.036-1.227-36.8 21.157-36.8 21.157-5.778 3.373-9.732 10.732-11.861 15.945-2.13 5.212-2.737 8.892-2.737 8.892s-10.037 8.892-15.511 15.638C37.874 148.715 31.249 159.212 26 166h138.468c-2.63-11.689-6.202-23.18-10.892-29.985"
                    fill="#1B4E61"
                />
                <path
                    d="M72.733 125.649l1.014 22.708s12.774-8.212 20.986-16.423c8.211-8.212 16.119-20.073 16.119-20.073l-38.12 13.788z"
                    fill="#FAF8DC"
                />
                <path
                    d="M65.435 6.63c-4.664-2.433-9.327-6.488-16.424 0-7.096 6.489-10.949 2.433-14.395 7.097-3.448 4.663-1.825 9.935-6.286 12.368-4.46 2.433-9.53 5.272-9.124 11.76.406 6.488 1.217 8.313-4.258 12.368-5.474 4.055-6.285 11.152-2.838 13.99 3.447 2.839 8.11 2.433 8.11 2.433s-3.244 6.894 2.839 10.746c6.082 3.853 8.92-1.216 11.76 1.014 2.838 2.23 4.46 8.921 14.192 4.663C58.744 78.812 65.435 6.63 65.435 6.63"
                    fill="#FFB600"
                />
                <path
                    d="M96.457 73.945s-4.055 10.34-8.112 14.6C84.29 92.8 77.802 99.086 65.029 99.086c-13.99-1.012-22.71-13.99-23.925-37.305-1.217-23.319 8.283-39.82 19.26-44.811 4.464-2.027 17.582-5.555 30.01 8.719 15.003 17.234 6.083 48.256 6.083 48.256"
                    fill="#F4A467"
                />
                <path
                    d="M58.368 51.507l2.505 16.444-7.776 1.341c-2.003.346-3.567-1.577-2.651-3.257l7.922-14.528zm39.507 71.911c-13.878 6.195-25.142 2.23-25.142 2.23l-7.704-26.562c12.773 0 19.26-6.286 23.315-10.541 4.058-4.26 8.113-14.6 8.113-14.6l16.422 34.47s-3.65 9.934-15.004 15.003"
                    fill="#EA8B49"
                />
                <path
                    d="M46.68 62.137c-4.501 0-8.163-3.662-8.163-8.163s3.662-8.163 8.163-8.163c4.5 0 8.162 3.662 8.162 8.163 0 4.5-3.662 8.163-8.162 8.163m22.303 0c-4.501 0-8.163-3.662-8.163-8.163s3.662-8.163 8.163-8.163c4.5 0 8.162 3.662 8.162 8.163 0 4.5-3.661 8.163-8.162 8.163"
                    fill="#080202"
                />
                <path
                    d="M54.342 54.69l-.524-1.432c2.592-.95 7.169-1.705 8.413.316l-1.298.8c-.42-.683-3.263-.905-6.59.315m22.225.024l-.37-1.479c5.243-1.31 17.624-2.8 22.121-1.301l-.482 1.446c-1.795-.598-5.404-.715-10.165-.33-3.99.322-8.348.975-11.104 1.664"
                    fill="#080202"
                />
                <path
                    d="M134.169 66.646c-2.433-3.244-3.853-8.921-1.217-12.976 2.636-4.055 2.027-9.733-2.838-11.557-4.867-1.825-6.894-4.056-6.083-9.327.81-5.272-.203-11.557-6.083-12.774-5.88-1.216-5.88-5.474-7.907-10.137-2.028-4.664-10.2-7.603-15.815-5.678-7.097 2.434-7.502 2.23-13.18-2.23-4.707-3.699-13.381-.608-18.247 3.65L60.365 16.97s0 3.042 5.273 7.706c5.27 4.663 11.759 5.474 15.611 7.502 3.853 2.027 4.258 5.677 5.069 10.137.811 4.461 6.286 4.258 6.691 7.705.406 3.447-3.244 6.083-3.447 10.95-.202 4.865 3.262 5.286 3.65 10.137.405 5.069-2.433 1.825-2.839 9.53-.405 7.704 4.664 11.76 10.747 10.745 3.248-.541 6.274-2.273 7.704-3.447 2.462 2.88 7.03 4.445 10.34 3.65 3.424-.821 5.266-3.841 5.982-6.791.592-2.44 2.262-4.483 4.498-5.626 4.143-2.116 9.222-6.258 4.525-12.522"
                    fill="#FFB600"
                />
                <path
                    d="M49.62 54.278a1.42 1.42 0 11-2.84 0 1.42 1.42 0 012.84 0m22.911.202a1.42 1.42 0 11-2.84 0 1.42 1.42 0 012.84 0"
                    fill="#080202"
                />
                <path
                    d="M52.719 77.097s1.327-2.05 4.583-2.66c3.256-.608 6.772 1.52 6.772 1.52s-1.26 3.415-4.997 3.632c-3.737.217-6.358-2.492-6.358-2.492"
                    fill="#EA8B49"
                />
                <path
                    d="M20.11 127.692a28.484 28.484 0 00-.933-1.536c-.078-.119-.339-.48-.53-.748a9.332 9.332 0 00-.192-.225c-.374-.418-.766-.82-1.176-1.202-.063-.058-.13-.113-.193-.171-.28-.187-.55-.394-.837-.562-.288-.17-.607-.307-.91-.463-.086-.028-.172-.06-.26-.087-.479-.145-.963-.249-1.45-.358-.57-.036-1.145-.06-1.715-.036l-.134.005c-.941.192-1.869.406-2.787.701-.367.119-.731.282-1.099.412-.12.059-.242.115-.36.176-1.022.526-2.25 1.124-3.086 1.927l1.076-.818c-.23.19-.454.387-.674.589-.392.36-.747.75-1.113 1.133l-.055.088c-.088.14-.235.34-.38.557-.051.156-.107.31-.152.468-.02.065-.034.131-.051.197l-.114.82a7.3 7.3 0 01-.25 1.971C1.628 139.71.816 152.762.304 166.335h26.273c-2.482-14.82-5.086-30.715-6.342-38.412-.042-.077-.083-.154-.126-.23"
                    fill="#F4A467"
                />
                <path
                    d="M78.6 35.762a10.415 10.415 0 00-3.718-2.811 16.267 16.267 0 00-6.174-1.863 16.11 16.11 0 00-4.44.207c-1.03.189-2.905.421-3.508 1.426-.51.852-.05 1.96.791 2.372 1.232.604 3.18.04 4.52.066a29.19 29.19 0 0111.108 2.447c1.214.53 2.315-.784 1.422-1.844m-29.757-.417a15.117 15.117 0 012.07-.583c-.38.166-1.404.407-1.533.441l-.537.142m-1.23-2.076c-.076.035-.148.074-.222.11a16.628 16.628 0 00-5.904 4.093c-.98 1.055.401 2.53 1.495 1.786.122.006.25 0 .386-.037 2.727-.72 5.62-1.248 8.275-2.202 1.63-.585 2.593-2.28 1.34-3.786-1.422-1.71-3.72-.742-5.37.036"
                    fill="#FFB600"
                />
            </g>
        </svg>
    )
}

export default CharTrailIntroValidation

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../redux/ducks/home';
import { imageParser } from '../../utils/utils';
import { getStorage } from '../../utils/storageHandler';
import { askUserPermission } from '../../firebase'; // Importe a função askUserPermission
import {
    Avatar, Container, AppBar, Toolbar, useMediaQuery,
    Typography, Button, Dialog, DialogContent, DialogActions
} from '@material-ui/core';
import QrLayout from '../../components/QrLayout2';
import ClassroomSection from './sections/classroom.section';
import TrailSection from './sections/trail.section';
import ScormSection from './sections/scorm.section';
import FreeSection from './sections/free.section';
import ExamSection from './sections/exam.section';
// import PeriodicQuestionSection from './sections/periodic.question.section';
import HighlightsSection from './sections/highlights.section';
import KnowMoreSection from './sections/knowmore.section';
import EmptyContent from '../../components/EmptyContent';
import { Icon } from '../../components/Images/Images';
import migration from '../../dist/img/projects/brk/migracao.jpg';

export default function Home(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const isScreenMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [openDialog, setOpenDialog] = useState(true);
    let storageURL = getStorage('url');
    let enabledModules = [];

    useEffect(() => {
        askUserPermission();
        return () => {
            dispatch(HomeActions.reset());
        }
    }, [dispatch]);

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-main" position="static" color="primary">
                <Toolbar>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {`${intl.get('HOME_TITLE')} ${auth.user.fname}`}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {intl.get("HOME_SUBTITLE")}
                            </Typography>
                        </div>
                    </div>
                    {
                        config.app.name_project === "FLEURY" && isScreenMd ?
                            null
                            :
                            <Avatar
                                alt="Avatar"
                                className="avatar-bordered-sm cursor-pointer"
                                src={imageParser(auth.user.image)} onClick={() => history.push(`/profile/${auth.user.id_user}`)}
                            />
                    }
                </Toolbar>
            </AppBar>
        )
    }

    function reorderHomeArray(array) {
        array.sort(function (a, b) {
            return config.home['order'].indexOf(a) - config.home['order'].indexOf(b);
        });
        return array;
    }

    Object.entries(auth.modules).map(item => {
        if (item[item.length - 1].ENABLED === 'Y' && Object.values(item).filter(module => config.home.order.includes(module)).length > 0) {
            enabledModules.push(item[0]);
        }
        return null;
    });

    enabledModules.push("KNOWMORE");
    enabledModules.push("HIGHLIGHTS");
    enabledModules = reorderHomeArray(enabledModules);

    if (storageURL && storageURL.length !== 0) {
        const urlParams = new URLSearchParams(storageURL);
        history.push("/classroom/qrcode?t=" + urlParams.get("t") + "&cod=" + urlParams.get("cod"));
    }

    const Modules = (type, position) => {
        switch (type) {
            case "CLASSROOM_COURSE":
                return (
                    <ClassroomSection position={position} {...props} />
                )

            case "EXAM":
                return (
                    <ExamSection position={position} {...props} />
                )

            case "FREE":
                return (
                    <FreeSection position={position} {...props} />
                )

            case "HIGHLIGHTS":
                return (
                    config.home.highlight.enabled
                        ?
                        <HighlightsSection position={position} {...props} />
                        : null
                )

            case "KNOWMORE":
                return (
                    config.home.knowmore
                        ?
                        <KnowMoreSection position={position} {...props} />
                        : null
                )

            case "SCORM":
                return (
                    <ScormSection position={position} {...props} />
                )

            case "TRAIL":
                return (
                    <TrailSection position={position} {...props} />
                )

            // @TODO - Comentado pois a verificacao se tem ou não pergunta não esta funcionando
            // case "PERIODIC_QUESTION":
            //     return (
            //         <div className={emptyPeriodic && !config.home.empty_modules_visible ? "d-none" : ""}>
            //             {position === 0 ? null : <Divider variant="middle" />}
            //             <SectionModule className="pb-3">
            //                 <div className="pl-3 pr-3">
            //                     <PeriodicQuestionSection
            //                         isEmpty={(empty) => setEmptyPeriodic(empty)}
            //                         {...props}
            //                     />
            //                 </div>
            //             </SectionModule>
            //         </div>
            //     )

            default:
        }
    }

    return (
        <QrLayout
            id="home"
            bottomNavigation={true}
            hideSidebar={!config.home.sidebar}
        >
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <div className="page-bottom-menu">
                <Container maxWidth="lg" className="container-full">
                    {
                        enabledModules.length !== 0 ?
                            enabledModules.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {Modules(item, index)}
                                    </div>
                                )
                            })
                            :
                            <EmptyContent icon={<Icon ic="home_filled" className="icon-xxl" />} text={intl.get("EMPTY_HOME")} />
                    }
                </Container>
            </div>
            {
                config.app.name_project === "BRK" ?
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                    >
                        <DialogContent>
                            <img src={migration} className="img-fluid" alt="Migração BRK" />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)} color="primary">
                                {intl.get("BTN_CLOSE")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null
            }
        </QrLayout>
    )
};

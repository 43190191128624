import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fill-rule="evenodd">
                <path fill="#FFF" fill-rule="nonzero" d="M0 0h512v512H0z" />
                <path fill="#19191A" d="M391.5259 186.176c-.3558 14.7051-3.3934 18.0154-17.9495 18.0732-40.8798.1625-81.7614.056-122.643.0542-38.4761 0-76.9522.045-115.4283-.0199-17.4673-.0307-17.4908-.1318-17.59-18.214-.0488-9.0124.0848-18.0265-.0125-27.0387-.0833-8.0033 3.1927-12.5483 11.744-12.2144 2.9997.1174 6.0102-.0108 9.0153-.0108 76.954.018 153.9061.018 230.8583.0704 20.355.0144 21.816 1.4927 22.0057 21.2753.0578 6.007.1444 12.0194 0 18.0246m43.1571-28.174c.1138-30.1324-14.4712-53.5737-52.9509-52.8896-47.5348.8465-95.0948.204-142.6459.2094-48.1506.0072-96.303-.0271-144.4537.0234-17.4673.0199-17.5612.1101-17.5774 18.068-.0434 48.7273-.2186 97.4546.083 146.1801.1824 29.3454-6.1023 26.9538 28.435 27.2747 9.3765.0852 13.2214-3.9092 12.9541-12.8655-.2528-8.4094-.679-16.8297-.5165-25.2355.251-13.0861 1.1432-13.7774 14.7963-14.2503 2.4037-.083 4.8147-.0018 7.222-.0018 61.3938.0036 122.7876.0036 184.1813.009 21.668.0018 43.3486.4964 65.0003-.0993 25.903-.7111 37.4322-10.958 43.522-36.1231 4.0399-16.6907 1.8872-33.5456 1.9504-50.2995" />
                <path fill="#DD021A" d="M254.9244 407.9538c-58.308.0018-116.6161.0126-174.924-.0307-3.2618-.0018-2.814 2.8121-2.8497-25.1723-.0065-5.4132-.0123-10.8335.0054-16.243.0437-13.3533.0723-13.8587 1.6871-13.9797 3.1086-.2328 6.2176-.0776 9.3263-.0776 113.7146 0 227.429-.0018 341.1435.0235.8968 0 1.7937.3159 2.6901.5776.6531.1913.9961 2.7454.9816 8.9346-.0284 12.0267-.0375 24.0713.0071 36.0925.0281 8.247-.4481 10.3426-1.2785 9.922-.6195-.314-1.2431-.0487-1.865-.0487-58.3079 0-116.6158 0-174.924.0018" />
            </g>
        </svg>
    )
}

export default LogoAppIcon

import React from 'react';
import intl from 'react-intl-universal';
import { linkVideoParser } from '../../../../utils/utils';
import {
    AppBar, Toolbar, IconButton, Slide,
    Dialog, Container, Grid
} from '@material-ui/core';
import Viewer from '../../../../components/DocViewer';
import EmptyContent from '../../../../components/EmptyContent';
import QrVideoPlayer from '../../../../components/QrVideoPlayer';
import { Icon } from '../../../../components/Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogExternalLink(props) {
    const { open, file, close, fileName } = props;

    function getMountFile() {
        let fileType = file.source_file_type;

        switch (fileType) {
            case 'video/mp4':
                return (
                    <Container maxWidth="md">
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} sm={8} md={12}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <QrVideoPlayer link={linkVideoParser(file.source) + "#toolbar=0"} />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                );
            case 'audio/mpeg':
                return (
                    <Container maxWidth="md">
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={12} sm={8} md={12}>
                                <audio controls>
                                    <source src={file.source} />
                                </audio>
                            </Grid>
                        </Grid>
                    </Container>
                );
            default:
                return (
                    <Viewer data={file.source} fileName={fileName} />
                );
        }
    }

    return (
        <Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={close} TransitionComponent={Transition}>
            <AppBar className="appbar appbar-default" position="sticky">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                    </div>
                </Toolbar>
            </AppBar>
            <Container maxWidth={false} className="library-file-content">
                <Grid container justify="center" alignItems="center" className="h-100">
                    <Grid item xs={12} className="pt-3 pb-3 h-100" align="center">
                        {
                            file && (file.source && file.source.length > 0) ?
                                getMountFile()
                                :
                                <EmptyContent icon={<Icon ic="book_stack_filled" className="icon-xxl" />} text={intl.get("EMPTY_LIBRARY")} />
                        }
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
};

import { qrService } from './base.service'

function getProfile (params) {
    return qrService('/v3/app/profile/basic', params)
}

function getTrailStats (params) {
    return qrService('/v3/app/profile/statistic/course', params)
}

function getTrailList (params) {
    return qrService('/v3/app/profile/statistic/course/list', params)
}

function getScormStats (params) {
    return qrService('/v3/app/profile/statistic/scorm', params)
}

function getScormList (params) {
    return qrService('/v3/app/profile/statistic/scorm/list', params)
}

function getClassroomStats (params) {
    return qrService('/v3/app/profile/statistic/classroom', params)
}

function getClassroomList (params) {
    return qrService('/v3/app/profile/statistic/classroom/list', params)
}

function getPerformanceStats (params) {
    return qrService('/v3/app/profile/statistic/performance', params)
}

function getPerformanceList (params) {
    return qrService('/v3/app/profile/statistic/performance/list', params)
}


export const profileService = {
    getProfile,
    getTrailStats,
    getTrailList,
    getScormStats,
    getScormList,
    getClassroomStats,
    getClassroomList,
    getPerformanceStats,
    getPerformanceList,
}
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../../redux/ducks/trail';
import ReactHtmlParser from 'react-html-parser';
import { Container, Typography, Grid, TextField, Button, Box } from '@material-ui/core';
import SnackBar from '../../../../components/snackBar';
import { LoadingBounce } from '../../../../components/Loading';
import { Icon, Char } from '../../../../components/Images/Images';

export default function IntroValidation(props) {
    const dispatch = useDispatch();
    const { step } = props.location.state;
    const { session } = useSelector(state => state.trail);
    const [validationCode, setValidationCode] = useState("");
    const [loadingButton, setLoadingButton] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    async function sendValidationCode() {
        setLoadingButton(true);
        await dispatch(TrailActions.getStepStart({
            id_step: step.id_step,
            activation_code: validationCode
        }));
        setLoadingButton(false);
    }

    if (session && session.load === "finished") {
        if (session.error) {
            setSnackMessage(session.messages);
            setSnackOpen(true);
            dispatch(TrailActions.reset());
        } else {
            return <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                state: {
                    origin: 'validation',
                    step: step,
                    stepResult: session.data
                }
            }} />
        }
    }

    return (
        <Grid container id="trail-steps-intro-validation">
            {
                config.learn.trail.vector_images ?
                    <Grid container alignItems="flex-end" justify="center" className="appbar-custom-content">
                        <Container maxWidth="md">
                            <Grid container justify="center">
                                <Grid item xs={12} sm={10} xl={12}>
                                    <Grid container direction="column" alignItems="center" justify="flex-end">
                                        <Typography variant="body1" component="h3" align="center">
                                            {intl.get("TRAIL_STEP_INTRO_VALIDATION_TEXT")}
                                        </Typography>
                                        <Char type="trail-intro-validation" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    :
                    <Grid container alignItems="flex-end" justify="center" className="appbar-custom-content __no-vector">
                        <Container maxWidth="md">
                            <Grid container alignItems="center" justify="center" className="pb-5">
                                <Grid item xs={12} align="center">
                                    <Icon ic="key" color="white" className="icon-xxl" />
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h3" component="h2">
                                        {intl.get("TRAIL_STEP_INTRO_VALIDATION_TITLE")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="body1" component="p">
                                        {intl.get("TRAIL_STEP_INTRO_VALIDATION_TEXT")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
            }

            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    {
                        step.bonus_multiplier
                            ?
                            <Grid item xs={12} sm={10} xl={12}>
                                <Box p={2} className="highlight-box">
                                    <Grid container alignItems="center" className="mb-1">
                                        <Icon ic="star_filled" color="yellow" className="icon-xs mr-1" />
                                        <Typography variant="body1" component="h5">
                                            {intl.get("TRAIL_STEP_INTRO_BONUS_TITLE")}
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body2" component="p">
                                        {intl.get("TRAIL_STEP_INTRO_BONUS_TEXT")}
                                        <span className="badge badge-pill badge-success ml-1">
                                            {Math.round(step.bonus_multiplier) + 'x'}
                                        </span>
                                    </Typography>
                                </Box>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} sm={10} xl={12}>
                        <Typography variant="body2" component="div" className="fs-md">
                            {ReactHtmlParser(step.description)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <Grid container spacing={1} className="flex-nowrap" alignItems="center">
                            <Grid item>
                                <Icon ic="key" className="mr-2" />
                            </Grid>
                            <Grid item className="w-100">
                                <TextField
                                    fullWidth
                                    name="validationCode"
                                    type="text"
                                    label={intl.get("LABEL_VALIDATION_CODE")}
                                    onChange={e => setValidationCode(e.target.value)}
                                    value={validationCode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <div id="bottom-bar" className="relative-bar">
                            {
                                loadingButton
                                    ?
                                    <LoadingBounce size={24} />
                                    :
                                    <Button
                                        id="btn_trail_step_intro_validation"
                                        disabled={validationCode ? false : true}
                                        variant="contained"
                                        onClick={sendValidationCode}
                                        className="btn-block-xs w-50"
                                    >
                                        {intl.get("BTN_SEND")}
                                    </Button>
                            }
                        </div>
                    </Grid>
                    <SnackBar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={snackOpen}
                        message={snackMessage}
                        status="error"
                        time={5}
                        closeSnack={() => setSnackOpen(false)}
                    />
                </Grid>
            </Container>
        </Grid>
    );
}
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../../config';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { actions as TrailActions } from '../../../../redux/ducks/trail';
import ReactHtmlParser from 'react-html-parser';
import { Container, Typography, Grid, Button, Box } from '@material-ui/core';
import SnackBar from '../../../../components/snackBar';
import { LoadingBounce } from '../../../../components/Loading';
import { Icon } from '../../../../components/Images/Images';

export default function IntroExam(props) {
    const dispatch = useDispatch();
    const { step } = props.location.state;
    const { session } = useSelector(state => state.trail);
    const [loadingButton, setLoadingButton] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [loadData, setLoadData] = useState(false);

    async function startQuestion() {
        setLoadingButton(true);
        await dispatch(TrailActions.getStepStart({
            id_step: step.id_step
        }));
        setLoadData(true);
    }

    if (loadData && (session && session.load === "finished")) {
        if (session.error) {
            setSnackMessage(session.messages);
            setSnackOpen(true);
            setLoadingButton(false);
            dispatch(TrailActions.reset());
        } else {
            return <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions`,
                state: {
                    origin: 'check',
                    step: step,
                    session: session.data,
                    question: session.data,
                    quizType: 'CHECK'
                }
            }} />
        }
    }

    return (
        <Grid container id="trail-steps-intro-check">
            {
                config.learn.trail.vector_images ?
                    <Grid container alignItems="flex-end" justify="center" className="appbar-custom-content">
                        <div className="stackbooks"></div>
                    </Grid>
                    :
                    <Grid container alignItems="flex-end" justify="center" className="appbar-custom-content __no-vector">
                        <Container maxWidth="md">
                            <Grid container alignItems="center" justify="center" className="pb-5">
                                <Grid item xs={12} align="center">
                                    <Icon ic="learn_check" color="white" className="icon-xxl" />
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="h3" component="h2">
                                        {intl.get("TRAIL_STEP_INTRO_CHECK_TITLE")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography variant="body1" component="p">
                                        {intl.get("TRAIL_STEP_INTRO_CHECK_TEXT")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
            }
            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    {
                        step.bonus_multiplier
                            ?
                            <Grid item xs={12} sm={10} xl={12}>
                                <Box p={2} className="highlight-box">
                                    <Grid container alignItems="center" className="mb-1">
                                        <Icon ic="star_filled" color="yellow" className="icon-xs mr-1" />
                                        <Typography variant="body1" component="h5">
                                            {intl.get("TRAIL_STEP_INTRO_BONUS_TITLE")}
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body2" component="p">
                                        {intl.get("TRAIL_STEP_INTRO_BONUS_TEXT")}
                                        <span className="badge badge-pill badge-success ml-1">
                                            {Math.round(step.bonus_multiplier) + 'x'}
                                        </span>
                                    </Typography>
                                </Box>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12} sm={10} xl={12}>
                        <Typography variant="body2" component="div" className="fs-md">
                            {ReactHtmlParser(step.description)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <div id="bottom-bar" className="relative-bar">
                            {
                                loadingButton ?
                                    <LoadingBounce size={24} />
                                    :
                                    <Button id="btn_trail_step_intro_check" variant="contained" className="btn-block-xs w-50" onClick={startQuestion}>
                                        {intl.get("BTN_TRAIL_START_2")}
                                    </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
                <SnackBar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackOpen}
                    message={snackMessage}
                    status="error"
                    time={5}
                    closeSnack={() => setSnackOpen(false)}
                />
            </Container>
        </Grid>
    )
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { actions as ExamActions } from '../../../redux/ducks/exam';
import {
    Typography, AppBar, Toolbar, IconButton, Button,
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions
} from '@material-ui/core';
import QrLayout from '../../../components/QrLayout2';
import Quiz from '../../../components/Game/Quiz';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ExamQuestions(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [didMount, setDidMount] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    const question = useSelector(state => state.exam.question);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (origin === "details") {
            setRedirect(false);
        } else {
            setRedirect(true);
        }

        if (didMount) {
            dispatch(ExamActions.getQuestions({
                id_exam: props.match.params.idExam,
            }));
        }

        return () => {
            dispatch(ExamActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match, origin]);

    function goBack() {
        let currentOrigin = "questions";

        return history.push({
            pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
            state: {
                origin: currentOrigin
            }
        })
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    {
                        config.layout.navbar ?
                            <IconButton
                                color="inherit"
                                aria-label="close"
                                edge="start"
                                onClick={() => setOpenAlert(true)}
                            >
                                <Icon ic="close" />
                            </IconButton>
                            : null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {question.data && question.data.name}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const sendTo = async (answer, idQuestion) => {
        setLoadingQuestion(true);

        await dispatch(ExamActions.getExamAnswer({
            id_session: question.data.session.id_session,
            id_exam: question.data.id_exam,
            id_question: idQuestion,
            answer: answer
        }));

        setLoadingQuestion(false);
    }

    return (
        !redirect ?
            <QrLayout id="exam-questions">
                <QrAppBar />
                {
                    !loadingQuestion && (question && question.load === "finished") ?
                        !question.error ?
                            question.data.length !== 0 ?
                                question.data.done && question.data.done === "Y" ?
                                    <Redirect to={{
                                        pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
                                        state: {
                                            origin: 'questions'
                                        }
                                    }} />
                                    :
                                    <Quiz
                                        module="exam"
                                        question={question.data.session}
                                        sendAnswer={sendTo}
                                        serverDate={question.data.server_date}
                                    />
                                :
                                <EmptyContent icon={<Icon ic="play_exam" className="icon-xxl" />} text={intl.get("EMPTY_EXAM")} />
                            :
                            <Redirect to={{
                                pathname: `${props.match.path.replace(/./g, '')}/exam/${props.match.params.idExam}`,
                                state: {
                                    origin: 'questions'
                                }
                            }} />
                        :
                        <LoadingContent />
                }
                <Dialog
                    open={openAlert}
                    onClose={() => setOpenAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {intl.get("EXAM_QUESTION_ALERT_TITLE")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {intl.get("EXAM_QUESTION_ALERT_TEXT")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenAlert(false)} color="primary">
                            {intl.get("BTN_CANCEL")}
                        </Button>
                        <Button onClick={() => goBack()} color="primary">
                            {intl.get("BTN_YES")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/exam`,
                state: {
                    origin: 'questions'
                }
            }} />
    )
}
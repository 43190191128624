import React, { useEffect, useState, useRef } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { gameService } from '../services/game.service';
import { useReactMediaRecorder } from "react-media-recorder";
import * as moment from 'moment';
import md5 from 'crypto-js/md5';
import { generateNonce, b64toBlob } from '../utils/utils';
import { Button, IconButton, Typography, Grid, LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SnackBar from './snackBar';
import { Icon } from './Images/Images';

export default function QrVideoRecorder(props) {
    const { idQuestion, sendAnswer, upload } = props;
    const user = useSelector(state => state.auth.user);
    const [rec, setRec] = useState(false);
    const [startRec, setStartRec] = useState(false);
    const [prevRec, setPrevRec] = useState(false);
    const [countdown, setCountdown] = useState(false);
    const [timer, setTimer] = useState(3);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [blobFile, setBlobFile] = useState(null);
    const [blobFileName, setBlobFileName] = useState("");
    const [loadingFile, setLoadingFile] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    useEffect(() => {
        if (countdown) {
            const interval = setInterval(() => {
                setTimer((time) => time - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [countdown]);

    const {
        status,
        startRecording,
        stopRecording,
        mediaBlobUrl,
        previewStream,
    } = useReactMediaRecorder({ video: true });

    const VideoPreview = ({ stream }) => {
        const videoRef = useRef();

        useEffect(() => {
            if (videoRef.current && stream) {
                videoRef.current.srcObject = stream;
            }
        }, [stream]);

        if (!stream) {
            return null;
        }

        return <video ref={videoRef} autoPlay controls={countdown ? false : true} />;
    };

    const onStartRecording = () => {
        setStartRec(true);
        setCountdown(true);
    }

    async function onStopRecording() {
        const videoBlob = await fetch(mediaBlobUrl).then(r => r.blob());
        const videofile = new Blob([videoBlob], { type: "video/webm" });
        setBlobFile(null);
        setFileName("");
        setBlobFileName("");
        setStartRec(false);
        setPrevRec(true);
        stopRecording();
        getFileRecord(videofile);
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    // Monta o arquivo Blob do vídeo gravado
    async function getFileRecord(v) {
        setBlobFile(v);
        setFileName(md5(user.id_user.concat(moment().format('hh:MM:ss')).concat(generateNonce(10))).toString() + '.webm');
        setBlobFileName(md5(user.id_user.concat(moment().format('hh:MM:ss')).concat(generateNonce(10))).toString() + '.webm');
    }

    // Transforma o vídeo que o usuário enviou da biblioteca no formato Blob
    async function getFileLibrary(e) {
        if (!file) {
            var fileInput = false;

            if (e.target.files[0]) {
                fileInput = true;
            }

            if (fileInput) {
                setFileName(e.target.files[0]);
                setBlobFileName(md5(user.id_user.concat(moment().format('hh:MM:ss')).concat(generateNonce(10))).toString() + '.' + e.target.files[0].name.split('.').pop());
                getBase64(e.target.files[0]).then(
                    data => {
                        setFile(data);
                    }
                );
            }
        }
    }

    async function sendFileUpload() {
        setLoadingFile(true);
        gameService.sendFileUpload({ file: blobFile, file_name: blobFileName }).then(async data => {
            if (data.sent && data.sent === 'ok') {
                sendAnswer(blobFileName, idQuestion);
                setFile(null);
                setFileName("");
                setBlobFile(null);
                setBlobFileName("");
                setLoadingFile(false);
            }
        }).catch(error => {
            setLoadingFile(false);
            setSnackOpen(true);
        })
    }

    if (timer === 0) {
        setCountdown(false);
        setTimer(3);
        startRecording();
    }

    if (file) {
        setBlobFile(b64toBlob(file, fileName.type));
        setFile(null);
    }

    return (
        <>
            {
                !loadingFile ?
                    <Grid container spacing={2} className="answer-type-upload-answer">
                        <Grid item xs={12}>
                            <div className="qr-video-recorder">
                                {
                                    status === "recording" ?
                                        <div className="qr-video-recorder-status">
                                            <Icon ic="live_on" className="icon-xs mr-2" />
                                            <Typography variant="body2" component="p">
                                                {intl.get("LABEL_VIDEO_RECORDING")}
                                            </Typography>
                                        </div>
                                        : null
                                }
                                <div className="qr-video-recorder-button">
                                    {
                                        !rec && (status !== "media_aborted" || status !== "permission_denied" || status !== "no_specified_media_found") ?
                                            <Button
                                                id="btn_video_rec_turn_on"
                                                variant="contained"
                                                onClick={() => setRec(true)}
                                                data-qa="open-input"
                                            >
                                                <Icon ic="live_on" className="icon-xs mr-2" />
                                                {intl.get("LABEL_VIDEO_REC_START")}
                                            </Button>
                                            :
                                            !prevRec ?
                                                countdown ?
                                                    <Typography variant="h1" component="p">
                                                        {timer}
                                                    </Typography>
                                                    :
                                                    !startRec ?
                                                        <IconButton
                                                            id="btn_video_rec_start"
                                                            color="inherit"
                                                            onClick={onStartRecording}
                                                            data-qa="start-recording"
                                                        >
                                                            <Icon ic="start" />
                                                        </IconButton>
                                                        :
                                                        <IconButton
                                                            id="btn_video_rec_stop"
                                                            color="inherit"
                                                            onClick={onStopRecording}
                                                            data-qa="stop-recording"
                                                        >
                                                            <Icon ic="stop" />
                                                        </IconButton>
                                                :
                                                <Button
                                                    type='button'
                                                    variant="contained"
                                                    onClick={() => setPrevRec(false)}
                                                    data-qa='start-replaying'
                                                >
                                                    <Icon ic="replay" className="icon-xs mr-2" />
                                                    {intl.get("LABEL_VIDEO_RECORD_AGAIN")}
                                                </Button>
                                    }
                                </div>
                                <div className="embed-responsive embed-responsive-16by9 rounded-lg position-relative">
                                    {
                                        status === "media_aborted" || status === "permission_denied" || status === "no_specified_media_found" ?
                                            <div className="no-media">
                                                <Icon ic="live_off" className="icon-xxxl" />
                                            </div>
                                            :
                                            !startRec ?
                                                <video src={mediaBlobUrl} autoPlay controls={!rec || countdown ? false : true} />
                                                :
                                                <VideoPreview stream={previewStream} />
                                    }
                                </div>
                            </div>
                        </Grid>
                        {
                            upload ?
                                <Grid item xs={12}>
                                    <div className="divider-word">
                                        <Typography variant="body2" component="span">
                                            {intl.get('DIVIDER_TEXT')}
                                        </Typography>
                                    </div>
                                    <div className="position-relative">
                                        <input
                                            accept="video/mp4,video/x-m4v,video/*"
                                            style={{ display: 'none' }}
                                            id="upload-button-file"
                                            multiple
                                            type="file"
                                            onChange={getFileLibrary}
                                        />

                                        <label htmlFor="upload-button-file">
                                            <Button
                                                variant="outlined"
                                                className="btn-card __answer"
                                                component="div"
                                            >
                                                <Icon ic="upload_outline" className="icon-xs mr-2" />
                                                {intl.get('LABEL_UPLOAD_VIDEO')}
                                            </Button>
                                        </label>
                                    </div>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <Alert icon={fileName ? <Icon ic="video" className="icon-xs" /> : false} severity={fileName ? "success" : "warning"} className="rounded">
                                {fileName ? fileName.name ? fileName.name : fileName : intl.get("EMPTY_UPLOAD_MEDIA")}
                            </Alert>
                        </Grid>
                        <Grid item xs={12} align="center" className="mb-3">
                            <Button
                                disabled={!blobFile}
                                className={`btn-check btn-card __answer${blobFile ? ' active' : ''}`}
                                variant="outlined"
                                onClick={() => sendFileUpload()}
                            >
                                <Icon ic="check" />
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    </Grid>
            }
            <SnackBar
                open={snackOpen}
                message={intl.get("ERROR_UPLOAD_MEDIA")}
                status="error"
                time={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                closeSnack={() => setSnackOpen(false)}
            />
        </>
    );
}

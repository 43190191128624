import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { trailService } from '../../../services/trail.service';
import { useDispatch, useSelector } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { imageParser } from '../../../utils/utils';
import {
    Grid, Divider, Container, InputBase,
    IconButton, AppBar, Toolbar, Typography
} from '@material-ui/core';
import QrLayout from '../../../components/QrLayout2';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from './components/CategoryItem';
import { LoadingCategoryItem, LoadingContent } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ThemesList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const { themes } = useSelector(state => state.trail);
    const [didMount, setDidMount] = useState(false);
    const [loadingSublist, setLoadingSublist] = useState(true);
    const [sublist, setSublist] = useState([]);
    const [filteredTrailList, setFilteredTrailList] = useState(null);
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const limit = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(TrailActions.getThemesList({}));
        }

        return () => {
            setDidMount(false);
            dispatch(TrailActions.reset());
        }
    }, [didMount, dispatch]);

    useEffect(() => {
        if ((themes.data && themes.data.length !== 0) && loadingSublist) {
            setLoadingSublist(false)
            themes.data.forEach(async theme => {
                let ret = await trailService.list({
                    id_course_theme: theme.id_course_theme,
                    limit: limit
                })
                setSublist(sublist => [...sublist, { id_theme: theme.id_course_theme, trails: ret.courses }])
            });
        }
    }, [themes, loadingSublist, limit]);

    useEffect(() => {
        setFilteredTrailList(null);

        async function fetchDataFiltered() {
            let list = await trailService.list({
                filter: search
            });
            setFilteredTrailList(list);
        }

        const timer = setTimeout(() => {
            if (search !== "") {
                fetchDataFiltered()
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [search]);

    function goBack() {
        let currentOrigin = "themes";

        switch (origin) {
            case 'list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('TRAIL_TITLE')}
                        </Typography>
                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(''); setFilteredTrailList(null); setSearchOpen(false) }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const TrailList = (id) => {
        if (sublist && sublist.length !== 0) {
            return (
                sublist.filter(obj => obj.id_theme === id).map(item => (
                    item.trails.map((trail, index) => {
                        return (
                            <CategoryItem
                                key={index}
                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${trail.id_course}`, state: { origin: "themes" } })}
                                bgColor={trail.bg_color}
                                image={trail.image}
                                title={trail.title}
                                currentStars={trail.current_stars}
                                totalStars={trail.total_stars}
                                currentStep={trail.current_step}
                                totalStep={trail.total_step}
                                complete={trail.complete}
                                obligatory={trail.obligatory}
                                available={trail.available}
                                dependency={trail.dependency_course_name ? trail.dependency_course_name : null}
                                dateRelease={trail.date_course_release ? trail.date_course_release : null}
                            />
                        )
                    })
                ))
            )
        } else {
            return (
                <div className="category-grid">
                    <Grid container className="category-list">
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                        <LoadingCategoryItem />
                    </Grid>
                </div>
            )
        }
    }

    const ThemeList = () => {
        return (
            themes.data.map((item, index) => {
                return (
                    parseInt(item.total_course) > 0 ?
                        <div key={index}>
                            {index < themes.data.length - 1
                                ?
                                <Divider variant="middle" />
                                : null
                            }
                            <SectionModule
                                iconType="image"
                                iconBgColor={item.bg_color}
                                icon={imageParser(item.image)}
                                title={item.title}
                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course_theme}/list`, state: { theme: item, origin: "themes" } })}
                            >
                                <SectionCategoryGrid action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course_theme}/list`, state: { theme: item, origin: "themes" } })}>
                                    {TrailList(item.id_course_theme)}
                                </SectionCategoryGrid>
                            </SectionModule>
                        </div>
                        : null
                )
            })
        )
    }

    const FilteredTrail = () => {
        if (filteredTrailList && filteredTrailList.courses.length > 0) {
            return (
                filteredTrailList.courses.map((item, index) => {
                    return (
                        <Grid item key={index} xs={6} sm={3} md={4} lg={3}>
                            <CategoryItem
                                key={index}
                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course}`, state: { origin: "themes" } })}
                                bgColor={item.bg_color}
                                image={item.image}
                                title={item.title}
                                currentStars={item.current_stars}
                                totalStars={item.total_stars}
                                currentStep={item.current_step}
                                totalStep={item.total_step}
                                complete={item.complete}
                                obligatory={item.obligatory}
                                available={item.available}
                                dependency={item.dependency_course_name ? item.dependency_course_name : null}
                                dateRelease={item.date_course_release ? item.date_course_release : null}
                            />
                        </Grid>
                    )
                })
            )
        } else if (filteredTrailList && filteredTrailList.courses.length === 0) {
            return (
                <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            );
        }

        return (
            <Grid container spacing={2} className="pt-2">
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
                <Grid item xs={6} sm={3} md={4} lg={3}>
                    <LoadingCategoryItem />
                </Grid>
            </Grid>
        )
    }

    return (
        <QrLayout id="trail-themes-list">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            {
                themes && themes.load === "finished" ?
                    !themes.error ?
                        themes.data.length !== 0 ?
                            search.length !== 0 ?
                                <Container maxWidth="lg" className="pt-3 pb-3">
                                    <Grid container spacing={2} className="category-list __fluid">
                                        <FilteredTrail />
                                    </Grid>
                                </Container>
                                :
                                <Container maxWidth="lg" className="container-fluid">
                                    <ThemeList />
                                </Container>
                            :
                            <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL")} />
                        :
                        <ErrorContent />
                    :
                    <LoadingContent />
            }
        </QrLayout>
    )
}

import { userService } from '../../services/user.service';

// CONST
export const Types = {
    COLLAPSEOPEN: 'settings/COLLAPSEOPEN',
    BRAND: 'settings/BRAND',
    ROLE: 'settings/ROLE',
    ROLE_FAMILY: 'settings/ROLE_FAMILY',
    ROLE_GROUP: 'settings/ROLE_GROUP',
    POSITION: 'settings/POSITION',
    REGIONAL: 'settings/REGIONAL',
    SUBAREA: 'settings/SUBAREA',
    BOARD: 'settings/BOARD',
    EXECUTIVE_BOARD: 'settings/EXECUTIVE_BOARD',
    MANAGING: 'settings/MANAGING',
    COST_CENTER: 'settings/COST_CENTER',
    EXTERNAL_ENTERPRISE: 'settings/EXTERNAL_ENTERPRISE',
};


// INITIAL STATE
var collapseOpen = false;

const initialState = {
    collapseOpen: false,
    brand: {},
    role: {},
    roleFamily: {},
    roleGroup: {},
    position: {},
    regional: {},
    subarea: {},
    board: {},
    executiveBoard: {},
    managing: {},
    costCenter: {},
    externalEnterprise: {},
}


// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.COLLAPSEOPEN:
            collapseOpen = !collapseOpen;
            return {
                ...state,
                collapseOpen: collapseOpen,
            }
        case Types.BRAND:
            return {
                ...state,
                brand: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ROLE:
            return {
                ...state,
                role: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ROLE_FAMILY:
            return {
                ...state,
                roleFamily: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ROLE_GROUP:
            return {
                ...state,
                roleGroup: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.POSITION:
            return {
                ...state,
                position: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.REGIONAL:
            return {
                ...state,
                regional: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SUBAREA:
            return {
                ...state,
                subarea: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BOARD:
            return {
                ...state,
                board: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.EXECUTIVE_BOARD:
            return {
                ...state,
                executiveBoard: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.MANAGING:
            return {
                ...state,
                managing: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.COST_CENTER:
            return {
                ...state,
                costCenter: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.EXTERNAL_ENTERPRISE:
            return {
                ...state,
                externalEnterprise: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        default:
            return state
    }
}


// ACTIONS
function collapseIsOpen() {
    return dispatch => {
        dispatch({
            type: Types.COLLAPSEOPEN,
        });
    }
}

function getBrandList(params) {
    return dispatch => {
        return userService.getBrandList(params).then(data => {
            dispatch({
                type: Types.BRAND,
                payload: {
                    data: data.brand,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BRAND,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRoleList(params) {
    return dispatch => {
        return userService.getRoleList(params).then(data => {
            dispatch({
                type: Types.ROLE,
                payload: {
                    data: data.roles,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ROLE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRoleFamilyList(params) {
    return dispatch => {
        return userService.getRoleFamilyList(params).then(data => {
            dispatch({
                type: Types.ROLE_FAMILY,
                payload: {
                    data: data.families,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ROLE_FAMILY,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRoleGroupList(params) {
    return dispatch => {
        return userService.getRoleGroupList(params).then(data => {
            dispatch({
                type: Types.ROLE_GROUP,
                payload: {
                    data: data.role_group,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ROLE_GROUP,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getPositionList(params) {
    return dispatch => {
        return userService.getPositionList(params).then(data => {
            dispatch({
                type: Types.POSITION,
                payload: {
                    data: data.position,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.POSITION,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRegionalList(params) {
    return dispatch => {
        return userService.getRegionalList(params).then(data => {
            dispatch({
                type: Types.REGIONAL,
                payload: {
                    data: data.regional,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REGIONAL,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getSubareaList(params) {
    return dispatch => {
        return userService.getSubareaList(params).then(data => {
            dispatch({
                type: Types.SUBAREA,
                payload: {
                    data: data.sub_areas,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SUBAREA,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getBoardList(params) {
    return dispatch => {
        return userService.getBoardList(params).then(data => {
            dispatch({
                type: Types.BOARD,
                payload: {
                    data: data.boards,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BOARD,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getExecutiveBoardList(params) {
    return dispatch => {
        return userService.getExecutiveBoardList(params).then(data => {
            dispatch({
                type: Types.EXECUTIVE_BOARD,
                payload: {
                    data: data.executive_boards,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.EXECUTIVE_BOARD,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getManagingList(params) {
    return dispatch => {
        return userService.getManagingList(params).then(data => {
            dispatch({
                type: Types.MANAGING,
                payload: {
                    data: data.managements,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.MANAGING,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getCostCenterList(params) {
    return dispatch => {
        return userService.getCostCenterList(params).then(data => {
            dispatch({
                type: Types.COST_CENTER,
                payload: {
                    data: data.cost_centers,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COST_CENTER,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getExternalEnterpriseList(params) {
    return dispatch => {
        return userService.getExternalEnterpriseList(params).then(data => {
            dispatch({
                type: Types.EXTERNAL_ENTERPRISE,
                payload: {
                    data: data.enterprise,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.EXTERNAL_ENTERPRISE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

export const actions = {
    collapseIsOpen,
    getBrandList,
    getRoleList,
    getRoleFamilyList,
    getRoleGroupList,
    getPositionList,
    getRegionalList,
    getSubareaList,
    getBoardList,
    getExecutiveBoardList,
    getManagingList,
    getCostCenterList,
    getExternalEnterpriseList
}

import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#5e32ff', // Blue[200]
            main: '#3700FF', // Blue[300]
            dark: '#2600b2', // Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#67f1e3', // Cyan[200]
            main: '#42EEDC', // Cyan[300]
            dark: '#34beb0', // Cyan[400]
            contrastText: '#fff',
        },
        error: {
            light: '#ff6546', // Red[200]
            main: '#FF3F19', // Red[300]
            dark: '#cc3214', // Red[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as GameActions } from '../../../redux/ducks/game';
import { Container, Grid, Typography, CardActionArea } from '@material-ui/core';
import SnackBar from '../../../components/snackBar';
import { LoadingBounce } from '../../../components/Loading';
import { Points, GameHelp } from '../../../components/Images/Images';

export default function Help(props) {
    const { question, gameType } = props;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const help = useSelector(state => state.game.help);
    const menuOpen = useSelector(state => state.qrLayout.menuOpen);
    const sidebarOpen = useSelector(state => state.qrLayout.sidebarOpen);
    const [loadingHelp, setLoadingHelp] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [updateButton, setUpdateButton] = useState(false);
    const [helpId, setHelpId] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (help && help.user_neuros) {
            auth.user.neuros = help.user_neuros;
            localStorage.setItem('auth.state.user', JSON.stringify(auth.user));
        }
    }, [auth.user, help])

    useEffect(() => {
        if (help && help.skip) {
            window.location.reload();
        }
    }, [help])

    async function sendHelp(id) {
        setHelpId(id);

        setLoadingHelp(true);
        try {
            await dispatch(GameActions.useHelp({
                id_help: id,
                id_question: question.question.id_question,
                id_category: question.question.id_category ? question.question.id_category : null,
                id_game_type: gameType,
                eliminated_answers: help && help.answers_to_eliminate ? help.answers_to_eliminate.join() : ""
            }));
        } catch (err) {
            setError(true);
        } finally {
            setLoadingHelp(false);
        }
    }

    if (updateButton) {
        if (!error) {
            let index = question.help.findIndex(item => {
                return item.id_help === helpId;
            })
            question.help[index].help_used = "Y";
        } else {
            setSnackMessage(help.messages);
            setSnackOpen(true);
        }
        setUpdateButton(false);
    }

    function helpIcon(type) {
        switch (type) {
            case "ELIMINATE_SP_1":
                return "eliminate-1"
            case "ELIMINATE_SP_3":
                return "eliminate-2"
            case "SHOW_ANSWERS_HIST_SP":
                return "answered"
            case "SKIP":
                return "skip"
            default:
                return ""
        }
    }

    return (
        <div className={`qr-game-help ${menuOpen ? "__menu-drawer-open" : "__menu-drawer-close"} ${sidebarOpen ? "__sidebar-drawer-open" : "__sidebar-drawer-close"}`}>
            <Container maxWidth="md">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} lg={8}>
                        {
                            !loadingHelp ?
                                <Grid container>
                                    {
                                        question.help.map((item, index) => {
                                            let isDisabled = item.help_used === "Y" || (parseInt(auth.user.neuros) < parseInt(item.help_price)) ? true : false;

                                            return (
                                                <Grid key={index} item xs className="help-item" align="center">
                                                    <CardActionArea className="h-100" onClick={() => { sendHelp(item.id_help); setUpdateButton(true) }} disabled={isDisabled}>
                                                        <div className="d-flex justify-content-center flex-nowrap">
                                                            <GameHelp type={helpIcon(item.help_type)} status={isDisabled ? "disabled" : "active"} />
                                                            <div className="help-item-neuros">
                                                                <Points type="neuros" className="img-xxs mr-1" />
                                                                <Typography variant="h6" component="span">
                                                                    {item.help_price}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <Typography variant="caption" component="p" className="font-weight-bold">
                                                            {item.help_name}
                                                        </Typography>
                                                    </CardActionArea>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                :
                                <Grid container justify="center">
                                    <LoadingBounce size={24} />
                                </Grid>
                        }
                    </Grid>
                </Grid>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status="error"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    time={3}
                    closeSnack={() => setSnackOpen(false)}
                />
            </Container>
        </div>
    )
}
import React from 'react';
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import {
    Dialog, DialogTitle, DialogContent, Grid, Typography,
    Card, CardContent, CardActionArea, IconButton
} from '@material-ui/core';
import { Icon, FreeLevel } from '../../../../components/Images/Images';

export default function DialogLevelQuestion(props) {
    const { open, close, item, path, category, type } = props;
    const { history } = useReactRouter();

    const DifficultyLevel = (id, qis) => {
        switch (id) {
            case "0":
                return (
                    <FreeLevel type="random" text="?" />
                )
            case "1":
                return (
                    <FreeLevel type="easy" text={qis} />
                )
            case "2":
                return (
                    <FreeLevel type="medium" text={qis} />
                )
            case "3":
                return (
                    <FreeLevel type="hard" text={qis} />
                )
            default:
                return <span className="text-white font-weight-bold">{intl.get('FREE_DIALOG_LEVEL_QUESTION_ERROR')}</span>
        }
    }

    function onLevelSelected(e) {
        history.push({
            pathname: `${path.replace(/./g, '')}/free/question`,
            state: {
                idCategory: item.id_category,
                idLevel: e.id_level,
                category: category,
                subcategory: type === "subcategory" ? item.name : ""
            }
        });
    }

    return (
        <Dialog
            id="dialog-level-question"
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" disableTypography>
                <Typography variant="h6" component="h2">
                    {intl.get('FREE_DIALOG_LEVEL_QUESTION_TITLE')}
                </Typography>
                <IconButton aria-label="close" onClick={close}>
                    <Icon ic="close" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} className="mb-3">
                    {
                        open && item.available_levels ?
                            item.available_levels.map((level, index) => {
                                return (
                                    <Grid item xs={6} key={index}>
                                        <Card>
                                            <CardActionArea className="card-level" onClick={() => onLevelSelected(level)} style={{ backgroundColor: level.bgcolor }}>
                                                <CardContent className="card-level-content">
                                                    {DifficultyLevel(level.id_level, level.qi_value)}
                                                    <Typography variant="body1" component="p" className="card-level-text">
                                                        {level.level}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            })
                            : null
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as ClassroomActions } from '../../../redux/ducks/classroom_course';
import { diffMoment, getDateTime } from '../../../utils/utils';
import { Card, CardContent, Container, Typography, Grid, Divider, Link, Button } from '@material-ui/core';
import QrLayout from '../../../components/QrLayout';
import QrCodeReader from './components/QrCodeReader';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import SnackBar from '../../../components/snackBar';
import { LoadingBounce } from '../../../components/Loading';
import { Icon, Logo } from '../../../components/Images/Images';

export default function ClassroomTicket(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const classroom = useSelector(state => state.classroom_course);
    const [didMount, setDidMount] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackStatus, setSnackStatus] = useState('error');
    let isClassFinished = !diffMoment(classroom.ticket.class_info && classroom.ticket.class_info.date_end_training) ? true : false;

    useEffect(() => {
        if (auth.authenticated === (false || null)) {
            history.replace('/');
        }
    }, [auth, history]);

    useEffect(() => {
        setDidMount(true);
        if (didMount) {
            dispatch(ClassroomActions.ticketDetails({
                id_training: props.match.params.idClassroomCourse,
                id_class: props.match.params.idClass
            }));
        }
        return () => {
            dispatch(ClassroomActions.clear());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match])

    async function sendConfirmPresence() {
        setLoadingButton(true);
        await dispatch(ClassroomActions.presence({
            id_training: props.match.params.idClassroomCourse,
            id_class: props.match.params.idClass,
            presence: 'Y'
        }));
        await dispatch(ClassroomActions.ticketDetails({
            id_training: props.match.params.idClassroomCourse,
            id_class: props.match.params.idClass
        }));
        setLoadingButton(false);
    }

    async function getSuccessSubscribed() {
        setLoadingButton(true);
        await dispatch(ClassroomActions.ticketDetails({
            id_training: props.match.params.idClassroomCourse,
            id_class: props.match.params.idClass
        }));
        setLoadingButton(false);
    }

    if (classroom.error) {
        setSnackMessage(classroom.error);
        setSnackOpen(true);
        setSnackStatus('error');
        dispatch(ClassroomActions.clearError({}));
    }

    if (classroom.presence) {
        setSnackMessage(intl.get('CLASSROOM_QRCODE_SUCCESS_TEXT'));
        setSnackOpen(true);
        setSnackStatus('success');
        getSuccessSubscribed();
        dispatch(ClassroomActions.clearSuccess({}));
    }

    return (
        <QrLayout
            id="classroom-ticket"
            appbar="transparent"
            history={history}
            page={intl.get('CLASSROOM_TICKET_TITLE')}
            sub={classroom.ticket.training_name ? classroom.ticket.training_name : classroom.ticket.training_theme}
            leftIcon="close"
        >
            <Container maxWidth="md" className="pt-3 pb-3">
                {
                    classroom.status === "finished" ?
                        classroom.ticket.length !== 0 ?
                            <Grid container justify="center" spacing={2}>
                                <Grid item xs={12} sm={10}>
                                    <Card>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} align="center">
                                                    <Logo type={themeMode === "DARK" ? "main-clean" : "main-color"} className="logo-ticket" />
                                                    <Divider className="mt-3" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" component="h5">
                                                        {classroom.ticket.training_theme}
                                                    </Typography>
                                                    {
                                                        classroom.ticket.training_name ?
                                                            <Typography variant="body2" component="p">
                                                                {classroom.ticket.training_name}
                                                            </Typography>
                                                            : null
                                                    }
                                                    <Typography variant="body2" component="p">
                                                        <strong>{intl.get('CLASSROOM_INSTRUCTOR_LABEL')}:</strong> {classroom.ticket.name_instructor}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider className="mb-3" />
                                                    <Typography variant="body2" component="h6">
                                                        {intl.get('CLASSROOM_TICKET_STUDENT_NAME')}
                                                    </Typography>
                                                    <Typography variant="body1" component="p">
                                                        {`${auth.user.fname} ${auth.user.lname}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider className="mb-3" />
                                                    <Grid container>
                                                        {
                                                            classroom.ticket.class_info.class_name ?
                                                                <Typography variant="body1" component="h2">
                                                                    {classroom.ticket.class_info.class_name}
                                                                </Typography>
                                                                : null
                                                        }
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" component="h6">
                                                                {intl.get('LABEL_START')}
                                                            </Typography>
                                                            <Typography variant="body1" component="p">
                                                                {getDateTime(classroom.ticket.class_info.class_date_start)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" component="h6">
                                                                {intl.get('LABEL_END')}
                                                            </Typography>
                                                            <Typography variant="body1" component="p">
                                                                {getDateTime(classroom.ticket.class_info.class_date_end)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider className="mb-3" />
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" component="h6">
                                                                {intl.get('LABEL_PLACE')}
                                                            </Typography>
                                                            <Typography variant="body1" component="p">
                                                                {classroom.ticket.class_info.local_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="body2" component="h6">
                                                                {intl.get('LABEL_ROOM')}
                                                            </Typography>
                                                            <Typography variant="body1" component="p">
                                                                {classroom.ticket.class_info.room_name}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    classroom.ticket.class_info.local_address ?
                                                        <Grid item xs={12}>
                                                            <Divider className="mb-3" />
                                                            <Typography variant="body2" component="h6">
                                                                {intl.get('LABEL_ADDRESS')}
                                                            </Typography>
                                                            <Typography variant="body1" component="p">
                                                                {classroom.ticket.class_info.local_address && classroom.ticket.class_info.local_address}
                                                                {classroom.ticket.class_info.local_address && classroom.ticket.class_info.local_number && ', '}
                                                                {classroom.ticket.class_info.local_number && classroom.ticket.class_info.local_number}
                                                                {(classroom.ticket.class_info.local_address || classroom.ticket.class_info.local_number) && classroom.ticket.class_info.local_complement && ' - '}
                                                                {classroom.ticket.class_info.local_complement && classroom.ticket.class_info.local_complement}
                                                                {(classroom.ticket.class_info.local_address || classroom.ticket.class_info.local_number || classroom.ticket.class_info.local_complement) && classroom.ticket.class_info.local_neighborhood && ' - '}
                                                                {classroom.ticket.class_info.local_neighborhood && classroom.ticket.class_info.local_neighborhood}
                                                            </Typography>
                                                            <Typography variant="body1" component="p">
                                                                {classroom.ticket.class_info.local_city && classroom.ticket.class_info.local_city}
                                                                {classroom.ticket.class_info.local_city && classroom.ticket.class_info.local_state && ' - '}
                                                                {classroom.ticket.class_info.local_state && classroom.ticket.class_info.local_state}
                                                            </Typography>
                                                            {
                                                                classroom.ticket.class_info.local_zip_code ?
                                                                    <Typography variant="body1" component="p">
                                                                        {classroom.ticket.class_info.local_zip_code}
                                                                    </Typography>
                                                                    : null
                                                            }
                                                            {
                                                                classroom.ticket.class_info.local_phone ?
                                                                    <Typography variant="body1" component="p">
                                                                        {`${intl.get("LABEL_PHONE").substr(0, 3)}: ${classroom.ticket.class_info.local_phone}`}
                                                                    </Typography>
                                                                    : null
                                                            }
                                                            {
                                                                classroom.ticket.class_info.local_information ?
                                                                    <Typography variant="body1" component="p">
                                                                        <Icon ic="info" color="info" className="icon-xxxs" /> {classroom.ticket.class_info.local_information}
                                                                    </Typography>
                                                                    : null
                                                            }
                                                        </Grid>
                                                        : null
                                                }
                                                {
                                                    classroom.ticket.class_info.class_information_subscribed ?
                                                        <Grid item xs={12}>
                                                            <Divider className="mb-3" />
                                                            <Typography variant="body2" component="h6">
                                                                {intl.get('CLASSROOM_TICKET_INFORMATION_SUBSCRIBED')}
                                                            </Typography>
                                                            <Typography variant="body1" component="p">
                                                                {
                                                                    classroom.ticket.class_info.class_information_subscribed.includes("http") ?
                                                                        <Link href={classroom.ticket.class_info.class_information_subscribed} target="_blank" rel="noopener">
                                                                            {classroom.ticket.class_info.class_information_subscribed}
                                                                        </Link>
                                                                        :
                                                                        classroom.ticket.class_info.class_information_subscribed
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        : null
                                                }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={8} md={6} lg={4} align="center">
                                            {
                                                !classroom.ticket.class_info.user_presence && !isClassFinished ?
                                                    loadingButton ?
                                                        <LoadingBounce size={24} color="white" />
                                                        :
                                                        config.app.name_project === "FLEURY" ?
                                                            <Button
                                                                id="btn_classroom_ticket_confirm"
                                                                variant="contained"
                                                                className="btn-block"
                                                                onClick={sendConfirmPresence}
                                                            >
                                                                {intl.get('CLASSROOM_TICKET_CONFIRM_BUTTON')}
                                                            </Button>
                                                            :
                                                            <QrCodeReader
                                                                class_id={classroom.ticket.class_info.class_id}
                                                                id_training={classroom.ticket.id_training}
                                                                successSubscribed={getSuccessSubscribed}
                                                            />
                                                    :
                                                    classroom.ticket.class_info.user_presence === "Y" ?
                                                        <Button
                                                            id="btn_classroom_ticket_confirmed_presence"
                                                            variant="contained"
                                                            className="btn-block"
                                                            disabled={true}
                                                        >
                                                            <Icon ic="check" className="icon-xs mr-2" /> {intl.get('CLASSROOM_TICKET_CONFIRMED_PRESENCE_BUTTON')}
                                                        </Button>
                                                        :
                                                        <Button
                                                            id="btn_classroom_subscription_disabled"
                                                            variant="contained"
                                                            className="btn-block"
                                                            disabled={true}
                                                        >
                                                            {intl.get('CLASSROOM_ABSENT_LABEL')}
                                                        </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <SnackBar
                                    open={snackOpen}
                                    message={snackMessage}
                                    status={snackStatus}
                                    time={4}
                                    closeSnack={() => { setSnackOpen(false); }}
                                />
                            </Grid>
                            :
                            <EmptyContent icon={<Icon ic="play_classroom" className="icon-xxl" />} text={intl.get("EMPTY_CLASSROOM")} />
                        :
                        classroom.errorLoading ?
                            <ErrorContent />
                            :
                            <div className="loading-content">
                                <LoadingBounce color="white" />
                            </div>
                }
            </Container>
        </QrLayout>
    )
}

import React from 'react';
import { imageParser } from '../../../utils/utils';
import { IconButton, Slide, Dialog, DialogContent, Grid, DialogTitle } from '@material-ui/core';
import QrImage from '../../QrImage';
import { Icon } from '../../Images/Images';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogExternalLink(props) {
    const { open, close, image } = props;

    return (
        <Dialog id="dialog-image-question" fullWidth maxWidth="md" open={open} onClose={close} TransitionComponent={Transition}>
            <DialogTitle>
                <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                    <Icon ic="close" />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container justify="center">
                    <Grid item xs={12} align="center">
                        <QrImage
                            src={imageParser(image)}
                            effect="blur"
                            className="img-fluid"
                            alt="Question"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

import React              from 'react'
import useReactRouter     from 'use-react-router'

import Card               from '@material-ui/core/Card'
import CardContent        from '@material-ui/core/CardContent'
import Button             from '@material-ui/core/Button'
import Typography         from '@material-ui/core/Typography'
import Grid               from '@material-ui/core/Grid'
import Divider            from '@material-ui/core/Divider';
import SatisfactionPoll   from '../../../components/ClassroomCourse/SatisfactionPoll';

export default function ClassroomCourseEvaluation(props) {
    const { history } = useReactRouter();
    function handleClick() {
        history.push(`${props.match.url}/ticket`);
    }
    return (
        <Grid
                container
                id="feeds"
                spacing={0}
                justify="center"
                alignItems="center"
                direction="column"
                // className="min-vh-100"
                style ={{minHeight: '80vh'}}
            >
                <Grid item xs={ 12 } sm={ 10 }>
                    <Grid container spacing={ 2 } >
                        <Card>
                            <CardContent
                                className="d-flex flex-column align-items-center">
                                <Typography variant="body2" className="text-light" component="h6">
                                    Pergunta 1 de 5
                                </Typography>
                            </CardContent>
                            <Divider />
                            <CardContent
                                className="d-flex flex-column align-items-center">
                                <Typography variant="h6" className="text-heading" component="h6" paragraph>
                                    Relacionamento entre turma e o facilitador
                                </Typography>
                                <SatisfactionPoll/>
                                <Typography variant="h6" className="text-dark" component="h6" paragraph>
                                    Ótimo
                                </Typography>
                                <Button variant="contained" color="primary" onClick={() => handleClick()}>
                                    Próximo
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

    )
}

import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <g fill="none" fill-rule="evenodd">
                <path fill="#3700FF" d="M0 0h512v512H0z" />
                <path d="M486.4 256c0 127.247-103.153 230.4-230.4 230.4S25.6 383.247 25.6 256 128.753 25.6 256 25.6 486.4 128.753 486.4 256" fill="#3700FF" />
                <path d="M258.672 214.43c14.851 8.081 103.281 56.414 103.281 56.414v87.07c-2.943-1.636-108.675-61.605-111.474-62.933-4.419-2.057-8.177-.218-11.711 2.063-7.943 5.118-75.274 49.399-75.892 49.735v-88.414s67.272-39.756 74.73-43.801a20.638 20.638 0 0 1 21.066-.134" fill="#FFF" />
                <path d="M431.293 243.157c-.57.422-57.385 39.543-57.385 39.543l.091-19.02-6.269-3.433s-87.886-48.012-103.137-56.328a32.698 32.698 0 0 0-16.333-4.371 32.627 32.627 0 0 0-16.636 4.552c-8.53 4.67-72.156 42.282-74.896 43.882l-5.906 3.497v16.994l-21.098-8.199c-3.918 6.21-7.112 14.228-8.652 24.446 7.116 2.197 12 7.111 11.754 12.538-.208 4.61-4.009 8.47-9.585 10.517 2.143 6.269 12.42 34.33-10.245 41.531-19.558 6.21-34.356-12.9-34.356-12.9 17.9-5.123 25.166-17.48 24.59-29.233-5.815-2.442-9.584-6.845-9.37-11.664.271-5.543 5.724-10.005 13.198-11.514v-.032c2.201-9.643 5.847-19.286 13.651-27.426l-31.008-12.926c-3.86-1.632-3.86-7.117 0-8.71l147.136-86.138a17.28 17.28 0 0 1 13.321 0L430.45 234.9c3.588 1.477 3.854 6.237.843 8.257" fill="#A2FF00" />
            </g>
        </svg>
    )
}

export default LogoAppIcon

import * as React from "react"

function LogoAppIcon(props) {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" {...props}>
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="bquest__a">
                    <stop stopColor="#FFF" offset="0%" />
                    <stop stopColor="#EDEDED" offset="100%" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="nonzero">
                <path fill="url(#bquest__a)" d="M0 0h512v512H0z" />
                <path
                    d="M134.488 211.94v101.241c18.67 4.023 24.776 4.699 32.921 4.699 31.9 0 45.134-22.128 45.134-56.323 0-35.534-12.208-55.986-43.437-55.986-7.46 0-16.623 1.01-34.618 6.37m124.215 48.944c0 57.662-33.263 93.871-89.26 93.871-16.96 0-34.618-1.68-58.368-8.383C95.123 341.679 88 333.3 88 317.543v-215.56c15.605-2.68 31.22-5.365 46.488-8.383v81.803c13.58-4.361 30.203-7.375 42.425-7.375 56.334 0 81.79 33.187 81.79 92.857"
                    fill="#000"
                />
                <path
                    d="M376.107 310.501v-101.24c-18.666-4.023-24.777-4.357-32.922-4.357-31.9 0-45.133 21.452-45.133 55.981 0 35.539 12.217 55.653 43.437 55.653 7.47 0 15.615-1.005 34.618-6.037m-124.215-49.278c0-57.328 33.263-93.87 89.26-93.87 16.969 0 34.617 2.013 58.367 8.716 15.952 4.699 23.085 13.078 23.085 28.835v202.482l-46.497 8.378v-69.058c-13.577 4.36-30.204 7.374-42.425 7.374-56.334 0-81.79-33.186-81.79-92.857"
                    fill="#000"
                />
                <path
                    d="M300.737 261.339c0 24.905-20.437 45.074-45.636 45.074-25.198 0-45.63-20.17-45.63-45.074 0-24.9 20.432-45.084 45.63-45.084 25.199 0 45.636 20.183 45.636 45.084"
                    fill="#CB092F"
                />
            </g>
        </svg>
    )
}

export default LogoAppIcon

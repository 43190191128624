import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { identifyIphone, imageParser, ParseHtml, typeFileFormat, typeFileIcon } from '../../../utils/utils';
import { useLastLocation } from 'react-router-last-location';
import SwipeableViews from 'react-swipeable-views';
import QrLayout from '../../../components/QrLayout2';
import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    IconButton,
    MobileStepper,
    Toolbar,
    Typography,
    useTheme
} from '@material-ui/core';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingContent } from '../../../components/Loading';
import DialogExternalLink from './components/DialogExternalLink';
import DialogSelfLink from './components/DialogSelfLink';
import QrVideoPlayer from '../../../components/QrVideoPlayer';
import { Char, Icon, Ribbon } from '../../../components/Images/Images';

export default function StepFlashcards(props) {
    const dispatch = useDispatch();
    const [didMount, setDidMount] = useState(false);
    const { history } = useReactRouter();
    const step = props.location.state && props.location.state.step ? props.location.state.step : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const { session } = useSelector(state => state.trail);
    const [modal, openModal] = useState({ open: false, file: null, fileName: null });
    const [modalSelfLink, openModalSelfLink] = useState({ open: false, file: null });
    const [redirectPage, setRedirectPage] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [animationSlide, setAnimationSlide] = useState(null);
    const theme = useTheme();
    const lastLocation = useLastLocation();

    useEffect(() => {
        setDidMount(true);

        if (lastLocation && lastLocation.pathname.includes("intro")) {
            setRedirectPage(false)
        } else {
            setRedirectPage(true)
        }

        if (didMount) {
            dispatch(TrailActions.getStepStart({
                id_step: step.id_step
            }));
        }

        return () => {
            setDidMount(false);
            dispatch(TrailActions.reset());
        }

    }, [didMount, dispatch, step, lastLocation, origin]);

    function goBack() {
        let currentOrigin = "flashcards";

        switch (origin) {
            case 'intro':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/intro`,
                    state: {
                        step: step,
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {step.step_name}
                        </Typography>
                        {
                            step.step_number_description ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {step.step_number_description}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setAnimationSlide("right");
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setAnimationSlide("left");
    };

    const handleStepChange = (step) => {
        setAnimationSlide((step + 1) === activeStep ? "left" : "right");
        setActiveStep(step);
    };

    const animationSlideChange = (index) => {
        if ((activeStep - 1) === index) {
            return "QrFadeOut"
        }

        if (activeStep === index && animationSlide === "right") {
            return "QrFadeInRight"
        }

        if (activeStep === index && animationSlide === "left") {
            return "QrFadeInLeft"
        }

        if ((activeStep + 1) === index) {
            return "QrFadeOut"
        }

        return ""
    }

    function openEmbed(file) {
        if (identifyIphone() && file.source_file_type.includes('application/pdf')) {
            window.open(file.source);
        } else {
            openModal({ open: true, file: file, fileName: step.step_name })
        }
    }

    const FlashcardContent = (content, index) => {
        switch (Object.keys(content)[0]) {
            case 'content_link':
                return (
                    <Grid container>
                        <Grid item xs={12} align="center">
                            <Button
                                color="primary"
                                variant="contained"
                                className="btn-primary btn-block-xs w-50"
                                target="_blank"
                                href={content.content_link}
                                rel="noopener"
                            >
                                {intl.get("BTN_OPEN_LINK")}
                            </Button>
                        </Grid>
                    </Grid>
                )
            case 'content_file':
                return (
                    <Grid container>
                        <Grid item xs={12} align="center">
                            <Button color="primary" variant="contained" className="btn-primary btn-block-xs w-50"
                                onClick={() => openEmbed(content.content_file)}>
                                {typeFileIcon(content.content_file.source_file_type)}{`${intl.get("BTN_OPEN_FILE")} ${typeFileFormat(content.content_file.source_file_type)}`}
                            </Button>
                        </Grid>
                    </Grid>
                )
            case 'content_video_file':
                return (
                    <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                        <QrVideoPlayer link={content.content_video_file.source} />
                    </div>
                )
            case 'content_text':
                return (
                    <Typography key={index} variant="body1" component="div">
                        {ParseHtml(content.content_text)}
                    </Typography>
                )
            case 'content_video_title':
                return (
                    <Typography key={index} variant="body1" component="div">
                        {ParseHtml(content.content_video_title)}
                    </Typography>
                )
            case 'content_video':
                return (
                    <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                        <QrVideoPlayer link={content.content_video} />
                    </div>
                )
            case 'content_audio':
                return (
                    <Grid container>
                        <Grid item xs={12} align="center">
                            <audio controls>
                                <source src={content.content_video} />
                            </audio>
                        </Grid>
                    </Grid>
                )
            case 'content_video_description':
                return (
                    <Typography key={index} variant="body1" component="div">
                        {ParseHtml(content.content_video_description)}
                    </Typography>
                )
            case 'content_subtitle':
                return (
                    <Typography key={index} variant="body1" component="div">
                        {ParseHtml(content.content_subtitle)}
                    </Typography>
                )
            case 'content_image':
                return (
                    <Grid container>
                        <Grid item xs={12} align="center">
                            <img src={imageParser(content.content_image)} alt="flashcard_image" className="img-fluid rounded" />
                        </Grid>
                    </Grid>
                )
            case 'content_highlighted_text':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body1" component="div">
                            {ParseHtml(content.content_highlighted_text)}
                        </Typography>
                    </Box>
                )
            case 'content_title':
                return (
                    <Typography key={index} variant="h3" component="div">
                        {ParseHtml(content.content_title)}
                    </Typography>
                )
            case 'content_html':
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            {ParseHtml(content.content_html)}
                        </Grid>
                    </Grid>
                )
            case 'content_link_self':
                return (
                    <Grid container>
                        <Grid item xs={12} align="center">
                            <Button color="primary" variant="contained" className="btn-primary btn-block-xs w-50"
                                onClick={() => openModalSelfLink({ open: true, file: content.content_link_self })}>
                                {intl.get("BTN_OPEN_LINK")}
                            </Button>
                        </Grid>
                    </Grid>
                )
            default:
        }
    }

    const Finish = () => {
        return (
            <Grid container className="finish-flashcard" justify="center">
                <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                    {
                        session.data.has_questions === "Y" ?
                            <Ribbon type="straight-filled" text={intl.get("TRAIL_STEP_FLASHCARD_FINISH_TITLE")} />
                            :
                            <Ribbon type="straight-filled" text={intl.get("TRAIL_STEP_FLASHCARD_NO_QUESTIONS_FINISH_TITLE")} />
                    }
                </Grid>
                <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                    {
                        session.data.has_questions === "Y" ?
                            <Char type="trail-pratice" />
                            :
                            <Char type="trail-no-questions" />
                    }
                </Grid>
                <Grid item xs={12} sm={10} lg={8} xl={10} align="center" className="p-3">
                    <Typography variant="body1" component="p">
                        {
                            session.data.has_questions === "Y" ?
                                intl.get("TRAIL_STEP_FLASHCARD_FINISH_TEXT")
                                :
                                intl.get("TRAIL_STEP_FLASHCARD_NO_QUESTIONS_FINISH_TEXT")
                        }
                    </Typography>
                    {
                        session.data.has_questions === "Y" ?
                            <Button id="btn_trail_steps_flashcards_finish" variant="contained" className="btn-block-xs w-50 mt-3"
                                onClick={() => history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions`,
                                    state: {
                                        origin: 'flashcards',
                                        step: step,
                                        session: session.data,
                                    }
                                })}>
                                {intl.get("BTN_TRAIL_START_2")}
                            </Button>
                            :
                            <Button id="btn_trail_steps_flashcards_finish" variant="contained" className="btn-block-xs w-50 mt-3"
                                onClick={() => history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                                    state: {
                                        origin: 'flashcards',
                                        step: step,
                                        session: session.data,
                                        content: 'Y'
                                    }
                                })}>
                                {intl.get("BTN_FINISH")}
                            </Button>
                    }
                </Grid>
            </Grid>
        )
    }

    const Flashcards = () => {
        return (
            <Grid container alignItems="center" justify="center" className="h-100">
                <Grid item xs={12} className="position-relative">
                    <SwipeableViews
                        className="flashcards-list"
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        slideClassName="overflow-hidden"
                    >
                        {
                            session.data.flashcards.map((item, index) => {
                                return (
                                    <Grid key={index} container alignItems="center" justify="center" className="h-100 animated QrFadeIn">
                                        <Grid item xs={12} sm={10} lg={8} xl={10} className="h-100 p-3">
                                            <Card className={`flashcard animated ${animationSlideChange(index)}`}>
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        {
                                                            session.data.flashcards[index].content.map((item, i) => {
                                                                return (
                                                                    <Grid item key={i} xs={12}>
                                                                        {FlashcardContent(item, i)}
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        <Grid container alignItems="center" justify="center" className="h-100 overflow-auto animated QrFadeIn">
                            <Finish />
                        </Grid>
                    </SwipeableViews>
                </Grid>
                <Grid item xs={12} sm={10} lg={8} xl={10} className="w-100">
                    <MobileStepper
                        className="stepper"
                        variant="progress"
                        steps={session.data.flashcards.length + 1}
                        activeStep={activeStep}
                        nextButton={
                            activeStep === (session.data.flashcards.length + 1) - 1 ?
                                session.data.has_questions === "Y" ?
                                    <Button id="btn_trail_steps_flashcards_finish" variant="contained" size="small"
                                        onClick={() => history.push({
                                            pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/questions`,
                                            state: {
                                                origin: 'flashcards',
                                                step: step,
                                                session: session.data,
                                            }
                                        })}>
                                        {intl.get("BTN_TRAIL_START_2")}
                                    </Button>
                                    :
                                    <Button id="btn_trail_steps_flashcards_finish" variant="contained" size="small"
                                        onClick={() => history.push({
                                            pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/result`,
                                            state: {
                                                origin: 'flashcards',
                                                step: step,
                                                session: session.data,
                                                content: 'Y'
                                            }
                                        })}>
                                        {intl.get("BTN_FINISH")}
                                    </Button>
                                :
                                <IconButton onClick={handleNext} disabled={activeStep === (session.data.flashcards.length + 1) - 1} aria-label="Next">
                                    {theme.direction === 'rtl'
                                        ?
                                        <Icon ic="arrow_left" />
                                        :
                                        <Icon ic="arrow_right" />}
                                </IconButton>
                        }
                        backButton={
                            <IconButton onClick={handleBack} disabled={activeStep === 0} aria-label="Back">
                                {theme.direction === 'rtl' ? <Icon ic="arrow_right" /> : <Icon ic="arrow_left" />}
                            </IconButton>
                        }
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        !redirectPage ?
            <QrLayout id="trail-steps-flashcards" hideSidebar>
                {
                    config.layout.navbar ?
                        <QrAppBar />
                        : null
                }
                {
                    session && session.load === "finished" ?
                        !session.error ?
                            session.data.flashcards.length !== 0 ?
                                <Container maxWidth="md" className="container-full">
                                    <Flashcards />
                                    <DialogExternalLink
                                        open={modal.open}
                                        file={modal.file}
                                        fileName={modal.fileName}
                                        close={() => openModal({ open: false })}
                                    />
                                    <DialogSelfLink
                                        open={modalSelfLink.open}
                                        file={modalSelfLink.file}
                                        close={() => openModalSelfLink({ open: false })}
                                    />
                                </Container>
                                :
                                <EmptyContent icon={
                                    <Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL_STEPS_CONTENT")} />
                            :
                            <ErrorContent />
                        :
                        <LoadingContent color="white" />
                }
            </QrLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/intro`,
                state: {
                    origin: 'flashcards'
                }
            }} />
    )
}

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { Grid, Typography, Hidden, IconButton, Button, TextField, Box } from '@material-ui/core';
import IntroLayout from './components/IntroLayout';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function AccessCodeAlternative(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ activation_code: '' });
    const [error, setError] = useState({ activation_code: false });
    const [auth_captcha, setAuth_captcha] = useState('');
    const [captcha, setCaptcha] = useState(config.captcha.type === 'invisible' ? true : false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [didMount, setDidMount] = useState(false);
    const [redirectPage, setRedirectPage] = useState(false);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const redirect = props.location.state && props.location.state.redirect ? props.location.state.redirect : '';
    const login = props.location.state && props.location.state.login ? props.location.state.login : '';
    const auth_password = props.location.state && props.location.state.auth_password ? props.location.state.auth_password : '';

    useEffect(() => {
        setDidMount(true);

        if (origin === "access-code" && redirect === "N") {
            setRedirectPage(false);
        } else {
            setRedirectPage(true);
        }

        return () => {
            setDidMount(false);
        }
    }, [didMount, origin, redirect]);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });

        //Valida os Demais
        if (e.target.value.length !== 0) {
            setError({ ...error, [e.target.name]: false });
        } else {
            if (e.target.value.length === 0) {
                setError({ ...error, [e.target.name]: true });
            }
        }
    }

    // --- Google Captcha ---
    const sendData = () => {
        if (config.captcha.type === 'invisible') {
            window.grecaptcha.reset();
            window.grecaptcha.execute();
        } else {
            dispatch(AuthActions.getCode({
                auth_captcha: auth_captcha,
                pincode: data.activation_code,
                email: login,
            }));
            setLoading(true);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    window.myCallback = onSubmitWithReCAPTCHA;

    async function onSubmitWithReCAPTCHA(token) {
        setAuth_captcha(token);

        if (config.captcha.type === 'invisible') {
            dispatch(AuthActions.getCode(
                {
                    auth_captcha: token,
                    pincode: data.activation_code,
                    email: login,
                    token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N"
                }
            ));
            setLoading(true);
        } else {
            setCaptcha(true);
        }
    }

    // --- end. Google Captcha ---

    useEffect(() => {
        if (loading && auth && auth.code.length !== 0 && auth.status === 'success' && Object.keys(auth.user).length === 0) {
            window.myCallback = onSubmitWithReCAPTCHA2;
            window.grecaptcha.reset();
            window.grecaptcha.execute();

            async function onSubmitWithReCAPTCHA2(token) {
                dispatch(AuthActions.login({
                    activation_code: auth.code,
                    login: login,
                    auth_password: auth_password,
                    auth_provider: 'QRANIO',
                    auth_captcha: token,
                    token_captcha_invisible: config.captcha.type === 'invisible' ? "Y" : "N",
                }));
            }

            auth.status = '';
        }

        if (Object.keys(auth.user).length !== 0 && loading && auth.code.length !== 0) {
            setLoading(false);
            history.push({
                pathname: `${history.location.pathname.replace(/./g, '')}`,
            });
            auth.code = '';
        }

        if (loading && auth.loginError && auth.loginError.length > 0) {
            window.grecaptcha.reset();
            setData({ ...data, activation_code: '' });
            setLoading(false);
            setSnackStatus(auth.status.length !== 0 ? auth.status : snackStatus);
            setSnackOpen(true);
            setSnackMessage(auth.loginError);

            if (auth.status.length !== 0 && auth.status !== 'success') {
                auth.loginError = "";
                auth.status = "";
            }
        }
    }, [auth, auth_captcha, data, dispatch, history, loading, snackStatus, login, auth_password]);


    return (
        !redirectPage ?
            <IntroLayout title={intl.get('INTRO_ACCESS_CODE_ALTERNATIVE_TITLE')} appbarMobile="default">
                <Grid container>
                    <Hidden smDown>
                        <Grid container className="intro-column-nav" justify="center" alignItems="center">
                            <Grid item xs={2}>
                                <IconButton aria-label={intl.get('BTN_BACK')} onClick={() => {
                                    history.push({
                                        pathname: `${history.location.pathname.replace(/./g, '')}/access-code`,
                                        state: {
                                            login: login,
                                            auth_password: auth_password,
                                            redirect: "N"
                                        }
                                    })
                                }}>
                                    <Icon ic="back" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography component="h2" variant="h3" align="center" className="text-dark">
                                    {intl.get('INTRO_ACCESS_CODE_ALTERNATIVE_TITLE')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Hidden>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="p" variant="body2">
                                {intl.get("INTRO_ACCESS_CODE_ALTERNATIVE_TEXT")}
                            </Typography>
                        </Grid>
                        {
                            config.app.name_project === "BQUEST" ?
                                <Grid item xs={12}>
                                    <Box p={2} className="highlight-box">
                                        <Typography variant="body2" component="p">
                                            {intl.get("INTRO_ACCESS_CODE_ALTERNATIVE_TEXT_BQUEST")}
                                        </Typography>
                                    </Box>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <TextField
                                id="activation_code"
                                name="activation_code"
                                autoComplete="activation_code"
                                type="text"
                                label={intl.get('LABEL_ALTERNATIVE_ACCESS_CODE')}
                                fullWidth
                                required
                                variant={config.layout.input_variant}
                                onChange={handleChange}
                                value={data.activation_code}
                                error={error.activation_code}
                                helperText={error.activation_code ? intl.get('ERROR_REQUIRED_FIELD') : null}
                            />
                        </Grid>

                        {/* Captcha */}
                        <Grid container justify="center">
                            {config.captcha.type === 'invisible'
                                ? <div className="g-recaptcha"
                                    data-sitekey={config.captcha.invisible_key}
                                    data-callback="myCallback"
                                    data-size="invisible">
                                </div>
                                : <div className="g-recaptcha mt-3"
                                    data-sitekey={config.captcha.key}
                                    data-callback="myCallback"
                                >
                                </div>
                            }
                        </Grid>

                        {/* Button */}
                        <Grid item xs={12} sm={8}>
                            {
                                !loading
                                    ?
                                    <Button
                                        id="btn_intro_access_code_alternative_send"
                                        disabled={captcha && !error.activation_code && data.activation_code.length !== 0 ? false : true}
                                        variant="contained"
                                        color="primary"
                                        value={"confirmated"}
                                        className="btn-block"
                                        onClick={sendData}
                                    >
                                        {intl.get("BTN_CONFIRM")}
                                    </Button>
                                    :
                                    <Grid container alignItems="center" justify="center">
                                        <LoadingBounce />
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    closeSnack={() => setSnackOpen(false)}
                />
            </IntroLayout>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}`
            }} />
    )
}

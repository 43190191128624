import React from 'react';
import RibbonStraight from './img/ribbons/RibbonStraight';
import Adventure from './img/play/Adventure';
import Boss from './img/play/Boss';
import Championship from './img/play/Championship';
import Classroom from './img/play/Classroom';
import Duels from './img/play/Duels';
import Exam from './img/play/Exam';
import Free from './img/play/Free';
import Onboarding from './img/play/Onboarding';
import PeriodicQuestion from './img/play/PeriodicQuestion';
import Scorm from './img/play/Scorm';
import Trail from './img/play/Trail';
import Library from './img/play/Library';
import EducationalPartnerships from './img/play/EducationalPartnerships';
import ScormDoctors from './img/play/ScormDoctors';
import FreeDifficultyFull from './img/level/FreeDifficultyFull';
import FreeDifficultyQis from './img/level/FreeDifficultyQis';
import MedalGold from './img/svgr/MedalGold';
import MedalSilver from './img/svgr/MedalSilver';
import MedalBronze from './img/svgr/MedalBronze';
import StageMedal from './img/svgr/StageMedal';
import ExamGradeCircle from './img/svgr/ExamGradeCircle';
import ImgPointsQis from './img/svgr/ImgPointsQis';
import ImgPointsXp from './img/svgr/ImgPointsXp';
import ImgPointsNeuros from './img/svgr/ImgPointsNeuros';
import ImgHelpTime from './img/svgr/ImgHelpTime';
import ImgHelpEliminate1 from './img/svgr/ImgHelpEliminate1';
import ImgHelpEliminate2 from './img/svgr/ImgHelpEliminate2';
import ImgHelpAnswered from './img/svgr/ImgHelpAnswered';
import ImgHelpSkip from './img/svgr/ImgHelpSkip';
import ImgRating1 from './img/svgr/ImgRating_1';
import ImgRating2 from './img/svgr/ImgRating_2';
import ImgRating3 from './img/svgr/ImgRating_3';
import ImgRating4 from './img/svgr/ImgRating_4';
import ImgRating5 from './img/svgr/ImgRating_5';

// ==============================================
// POINTS
// ==============================================

export function PointsImg(props) {
    switch (props.type) {
        case 'qis':
            return (
                <ImgPointsQis {...props} />
            )
        case 'xp':
            return (
                <ImgPointsXp {...props} />
            )
        case 'neuros':
            return (
                <ImgPointsNeuros {...props} />
            )
        default:
            return <span className="text-danger font-weight-bold">Imagem não encontrada</span>
    }
}

// ==============================================
// GAME HELP
// ==============================================

export function GameHelpImg(props) {
    switch (props.type) {
        case 'time':
            return (
                <ImgHelpTime {...props} />
            )
        case 'eliminate-1':
            return (
                <ImgHelpEliminate1 {...props} />
            )
        case 'eliminate-2':
            return (
                <ImgHelpEliminate2 {...props} />
            )
        case 'answered':
            return (
                <ImgHelpAnswered {...props} />
            )
        case 'skip':
            return (
                <ImgHelpSkip {...props} />
            )
        default:
            return <span className="text-danger font-weight-bold">Imagem não encontrada</span>
    }
}

// ==============================================
// STAGE MEDAL
// ==============================================

export function StageMedalImg(props) {
    return (
        <StageMedal {...props} />
    )
}

// ==============================================
// EXAM GRADE
// ==============================================

export function ExamGradeImg(props) {
    return (
        <ExamGradeCircle {...props} />
    )
}

// ==============================================
// RIBBON IMAGE
// ==============================================

export function RibbonImg(props) {
    switch (props.type) {
        case 'straight-filled':
            return (
                <RibbonStraight {...props} />
            )
        case 'straight-chalk':
            return (
                <RibbonStraight {...props} />
            )
        default:
            return <span className="text-danger font-weight-bold">Ribbon não encontrado</span>
    }
}

// ==============================================
// MODULES IMAGE
// ==============================================

export function PlayImg(props) {
    switch (props.type) {
        case 'adventure':
            return (
                <Adventure {...props} />
            )
        case 'boss':
            return (
                <Boss {...props} />
            )
        case 'championship':
            return (
                <Championship {...props} />
            )
        case 'classroom':
            return (
                <Classroom {...props} />
            )
        case 'duels':
            return (
                <Duels {...props} />
            )
        case 'exam':
            return (
                <Exam {...props} />
            )
        case 'free':
            return (
                <Free {...props} />
            )
        case 'onboarding':
            return (
                <Onboarding {...props} />
            )
        case 'periodic-question':
            return (
                <PeriodicQuestion {...props} />
            )
        case 'scorm':
            return (
                <Scorm {...props} />
            )
        case 'trail':
            return (
                <Trail {...props} />
            )
        case 'library':
            return (
                <Library {...props} />
            )
        case 'educational-partners':
            return (
                <EducationalPartnerships {...props} />
            )
        case 'scorm-doctors':
            return (
                <ScormDoctors {...props} />
            )
        default:
            return <span className="text-danger font-weight-bold">Módulo não encontrado</span>
    }
}

// ==============================================
// Free Difficulty Level
// ==============================================

export function FreeLevelImg(props) {
    if (props.type === "random") {
        return (
            <FreeDifficultyQis {...props} />
        )
    } else {
        return (
            <FreeDifficultyFull {...props} />
        )
    }
}

// ==============================================
// Medal
// ==============================================

export function MedalImg(props) {
    switch (props.type) {
        case '1': // Gold
            return (
                <MedalGold {...props} />
            )
        case '2': // Silver
            return (
                <MedalSilver {...props} />
            )
        case '3': // Bronze
            return (
                <MedalBronze {...props} />
            )
        default:
            return <span className="text-danger font-weight-bold">Medalha não encontrada</span>
    }
}

// ==============================================
// Rating
// ==============================================

export function RatingImg(props) {
    switch (props.type) {
        case '1': // Péssimo
            return (
                <ImgRating1 {...props} />
            )
        case '2': // Ruim
            return (
                <ImgRating2 {...props} />
            )
        case '3': // Regular
            return (
                <ImgRating3 {...props} />
            )
        case '4': // Bom
            return (
                <ImgRating4 {...props} />
            )
        case '5': // Ótimo
            return (
                <ImgRating5 {...props} />
            )
        default:
            return <span className="text-danger font-weight-bold">Emoji não encontrado</span>
    }
}

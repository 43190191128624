import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Resizer from 'react-image-file-resizer';
import { useSelector } from 'react-redux';
import { gameService } from '../services/game.service';
import md5 from 'crypto-js/md5';
import * as moment from 'moment';
import { generateNonce, b64toBlob } from '../utils/utils';
import { Grid, Button, LinearProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SnackBar from './snackBar';
import { Icon } from './Images/Images';

export default function QrImageUpload(props) {
    const { sendAnswer, idQuestion } = props;
    const user = useSelector(state => state.auth.user);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [blobFile, setBlobFile] = useState(null);
    const [blobFileName, setBlobFileName] = useState("");
    const [loadingFile, setLoadingFile] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    async function getFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            var fileInput = false;

            if (e.target.files[0]) {
                fileInput = true;
            }

            if (fileInput) {
                setFileName(e.target.files[0])
                setBlobFileName(md5(user.id_user.concat(moment().format('hh:MM:ss')).concat(generateNonce(10))).toString() + '.' + e.target.files[0].name.split('.').pop());
                Resizer.imageFileResizer(
                    e.target.files[0],
                    1280,
                    1280,
                    'JPEG',
                    100,
                    0,
                    uri => {
                        setFile(uri)
                    },
                    'base64'
                );
            }
        }
    }

    async function sendFileUpload() {
        setBlobFile(b64toBlob(file, fileName.type));
        setLoadingFile(true);
        gameService.sendFileUpload({ file: blobFile, file_name: blobFileName }).then(async data => {
            if (data.sent && data.sent === 'ok') {
                sendAnswer(blobFileName, idQuestion);
                setFile(null);
                setFileName("");
                setBlobFile(null);
                setBlobFileName("");
                setLoadingFile(false);
            }
        }).catch(error => {
            setLoadingFile(false);
            setSnackOpen(true);
        })
    }

    return (
        <>
            {
                !loadingFile ?
                    <Grid container spacing={2} className="answer-type-upload-answer">
                        <Grid item xs={12}>
                            <div className="position-relative">
                                <input
                                    id="upload-button-file"
                                    accept="image/*"
                                    type="file"
                                    style={{ display: 'none' }}
                                    multiple
                                    onChange={(e) => getFile(e)}
                                />
                                <label htmlFor="upload-button-file">
                                    <Button
                                        variant="outlined"
                                        className="btn-card __answer"
                                        component="div"
                                    >
                                        <Icon ic="upload_outline" className="icon-xs mr-2" />
                                        {intl.get("LABEL_UPLOAD_IMAGE")}
                                    </Button>
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert icon={fileName || blobFileName ? <Icon ic="image" className="icon-xs" /> : false} severity={fileName || blobFileName ? "success" : "warning"} className="rounded">
                                {fileName || blobFileName ? fileName && fileName.name ? fileName.name : blobFileName : intl.get("EMPTY_UPLOAD_MEDIA")}
                            </Alert>
                        </Grid>
                        <Grid item xs={12} align="center" className="mb-3">
                            <Button
                                disabled={!file}
                                className={`btn-check btn-card __answer${file ? ' active' : ''}`}
                                variant="outlined"
                                onClick={() => sendFileUpload()}
                            >
                                <Icon ic="check" />
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    </Grid>
            }
            <SnackBar
                open={snackOpen}
                message={intl.get("ERROR_UPLOAD_MEDIA")}
                status="error"
                time={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                closeSnack={() => setSnackOpen(false)}
            />
        </>
    )
}

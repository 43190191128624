import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#D02143', // Red[200]
            main: '#CB092F', // Red[300]
            dark: '#A30725', // Red[500]
            contrastText: '#fff',
        },
        secondary: {
            light: '#6BBAE7', // Blue[200]
            main: '#5BB3E5', // Blue[300]
            dark: '#51A1CD', // Blue[400]
            contrastText: '#fff',
        },
        error: {
            light: '#C9C9CB', // Gray[200]
            main: '#939397', // Gray[300]
            dark: '#666669', // Gray[400]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)

import * as React from "react"

function CharSurveyFinished(props) {
    return (
        <svg width={180} height={180} viewBox="0 0 180 180" {...props}>
            <defs>
                <filter
                    x="-7.2%"
                    y="-7.1%"
                    width="114.5%"
                    height="114.3%"
                    filterUnits="objectBoundingBox"
                    id="b"
                >
                    <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation={2}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <circle id="a" cx={90} cy={90} r={90} />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="c" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <use fillOpacity={0.4} fill="#FFF" xlinkHref="#a" />
                <g filter="url(#b)" mask="url(#c)">
                    <path
                        d="M184.507 148.83c-12.546-18.2-21.113-21.901-26.314-27.454-5.202-5.552-8.568-25.602-18.665-26.836-10.097-1.234-37.023 21.284-37.023 21.284-5.814 3.393-9.791 10.796-11.933 16.04-2.142 5.244-2.754 8.946-2.754 8.946s-10.097 8.945-15.605 15.731c-4.11 5.065-10.775 15.625-16.055 22.453h139.307c-2.646-11.759-6.24-23.318-10.958-30.164"
                        fill="#1B4E61"
                    />
                    <path
                        d="m103.174 138.401 1.02 22.844s12.851-8.26 21.113-16.521c8.261-8.261 16.217-20.193 16.217-20.193l-38.35 13.87Z"
                        fill="#FAF8DC"
                    />
                    <path
                        d="M95.831 18.67c-4.692-2.447-9.383-6.527-16.523 0-7.14 6.527-11.015 2.448-14.483 7.139-3.468 4.691-1.836 9.995-6.323 12.442-4.488 2.448-9.587 5.304-9.18 11.83.408 6.528 1.225 8.363-4.283 12.443-5.508 4.08-6.324 11.218-2.856 14.074 3.468 2.855 8.16 2.447 8.16 2.447s-3.264 6.935 2.855 10.81c6.12 3.876 8.975-1.223 11.831 1.02 2.856 2.244 4.488 8.975 14.279 4.692C89.1 91.283 95.831 18.67 95.831 18.67"
                        fill="#FFB600"
                    />
                    <path
                        d="M127.041 86.388s-4.079 10.402-8.16 14.687c-4.08 4.281-10.607 10.605-23.458 10.605-14.075-1.018-22.847-14.074-24.07-37.529-1.224-23.458 8.333-40.06 19.377-45.08 4.491-2.039 17.688-5.587 30.192 8.772 15.093 17.337 6.12 48.545 6.12 48.545"
                        fill="#F4A467"
                    />
                    <path
                        d="m88.721 63.815 2.52 16.543-7.822 1.35c-2.015.347-3.59-1.587-2.668-3.278l7.97-14.615Zm39.748 72.342c-13.962 6.233-25.296 2.244-25.296 2.244l-7.75-26.721c12.851 0 19.377-6.324 23.457-10.605 4.082-4.285 8.161-14.687 8.161-14.687l16.522 34.675s-3.672 9.995-15.094 15.094"
                        fill="#EA8B49"
                    />
                    <path
                        d="M76.963 74.509c-4.529 0-8.213-3.684-8.213-8.212s3.684-8.211 8.213-8.211c4.528 0 8.212 3.683 8.212 8.211 0 4.528-3.684 8.212-8.212 8.212m22.437 0c-4.527 0-8.212-3.684-8.212-8.212s3.685-8.211 8.213-8.211c4.528 0 8.212 3.683 8.212 8.211 0 4.528-3.684 8.212-8.212 8.212"
                        fill="#080202"
                    />
                    <path
                        d="m84.672 67.017-.528-1.44c2.608-.956 7.213-1.715 8.464.318l-1.306.804c-.422-.686-3.282-.91-6.63.318m22.36.024-.372-1.488c5.275-1.318 17.73-2.817 22.255-1.31l-.485 1.456c-1.806-.602-5.437-.72-10.226-.333-4.014.325-8.399.982-11.172 1.675"
                        fill="#080202"
                    />
                    <path
                        d="M164.982 79.045c-2.448-3.263-3.876-8.975-1.224-13.054 2.652-4.08 2.04-9.79-2.856-11.626-4.896-1.836-6.935-4.08-6.12-9.383.816-5.303-.204-11.626-6.119-12.85-5.915-1.224-5.915-5.507-7.955-10.198-2.04-4.692-10.262-7.648-15.91-5.711-7.14 2.447-7.549 2.243-13.26-2.244-4.736-3.721-13.463-.612-18.359 3.671l-2.448 11.422s0 3.06 5.305 7.752c5.303 4.69 11.83 5.507 15.706 7.546 3.876 2.04 4.284 5.711 5.1 10.199.815 4.487 6.323 4.283 6.731 7.75.408 3.468-3.264 6.12-3.468 11.015-.204 4.896 3.281 5.319 3.672 10.199.408 5.099-2.448 1.835-2.856 9.586-.408 7.75 4.692 11.83 10.811 10.81 3.268-.544 6.313-2.287 7.752-3.467 2.477 2.898 7.072 4.471 10.403 3.672 3.444-.827 5.297-3.865 6.018-6.832.596-2.455 2.275-4.51 4.525-5.66 4.168-2.129 9.278-6.296 4.552-12.597"
                        fill="#FFB600"
                    />
                    <path
                        d="M79.92 66.603a1.428 1.428 0 1 1-2.856 0 1.428 1.428 0 0 1 2.856 0m23.05.204a1.428 1.428 0 1 1-2.856 0 1.428 1.428 0 0 1 2.857 0"
                        fill="#080202"
                    />
                    <path
                        d="M83.038 89.558s1.336-2.063 4.612-2.675c3.275-.612 6.812 1.528 6.812 1.528s-1.267 3.436-5.027 3.655c-3.76.218-6.397-2.508-6.397-2.508"
                        fill="#EA8B49"
                    />
                    <path
                        d="M50.231 140.457a28.654 28.654 0 0 0-.938-1.545c-.078-.12-.34-.483-.534-.753a9.389 9.389 0 0 0-.192-.227c-.377-.42-.77-.824-1.183-1.209l-.194-.172c-.281-.187-.555-.396-.842-.565-.29-.17-.611-.309-.915-.465-.088-.029-.174-.061-.262-.088-.482-.147-.97-.25-1.459-.36a16.1 16.1 0 0 0-1.725-.036l-.136.005c-.947.193-1.88.407-2.803.705-.37.119-.736.283-1.106.414-.12.06-.243.116-.362.177-1.029.53-2.264 1.13-3.105 1.938l1.083-.823c-.231.192-.457.39-.678.593-.395.362-.752.754-1.12 1.14l-.055.088c-.09.141-.237.343-.382.561-.052.157-.11.311-.154.47-.02.066-.034.133-.052.2l-.114.823a7.328 7.328 0 0 1-.252 1.984c-1.113 9.234-1.93 22.365-2.445 36.02h26.432c-2.497-14.91-5.116-30.9-6.38-38.643l-.127-.232"
                        fill="#F4A467"
                    />
                    <path
                        d="M109.077 47.977a10.478 10.478 0 0 0-3.741-2.829 16.366 16.366 0 0 0-6.211-1.874 16.209 16.209 0 0 0-4.468.208c-1.036.19-2.923.424-3.528 1.435-.514.857-.05 1.972.795 2.386 1.24.608 3.2.041 4.548.066a29.36 29.36 0 0 1 11.174 2.462c1.222.533 2.33-.788 1.431-1.854m-29.937-.42a15.21 15.21 0 0 1 2.082-.587c-.382.167-1.412.41-1.542.444l-.54.143m-1.238-2.089c-.076.036-.148.075-.223.111a16.745 16.745 0 0 0-5.94 4.117c-.986 1.062.404 2.545 1.505 1.797.122.006.25 0 .388-.037 2.744-.725 5.655-1.255 8.325-2.215 1.64-.59 2.608-2.293 1.347-3.81-1.43-1.719-3.742-.746-5.402.037"
                        fill="#FFB600"
                    />
                </g>
            </g>
        </svg>
    )
}

export default CharSurveyFinished

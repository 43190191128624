import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from "../../config";
import useReactRouter from 'use-react-router';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    AppBar, Toolbar, IconButton, Typography, useMediaQuery,
    Container, ListItem, ListItemText, List, ListItemIcon, Collapse
} from '@material-ui/core';
import QrLayout from '../../components/QrLayout2';
import { Icon } from '../../components/Images/Images';

const ListItemLink = React.forwardRef((props, ref) => (
    <NavLink {...props} activeClassName="selected" innerRef={ref} exact />
));

export default function MenuList(props) {
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [openAbout, setOpenAbout] = useState(false);
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_FAQ');
    let faq = auth.modules && auth.modules.FAQ;

    useEffect(() => {
        if (auth.authenticated === (false || null)) {
            history.replace('/');
        }
    }, [auth, history]);

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('FAQ_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("FAQ_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <QrLayout
            id="faq"
            bottomNavigation={bottomMenuItem}
        >
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <div className={`${bottomMenuItem ? "page-bottom-menu" : ""}`}>
                <Container maxWidth="md" className="container-list pt-2 pb-4">
                    <List component="nav">
                        {
                            // COMO JOGAR
                            faq.HOW_TO_PLAY && faq.HOW_TO_PLAY === 'Y' ?
                                <ListItem button component={ListItemLink} to="/faq/how-to-play">
                                    <ListItemIcon>
                                        <Icon ic="brain_filled" color="light" />
                                    </ListItemIcon>
                                    <ListItemText primary={intl.get('FAQ_HOW_TO_PLAY_TITLE')} secondary={intl.get('FAQ_HOW_TO_PLAY_DESCRIPTION')} />
                                </ListItem>
                                : null
                        }
                        {
                            // SOBRE O PERFIL
                            faq.PROFILE && faq.PROFILE === 'Y' ?
                                <ListItem button component={ListItemLink} to="/faq/my-profile">
                                    <ListItemIcon>
                                        <Icon ic="user_filled" color="light" />
                                    </ListItemIcon>
                                    <ListItemText primary={intl.get('FAQ_MY_PROFILE_TITLE')} secondary={intl.get('FAQ_MY_PROFILE_DESCRIPTION')} />
                                </ListItem>
                                : null
                        }
                        {
                            // PERGUNTAS FREQUENTES
                            faq.FREQUENTLY_ASKED && faq.FREQUENTLY_ASKED === 'Y' ?
                                <ListItem button component={ListItemLink} to="/faq/questions">
                                    <ListItemIcon>
                                        <Icon ic="faq" color="light" />
                                    </ListItemIcon>
                                    <ListItemText primary={intl.get('FAQ_FREQUENTLY_ASKED_TITLE')} secondary={intl.get('FAQ_FREQUENTLY_ASKED_DESCRIPTION')} />
                                </ListItem>
                                : null
                        }
                        {
                            // CONTATO
                            faq.CONTACT && faq.CONTACT === 'Y' ?
                                <ListItem button component={ListItemLink} to="/faq/contact">
                                    <ListItemIcon>
                                        <Icon ic="new_message" color="light" />
                                    </ListItemIcon>
                                    <ListItemText primary={intl.get('FAQ_CONTACT_TITLE')} secondary={intl.get('FAQ_CONTACT_DESCRIPTION')} />
                                </ListItem>
                                : null
                        }
                        {
                            // INFO
                            (faq.ABOUT && faq.ABOUT === 'Y') || (faq.PRIVACY_STATEMENT && faq.PRIVACY_STATEMENT === 'Y') || (faq.TERMS_OF_USE && faq.TERMS_OF_USE === 'Y') ?
                                <>
                                    <ListItem button onClick={() => setOpenAbout(!openAbout)}>
                                        <ListItemIcon>
                                            <Icon ic="about" color="light" />
                                        </ListItemIcon>
                                        <ListItemText primary={intl.get('FAQ_INFO_TITLE')} secondary={intl.get('FAQ_INFO_DESCRIPTION')} />
                                        {
                                            openAbout ?
                                                <Icon ic="chevron_up" color="light" className="icon-xxxs" />
                                                :
                                                <Icon ic="chevron_down" color="light" className="icon-xxxs" />
                                        }
                                    </ListItem>
                                    <Collapse in={openAbout}>
                                        {
                                            // TERMOS DE USO
                                            faq.TERMS_OF_USE && faq.TERMS_OF_USE === 'Y' ?
                                                <ListItem button component={ListItemLink} to="/faq/terms-of-use" className="collapse-item">
                                                    <ListItemText primary={intl.get('FAQ_INFO_TERMS_TITLE')} />
                                                </ListItem>
                                                : null
                                        }
                                        {
                                            // POLÍTICAS DE PRIVACIDADE
                                            faq.PRIVACY_STATEMENT && faq.PRIVACY_STATEMENT === 'Y' ?
                                                <ListItem button component={ListItemLink} to="/faq/privacy-policies" className="collapse-item">
                                                    <ListItemText primary={intl.get('FAQ_INFO_PRIVACY_POLICIES_TITLE')} />
                                                </ListItem>
                                                : null
                                        }
                                    </Collapse>
                                </>
                                : null
                        }
                    </List>
                </Container>
            </div>
        </QrLayout>
    )
}
import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Daily from './list.daily';
import Weekly from './list.weekly';
import Monthly from './list.monthly';
import Question from './question';
import Result from './question.result';

export default function PeriodicQuestion(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.PERIODIC_QUESTION && auth.modules.PERIODIC_QUESTION.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        auth.authenticated &&
        <Switch>
            <Route path={`${props.match.path}`} exact
                component={
                    (props) =>
                        auth.modules.PERIODIC_QUESTION.SCHEDULE === "DAILY" ?
                            <Daily {...props} />
                            : auth.modules.PERIODIC_QUESTION.SCHEDULE === "WEEKLY" ?
                                <Weekly {...props} />
                                : auth.modules.PERIODIC_QUESTION.SCHEDULE === "MONTHLY" ?
                                    <Monthly {...props} />
                                    :
                                    <Daily {...props} />
                }
            />
            <Route path={`${props.match.path}/:idPeriodic/question`} exact component={
                (props) => <Question {...props} />}
            />
            <Route path={`${props.match.path}/:idPeriodic/question/result`} exact component={
                (props) => <Result {...props} />}
            />
        </Switch>
    )
}
import React, { useState, useEffect, useRef } from 'react';
import config from '../config';
import useReactRouter from 'use-react-router';
import { useDispatch } from 'react-redux';
import { actions as AuthActions } from '../redux/ducks/auth';
import { Typography, AppBar, Toolbar, Tab, Tabs, IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Icon } from '../components/Images/Images';

export default function Appbar(props) {
    const { goBack, customNavigation, rightIcon, bottomNavigation, appbar, sub, page, subIcon, leftIcon, color, complement, changeTab, tabValue, tabLabel, type } = props;
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const appbarRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [appbarHeight, setAppbarHeight] = useState(0);
    const [show, setShow] = useState(false);

    function navigationHandler() {
        if (goBack) {
            history.push({ pathname: goBack, state: props.history.location.state })
        } else if (customNavigation) {
            dispatch(customNavigation)
        } else {
            if (isMobile && type && type === "intro") {
                dispatch(AuthActions.showSmallscreenLogin());
            }
            history.goBack()
        }
    }

    function a11yProps(index) {
        return {
            id: `qr-tab-${index}`,
            'aria-controls': `qr-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        setAppbarHeight(appbarRef && appbarRef.current && appbarRef.current.clientHeight)

        function handleScroll() {
            const position = window.pageYOffset;
            setScrollPosition(position);

            setShow(scrollPosition > (appbarHeight + 16));
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition, appbarHeight]);

    function RenderAppBar() {
        switch (appbar) {
            case 'none':
                return null;
            case 'main':
                return (
                    <AppBar ref={appbarRef} position={show ? 'sticky' : 'static'} className={`appbar ${show ? 'appbar-default' : 'appbar-main'}`}>
                        <Toolbar>
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h4" noWrap>
                                    {page}
                                </Typography>
                                {sub
                                    ?
                                    <div className="w-100 d-flex overflow-hidden">
                                        {subIcon
                                            ?
                                            <div className="ic-subtitle mr-1">
                                                {subIcon}
                                            </div>
                                            : null}
                                        <Typography variant="body2" component="p" noWrap>
                                            {sub}
                                        </Typography>
                                    </div>
                                    : null
                                }
                            </div>
                            {rightIcon}
                        </Toolbar>
                    </AppBar >
                )
            case 'overlay':
                return (
                    <AppBar ref={appbarRef} position={show ? 'sticky' : 'absolute'} className={`appbar ${show ? 'appbar-default' : 'appbar-overlay'}`}>
                        <Toolbar>
                            {!bottomNavigation && leftIcon !== "none"
                                ? <IconButton
                                    color="inherit"
                                    aria-label="back"
                                    edge="start"
                                    onClick={() => navigationHandler()}
                                >
                                    {
                                        leftIcon ?
                                            <Icon ic={leftIcon} />
                                            : <Icon ic="back" />
                                    }
                                </IconButton>
                                : null
                            }
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h4" noWrap>
                                    {page}
                                </Typography>
                                {sub
                                    ?
                                    <div className="w-100 d-flex overflow-hidden">
                                        {subIcon
                                            ?
                                            <div className="ic-subtitle mr-1">
                                                {subIcon}
                                            </div>
                                            : null}
                                        <Typography variant="body2" noWrap>
                                            {sub}
                                        </Typography>
                                    </div>
                                    : null
                                }
                            </div>
                            {rightIcon}
                        </Toolbar>
                    </AppBar >
                )
            case 'transparent':
                return (
                    <AppBar ref={appbarRef} position="absolute" className="appbar appbar-transparent" color={color ? color : "primary"}>
                        <Toolbar>
                            {!bottomNavigation && leftIcon !== "none"
                                ? <IconButton
                                    color="inherit"
                                    aria-label="back"
                                    edge="start"
                                    onClick={() => navigationHandler()}
                                >
                                    {
                                        leftIcon ?
                                            <Icon ic={leftIcon} />
                                            : <Icon ic="back" />
                                    }
                                </IconButton>
                                : null
                            }
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h4" noWrap>
                                    {page}
                                </Typography>
                                {sub
                                    ?
                                    <div className="w-100 d-flex overflow-hidden">
                                        {subIcon
                                            ?
                                            <div className="ic-subtitle mr-1">
                                                {subIcon}
                                            </div>
                                            : null}
                                        <Typography variant="body2" noWrap>
                                            {sub}
                                        </Typography>
                                    </div>
                                    : null
                                }
                            </div>
                            {rightIcon}
                        </Toolbar>
                    </AppBar >
                )
            case 'fixed':
                return (
                    <AppBar ref={appbarRef} className="appbar appbar-default" position="sticky" color={color ? color : "primary"}>
                        <Toolbar>
                            {!bottomNavigation && leftIcon !== "none"
                                ? <IconButton
                                    color="inherit"
                                    aria-label="back"
                                    edge="start"
                                    onClick={() => navigationHandler()}
                                >
                                    {
                                        leftIcon ?
                                            <Icon ic={leftIcon} />
                                            : <Icon ic="back" />
                                    }
                                </IconButton>
                                : null
                            }
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h4" component="h6" noWrap>
                                    {page}
                                </Typography>
                                {sub
                                    ?
                                    <div className="w-100 d-flex overflow-hidden">
                                        {subIcon
                                            ?
                                            <div className="ic-subtitle mr-1">
                                                {subIcon}
                                            </div>
                                            : null}
                                        <Typography variant="body2" noWrap>
                                            {sub}
                                        </Typography>
                                    </div>
                                    : null
                                }
                            </div>
                            {rightIcon}
                        </Toolbar>
                    </AppBar >
                )
            case 'tabs':
                return (
                    <AppBar ref={appbarRef} className="appbar appbar-default" position="sticky" color={color ? color : "primary"}>
                        <Toolbar>
                            {!bottomNavigation && leftIcon !== "none"
                                ? <IconButton
                                    color="inherit"
                                    aria-label="back"
                                    edge="start"
                                    onClick={() => navigationHandler()}
                                >
                                    {
                                        leftIcon ?
                                            <Icon ic={leftIcon} />
                                            : <Icon ic="back" />
                                    }
                                </IconButton>
                                : null
                            }
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h4" component="h6" noWrap>
                                    {page}
                                </Typography>
                                {sub
                                    ?
                                    <div className="w-100 d-flex overflow-hidden">
                                        {subIcon
                                            ?
                                            <div className="ic-subtitle mr-1">
                                                {subIcon}
                                            </div>
                                            : null}
                                        <Typography variant="body2" noWrap>
                                            {sub}
                                        </Typography>
                                    </div>
                                    : null
                                }
                            </div>
                            {rightIcon}
                        </Toolbar>
                        <Tabs value={tabValue} onChange={changeTab} variant={isMobile ? "fullWidth" : "standard"} aria-label="Qranio Tab" centered>
                            {
                                tabLabel.map((tab, key) => {
                                    return <Tab key={key} label={tab} {...a11yProps(key)} />
                                })
                            }
                        </Tabs>
                    </AppBar >
                )
            case 'tabs-main':
                return (
                    <AppBar ref={appbarRef} position={show ? 'sticky' : 'static'} className={`appbar ${show ? 'appbar-default' : 'appbar-main'} __tabs`}>
                        <Toolbar>
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h4" noWrap>
                                    {page}
                                </Typography>
                                {sub
                                    ?
                                    <div className="w-100 d-flex overflow-hidden">
                                        {subIcon
                                            ?
                                            <div className="ic-subtitle mr-1">
                                                {subIcon}
                                            </div>
                                            : null}
                                        <Typography variant="body2" component="p" noWrap>
                                            {sub}
                                        </Typography>
                                    </div>
                                    : null
                                }
                            </div>
                            {rightIcon}
                        </Toolbar>
                        <Tabs value={tabValue} onChange={changeTab} variant={isMobile ? "fullWidth" : "standard"} aria-label="Qranio Tab" centered>
                            {
                                tabLabel.map((tab, key) => {
                                    return <Tab key={key} label={tab} {...a11yProps(key)} />
                                })
                            }
                        </Tabs>
                    </AppBar >
                )
            case 'titleless':
                return (
                    <AppBar ref={appbarRef} className="appbar appbar-default" position="sticky" color={color ? color : "primary"}>
                        <Toolbar>
                            {!bottomNavigation && leftIcon !== "none"
                                ? <IconButton
                                    color="inherit"
                                    aria-label="back"
                                    edge="start"
                                    onClick={() => navigationHandler()}
                                >
                                    {
                                        leftIcon ?
                                            <Icon ic={leftIcon} />
                                            : <Icon ic="back" />
                                    }
                                </IconButton>
                                : null
                            }
                            <div className="w-100">
                            </div>
                            {rightIcon}
                        </Toolbar>
                    </AppBar >
                )
            default:
                return (
                    <AppBar ref={appbarRef} className="appbar appbar-default" position="sticky" color={color ? color : "primary"}>
                        <Toolbar>
                            {!bottomNavigation && leftIcon !== "none"
                                ? <IconButton
                                    color="inherit"
                                    aria-label="back"
                                    edge="start"
                                    onClick={() => navigationHandler()}
                                >
                                    {
                                        leftIcon ?
                                            <Icon ic={leftIcon} />
                                            : <Icon ic="back" />
                                    }
                                </IconButton>
                                : null
                            }
                            <div className="w-100 overflow-hidden">
                                <Typography variant="h4" noWrap>
                                    {page}
                                </Typography>
                                {sub
                                    ?
                                    <div className="w-100 d-flex overflow-hidden">
                                        {subIcon
                                            ?
                                            <div className="ic-subtitle mr-1">
                                                {subIcon}
                                            </div>
                                            : null}
                                        <Typography variant="body2" noWrap>
                                            {sub}
                                        </Typography>
                                    </div>
                                    : null
                                }
                            </div>
                            {rightIcon}
                        </Toolbar>
                        {complement ? complement : null}
                    </AppBar >
                )
        }
    }

    return (
        config.layout.navbar ?
            <RenderAppBar />
            : null
    )
}
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { notificationService } from './services/notification.service';
import project from './config';

// Inicializa o Firebase com as configurações do projeto
// console.log('[firebase.js:6] Iniciando Firebase com as configurações:', project.firebase.config);
const firebaseApp = initializeApp(project.firebase.config);
const messaging = getMessaging(firebaseApp);
const analytics = getAnalytics(firebaseApp);

// console.log('[firebase.js:10] Firebase inicializado com sucesso.');

// Função para registrar o Service Worker e garantir que ele está ativo antes de solicitar permissões
const ensureServiceWorkerActive = async () => {
    if ('serviceWorker' in navigator) {
        // console.log('[firebase.js:15] Tentando garantir que o Service Worker está ativo.');
        const registration = await navigator.serviceWorker.ready;
        // console.log('[firebase.js:17] Service Worker ativo:', registration);
        return registration;
    } else {
        throw new Error('Service Workers não são suportados neste navegador.');
    }
};

// Solicita permissão para notificações somente se o usuário estiver autenticado e se o token ainda não foi gerado
export const askUserPermission = async () => {
    try {
        // Garante que o Service Worker está ativo antes de solicitar permissões
        const registration = await ensureServiceWorkerActive();

        // Verifica se o usuário está autenticado e se há um usuário válido armazenado no localStorage
        const isAuthenticated = JSON.parse(localStorage.getItem('auth.state.authenticated'));
        const user = JSON.parse(localStorage.getItem('auth.state.user'));
        const existingToken = localStorage.getItem('auth.state.notification.token');

        // Verifica se o usuário está autenticado, se há um usuário válido e se o token já foi gerado
        if (isAuthenticated && user) {
            // console.log('[firebase.js:28] Usuário autenticado, solicitando permissão de notificação.');

            // Solicita permissão para notificações diretamente do navegador
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                // console.log('[firebase.js:32] Permissão concedida com sucesso.');

                // Obtém o token usando a chave VAPID correta do projeto
                const token = await getToken(messaging, { vapidKey: project.firebase.vapidKey, serviceWorkerRegistration: registration });
                // console.log('[firebase.js:36] Token obtido:', token);

                // Verifica se o token já existe no localStorage para evitar sobrescrever desnecessariamente
                if (existingToken !== token) {
                    await notificationService.setUserToken({
                        id_register: token,
                    });
                    // console.log('[firebase.js:40] Token enviado para o serviço de notificação.');

                    localStorage.setItem('auth.state.notification.token', JSON.stringify(token));
                    // console.log('[firebase.js:43] Token armazenado no localStorage.');
                } else {
                    // console.log('[firebase.js:46] Token já existe no localStorage, não é necessário reenviar.');
                }

                return token;
            } else {
                // console.warn('[firebase.js:49] Permissão de notificação negada pelo usuário.');
            }
        } else {
            // console.warn('[firebase.js:52] Usuário não autenticado ou token já gerado. Nenhuma ação necessária.');
        }
    } catch (error) {
        // console.error('[firebase.js:55] Erro ao solicitar permissão de notificação:', error);
    }
};

// Captura mensagens quando o app está em primeiro plano
export const getMessage = () => {
    // console.log('[firebase.js:60] Configurando listener para mensagens em primeiro plano.');
    try {
        onMessage(messaging, (payload) => {
            // console.log('[firebase.js:62] Mensagem recebida em primeiro plano:', payload);
        });
    } catch (error) {
        // console.error('[firebase.js:65] Erro ao configurar listener de mensagens:', error);
    }
};

// Função para registrar o recebimento de notificações no Analytics
export const reportNotification = () => {
    try {
        // console.log('[firebase.js:70] Registrando evento de notificação recebida no Analytics.');
        logEvent(analytics, 'notification_received');
        // console.log('[firebase.js:72] Evento de notificação registrado com sucesso.');
    } catch (error) {
        // console.error('[firebase.js:74] Erro ao registrar evento de notificação no Analytics:', error);
    }
};

// Função para registrar o evento de login no Analytics
export const loginEvent = (method) => {
    try {
        // console.log('[firebase.js:79] Registrando evento de login no Analytics para o usuário:', method.data.user.id_user);
        setUserId(analytics, method.data.user.id_user);
        logEvent(analytics, 'login', { method: method.data.user.id_user });
        // console.log('[firebase.js:82] Evento de login registrado com sucesso.');
    } catch (error) {
        // console.error('[firebase.js:84] Erro ao registrar evento de login no Analytics:', error);
    }
};

export default firebaseApp;

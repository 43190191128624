import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HomeActions } from '../../../redux/ducks/home';
import { Divider } from '@material-ui/core';
import SectionModule from '../../../components/SectionModule';
import SectionCategoryGrid from '../../../components/SectionCategoryGrid';
import CategoryItem from '../../Learn/Trail/components/CategoryItem';
import EmptyContent from '../../../components/EmptyContent';
import { LoadingCategoryGrid } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function TrailSection(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const trails = useSelector(state => state.home.trails);
    const [didMount, setDidMount] = useState(false);
    const limit = 10;

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            if (config.home.trail === "themes") {
                dispatch(HomeActions.getTrailThemesList({
                    limit: limit,
                    page: 1
                }));
            } else {
                dispatch(HomeActions.getTrailList({
                    limit: limit,
                    page: 1
                }));
            }
        }

        return () => {
            setDidMount(false);
        }
    }, [didMount, dispatch]);

    const Section = (props) => {
        const { children } = props;

        return (
            <SectionModule
                iconType="play"
                iconSectionColor="section-play-trail"
                icon="trail"
                title={intl.get("TRAIL_TITLE")}
                subtitle={intl.get("TRAIL_SUBTITLE_SECTION")}
                action={() => history.push('/trail')}
            >
                {children}
            </SectionModule>
        )
    }

    const SectionThemesContent = () => {
        return (
            <Section>
                <SectionCategoryGrid action={() => history.push('/trail')}>
                    {
                        trails.data.map((item, index) => {
                            return (
                                <CategoryItem
                                    key={index}
                                    action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course_theme}/list`, state: { theme: item, origin: "home" } })}
                                    type="themes"
                                    bgColor={item.bg_color}
                                    image={item.category_image ? item.category_image : item.image}
                                    title={item.title}
                                />
                            )
                        })
                    }
                </SectionCategoryGrid>
            </Section>
        )
    }

    const SectionContent = () => {
        return (
            <Section>
                <SectionCategoryGrid action={() => history.push('/trail')}>
                    {
                        trails.data.map((item, index) => {
                            return (
                                <CategoryItem
                                    key={index}
                                    action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/trail/${item.id_course}`, state: { origin: "home" } })}
                                    bgColor={item.bg_color}
                                    image={item.category_image ? item.category_image : item.image}
                                    title={item.title}
                                    currentStars={item.current_stars}
                                    totalStars={item.total_stars}
                                    currentStep={item.current_step}
                                    totalStep={item.total_step}
                                    complete={item.complete}
                                    obligatory={item.obligatory}
                                    available={item.available}
                                    dependency={item.dependency_course_name ? item.dependency_course_name : null}
                                    dateRelease={item.date_course_release ? item.date_course_release : null}
                                />
                            )
                        })
                    }
                </SectionCategoryGrid>
            </Section>
        )
    }

    return (
        trails && trails.load === "finished" ?
            !trails.error ?
                trails.data.length !== 0 ?
                    <div>
                        <Divider variant="middle" />
                        {
                            config.home.trail === "themes" ?
                                <SectionThemesContent />
                                :
                                <SectionContent />
                        }
                    </div>
                    :
                    config.home.empty_modules_visible ?
                        <div>
                            <Divider variant="middle" />
                            <Section>
                                <EmptyContent
                                    type="section"
                                    icon={<Icon ic="play_trail" className="icon-xxl" />}
                                    text={intl.get("EMPTY_TRAIL")}
                                    padding={true}
                                />
                            </Section>
                        </div>
                        : null
                :
                <div>
                    <Divider variant="middle" />
                    <Section>
                        <LoadingCategoryGrid action={() => history.push('/trail')} limit={limit} />
                    </Section>
                </div>
            :
            <div>
                <Divider variant="middle" />
                <Section>
                    <LoadingCategoryGrid action={() => history.push('/trail')} limit={limit} />
                </Section>
            </div>
    )
};

import React, { useEffect, useState } from 'react';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { currentLocale } from '../../../router/Router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as PeriodicActions } from '../../../redux/ducks/periodic';
import { getPeriodicTitle } from '../../../utils/utils';
import moment from 'moment';
import QrLayout from '../../../components/QrLayout2';
import { AppBar, Toolbar, Container, Typography, Grid, IconButton, Divider } from '@material-ui/core';
import DaysOfWeek from './components/DaysOfWeek';
import PlayLatest from './components/PlayLatest';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingPeriodic } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function PeriodicDaily(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const list = useSelector(state => state.periodic.list);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [didMount, setDidMount] = useState(false);
    const [hasData, setHasData] = useState(false);
    const [loadingData, setLoadindData] = useState(false);
    const [havePreviousMonth, setHavePreviousMonth] = useState(false);
    moment.locale(currentLocale);

    useEffect(() => {
        setDidMount(true);

        if (didMount && !hasData) {
            dispatch(PeriodicActions.getList({
                initial_date: moment(date).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                final_date: moment(date).endOf('month').format('YYYY-MM-DD'),
            }));
        }

        return () => {
            dispatch(PeriodicActions.reset());
            setHasData(true);
            setDidMount(false);
        }
    }, [didMount, dispatch, date, hasData]);

    function goBack() {
        let currentOrigin = "periodic";

        switch (origin) {
            case 'question':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'result':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/learn`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    async function getNewMonth(month) {
        setLoadindData(true);
        await dispatch(PeriodicActions.getList({
            initial_date: moment(month).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            final_date: moment(month).endOf('month').format('YYYY-MM-DD'),
        }));
        setLoadindData(false);
    }

    function changeMonth(action) {
        switch (action) {
            case "previous":
                setDate(moment(date).subtract(1, 'months').format('YYYY-MM-DD'));
                getNewMonth(moment(date).subtract(1, 'months').format('YYYY-MM-DD'));
                break;
            case "next":
                setDate(moment(date).add(1, 'months').format('YYYY-MM-DD'));
                getNewMonth(moment(date).add(1, 'months').format('YYYY-MM-DD'));
                break;
            default:
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {getPeriodicTitle(auth.modules.PERIODIC_QUESTION.SCHEDULE)}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const HeaderCalendar = () => {
        return (
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs align="left">
                    {
                        havePreviousMonth ?
                            <IconButton onClick={() => changeMonth('previous')}>
                                <Icon ic="arrow_left" />
                            </IconButton>
                            : null
                    }
                </Grid>
                <Grid item xs align="center">
                    <Typography variant="h5" component="h5" noWrap>
                        {moment(date).format("MMMM YYYY")}
                    </Typography>
                </Grid>
                <Grid item xs align="right">
                    {
                        moment(date).format("YYYY-MM") < moment().format("YYYY-MM") ?
                            <IconButton onClick={() => changeMonth('next')}>
                                <Icon ic="arrow_right" />
                            </IconButton>
                            : null
                    }
                </Grid>
            </Grid>
        )
    }

    const ContentCalendar = () => {
        let days = [];
        let weeks = [];
        let pos = list.data.length - 1;
        let init = parseInt(list.data[pos].schedule[0].weekday);
        let totalDaysInMonth = parseInt(moment(date).daysInMonth());
        let totalPositions = totalDaysInMonth + init;
        let totalWeeks = Math.ceil(totalPositions / 7);
        let totalDays = totalWeeks * 7;

        setTimeout(() => setHavePreviousMonth(list.data.length > 1 ? true : false), 0);

        for (let i = 0; i < totalDays; i++) {
            if (init > 0) {
                if (i < init) {
                    days[i] = { display: "", weekday: i.toString() };
                } else {
                    days[i] = list.data[pos].schedule[i - init] ? list.data[pos].schedule[i - init] : { display: "", weekday: [(i + (init - 1)) - totalDaysInMonth].toString() };
                }
            } else {
                days[i] = list.data[pos].schedule[i] ? list.data[pos].schedule[i] : "";
            }
        }

        for (let i = 0; i < totalWeeks; i++) {
            weeks[i] = days.slice((i * 7), (i + 1) * 7);
        }

        return (
            weeks.map((item, index) => {
                return (
                    <Grid key={index} container justify="center" className="pt-2 pb-2">
                        {
                            item.map((day, i) => {
                                return (
                                    <Grid key={i} item xs align="center">
                                        {
                                            day.display ?
                                                <IconButton
                                                    aria-label={day.display}
                                                    className={`MuiButton-root MuiButton-outlined 
                                                    btn-calendar${day.question && (day.question.answered === "Y" || day.question.answered_by_sms === "Y") ? day.question.answered_right === "Y" ? " btn-outline-success" : " btn-outline-danger" : ""}`}
                                                    disabled={!day.question}
                                                    onClick={() => history.push({
                                                        pathname: `${props.match.path.replace(/./g, '')}/periodic/${day.question.id_question}/question`,
                                                        state: {
                                                            origin: 'periodic',
                                                            date: day.question.question_date
                                                        }
                                                    })}
                                                >
                                                    {day.display}
                                                </IconButton>
                                                :
                                                null
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            })
        )
    }

    return (
        <QrLayout id="periodic-daily">
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="md" className="pt-3 pb-4">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={10} xl={12}>
                        <HeaderCalendar />
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={10} xl={12}>
                        <DaysOfWeek />
                    </Grid>
                    {
                        !loadingData && (list && list.load === "finished") ?
                            !list.error ?
                                <>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <ContentCalendar />
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <PlayLatest />
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12} sm={10} xl={12}>
                                    <ErrorContent type="section" />
                                </Grid>
                            :
                            <LoadingPeriodic type={auth.modules.PERIODIC_QUESTION.SCHEDULE} />
                    }
                </Grid>
            </Container>
        </QrLayout>
    )
}
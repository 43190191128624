import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import ClassroomList from './list.classroom';
import ClassroomDetails from './details';

import ClassroomTicket from './ticket';
import ClassroomCourseEvaluation from './rating';
import Survey from '../../../components/Survey';

export default function ClassroomCourse(props) {
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();

    useEffect(() => {
        if (auth.authenticated === false || (auth.modules.CLASSROOM_COURSE && auth.modules.CLASSROOM_COURSE.ENABLED === 'N')) {
            history.replace('/');
        }
    }, [auth, history]);

    return (
        auth.authenticated &&
        <Switch>
            <Route path={`${props.match.path}`} exact component={(props) =>
                <ClassroomList {...props} />}
            />
            <Route path={`${props.match.path}/:idClassroomCourse`} exact component={(props) =>
                <ClassroomDetails {...props} />}
            />

            <Route path={`${props.match.path}/:idClassroomCourse/curso`} exact component={(props) =>
                <ClassroomCourseEvaluation {...props} />}
            />

            <Route path={`${props.match.path}/:idClassroomCourse/ticket/:idClass`} exact component={(props) =>
                <ClassroomTicket {...props}
                />}
            />

            <Route path={`${props.match.path}/:idClassroomCourse/survey`} exact component={
                (props) => <Survey {...props} />}
            />
        </Switch>
    )
};

import * as React from "react"

function LogoMainColor(props) {
    return (
        <svg width={352} height={128} viewBox="0 0 352 128" {...props}>
            <g fill="none" fill-rule="evenodd">
                <path d="m103.948 91.016-11.925 7.323c-.628.369-1.255.554-1.946.554a3.535 3.535 0 0 1-1.757-.431c-1.193-.616-1.883-1.785-1.883-3.139V80.677c0-1.292.69-2.461 1.883-3.138 1.192-.616 2.573-.616 3.703.123l11.925 7.323c1.067.677 1.695 1.784 1.695 3.016 0 1.23-.628 2.4-1.695 3.014Zm8.285-19.939C109.659 66.707 104.89 64 99.743 64H88.194c-5.147 0-9.916 2.708-12.49 7.077l-5.774 9.846a13.94 13.94 0 0 0 0 14.154l5.774 9.846c2.574 4.37 7.344 7.077 12.49 7.077h11.612c5.147 0 9.916-2.708 12.49-7.077l5.774-9.846a13.94 13.94 0 0 0 0-14.154l-5.837-9.846Z" fill="#00B74F" />
                <path d="M132 74.577h3.526v5.037c1.404-1.914 3.019-3.35 4.845-4.307 1.827-.957 3.865-1.435 6.114-1.435 3.923 0 7.28 1.402 10.07 4.206 2.791 2.803 4.186 6.178 4.186 10.123 0 4.03-1.382 7.45-4.147 10.263-2.765 2.812-6.101 4.218-10.008 4.218-2.198 0-4.22-.453-6.063-1.36-1.843-.906-3.509-2.258-4.997-4.054V112H132V74.577Zm14.206 2.695c-3.078 0-5.67 1.066-7.775 3.198-2.106 2.132-3.159 4.785-3.159 7.958 0 2.082.47 3.954 1.408 5.616.939 1.662 2.283 2.98 4.034 3.954 1.75.974 3.598 1.46 5.542 1.46 1.911 0 3.708-.49 5.391-1.473 1.683-.982 3.023-2.354 4.02-4.117.999-1.763 1.497-3.61 1.497-5.54 0-1.948-.494-3.795-1.484-5.541-.989-1.746-2.32-3.102-3.995-4.067-1.674-.966-3.5-1.448-5.48-1.448ZM167.342 64h3.551v37.977h-3.551V64Zm38.918 10.577v27.4h-3.5v-4.71c-1.489 1.797-3.159 3.149-5.01 4.055-1.852.907-3.877 1.36-6.076 1.36-3.906 0-7.242-1.406-10.007-4.218-2.765-2.812-4.148-6.233-4.148-10.263 0-3.945 1.395-7.32 4.186-10.123 2.79-2.804 6.147-4.206 10.07-4.206 2.267 0 4.317.478 6.152 1.435 1.835.957 3.446 2.393 4.833 4.307v-5.037h3.5Zm-14.205 2.695c-1.979 0-3.805.482-5.48 1.448-1.674.965-3.006 2.32-3.995 4.067-.99 1.746-1.484 3.593-1.484 5.54 0 1.931.499 3.778 1.497 5.54.997 1.764 2.338 3.136 4.02 4.118a10.565 10.565 0 0 0 5.416 1.474c1.945 0 3.788-.487 5.53-1.461 1.742-.974 3.083-2.292 4.021-3.954.939-1.662 1.408-3.534 1.408-5.616 0-3.173-1.053-5.826-3.158-7.958-2.106-2.132-4.697-3.198-7.775-3.198Zm19.715-2.695h3.755l9.487 21.104 9.208-21.104H238L221.537 112h-3.755l5.327-12.088-11.339-25.335Z" fill="#00B74F" fill-rule="nonzero" />
                <path d="m85.744 31.375-3.905-.507c-4.08-.514-6.528-1.783-6.528-4.543 0-3.126 2.973-5.123 7.587-5.123 4.436 0 8.727 1.706 10.857 3.198l2.53-4.615c-3.264-2.304-8.126-3.743-13.448-3.743-8.196 0-13.55 3.992-13.55 10.61 0 5.233 3.652 8.722 11.195 9.676l3.902.473c4.58.581 6.778 2.178 6.778 4.722 0 3.417-3.407 5.272-8.941 5.272-5.634 0-8.994-1.674-11.523-3.67L68 47.554c4.436 3.417 10.043 4.434 14.193 4.434 9.19 0 14.937-4.252 14.937-10.827 0-5.716-4.22-8.805-11.386-9.787m40.912 4.865c0 7.048-4.055 10.174-9.555 10.174s-9.58-3.126-9.58-10.174V16.727h-5.82V36.79c0 10.427 7.275 15.186 15.4 15.186 8.055 0 15.362-4.76 15.362-15.186l.01-20.063h-5.817V36.24Zm40.082-15.196v-4.317h-27.853v5.439h20.4l-21.004 24.78v4.317h28.777v-5.39h-21.077l20.757-24.829Zm20.61 25.377c-6.915 0-11.6-5.56-11.6-12.426 0-6.87 4.578-12.394 11.6-12.389 6.814 0 11.569 5.304 11.569 12.389 0 7.085-4.755 12.426-11.568 12.426Zm11.214-25.015c-2.661-3.268-6.597-5.379-11.884-5.379-9.482 0-16.862 7.562-16.862 17.989S177.206 52 186.678 52c5.287 0 9.223-2.106 11.884-5.342v4.604h5.819V16.728h-5.819v4.678ZM229.184 16c-3.905 0-8.269 1.343-11.04 5.232v-4.506H212.4v34.537h5.768V31.896c0-6.43 4.544-10.247 9.973-10.247 5.868 0 9.273 3.744 9.273 10.1v19.514h5.818v-21.45c0-8.285-5.747-13.808-14.047-13.813m36.965 30.421c-6.775 0-11.884-5.197-11.884-12.426 0-7.236 5.143-12.389 11.884-12.389 6.744 0 11.89 5.16 11.89 12.389 0 7.229-5.112 12.426-11.89 12.426m0-30.412c-10.22 0-17.81 7.743-17.81 17.986s7.59 17.984 17.81 17.984c10.223 0 17.85-7.739 17.85-17.984 0-10.248-7.627-17.986-17.85-17.986" fill="#0046AD" />
            </g>
        </svg>
    )
}

export default LogoMainColor

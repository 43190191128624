import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import intl from 'react-intl-universal';
import config from '../../config';
import * as yup from 'yup';
import { actions as FaqActions } from '../../redux/ducks/faq';
import {
    Container, Grid, TextField, Button,
    AppBar, Toolbar, IconButton, Typography
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import QrLayout from '../../components/QrLayout2';
import SnackBar from '../../components/snackBar';
import { LoadingBounce } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';
import ImgHeaderContact from '../../dist/img/svgr/ImgHeaderContact';

const validations = yup.object().shape({
    subject: yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
    message: yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
})

export default function Contact(props) {
    const { history } = useReactRouter();
    const dispatch = useDispatch();
    const [snackOpen, setSnackOpen] = useState(false);
    const [status, setStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const faq = useSelector(state => state.faq);
    let user = {};

    if (localStorage.getItem('auth.state.user') && localStorage.getItem('auth.state.user').length > 0) {
        user = JSON.parse(localStorage.getItem('auth.state.user'));
    }

    async function sendEmail(data) {
        setLoading(true);
        await dispatch(FaqActions.sendEmail({
            name: user ? user.fname : "",
            email: user ? user.email : "",
            subject: data.subject,
            message: data.message
        }))
        setLoading(false);
    }

    if (faq && (faq.sendEmail && (faq.sendEmail.status && faq.sendEmail.status.length > 0))) {
        setLoading(false);
        setSnackOpen(true);
        setStatus(faq.sendEmail.status);

        if (faq.sendEmail.status === "success") {
            setSnackMessage(intl.get("SUCCESS_SENT_MESSAGE"));
        } else {
            setSnackMessage(faq.sendEmail.messages);
        }

        faq.sendEmail.status = "";
        faq.sendEmail.massages = "";
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("FAQ_CONTACT_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <QrLayout>
            {
                config.layout.navbar ?
                    <QrAppBar />
                    : null
            }
            <Container maxWidth="md" className="pt-4 pb-4">
                <Formik
                    validationSchema={validations}
                    initialValues={{
                        subject: '',
                        message: '',
                    }}
                    onSubmit={(data) => { sendEmail(data) }}
                >
                    {
                        ({ handleBlur, handleChange, handleSubmit, errors, touched }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Grid container justify="center" spacing={3}>
                                    <Grid item xs={12} sm={10} xl={12} align="center">
                                        <ImgHeaderContact />
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <Typography variant="body2" component="p" className="fs-md">
                                            {intl.get("FAQ_CONTACT_TEXT_1")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <Typography variant="body2" component="p">
                                            {intl.get("FAQ_CONTACT_TEXT_2")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <TextField
                                            id="subject"
                                            name="subject"
                                            label={intl.get('LABEL_SUBJECT')}
                                            type="text"
                                            fullWidth
                                            required
                                            variant={config.layout.input_variant}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={(errors.subject && touched.subject)}
                                            helperText={(errors.subject && touched.subject) && errors.subject}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10} xl={12}>
                                        <TextField
                                            id="message"
                                            name="message"
                                            label={intl.get('LABEL_MESSAGE')}
                                            multiline
                                            fullWidth
                                            required
                                            rows={4}
                                            variant={config.layout.input_variant}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={(errors.message && touched.message)}
                                            helperText={(errors.message && touched.message) && errors.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        {
                                            !loading
                                                ?
                                                <Button
                                                    id="btn_settings_send"
                                                    variant="contained"
                                                    className="btn-block"
                                                    type="submit"
                                                    onSubmit={() => this.onSubmit()}
                                                >
                                                    {intl.get('BTN_SEND')}
                                                </Button>
                                                :
                                                <Grid container alignItems="center" justify="center">
                                                    <LoadingBounce />
                                                </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }
                </Formik>
            </Container>
            <SnackBar
                open={snackOpen}
                message={snackMessage}
                status={status}
                time={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                closeSnack={() => setSnackOpen(false)}
            />
        </QrLayout>
    )
}
import React from 'react';
import ReactPlayer from 'react-player';
import { linkVideoParser } from '../utils/utils';
import CardMedia from '@material-ui/core/CardMedia';
import config from '../config';

export default function QrVideoPlayer(props) {
    const { link } = props;

    function isOldPlayer(link) {
        return link.includes("microsoftstream") || link.includes("kaltura") || link.includes("qrbrscorm");
    }

    return (
        <div>
            {
                isOldPlayer(link) ?
                    <CardMedia
                        component="iframe"
                        src={linkVideoParser(link)}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    :
                    <ReactPlayer
                        height="0px"
                        config={{
                            file: {
                                attributes: {
                                    controlsList: config.layout.videos.download ? '' : 'nodownload',
                                    disablePictureInPicture: !config.layout.videos.picture_in_picture,
                                    crossOrigin: true
                                },
                            }
                        }}
                        url={linkVideoParser(link)}
                        controls
                        volume={1}
                    />
            }
        </div>
    )
}

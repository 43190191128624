import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale';

// A custom theme for this app

export default createMuiTheme({
    palette: {
        primary: {
            light: '#346bcb', // Blue[200]
            main: '#1e5bc6', // Blue[300]
            dark: '#1b51b2', // Blue[400]
            contrastText: '#fff',
        },
        secondary: {
            light: '#ec4a51', // Red[200]
            main: '#e81e26', // Red[300]
            dark: '#d01b22', // Red[400]
            contrastText: '#fff',
        },
        error: {
            light: '#828282', // Gray[600]
            main: '#707070', // Gray[700]
            dark: '#5e5e5e', // Gray[800]
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        button: {
            fontWeight: 'bold',
        },
    }
}, ptBR)
